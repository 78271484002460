import React from 'react';
import classnames from 'classnames';

import ContextMenuHandler from 'other/utils/ContextMenuHandler';
import { isIPhone } from 'components';
import { L } from 'types';

/**/
export type AbstractCardProps<T> = {
  location: T;
  map: L.Map;
  onClose: (id: number) => void;
  onPage: (id: number) => void;
  showPicture: boolean;
  zoom: number;
};

// @ts-ignore
abstract class AbstractCard<T, Props> extends React.PureComponent<Props<T>> {
  protected container?: HTMLElement;

  public static readonly CLASS_NAMES = {
    CLOSE: 'MapEntityHeader__close',
    EXTRA: 'MapEntityCard2__extra',
    FAV: 'MapEntityPhoto__fav',
    MIN: 'MapEntityHeader__minimize',
    PHOTO: 'MapEntityPhoto',
    PLAY: 'MapEntityPlayButton__play',
    TEXT: 'MapEntityText',
    TEXT_ROW: 'FarmToolTip__row'
  };

  protected static POPUP_OPTIONS = {
    autoClose: false,
    autoPan: false,
    closeButton: false,
    closeOnEscapeKey: false,
    closeOnClick: false,
    pane: 'popupPane'
  };

  /**/
  protected static getFigure(src: string, name: string): string {
    return `
      <fig class="${AbstractCard.CLASS_NAMES.PHOTO}">
        <img class="MapEntityPhoto__photo" src="${encodeURI(src)}" alt="${name}" />
      </fig>
    `;
  }

  /**/
  public static getHeader(
    name: string,
    country: string,
    closable?: boolean,
    collapsible?: boolean
  ): string {
    if (!name || !country) return '';

    const cls = collapsible
      ? 'MapEntityHeader--shortest'
      : closable
        ? 'MapEntityHeader--shorter'
        : '';

    return `
      <section class="MapEntityHeader ${cls}">
        <div class="MapEntityHeader__info">
          <img class="MapEntityHeader__flag" src="/assets/flags/${country}.png" alt="Flag" />
          
          <h3 class="app-subtitle-2 ellipsis MapEntityHeader__name">${name}</h3>
        </div>
        
        ${AbstractCard.getMinButton(collapsible)}
        ${AbstractCard.getCloseButton(closable)}
      </section>
    `;
  }

  /**
   *
   */
  private static getCloseButton(closable?: boolean): string {
    if (!closable) return '';
    return '<a class="MapEntityHeader__close" title="Close"></a>';
  }

  /**
   *
   */
  private static getMinButton(collapsible?: boolean): string {
    if (!collapsible) return '';
    return '<a class="MapEntityHeader__minimize" title="Minimize"></a>';
  }

  /**/
  protected static getFav(isInFavourites: boolean): string {
    const cls = classnames(
      AbstractCard.CLASS_NAMES.FAV,
      'icon-custom',
      isInFavourites ? 'icon-star-filled' : 'icon-star'
    );

    return `<span class="${cls}" />`;
  }

  protected popup: L.Popup;

  /**/
  componentDidMount() {
    this.create();
  }

  /**/
  componentWillUnmount() {
    this.popup?.remove();
  }

  /**/
  protected abstract getInfo(): string;

  /**/
  protected abstract getContent(): HTMLElement;

  /**/
  protected getPopupOptions() {
    return AbstractCard.POPUP_OPTIONS;
  }

  /**/
  protected create(): void {
    const { location, map } = this.props;

    this.popup = new window.L['Popup2'](this.getPopupOptions())
      .setLatLng([location.latitude, location.longitude])
      .setContent(this.getContent())
      .addTo(map);
  }

  /**/
  protected getWrapper(): HTMLElement {
    const popup = document.createElement('DIV');
    popup.classList.add('MapEntityCard2');
    popup.addEventListener('click', this.handleCardClick, false);
    popup.addEventListener('contextmenu', this.handleContext, false);
    popup.addEventListener('touchend', this.handleCardClick, false);

    if (isIPhone()) {
      const contextMenuHandler = new ContextMenuHandler(this.handleContext);
      popup.addEventListener('touchcancel', contextMenuHandler.clearTimer, true);
      popup.addEventListener('touchend', contextMenuHandler.clearTimer, true);
      popup.addEventListener('touchmove', contextMenuHandler.clearTimer, true);
      popup.addEventListener('touchstart', contextMenuHandler.onTouchStart, true);
    }

    return popup;
  }

  /**/
  private handleClose = () => {
    const { location, onClose } = this.props;
    this.popup.remove();
    onClose(location.id);
  };

  /**/
  protected handleCardClick = (e: Event) => {
    const { location, onFav, onPage } = this.props;
    const { CLOSE, FAV, MIN, PHOTO, PLAY, TEXT, TEXT_ROW } =
      AbstractCard.CLASS_NAMES;

    const target = e.target as any;
    const cls = (name) => `.${name}`;
    e.stopPropagation();

    if (target.closest(cls(FAV))) {
      return onFav(location.id);
    }
    if (
      target.closest(cls(PHOTO)) ||
      target.closest(cls(TEXT)) ||
      target.closest(cls(TEXT_ROW))
    ) {
      return onPage(location.id);
    }
    if (target.closest(cls(PLAY))) {
      return this.handlePlayClick();
    }
    if (target.closest(cls(CLOSE))) {
      return this.handleClose();
    }
    if (target.closest(cls(MIN))) {
      return this.handleMinimize();
    }
  };

  /**/
  private handleContext = (e: Event) => {
    const { PHOTO, TEXT, TEXT_ROW } = AbstractCard.CLASS_NAMES;
    const target = e.target as any;
    const cls = (name) => `.${name}`;
    e.preventDefault();

    if (
      target.closest(cls(PHOTO)) ||
      target.closest(cls(TEXT)) ||
      target.closest(cls(TEXT_ROW))
    ) {
      this.onNewTab();
    }
  };

  /**/
  protected handleMinimize = () => Function.prototype();

  /**/
  protected handlePlayClick = () => Function.prototype();

  /**/
  protected onNewTab = () => Function.prototype();

  /**/
  render() {
    return null;
  }
}

/**/
export default AbstractCard;
