import React from 'react';
import { createRoot } from 'react-dom/client';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';

import './index.scss';
import App from './App';
import { runSessionService } from './services';
import { store, history } from './store';
import Tracker from './components/Tracker/Tracker';

runSessionService();

const app = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>

    <Tracker />
  </Provider>
);

const root = createRoot(document.getElementById('app'));
root.render(app);
