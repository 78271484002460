import { ECompaniesActions, fetchSet } from './companiesConstants';
import { ENDPOINTS, stringComparator } from 'other';
import { http, SettingsService, THttpResponse } from 'services';

import { TCompaniesFilter, TCompany } from 'types';
import { TState } from 'store';

export function fetchCompaniesAction() {
  return (dispatch, getState) => {
    const { companies } = getState() as TState;
    if (companies.companies.length > 0) return;

    dispatch(fetchSet.request());

    http
      .send(ENDPOINTS.COMPANIES)
      .then(({ data }: THttpResponse<TCompany[]>) => {
        data.sort((a: TCompany, b: TCompany) =>
          stringComparator(a.name, b.name)
        );

        dispatch(fetchSet.success({ companies: data as any }));
      })
      .catch((e) => dispatch(fetchSet.error(e)));
  };
}

/**
 * Defines whether current settings are different from the initial ones
 * disregarding `isFilterSet` field value. Relates to the Companies page.
 * @param settings
 */
function isFilterSet(settings: TCompaniesFilter): boolean {
  const { companyValue, countryValue, fleetValue } = settings;
  return (
    companyValue.length > 0 || countryValue.length > 0 || fleetValue.length > 0
  );
}

/**
 * Sets filter for companies
 * @param value
 * @returns {Function}
 */
export function setCompaniesFilterAction(value: Partial<TCompaniesFilter>) {
  return (dispatch, getState) => {
    const {
      companies: { filterSettings }
    } = getState() as TState;

    // It's called 'partial' because we still need to update 'isFilterSet'.
    const partialUpdate: TCompaniesFilter = {
      ...filterSettings,
      ...value
    };

    const update: TCompaniesFilter = {
      ...partialUpdate,
      isFilterSet: isFilterSet(partialUpdate)
    };

    dispatch({
      payload: { filterSettings: update },
      type: ECompaniesActions.SET_FILTER
    });

    SettingsService.writeSettings({
      [SettingsService.COMPANIES_FILTER]: update
    });
  };
}

/**
 * Resets filter settings, related to the Service list page.
 */
export function resetCompaniesFilterAction() {
  return (dispatch, getState) => {
    const { companies } = getState() as TState;
    const update = {
      ...companies.filterSettings,
      companyValue: [],
      countryValue: [],
      fleetValue: [],
      isFilterSet: false
    };

    SettingsService.writeSettings({
      [SettingsService.COMPANIES_FILTER]: update
    });

    dispatch({
      payload: { filterSettings: update },
      type: ECompaniesActions.RESET_FILTER
    });
  };
}
