import { createSelector } from 'reselect';

import { ENV } from 'other';
import { ESupportedApps, TSelectOption, TVesselType } from 'types';

const transformNestedArray = (
  options: TVesselType[]
): TSelectOption<number>[] => {
  if (!options || options.length === 0) return [];

  return (
    options
      .filter(({ supportedApps }: TVesselType) =>
        supportedApps.includes(ENV.APP_NAME.toUpperCase() as ESupportedApps)
      )
      .flatMap(({ id, value, subTypes }: TVesselType) => {
        const subs =
          subTypes && subTypes.length ? transformNestedArray(subTypes) : [];

        return subs.concat([{ label: value.en_GB, value: id }]);
      })
      // Remove 'Fishing vessel' parent type.
      .filter(({ value }: TSelectOption<number>) => value !== 1)
  );
};

export const selectVesselTypesOptions = createSelector(
  (options: TVesselType[]): TVesselType[] => options,
  transformNestedArray
);
