import { GA, shouldRunTracking } from 'services';

/**
 *
 */
class ImpressionTracker {
  private readonly articleId: number;
  private readonly elem: HTMLDivElement;
  private observer: IntersectionObserver;

  constructor(elem: HTMLDivElement, articleId: number) {
    if (!window.IntersectionObserver) return;

    this.articleId = articleId;
    this.elem = elem;
    this.initializeObserver();
  }

  private initializeObserver(): void {
    if (!shouldRunTracking()) return;

    const options = {
      root: null,
      threshold: 0.85
    };

    this.observer = new IntersectionObserver(this.handleIntersection, options);
    this.observer.observe(this.elem);
  }

  private handleIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach(
      (entry: IntersectionObserverEntry) =>
        entry.isIntersecting && GA.reportNewsImpression(this.articleId)
    );
  };
}

export default ImpressionTracker;
