import { push } from 'connected-react-router';

import { areEqual, deepClone, ENDPOINTS, ROUTES, SUB_POINTS } from 'other';
import { BannerUploader } from './BannerUploader';
import {
  buildQueryString,
  campaignInitialState,
  TAction,
  TCampaignState,
  TState
} from 'store';
import {
  calcSet,
  campaignBlueprint,
  createSet,
  ECampaignActions,
  fetchArticles,
  fetchProducts,
  fetchReportSet,
  removeSet,
  submitSet,
  uploadSet
} from './campaignConstants';
import { createPromoBodyCore, TPromoOptions } from '../promo/promoActions';
import { fetchServiceCategoriesAction } from '../provider/providers/providersActions';
import { http } from 'services';
import { selectVesselTypesOptions } from 'components';

import {
  EBannerType,
  ECampaignFormStep,
  EPromotedType,
  TArticle,
  TBannerSet,
  TFormFields,
  TMedia,
  TPaymentReport,
  TPromotion,
  TPromotionCost,
  TProvider
} from 'types';
import { THttpResponse } from 'services';

const uploadProgressAction = (
  type: EBannerType,
  uploadProgress: number
): TAction<TCampaignState, ECampaignActions> => ({
  type: ECampaignActions.BANNER_UPLOAD_PROGRESS,
  payload: {
    [type]: { uploadProgress: uploadProgress }
  }
});

/**
 * Creates a campaign blueprint, so as to bind the banners to.
 */
export function createCampaignBlueprintAction() {
  return (dispatch) => {
    dispatch(createSet.request());

    http
      .send({
        body: campaignBlueprint,
        method: 'POST',
        url: ENDPOINTS.PROMOTION
      })
      .then(({ data }: THttpResponse<TPromotion>) =>
        dispatch(createSet.success({ promotion: data }))
      )
      .catch((e: Error) => dispatch(createSet.error(e)));
  };
}

export function removeCampaignAction(id: number) {
  return (dispatch) => {
    dispatch(removeSet.request());

    http
      .send({
        method: 'DELETE',
        url: `${ENDPOINTS.PROMOTION}/${id}`
      })
      ?.then(() => {
        dispatch(removeSet.success());
        dispatch(clearCampaignAction());
        dispatch(push(ROUTES.NEWS));
      })
      .catch((e: Error) => dispatch(removeSet.error(e)));
  };
}

export function clearCampaignAction() {
  return (dispatch, getStatus) => {
    const {
      campaign: { promotion }
    } = getStatus() as TState;

    isModified(promotion) && dispatch(removeCampaignAction(promotion.id));
    dispatch(resetStateAction());
  };
}

export const resetStateAction = (): TAction<
  TCampaignState,
  ECampaignActions
> => ({
  type: ECampaignActions.CLEAR_CAMPAIGN,
  payload: campaignInitialState
});

export const clearReportAction = (): TAction<
  TCampaignState,
  ECampaignActions
> => ({
  type: ECampaignActions.CLEAR_REPORT,
  payload: { report: null }
});

export function updateFieldsAction(update: TFormFields) {
  return (dispatch, getState) => {
    const { campaign } = getState() as TState;
    dispatch({
      type: ECampaignActions.UPDATE_FIELD,
      payload: {
        fields: {
          ...campaign.fields,
          ...update
        }
      }
    });
  };
}

/** Uploads a banner image */
export function uploadBannerAction(file: File, type: EBannerType) {
  return (dispatch, getState) => {
    const {
      campaign: { promotion }
    }: TState = getState();

    const onStart = (type: EBannerType) => {
      dispatch(uploadSet.request());
      dispatch(uploadProgressAction(type, 0));
    };

    const onProgress = (type: EBannerType, progress: number) =>
      dispatch(uploadProgressAction(type, progress));

    const onSuccess = (type: EBannerType, data: TMedia) => {
      const payload = {
        [type]: {
          data: data,
          uploadProgress: null
        }
      };
      dispatch(uploadSet.success(payload));
      dispatch(checkSteps());
    };

    const onError = (type: EBannerType, message: string) => {
      dispatch(uploadProgressAction(type, null));
      dispatch(uploadSet.error(new Error(`${type} banner: ${message}`)));
    };

    new BannerUploader(type, onStart, onProgress, onSuccess, onError).upload(
      file,
      promotion.id
    );
  };
}

export function calculateCostAction(fields: TFormFields) {
  return (dispatch, getState) => {
    const {
      campaign,
      dictionaries: { promotionCountries, vesselTypes },
      providers: { categories }
    } = getState() as TState;

    dispatch(updateFieldsAction(fields));
    if (campaign.isPending) return;

    const options = {
      countries: promotionCountries,
      services: categories,
      vesselTypes: selectVesselTypesOptions(vesselTypes)
    } as any;

    const body = createCampaignBody(fields, options);
    const url = `${ENDPOINTS.PROMOTION_COST}?${buildQueryString(body)}`;
    dispatch(calcSet.request());

    http
      .send(url)
      .then(({ data }: THttpResponse<TPromotionCost>) =>
        dispatch(calcSet.success({ cost: data }))
      )
      .catch((e: Error) => dispatch(calcSet.error(e)));
  };
}

function createCampaignBody(
  fields: TFormFields,
  options: TPromoOptions
): Partial<TPromotion> {
  const body = createPromoBodyCore(fields, options);
  return {
    ...body,
    ...(body.bannerUrl && { bannerUrl: body.bannerUrl[0] }),
    type: EPromotedType.BANNER
  };
}

export function checkSteps(forceNext?: boolean) {
  return (dispatch, getState) => {
    const {
      campaign: {
        [EBannerType.MOBILE]: mobile,
        [EBannerType.MENU]: menu,
        [EBannerType.NEWS]: news,
        [EBannerType.FILTERS]: filters
      }
    } = getState() as TState;
    let step;
    const banners = [mobile, menu, news, filters];

    if (forceNext) {
      step = ECampaignFormStep.RECAP;
    } else {
      const numberOfBanners = banners.reduce((sum, item) => {
        return item.data ? sum + 1 : sum;
      }, 0);
      step =
        numberOfBanners < 4 ? ECampaignFormStep.UPLOAD : ECampaignFormStep.FORM;
    }

    dispatch({
      type: ECampaignActions.ALLOW_FORM_STEP,
      payload: { availableStep: step }
    });
  };
}

export function fetchTargetsAction() {
  return (dispatch, getState) => {
    const { session } = getState() as TState;
    const providerId: number = session.user.userInfo.serviceProvidersId[0];
    dispatch(fetchArticlesAction(providerId));
    dispatch(fetchProductsAction(providerId));
    dispatch(fetchServiceCategoriesAction());
  };
}

export function fetchArticlesAction(providerId: number) {
  return (dispatch) => {
    dispatch(fetchArticles.request());

    http
      .send(`${ENDPOINTS.NEWS}${SUB_POINTS.PROVIDER}/${providerId}`)
      .then(({ data }: THttpResponse<TArticle[]>) =>
        dispatch(fetchArticles.success({ articles: data }))
      )
      .catch((e) => dispatch(fetchArticles.error(e)));
  };
}

export function fetchProductsAction(providerId: number) {
  return (dispatch) => {
    dispatch(fetchProducts.request());

    http
      .send(`${ENDPOINTS.SERVICE_PROVIDER}/${providerId}`)
      .then(({ data }: THttpResponse<TProvider>) =>
        dispatch(
          fetchProducts.success({
            products: (data.products || []).sort((a, b) => b.id - a.id)
          })
        )
      )
      .catch((e) => dispatch(fetchProducts.error(e)));
  };
}

export function submitCampaignAction() {
  return (dispatch, getState) => {
    const {
      campaign: { fields, promotion },
      dictionaries: { promotionCountries, vesselTypes },
      providers: { categories }
    } = getState() as TState;

    const options = {
      countries: promotionCountries,
      services: categories,
      vesselTypes: selectVesselTypesOptions(vesselTypes)
    } as any;

    dispatch(submitSet.request());

    http
      .send({
        body: createCampaignBody(fields, options),
        method: 'PUT',
        url: `${ENDPOINTS.PROMOTION}/${promotion.id}`
      })
      .then(() => {
        dispatch(submitSet.success());
        dispatch(checkSteps(true));
        dispatch(changeStepAction(ECampaignFormStep.RECAP));
      })
      .catch((e) => dispatch(submitSet.error(e)));
  };
}

export function payForCampaignAction() {
  return (dispatch, getState) => {
    const {
      campaign: { promotion }
    } = getState() as TState;

    const url = `${ENDPOINTS.PROMOTION}/${promotion.id}${SUB_POINTS.PAYMENTS}`;
    const body = {
      redirectUrl: `${window.location.origin}${ROUTES.CAMPAIGN_SELECT}?promoId=${promotion.id}`
    };

    http
      .send({
        body: body,
        method: 'POST',
        url: url
      })
      .then(({ data }: THttpResponse<TPaymentReport>) => {
        window.location.href = decodeURIComponent(data.checkoutLink);
      })
      .catch(window.console.error);
  };
}

function isModified(promotion: TPromotion): boolean {
  if (!promotion) return false;

  const { id, ...fields } = deepClone(promotion);
  Object.entries(promotion).forEach(([key, value]) => {
    if (!value) {
      delete fields[key];
    }
  });
  return !areEqual(fields, campaignInitialState);
}

export function fetchReportAction(promoId: string) {
  return (dispatch) => {
    const url = `${ENDPOINTS.PROMOTION}/${promoId}${SUB_POINTS.PAYMENTS}`;
    dispatch(fetchReportSet.request());

    http
      .send(url)
      .then(({ data }: THttpResponse<TPaymentReport[]>) =>
        dispatch(
          fetchReportSet.success({
            report: data[0] || null
          })
        )
      )
      .catch((e) => dispatch(fetchReportSet.error(e)));
  };
}

export const assignCampaignsAction = (
  campaigns: TBannerSet
): TAction<TCampaignState, ECampaignActions> => ({
  type: ECampaignActions.ASSIGN_CAMPAIGNS,
  payload: { campaigns: campaigns }
});

export function changeStepAction(step: ECampaignFormStep) {
  return (dispatch, getState) => {
    const {
      campaign: { availableStep }
    } = getState() as TState;

    if (step > availableStep) return;

    dispatch({
      type: ECampaignActions.SET_FORM_STEP,
      payload: { currentStep: step }
    });
  };
}
