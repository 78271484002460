import React from 'react';
import { IconButton } from 'components';
import { TMouseHandler } from 'types';

/**/
type Props = {
  /** name of a CSS class. */
  className?: string;
  /** click event callback.*/
  onClick: TMouseHandler;
  /** an HTML attribute.*/
  title?: string;
};

/**
 *
 */
const CloseButton: React.FC<Props> = ({
  className,
  onClick,
  title = 'Cancel'
}) => (
  <IconButton
    className={className}
    data-testid="CloseButton"
    icon="close"
    onClick={onClick}
    size="S"
    title={title}
  />
);

/**/
export { CloseButton };
