import React from 'react';
import classnames from 'classnames';
import throttle from 'lodash.throttle';

import './GotoTopButton.scss';
import { ActionButton } from '../ActionButton/ActionButton';
import { APP_CONTENT } from 'other';

/**/
type Props = {
  className?: string;
  elementId?: string;
  onClick?: (e) => void;
};
/**/
type State = { isVisible: boolean };

/**
 *
 */
class GotoTopButton extends React.PureComponent<Props, State> {
  private element: HTMLElement;
  private static defaultProps: Partial<Props> = {
    elementId: APP_CONTENT
  };

  state: State = { isVisible: false };

  /**/
  handleScroll = (e: Event) => {
    const isVisible = this.element.scrollTop > e.target['offsetHeight'];
    this.state.isVisible !== isVisible &&
      this.setState({ isVisible: isVisible });
  };

  /**/
  onScroll = throttle(this.handleScroll, 300);

  /**/
  componentDidMount() {
    this.element = document.getElementById(this.props.elementId);
    this.element.addEventListener('scroll', this.onScroll);
  }

  /**/

  /**/
  componentWillUnmount() {
    this.element?.removeEventListener('scroll', this.onScroll);
  }

  /**/
  handleClick = () =>
    this.element?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

  /**/
  render() {
    const { className, onClick } = this.props;
    const cls = classnames('GotoTopButton', className);
    if (!this.state.isVisible) return null;

    return (
      <ActionButton
        className={cls}
        icon="arrow-left"
        size="L"
        onClick={onClick || this.handleClick}
      />
    );
  }
}

/**/
export { GotoTopButton };
