import message from 'antd/lib/message';
import { push } from 'connected-react-router';

import * as farms from './farmsLocations/farmsLocationsActions';
import * as vessels from './vesselsLocations/vesselsLocationsActions';
import { assignEntries } from '../player/playerActions';
import { ENV, ERR_MESSAGE_DURATION, isNumber, ROUTES } from 'other';
import {
  purgeTracksAction,
  removeTrackPointsAction
} from './tracks/tracksActions';
import { resetMapEntitiesAction } from './mapEntities/mapEntitiesActions';
import { updateMapOptionsAction } from './mapOptions/mapOptionsActions';

import { TState } from 'store';
import { TVesselLocation, TVesselTrack } from 'types';

export function watchLocationsAction(preserveData?: boolean) {
  return (dispatch) => {
    dispatch(vessels.watchLocationsAction(preserveData));
    ENV.IS_AF && dispatch(farms.watchLocationsAction(preserveData));
  };
}

export function unwatchLocationsAction() {
  return (dispatch) => {
    dispatch(vessels.unwatchLocationsAction());
    ENV.IS_AF && dispatch(farms.unwatchLocationsAction());
  };
}

export function checkUrlParamsAction() {
  return (dispatch, getState) => {
    // @ts-ignore
    const { router } = getState() as TState;
    const query = router.location.query;
    const { lat, lng, zoom } = query;
    const _zoom = parseInt(zoom);

    if (
      typeof lat === 'undefined' &&
      typeof lng === 'undefined' &&
      typeof zoom === 'undefined'
    ) {
      return;
    }

    if (!isLatLngValid(lat, lng)) {
      return message.error(
        'Wrong latitude or longitude value!',
        ERR_MESSAGE_DURATION
      );
    }

    if (!isNumber(_zoom)) {
      return message.error('Wrong zoom value!', ERR_MESSAGE_DURATION);
    }

    dispatch(
      updateMapOptionsAction({
        center: new window.L.LatLng(parseFloat(lat), parseFloat(lng)),
        zoom: parseInt(zoom)
      })
    );
  };
}

/**/
function isLatLngValid(lat: string, lng: string): boolean {
  const _lat = parseFloat(lat);
  const _lng = parseFloat(lng);
  if (!isNumber(_lat) || !isNumber(_lng)) {
    return false;
  }

  return _lat >= -90 && _lat <= 90 && _lng >= -180 && _lng <= 180;
}

/**/
export function goToPlayer(vesselId: number) {
  return (dispatch, getState) => {
    const { tracks, vesselsLocations } = getState() as TState;
    const track = tracks.tracks.find(
      (t: TVesselTrack) => t.vesselId === vesselId
    );

    const vessel =
      vesselsLocations.locations?.find(
        (l: TVesselLocation) => l.id === vesselId
      ) || ({} as any);

    dispatch(assignEntries(track?.locations, vessel));
    dispatch(push(`${ROUTES.PLAYER}/${vesselId}`));
  };
}

/**/
export function clearMapEntriesAction() {
  return (dispatch) => {
    dispatch(resetMapEntitiesAction());
    dispatch(purgeTracksAction());
    dispatch(removeTrackPointsAction());
  };
}
