import React from 'react';

import './HistoricalCatchControls.scss';
import AbstractHCSelector from './AbstractHCSelector';

/**
 *
 */
class EquipmentSelector extends AbstractHCSelector {
  protected icon = 'fishing-gear';
  protected label = 'Equipment';
}

/**
 *
 */
class SpecieSelector extends AbstractHCSelector {
  protected icon = 'biolytics';
  protected label = 'Specie';
}

/**
 *
 */
class VesselSelector extends AbstractHCSelector {
  protected icon = 'vessel';
  protected label = 'Vessels';
}

/**
 *
 */
class WeightSelector extends AbstractHCSelector {
  protected icon = 'tote';
  protected label = 'Weight';
}

/**/
export { EquipmentSelector, SpecieSelector, VesselSelector, WeightSelector };
