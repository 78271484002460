import React from 'react';
import throttle from 'lodash.throttle';

import './TimeLine.scss';
import { isIPad } from '../../../../helpers/helpers';
import { isTouchDevice } from 'other';

/**/
type Props = {
  // The final point value.
  maxValue: number;
  // Triggered on the slider drag stop.
  onChange: (value: number) => void;
  value?: number;
};

/**
 *
 */
class TimeLine extends React.PureComponent<Props> {
  private static HANDLE_WIDTH = 20; // Equals the `div.TimeControl__point` width.
  private static LINE_THICKNESS_IN_HALF = 2; // .TimeControl__line height / 2.

  private handleChange = throttle(this.props.onChange, 50);
  private line = React.createRef<HTMLDivElement>();
  private point = React.createRef<HTMLDivElement>();
  private shiftX = 0;

  /**/
  getLineWidth(): number {
    return Math.round(
      this.line.current.getBoundingClientRect().width - TimeLine.HANDLE_WIDTH
    );
  }

  /**/
  getLeftOffset(): number {
    const { maxValue, value } = this.props;
    const _value = value > maxValue ? maxValue : value;

    if (!this.line.current || !this.point.current) {
      return 0;
    }

    const x = (this.getLineWidth() * _value) / maxValue;
    return Math.round(x < 0 ? 0 : x);
  }

  /**/
  moveAt(pageX: number): void {
    const line = this.line.current;
    if (!line) return;

    const left = pageX - this.shiftX - TimeLine.HANDLE_WIDTH / 2;
    const value = (left * this.props.maxValue) / this.getLineWidth();
    this.handleChange(Math.floor(value));
  }

  /**/
  handleMouseDown = (e) => {
    let clientX = e.clientX;
    let pageX = e.pageX;
    const deltaX =
      this.line.current.parentElement.getBoundingClientRect().left +
      TimeLine.LINE_THICKNESS_IN_HALF;

    if (e.touches && e.touches[0]) {
      clientX = e.touches[0].clientX;
      pageX = e.touches[0].pageX;

      document.addEventListener('touchmove', this.handleDrag);
      document.addEventListener('touchend', this.handleEnd);
    } else if (isIPad()) {
      document.addEventListener('mousemove', this.handleDrag);
      document.addEventListener('mouseup', this.handleEnd);
      document.addEventListener('touchmove', this.handleDrag);
      document.addEventListener('touchend', this.handleEnd);
    } else {
      document.addEventListener('mousemove', this.handleDrag);
      document.addEventListener('mouseup', this.handleEnd);
    }

    this.shiftX =
      clientX - this.point.current.getBoundingClientRect().left + deltaX;
    this.moveAt(pageX);
  };

  /**/
  handleDrag = (e) => {
    const pageX = 'pageX' in e ? e.pageX : e.touches && e.touches[0]?.pageX;
    e.stopPropagation();
    this.moveAt(pageX);
  };

  /**/
  handleEnd = (e) => {
    this.shiftX = 0;
    document.removeEventListener('mousemove', this.handleDrag);
    document.removeEventListener('mouseup', this.handleEnd);
    document.removeEventListener('touchmove', this.handleDrag);
    document.removeEventListener('touchend', this.handleEnd);
  };

  /**/
  getHandler() {
    if (isIPad()) {
      return {
        onMouseDown: this.handleMouseDown,
        onTouchStart: this.handleMouseDown
      };
    } else if (isTouchDevice()) {
      return {
        onTouchStart: this.handleMouseDown
      };
    }

    return {
      onMouseDown: this.handleMouseDown
    };
  }

  /**/
  render() {
    const pointStyle = { left: this.getLeftOffset() + 'px' };
    const lineStyle = {
      right: this.line.current
        ? this.getLineWidth() - this.getLeftOffset() + 'px'
        : '100%'
    };

    return (
      <section className="TimeControl">
        <div className="TimeControl__line" ref={this.line} title="Drag me">
          <div
            className="TimeControl__point"
            ref={this.point}
            style={pointStyle}
            {...this.getHandler()}
          />

          <div className="TimeControl__progress" style={lineStyle} />
        </div>
      </section>
    );
  }
}

/**/
export { TimeLine };
