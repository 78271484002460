export type TScrollValue = {
  path: string;
  scroll: number;
};

/**/
class ScrollService {
  /**/
  public static set(value: number) {
    const item: TScrollValue = {
      path: window.location.pathname,
      scroll: value
    };
    sessionStorage.setItem('scroll', JSON.stringify(item));
  }

  /**/
  public static get(): TScrollValue {
    const str = sessionStorage.getItem('scroll');
    if (!str) return null;

    try {
      return JSON.parse(str);
    } catch (e) {
      window.console.error(e);
    }
  }
}

/**/
export { ScrollService };
