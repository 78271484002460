import React from 'react';

import { APP_CONTENT, ENV, ROUTES } from 'other';
import {
  BurgerAside,
  CookieConsentCustom,
  Header,
  RouteSwitch
} from 'components';
import { CollapseAside } from '../Aside/CollapseAside';

/**/
type Props = {
  isAsideOpen: boolean;
  isLoggedIn: boolean;
  pathname: string;
  toggleAside: () => void;
};

/**
 *
 */
function MainContainer(props: Props) {
  const { isAsideOpen, isLoggedIn, pathname, toggleAside } = props;
  const items = isLoggedIn ? ENV.NAVIGATION : ENV.NAVIGATION_ANON;
  const hideAside = !isLoggedIn && pathname === ROUTES.HOME;

  return (
    <>
      <Header
        isAsideOpen={isAsideOpen}
        isLoggedIn={isLoggedIn}
        toggleAside={toggleAside}
      />

      <div className="Main__container">
        <BurgerAside {...props} items={items} />

        {/*@ts-ignore*/}
        {hideAside || <CollapseAside {...props} items={items} />}

        <div className="Main__content" id={APP_CONTENT}>
          <RouteSwitch isLoggedIn={isLoggedIn} />
          <CookieConsentCustom />
        </div>
      </div>
    </>
  );
}

/**
 *
 */
export { MainContainer };
