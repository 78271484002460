import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Flag, TooltipCustom } from 'components';
import { ROUTES, toTitleCase } from 'other';

type Props = {
  flagIso?: string;
  itemId: number;
  itemText: string;
  hoverText?: string;
  link?: string;
};

const LinkWithFlag: FC<Props> = ({
  flagIso,
  hoverText,
  itemId,
  itemText,
  link
}) => {
  const title = hoverText || itemText;

  return (
    <>
      {flagIso && <Flag flagIso={flagIso} />}

      <TooltipCustom hideTooltipForMobiles={true} title={toTitleCase(title)}>
        <Link to={`${link}/${itemId}`}>{toTitleCase(itemText)}</Link>
      </TooltipCustom>
    </>
  );
};

LinkWithFlag.propTypes = {
  flagIso: PropTypes.string,
  hoverText: PropTypes.string,
  itemId: PropTypes.number.isRequired,
  itemText: PropTypes.string.isRequired,
  link: PropTypes.string
};

LinkWithFlag.defaultProps = {
  link: ROUTES.VESSEL
};

export { LinkWithFlag };
