import React from 'react';
import { isApp, isIPhone, isIPad } from '../../helpers/helpers';
import { EPlayMode, EPlayRate } from './types';

/**/
export type TTimeEngineState = {
  currentIndex: number;
  maxIndex: number;
  playMode: EPlayMode;
  playRate: EPlayRate;
};

export type TTimeEngineProps = {
  onAfterPlay?: () => void;
  onAfterStop?: () => void;
};

/**
 *
 */
function getUpdateMultiplier(): number {
  if (isIPad()) return 20;
  if (isApp() && isIPhone()) return 5;
  return 1;
}

/**
 *
 */
class TimeEngine extends React.PureComponent<any, TTimeEngineState> {
  static defaultProps = {
    onAfterPause: Function.prototype,
    onAfterPlay: Function.prototype,
    onAfterStop: Function.prototype
  };

  static INTERVAL = 40;
  static UPDATE_MULTIPLIER = getUpdateMultiplier();
  // Defines the playback speed:
  // 1000 / UPDATE_INTERVAL == frames per second.
  static UPDATE_INTERVAL = TimeEngine.UPDATE_MULTIPLIER * TimeEngine.INTERVAL;
  // Timeout ID.
  private timer;

  state: TTimeEngineState = {
    currentIndex: 0,
    maxIndex: 0,
    playMode: EPlayMode.STOP,
    playRate: EPlayRate.NORMAL
  };

  play = (): void => {
    const { currentIndex, maxIndex, playRate } = this.state;
    let cIndex = currentIndex;

    if (currentIndex === 0 || currentIndex + playRate > maxIndex) {
      cIndex = -1;
    }

    this.tick({
      currentIndex: cIndex,
      playMode: EPlayMode.PLAY
    });
    this.timer =
      this.timer || setInterval(this.tick, TimeEngine.UPDATE_INTERVAL);

    this.props.onAfterPlay();
  };

  private tick = (state: Partial<TTimeEngineState> = {}): void => {
    const { currentIndex, maxIndex, playMode, playRate } = this.state;
    const rate = playRate * TimeEngine.UPDATE_MULTIPLIER;
    const cIndex = state.currentIndex || currentIndex;

    if (playMode === EPlayMode.PLAY && cIndex + rate > maxIndex) {
      return this.stop({
        ...state,
        currentIndex: maxIndex
      });
    }

    this.update({
      ...state,
      currentIndex: cIndex + rate
    });
  };

  stop = (state: Partial<TTimeEngineState> = {}): void => {
    this.destroy();
    this.update({
      ...state,
      playMode: EPlayMode.STOP
    });
    this.props.onAfterStop();
  };

  pause = (state: Partial<TTimeEngineState> = {}): void => {
    this.destroy();
    this.update({
      ...state,
      playMode: EPlayMode.PAUSE
    });
    this.props.onAfterPause();
  };

  setPlayRate = (rate: EPlayRate): void =>
    this.update({
      playRate: rate
    });

  setMaxIndex = (maxIndex: number): void =>
    this.update({
      maxIndex: maxIndex
    });

  setCurrentIndex = (index: number): void => {
    const { maxIndex } = this.state;
    let currentIndex;

    if (index < 0 || !index) {
      currentIndex = 0;
    } else if (index > maxIndex) {
      currentIndex = maxIndex;
    } else {
      currentIndex = index;
    }
    this.update({ currentIndex: currentIndex });
  };

  destroy = (): void => {
    clearInterval(this.timer);
    this.timer = null;
  };

  private update(state: Partial<TTimeEngineState>): void {
    // console.log(JSON.stringify({ ...this.state, ...state }, null, 2));
    this.setState(state as any);
  }

  render() {
    return null;
  }
}

/**/
export { TimeEngine };
