import React, { FC, ReactElement } from 'react';

import './TrialThankYou.scss';
import { ENV } from 'other';

const TrialThankYou: FC = (): ReactElement => {
  const src = `/assets/logo/Logo-black-${ENV.APP_ABBR}.svg`;

  return (
    <div className="TrialThankYou">
      <h2 className="TrialThankYou__title">Thank You!</h2>
      <p>We will send you username and password shortly by e-mail </p>
      <img
        alt={ENV.APP_NAME}
        className="TrialThankYou__img"
        height="22"
        src={src}
        width="130"
      />
    </div>
  );
};

export { TrialThankYou };
