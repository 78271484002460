import React, { ReactElement, FC, memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Overlay404.scss';
import { LoaderOverlay } from 'components';
import Page404 from 'pages/Page404/Page404';

type Props = {
  children: ReactElement | ReactElement[];
  className?: string;
  isLoading: boolean;
  isNotFound: boolean;
};

const Component: FC<Props> = ({
  className,
  children,
  isNotFound,
  isLoading
}) => {
  const cls: string = classnames('Overlay404', className);

  return isNotFound ? (
    <Page404 />
  ) : (
    <LoaderOverlay className={cls} isLoading={isLoading} isTransparent={true}>
      {children}
    </LoaderOverlay>
  );
};

Component.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isNotFound: PropTypes.bool.isRequired
};

export const Overlay404 = memo(Component);
