import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './PromoButton.scss';
import { IconCustom } from 'components/common/IconCustom/IconCustom';
import { PrimaryButton } from 'components';
import { PROMO_SEARCH_VALUE, ROUTES } from 'other';
import { EPromoStatus } from 'types';

/**/
type Props = {
  articleId: number;
  size?: 'default' | 'small';
  status?: EPromoStatus;
};

const Icon = <IconCustom className="PromoButton__icon" type="plus" />;

/**
 *
 */
const PromoButton: FC<Props> = ({ articleId, size, status }) => {
  const to = `${ROUTES.NEWS_EDITOR}/${articleId}?q=${PROMO_SEARCH_VALUE}`;
  const style = {
    fontSize: size === 'small' ? '14px' : '18px'
  };

  if (!status || status === EPromoStatus.UNABLE) {
    return null;
  }
  if (status === EPromoStatus.FAILED) {
    return (
      <div className="PromotedArticle__label PromoButton__label--failure">
        Payment&nbsp;failed
      </div>
    );
  }
  if (status === EPromoStatus.PROMOTED) {
    return (
      <div className="PromotedArticle__label PromoButton__label--success">
        Promoted
      </div>
    );
  }

  return (
    <Link className="PromoButton__wrapper" to={to}>
      <PrimaryButton className="PromoButton" size="XS" style={style}>
        <IconCustom type="plus" />
        Promote
      </PrimaryButton>
    </Link>
  );
};

/**/
PromoButton.propTypes = {
  articleId: PropTypes.number.isRequired,
  size: PropTypes.oneOf(['default', 'small']),
  status: PropTypes.oneOf(Object.values(EPromoStatus))
};

export { PromoButton };
