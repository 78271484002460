import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import './ArticleBanner.scss';
import {
  ArticleLogo,
  BannerMenuOverlay,
  CommonBanner,
  PopoverGroup
} from 'components';
import { TArticleSource } from 'types';

/**/
type Props = {
  canEdit: boolean;
  imgUrl: string;
  isDraft: boolean;
  onEdit: (event) => void;
  onRemove: (event) => void;
  source: TArticleSource;
};

/**
 *
 */
const ArticleBanner: React.FC<Props> = ({
  canEdit,
  imgUrl,
  isDraft,
  onEdit,
  onRemove,
  source
}): ReactElement => {
  const items = [
    { handler: onEdit, label: 'Edit' },
    { handler: onRemove, label: 'Delete' }
  ];

  const actions = <PopoverGroup items={items} />;

  return (
    <div className="ArticleBanner">
      <CommonBanner className="" isDraft={isDraft} src={imgUrl} />
      <ArticleLogo source={source} />

      {canEdit && <BannerMenuOverlay actions={actions} />}
    </div>
  );
};

/**/
ArticleBanner.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  imgUrl: PropTypes.string.isRequired,
  isDraft: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  // @ts-ignore
  source: PropTypes.object.isRequired
};
export { ArticleBanner };
