import React, { PureComponent, ReactElement } from 'react';
import PropTypes from 'prop-types';
import message from 'antd/lib/message';

import {
  addNote,
  CrmSection,
  deleteConfirm,
  deleteNote,
  editNote,
  EmptyList,
  getNotes,
  LoaderOverlay,
  NoteCard,
  NoteForm
} from 'components';
import { PLACEHOLDER, updateArrayItem } from 'other';

import { EEntityType, TNote } from 'types';

type Props = {
  entityId: number;
  entityType: EEntityType;
};

type State = {
  notes: TNote[];
  editableNote: TNote;
  isLoading: boolean;
};

class Notes extends PureComponent<Props, State> {
  static propTypes;

  state: State = {
    editableNote: null,
    isLoading: true,
    notes: []
  };

  async componentDidMount(): Promise<void> {
    const { entityId, entityType } = this.props;

    try {
      const notes = await getNotes(entityId, entityType);

      this.setState({ isLoading: false, notes: notes.reverse() });
    } catch (e) {
      this.handleError(e);
    }
  }

  handleError = ({ message: errorMessage }: Error): void => {
    this.setState({ isLoading: false });
    message.error(errorMessage);
  };

  handleFormSubmit = async (formData: Partial<TNote>): Promise<void> => {
    const { editableNote, notes } = this.state;
    const { entityType: type, entityId: targetId } = this.props;
    this.setState({ isLoading: true });

    try {
      let updatedNotes;

      if (editableNote) {
        const editedNote = await editNote({
          ...editableNote,
          ...formData,
          targetId,
          type
        });
        updatedNotes = updateArrayItem(editedNote, notes);
      } else {
        const addedNote = await addNote({ ...formData, targetId, type });
        updatedNotes = [addedNote, ...this.state.notes];
      }

      this.setState({
        editableNote: null,
        isLoading: false,
        notes: updatedNotes
      });
    } catch (e) {
      this.handleError(e);
    }
  };

  handleFormClose = (): void => this.setState({ editableNote: null });

  deleteNote = async (noteId: number): Promise<void> => {
    this.setState({ isLoading: true });

    try {
      await deleteNote(noteId);
      const notes = this.state.notes.filter(({ id }) => id !== noteId);

      this.setState({ isLoading: false, notes });
    } catch (e) {
      this.handleError(e);
    }
  };

  handleRemove = (noteId: number): void => {
    deleteConfirm('Remove this note permanently?', () =>
      this.deleteNote(noteId)
    );
  };

  renderNoteForm = (hideModal: () => void): ReactElement => (
    <NoteForm
      hideModal={hideModal}
      initialValues={this.state.editableNote}
      onFormClose={this.handleFormClose}
      onFormSubmit={this.handleFormSubmit}
    />
  );

  handleEdit = (noteId: number): void =>
    this.setState({
      editableNote: this.state.notes.find(({ id }) => id === noteId)
    });

  render(): ReactElement {
    const { isLoading, editableNote, notes } = this.state;
    const modalTitle = editableNote === null ? 'add note' : 'edit note';
    const notesList = (hideModal, showModal) => {
      if (notes?.length > 0) {
        return notes.map((note) => (
          <NoteCard
            key={note.id}
            note={note}
            showModal={showModal}
            onEdit={this.handleEdit}
            onRemove={this.handleRemove}
          />
        ));
      }

      return (
        <EmptyList
          placeholder="Add your first note"
          placeholderImgUrl={PLACEHOLDER.LOCATION_CARD}
        />
      );
    };

    return (
      <LoaderOverlay isLoading={isLoading} isTransparent={true}>
        <CrmSection
          addButtonTitle="Add note"
          modalContent={this.renderNoteForm}
          modalTitle={modalTitle}
        >
          {notesList}
        </CrmSection>
      </LoaderOverlay>
    );
  }
}

Notes.propTypes = {
  entityId: PropTypes.number.isRequired,
  entityType: PropTypes.oneOf(Object.keys(EEntityType)).isRequired
};

export { Notes };
