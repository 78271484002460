import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './ArticleTitle.scss';
import { getDate, ROUTES } from 'other';
import { TArticleSource } from 'types';

type Props = {
  source?: TArticleSource;
  time: string;
  title: string;
};

const ArticleTitle = ({ source, time, title }: Props) => {
  const date: string = getDate(time);
  const issuerPageUrl: string = source
    ? `${ROUTES.SERVICE_PROVIDER}/${source.id}`
    : null;

  const info: ReactNode = source && (
    <div className="ArticleTitle__created app-overline-1">
      Posted by{' '}
      <Link
        className="ArticleTitle__link app-overline-1--underline"
        to={issuerPageUrl}
      >
        {source.name}
      </Link>
      {date && <span>, {date}</span>}
    </div>
  );

  return (
    <>
      <h1 className="app-h1">{title}</h1>
      {info}
    </>
  );
};

ArticleTitle.propTypes = {
  source: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }),
  time: PropTypes.string,
  title: PropTypes.string
};
export { ArticleTitle };
