import { createAsyncActions, TCompaniesState } from 'store';

export enum ECompaniesActions {
  FETCH_COMPANIES = 'companies/FETCH_COMPANIES',
  SET_FILTER = 'companies/SET_FILTER',
  RESET_FILTER = 'companies/RESET_FILTER'
}

export const fetchSet = createAsyncActions<TCompaniesState>(
  ECompaniesActions.FETCH_COMPANIES
);
