import React, { FC, ReactElement, useState } from 'react';
import PropTypes from 'prop-types';
import Collapse, { CollapseProps } from 'antd/lib/collapse';

import './ShowMoreLess.scss';
import { IconCustom } from 'components';

type Props = {
  children?: ReactElement;
  onChange?: (isExpanded: boolean) => void;
};

/**
 *
 */
const ShowMoreLess: FC<Props> = ({ children, onChange }): ReactElement => {
  const [isActive, setActive] = useState(false);
  const header = isActive ? 'Show less' : 'Show all';

  const expandIcon = () => <IconCustom type="chevron-up" />;

  const handleChange = () => {
    setActive(!isActive);
    typeof onChange === 'function' && onChange(!isActive);
  };

  const items: CollapseProps['items'] = [
    {
      key: 'ShowMoreLessPanel',
      label: header,
      children: children
    }
  ];

  return (
    <Collapse
      className="ShowMoreLess"
      expandIcon={expandIcon}
      ghost={true}
      items={items}
      onChange={handleChange}
    />
  );
};

/**/
ShowMoreLess.propTypes = {
  children: PropTypes.element,
  onChange: PropTypes.func
};

export { ShowMoreLess };
