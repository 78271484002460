import { History, Location } from 'history';
import dayjs from 'dayjs';

import { API_URL, ENDPOINTS, ENV, queryString } from 'other';
import { getToken } from '../../../services';

export const findQueryInFields = (fields: string[], query: string): boolean =>
  fields.some(
    (text) => text && text.toLowerCase().includes(query.toLowerCase().trim())
  );

export const addKeyAndReverse = <T extends { id: string | number }>(
  items: T[]
): Array<T & { key: string }> =>
  items.map((item: T) => ({ ...item, key: item.id.toString() })).reverse();

export function updateQueryString(
  fieldValue: any,
  fieldName: string,
  history: History,
  location: Location
): void {
  const parsed = queryString.parse(location.search);
  parsed[fieldName] = fieldValue;

  history.replace({
    search: queryString.build(parsed)
  });
}

export function getQueryString<T extends Record<string, any>>(
  location: Location
): T {
  return queryString.parse(location.search) as any;
}

/**/
export function exportProposals(ids: number[]): void {
  fetch(`${API_URL}${ENDPOINTS.CRM_PROPOSALS}/export`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      'X-Application': ENV.APP_NAME.toUpperCase(),
      'X-Auth-Token': getToken()
    },
    body: JSON.stringify(ids)
  })
    .then((resp: any) => {
      const { status, statusText, url } = resp;
      if (!/^2/.test(status)) {
        throw Error(`${status} ${statusText} "${url}"`);
      }
      return resp.blob();
    })
    .then((blob: Blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = dayjs().format('YYYY_MMM_DD_HH:mm_CRM_Proposals') + '.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(window.console.error);
}
