import React from 'react';
import { ButtonProps } from 'antd/lib/button';

import './ActionButtonReached.scss';
import { AppButton } from '../helpers';
import { IconCustom } from '../../IconCustom/IconCustom';

/**/
type Props = Omit<ButtonProps, 'icon' | 'size'> & {
  iconLeft?: string;
  iconRight?: string;
  size?: 'S' | 'M';
};

/**
 *
 */
export function ActionButtonReached(props: Props) {
  const { children, iconLeft, iconRight, ...rest } = props;

  return (
    <AppButton
      mainClassName="ActionButtonReached"
      shape="round"
      size="L"
      {...rest}
    >
      {iconLeft && (
        <IconCustom
          className="ActionButtonReached__icon ActionButtonReached__icon--left"
          type={iconLeft}
        />
      )}

      <span className="ActionButtonReached__text">{children}</span>

      {iconRight && (
        <IconCustom
          className="ActionButtonReached__icon ActionButtonReached__icon--right"
          type={iconRight}
        />
      )}
    </AppButton>
  );
}
