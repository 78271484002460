import React, { ReactElement } from 'react';

import './FarmCardBasic.scss';
import { getFarmPlaceholder } from 'components/helpers/utils';
import { ItemCard, TCardItem } from 'components';
import { MEDIA_BASE_URL, ROUTES } from 'other';

import { TFarm, TIdLocationDict, TOwnerShort } from 'types';

type Props = {
  children?: ReactElement;
  item: TFarm;
};

function FarmCardBasic(props: Props) {
  const { children, item } = props;
  const path = `${ROUTES.FARM}/${item.id}`;

  const testProps = {
    'data-owner': item.owners?.map((o: TOwnerShort): number => o.id).join(','),
    'data-flag': item.country,
    'data-species':
      item.species &&
      item.species.map((s: TIdLocationDict): number => s.id).join(','),
    'data-type': item.type?.value.en_GB
  };

  const _item: TCardItem = {
    flag: item.country,
    name: item.name,
    placeholder: getFarmPlaceholder(item.type?.id),
    src:
      item.photo?.thumbnail && `${MEDIA_BASE_URL}/${item.photo.thumbnail.path}`
  };

  return (
    <div className="FarmCardBasic" {...testProps}>
      <ItemCard item={_item} path={path}>
        {children}
      </ItemCard>
    </div>
  );
}

export default FarmCardBasic;
