import React, { ReactElement } from 'react';

import './Page404.scss';
import { ENV, PLACEHOLDER } from 'other';
import { GoBackButton } from 'components';

const Page404 = (): ReactElement => {
  const src = PLACEHOLDER['NOT_FOUND_' + ENV.APP_ABBR];

  return (
    <div className="app-page-text">
      <div className="Page404">
        <img src={src} alt="Page 404" />
        <h1 className="Page404__title app-h1">404</h1>
        <p>Sorry, the requested resource can’t be found</p>
        <GoBackButton />
      </div>
    </div>
  );
};

export default Page404;
