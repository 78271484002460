import { UploadFile } from 'antd/lib/upload/interface';

import { ENDPOINTS } from 'other';
import { http } from 'services';

import { TAttachedFile, TProposal } from 'types';

export const getProposals = async (
  entityId: number,
  entityType: string
): Promise<TProposal[]> => {
  const url = `${ENDPOINTS.CRM_PROPOSALS}?entityId=${entityId}&type=${entityType}`;
  const { data } = await http.send(url);

  return data;
};

export const getProposal = async (proposalId: number): Promise<TProposal> => {
  const url = `${ENDPOINTS.CRM_PROPOSALS}/${proposalId}`;
  const { data } = await http.send(url);

  return data;
};

export const addProposal = async (
  proposal: Partial<TProposal>
): Promise<TProposal> => {
  const { data } = await http.send({
    body: proposal,
    method: 'POST',
    url: ENDPOINTS.CRM_PROPOSALS
  });

  return data;
};

export const editProposal = async (proposal: TProposal): Promise<TProposal> => {
  const { data } = await http.send({
    body: proposal,
    method: 'PUT',
    url: `${ENDPOINTS.CRM_PROPOSALS}/${proposal.id}`
  });

  return data;
};

export const deleteProposal = async (
  proposalId: number
): Promise<TProposal> => {
  const { data } = await http.send({
    method: 'DELETE',
    url: `${ENDPOINTS.CRM_PROPOSALS}/${proposalId}`
  });

  return data;
};

export const addProposalPhoto = async (
  proposalId: number,
  file: UploadFile
): Promise<TProposal> => {
  const formData = new FormData();
  formData.append('file', file.originFileObj);
  formData.append('proposalId', proposalId.toString());

  const { data } = await http.send({
    body: formData,
    method: 'POST',
    url: `${ENDPOINTS.CRM_PROPOSALS}/${proposalId}/file`
  });

  return data;
};

export const deleteProposalPhoto = async (
  proposalId: number,
  { id }: TAttachedFile
): Promise<TProposal> => {
  const { data } = await http.send({
    method: 'DELETE',
    url: `${ENDPOINTS.CRM_PROPOSALS}/${proposalId}/file/${id}`
  });

  return data;
};
