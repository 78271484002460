import React, { FC } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import './RejectReasonIndicator.scss';
import { PROPOSAL_OTHER_REJECT_REASON_ID } from 'other';

import { TState } from 'store';

export const selectRejectReasons = createSelector(
  (state: TState) => state.dictionaries,
  ({ proposalRejectReasons }) => {
    return proposalRejectReasons.reduce(
      (rejectReasons, { id, value: { en_GB } }) => {
        rejectReasons[id] = en_GB;

        return rejectReasons;
      },
      {}
    );
  }
);

type Props = {
  rejectReasonId: number;
  rejectReasonCustom?: string;
};

const RejectReasonIndicator: FC<Props> = ({
  rejectReasonId,
  rejectReasonCustom
}) => {
  const rejectReasons = useSelector(selectRejectReasons);

  if (!rejectReasonId) return null;

  const indicatorClassName = classnames(
    'RejectReasonIndicator',
    `RejectReasonIndicator--${rejectReasonId}`
  );
  const text =
    rejectReasonCustom && rejectReasonId === PROPOSAL_OTHER_REJECT_REASON_ID
      ? rejectReasonCustom
      : rejectReasons[rejectReasonId];

  return <div className={indicatorClassName}>{text}</div>;
};

RejectReasonIndicator.propTypes = {
  rejectReasonId: PropTypes.number,
  rejectReasonCustom: PropTypes.string
};

export default RejectReasonIndicator;
