import React from 'react';
import { ButtonProps } from 'antd/lib/button';

import './IconButtonOutlined.scss';
import { AppButton } from '../helpers';
import { IconCustom } from 'components/common/IconCustom/IconCustom';

/**/
type Props = Omit<ButtonProps, 'icon' | 'size'> & {
  icon: string;
  size?: 'S' | 'M';
};

/**
 *
 */
export function IconButtonOutlined(props: Props) {
  const { icon, ...rest } = props;
  return (
    <AppButton mainClassName="IconButtonOutlined" shape="circle" {...rest}>
      <IconCustom className="IconButtonOutlined__icon" type={icon} />
    </AppButton>
  );
}
