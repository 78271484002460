import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import './CompanyCardDescription.scss';
import { ItemCard, TCardItem } from 'components';
import { PLACEHOLDER, ROUTES } from 'other';
import { TCompanyOfGroup } from 'types';

type Props = {
  imgSrc: string;
  item: TCompanyOfGroup;
};

/**/
const CompanyCardDescription: FC<Props> = ({ imgSrc, item }) => {
  if (!item) return null;

  const path = `${ROUTES.COMPANY}/${item.companyId}`;
  const testProps = {
    'data-flag': item.country?.iso,
    'data-regnumber': item.registrationNumber
  };
  const _item: TCardItem = {
    flag: item.country?.iso,
    name: item.name,
    placeholder: item['isGroup'] ? PLACEHOLDER.GROUP : PLACEHOLDER.COMPANY,
    src: imgSrc
  };

  return (
    <div className="CompanyCardDescription" {...testProps}>
      <ItemCard item={_item} path={path} />

      <div className="CompanyCardDescription__info">
        <strong className="CompanyCard__infoTitle">
          <Link to={path}>{item.name}</Link>
        </strong>

        <p>
          {item.address}
          <br />
          {item.city}
          <br />
          {item.postcode}
          <br />
        </p>
      </div>
    </div>
  );
};

/**/
export { CompanyCardDescription };
