import React, { FC } from 'react';
import PropTypes from 'prop-types';

import './CompanyVessels.scss';
import {
  AddButtonStar,
  ShortVessel,
  TInjectedWithFleetsProps
} from 'components';
import { ENV } from 'other';
import withFleets from 'components/common/vessel/withFleets/withFleets';

import { TVesselOfCompany } from 'types';

/**/
type Props = TInjectedWithFleetsProps & {
  vessels: TVesselOfCompany[];
};

/**
 *
 */
const Component: FC<Props> = ({ favVesselIds, onFavClick, vessels }) => {
  if (!vessels || vessels.length === 0) return null;

  const handleFav = (ids: number[]) => onFavClick({ vessels: ids });

  const handleFavButch = () =>
    handleFav(vessels.map(({ vesselId }: TVesselOfCompany) => vesselId));

  const handleFavSingle = (id: number) => handleFav([id]);

  const vesselsList = vessels.map((vessel) => (
    <ShortVessel
      favVesselIds={favVesselIds}
      key={vessel.vesselId}
      vessel={vessel}
      onFavClick={handleFavSingle}
    />
  ));

  return (
    <div className="CompanyVessels">
      <header className="CompanyVessels__header">
        {ENV.IS_FF && <h2 className="CompanyVessels__title">Vessels</h2>}

        <AddButtonStar
          label={`Add all to my ${ENV.FLEET}`}
          onAdd={handleFavButch}
        />
      </header>

      <div className="CompanyVessels__content">{vesselsList}</div>
    </div>
  );
};

/**/
Component.propTypes = {
  favVesselIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onFavClick: PropTypes.func.isRequired,
  vessels: PropTypes.array
};

export const CompanyVessels = withFleets(Component);
