import { createAsyncActions, TNewsRecentState } from 'store';

export enum ENewsRecentActions {
  NEWS_RECENT_FETCH = 'newsRecent/NEWS_RECENT_FETCH',
  NEWS_RECENT_CLEAR = 'newsRecent/NEWS_RECENT_CLEAR'
}

export const fetchSet = createAsyncActions<TNewsRecentState>(
  ENewsRecentActions.NEWS_RECENT_FETCH
);
