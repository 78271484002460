import { createAsyncActions, TFleetsState } from 'store';

enum EFleetsActions {
  FETCH_FLEET = 'fleets/FETCH_FLEET',
  CREATE_FLEET = 'fleets/CREATE_FLEET',
  REMOVE_FLEET = 'fleets/REMOVE_FLEET',
  RENAME_FLEET = 'fleets/RENAME_FLEET',
  ADD_TO_FLEET = 'fleets/ADD_TO_FLEET',
  REMOVE_FROM_FLEET = 'fleets/REMOVE_FROM_FLEET'
}

export const fetchSet = createAsyncActions<TFleetsState>(
  EFleetsActions.FETCH_FLEET
);
export const createSet = createAsyncActions<TFleetsState>(
  EFleetsActions.CREATE_FLEET
);
export const removeSet = createAsyncActions<TFleetsState>(
  EFleetsActions.REMOVE_FLEET
);
export const addVesselSet = createAsyncActions<TFleetsState>(
  EFleetsActions.ADD_TO_FLEET
);
export const removeVesselSet = createAsyncActions<TFleetsState>(
  EFleetsActions.REMOVE_FROM_FLEET
);
export const renameSet = createAsyncActions<TFleetsState>(
  EFleetsActions.RENAME_FLEET
);
