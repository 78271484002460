import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import Modal from 'antd/lib/modal';

import './ModalWrapper.scss';
import { ModalEditorHeader } from 'components';
import { TMouseHandler } from 'types';

type Props = {
  /** React children or a child. */
  children: any;
  /** visibility flag. */
  isOpen: boolean;
  /** modal props. */
  modalProps?: { [key: string]: string };
  /** cancel click handler. */
  onCancel: TMouseHandler;
  /** the menu title. */
  title: string;
};

const defaultModalProps = {
  closable: false,
  destroyOnClose: true,
  footer: null,
  icon: null,
  maskClosable: true,
  title: null
};

/**
 * Wraps the given component into a modal menu.
 * @param children
 * @param isOpen
 * @param modalProps
 * @param onCancel
 * @param title
 * @constructor
 */
const ModalWrapper = ({
  children,
  isOpen,
  modalProps,
  onCancel,
  title
}: Props): ReactElement => (
  <Modal open={isOpen} {...{ ...defaultModalProps, ...modalProps }}>
    <ModalEditorHeader title={title} onCancel={onCancel} />
    {children}
  </Modal>
);

ModalWrapper.propTypes = {
  isOpen: PropTypes.bool,
  modalProps: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string
};

export { ModalWrapper };
