import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { ROUTES } from '../../other';
import { withAuth } from '../withAuth';

class AuthoredRoute extends React.PureComponent {
  render() {
    const { component: Component, rights, roles, ...rest } = this.props;
    const isAuthored = !!roles.filter((role) => rights.includes(role)).length;

    const render = (props) => {
      const direction = {
        pathname: ROUTES.HOME,
        state: { from: props.location }
      };

      return isAuthored ? (
        <Component {...props} />
      ) : (
        <Redirect to={direction} />
      );
    };

    return <Route render={render} rights={rights} {...rest} />;
  }
}

AuthoredRoute.propTypes = {
  component: PropTypes.any.isRequired,
  rights: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired
};

export default withAuth(AuthoredRoute);
