import React from 'react';
import PropTypes from 'prop-types';

import './SaveCancelGroup.scss';
import { DefaultButton } from '../DefaultButton/DefaultButton';
import { PrimaryButton } from 'components';
import { TMouseHandler } from 'types';

/**/
type Props = {
  /** Save button HTML property. */
  disabled?: boolean;
  /** cancel event callback. */
  onCancel: TMouseHandler;
  /**  submit event callback. */
  onSave?: TMouseHandler;
};

/**
 * `Cancel-Save` button couple.
 * @param disabled
 * @param saveProps
 * @param onCancel
 * @param onSave
 * @constructor
 */
const SaveCancelGroup = ({
  disabled,
  // @ts-ignore
  saveProps,
  onCancel,
  onSave
}: Props) => (
  <div className="SaveCancelGroup">
    <DefaultButton
      className="SaveCancelGroup__button"
      data-testid="SaveCancelGroup__cancelBtn"
      onClick={onCancel}
      size="M"
    >
      Cancel
    </DefaultButton>

    <PrimaryButton
      className="SaveCancelGroup__button"
      data-testid="SaveCancelGroup__saveBtn"
      disabled={disabled}
      htmlType="submit"
      onClick={onSave}
      size="M"
      {...saveProps}
    >
      Save
    </PrimaryButton>
  </div>
);

/**/
SaveCancelGroup.propTypes = {
  disabled: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func
};

export { SaveCancelGroup };
