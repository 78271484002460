import React from 'react';
import Progress from 'antd/lib/progress';
import './ProgressLine.scss';

type Props = {
  position?: 'top' | 'bottom';
  progress?: number;
};

/**
 *
 */
function ProgressLine({ position = 'top', progress }: Props) {
  if (progress === void 0 || progress === null) return null;

  const cls = `ProgressLine ProgressLine--${position}`;

  return (
    <div className={cls}>
      <Progress
        className="ProgressLine__progress"
        percent={progress}
        size="small"
        status="active"
      />
    </div>
  );
}

/**/
export { ProgressLine };
