import React from 'react';
import PropTypes from 'prop-types';

import './PromotionCost.scss';
import { IconCustom, PrimaryButton } from 'components';
import { TPromotionCost } from 'types';

type Props = {
  btnText: string;
  cost?: TPromotionCost;
  form?: string;
  isPending: boolean;
};

const loader = (
  <div className="PromotionCost__cost">
    ...is being calculated
    <br />
    <IconCustom type="loading" />
  </div>
);

/**/
class PromotionCost extends React.PureComponent<Props> {
  static propTypes;

  render() {
    const { btnText, cost, form, isPending } = this.props;
    if (!cost) return null;

    const budget = (
      <div className="PromotionCost__cost">
        <div className="PromotionCost__total">&euro;{cost.amount}</div>
        {cost.rate && (
          <small className="PromotionCost__rate app-body-2">
            &euro;{cost.rate} per day
          </small>
        )}
      </div>
    );

    return (
      <div className="PromotionCost">
        <h3 className="app-h3">Total budget</h3>
        <div className="PromotionCost__budget">
          {isPending ? loader : budget}
        </div>

        <PrimaryButton
          block
          className="app-btn-success"
          disabled={isPending}
          form={form || void 0}
          htmlType="submit"
          loading={isPending}
          size="M"
        >
          {btnText}
        </PrimaryButton>
      </div>
    );
  }
}

/**/
PromotionCost.propTypes = {
  cost: PropTypes.object,
  form: PropTypes.string,
  isCostLoading: PropTypes.bool,
  btnText: PropTypes.string
};

export { PromotionCost };
