import React, { PureComponent, ReactElement } from 'react';
import PropTypes from 'prop-types';
import { UploadFile } from 'antd/lib/upload/interface';

import {
  CrmSection,
  deleteConfirm,
  EmptyList,
  LoaderOverlay,
  ProposalCard,
  ProposalsContext,
  ProposalForm,
  RejectReasonModal,
  TInjectedModalStateProps
} from 'components';
import { PLACEHOLDER } from 'other';
import {
  PTInjectedModalStateProps,
  withModalState
} from '../../withModalState';

import { EProposalStatus, TProposal } from 'types';

type Props = TInjectedModalStateProps & {
  status?: EProposalStatus;
};

type State = {
  editableProposal: TProposal;
};

class Component extends PureComponent<Props, State> {
  static propTypes;
  static contextType = ProposalsContext;
  context: React.ContextType<typeof ProposalsContext>;

  state: State = {
    editableProposal: null
  };

  handleFormSubmit = async (
    formData: Partial<TProposal>,
    fileList: UploadFile[]
  ): Promise<void> => {
    const { editableProposal } = this.state;
    // @ts-ignore
    const { addProposal, editProposal } = this.context;

    editableProposal
      ? await editProposal({ ...editableProposal, ...formData }, fileList)
      : await addProposal(formData as any, fileList);
  };

  handleFormClose = (): void => this.setState({ editableProposal: null });

  handleEdit = (proposal: TProposal): void =>
    this.setState({ editableProposal: proposal });

  handleDelete = ({ id }: TProposal): void => {
    // @ts-ignore
    const { deleteProposal } = this.context;

    deleteConfirm(
      `Remove this ${this.getProposalTypeText()} permanently?`,
      async () => await deleteProposal(id)
    );
  };

  getFilteredProposals = (): TProposal[] => {
    const { status } = this.props;
    // @ts-ignore
    const { proposals } = this.context;

    const filterFunction =
      status === EProposalStatus.HISTORY
        ? ({ status: proposalStatus }) =>
            proposalStatus === EProposalStatus.HISTORY ||
            proposalStatus === EProposalStatus.REJECTED
        : ({ status: proposalStatus }) => proposalStatus === status;

    return proposals ? proposals.filter(filterFunction) : [];
  };

  getProposalTypeText = (): string =>
    this.props.status === EProposalStatus.ONGOING
      ? 'proposal'
      : this.props.status.replace(/_/g, ' ').toLowerCase();

  renderProposalForm = (hideModal: () => void): ReactElement => {
    const { editableProposal } = this.state;
    const { status } = this.props;
    const initialValues = editableProposal || (status && { status }) || null;

    return (
      <ProposalForm
        hideModal={hideModal}
        initialValues={initialValues}
        onFormClose={this.handleFormClose}
        onFormSubmit={this.handleFormSubmit}
      />
    );
  };

  handleRejectReasonClose = () => {
    this.props.onModalHide();
    this.handleFormClose();
  };

  handleRejectReasonSelect = async (
    rejectReasonId: number,
    rejectReasonCustom: string
  ) => {
    // @ts-ignore
    await this.context.changeStatus(
      this.state.editableProposal.id,
      EProposalStatus.REJECTED,
      rejectReasonId,
      rejectReasonCustom
    );

    this.handleFormClose();
  };

  handleStatusChange = async (
    status: EProposalStatus,
    proposal: TProposal
  ): Promise<void> => {
    if (status === EProposalStatus.REJECTED) {
      this.handleEdit(proposal);
      this.props.onModalShow();

      return;
    }

    // @ts-ignore
    await this.context.changeStatus(proposal.id, status);
  };

  render(): ReactElement {
    const { isModalVisible } = this.props;
    // @ts-ignore
    const { isLoading } = this.context;
    const proposalTypeText = this.getProposalTypeText();
    const proposalsList = (hideModal, showModal) => {
      const filteredProposals = this.getFilteredProposals();

      if (filteredProposals.length > 0) {
        return filteredProposals.map((proposal) => (
          <ProposalCard
            key={proposal.id}
            proposal={proposal}
            showModal={showModal}
            onDelete={this.handleDelete}
            onEdit={this.handleEdit}
            onStatusChange={this.handleStatusChange}
          />
        ));
      }

      return (
        <EmptyList
          placeholder={`Add your first ${proposalTypeText}`}
          placeholderImgUrl={PLACEHOLDER.CONTACT_CARD}
        />
      );
    };

    return (
      <>
        <RejectReasonModal
          isModalVisible={isModalVisible}
          onCancel={this.handleRejectReasonClose}
          onReasonSelect={this.handleRejectReasonSelect}
        />
        <LoaderOverlay isLoading={isLoading} isTransparent={true}>
          <CrmSection
            addButtonTitle={`Add ${proposalTypeText}`}
            modalContent={this.renderProposalForm}
            modalTitle={proposalTypeText}
          >
            {proposalsList}
          </CrmSection>
        </LoaderOverlay>
      </>
    );
  }
}

Component.propTypes = {
  status: PropTypes.string,
  ...PTInjectedModalStateProps
};

export const Proposals = withModalState(Component);
