import { ENDPOINTS } from 'other';
import { http } from 'services';

import { TNote } from 'types';

export const getNotes = async (
  entityId: number,
  entityType: string
): Promise<TNote[]> => {
  const url = `${ENDPOINTS.CRM_NOTES}?entityId=${entityId}&type=${entityType}`;
  const { data } = await http.send(url);

  return data;
};

export const addNote = async (note: Partial<TNote>): Promise<TNote> => {
  const { data } = await http.send({
    body: note,
    method: 'POST',
    url: ENDPOINTS.CRM_NOTES
  });

  return data;
};

export const editNote = async ({
  description,
  id,
  targetId,
  title,
  type
}: TNote): Promise<TNote> => {
  const body = {
    description,
    id,
    targetId,
    title,
    type
  };
  const { data } = await http.send({
    body,
    method: 'PUT',
    url: `${ENDPOINTS.CRM_NOTES}/${id}`
  });

  return data;
};

export const deleteNote = async (noteId: number): Promise<TNote> => {
  const { data } = await http.send({
    method: 'DELETE',
    url: `${ENDPOINTS.CRM_NOTES}/${noteId}`
  });

  return data;
};
