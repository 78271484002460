import { push } from 'connected-react-router';

import { emailPattern } from 'other';
import { isApp, ModalPhoto } from 'components';
import { showTrialModalAction } from 'store/trialModal/trialModalActions';
import { store } from 'store';

/**/
export const QUILL_MENTION_FREE_TRIAL_VALUE = {
  id: '0',
  value: 'Free trial - click here!'
};

/**
 *
 */
export function handleBodyClick(e): void {
  if (e.target.tagName === 'IMG') {
    new ModalPhoto(e.target.src);
  } else if (e.target.closest('a')) {
    handleAnchorClick(e);
  } else {
    handleNonAnchorClick(e);
  }
}

/**
 *
 */
function openFileInNewTab(dataUrl: string, filename: string) {
  const link = document.createElement('a');
  link.target = '_blank';
  link.href = dataUrl;
  link.download = filename;

  if (isApp()) {
    (<any>window).open(dataUrl, '_system', 'hidden=yes,location=no');
    return false;
  }

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 *
 */
function openExternalLink(url: string) {
  if (isApp()) {
    (<any>window).open(url, '_system');
    return false;
  }

  window.open(url, '_blank');
}

/**
 *
 */
function handleAnchorClick(e) {
  e.preventDefault();
  const anchor = e.target.closest('a');
  if (!anchor.href) return;

  // An attached file - open in a new tab.
  if (anchor.href.startsWith('https://storage.googleapis.com')) {
    openFileInNewTab(anchor.href, anchor.download);
    return;
  }

  // If the anchor href points to the resource, hosted on the same
  // origin, we will make a local redirect.
  const isSameOrigin = anchor.href.startsWith(window.location.origin);
  const parts = anchor.href.split('/');
  const tail = parts[parts.length - 1];
  const isEmail = emailPattern.test(tail) || anchor.href.startsWith('mailto:');

  if (isEmail) {
    window.location.href = anchor.href.startsWith('mailto:')
      ? anchor.href
      : `mailto:${tail}`;
  } else if (isSameOrigin) {
    store.dispatch(push(anchor.pathname));
  } else {
    openExternalLink(anchor.href);
  }
}

/**
 *
 */
export function getArticleContent(longDescription: any): any {
  if (typeof longDescription === 'string')
    return {
      __html: longDescription
    };

  const formatted = JSON.stringify(longDescription, null, 2);
  const html = `<pre><code class="app-error-msg">${formatted}</code></pre>`;
  return { __html: html };
}

/**
 *
 */
function handleNonAnchorClick(e) {
  const mention = e.target.closest('span.mention');

  // Mention click
  if (mention) {
    if (mention.dataset.id === QUILL_MENTION_FREE_TRIAL_VALUE.id) {
      store.dispatch(showTrialModalAction(null));
    } else if (mention.dataset.path) {
      store.dispatch(push(mention.dataset.path));
    }
  }
}

/**
 *
 */
export function handleBodyContext(e) {
  e.preventDefault();
  const mention = e.target.closest('span.mention');

  // Mention click
  if (mention) {
    if (mention.dataset.path) {
      const url = window.location.origin + mention.dataset.path;
      window.open(url, '_blank')?.focus();
    }
  }
}
