import React from 'react';

import {
  CARD_CLASS,
  getMentionModule,
  RTEBasic,
  RTEFile,
  TFileRestriction
} from 'components';

import { TFileDescription } from 'types';

/**/
type Props = {
  trialOption: { id: string; value: string };
  onRemove: (fileId: number) => void;
  onUpload: (file: File, onSuccess: (desc: TFileDescription) => void) => void;
};

/**/
type State = { fileDescription: TFileDescription };

/**/
const FILE_RESTRICTION: TFileRestriction = {
  extensions: ['pdf', 'pdfs'],
  maxSize: 31457280,
  message: 'File must be smaller than 30MB.'
};

/**
 *
 */
export class RTE extends React.PureComponent<Props, State> {
  state: State = { fileDescription: void 0 };
  // A list of files that have been added during the session (even removed).
  private fileSet = new Set<number>();
  private modules = {
    ...RTEFile.defaultProps.modules,
    mentions: getMentionModule(this.props.trialOption)
  };

  componentWillUnmount(): void {
    this.cleanFiles();
  }

  handleUpload = (file: File) => this.props.onUpload(file, this.addFile);

  addFile = (desc: TFileDescription) => {
    this.setState({ fileDescription: desc });
    this.fileSet.add(desc.id);
  };

  // Performs file cleanup: some files could have been added but then removed. We'd like to
  // remove them from the DB.
  cleanFiles(): void {
    const query = `div.${CARD_CLASS}[data-file-id]`;
    const nodes = document.documentElement.querySelectorAll(query);
    const legitimateFiles = Array.from(nodes).map(
      (div: HTMLDivElement): number => parseInt(div.dataset.fileId)
    );

    Array.from(this.fileSet)
      .filter((id: number) => !legitimateFiles.includes(id))
      .forEach((id: number) => this.props.onRemove(id));
  }

  render() {
    const { trialOption, ...rest } = this.props;
    const props = {
      fileDescription: this.state.fileDescription,
      fileRestriction: FILE_RESTRICTION,
      formats: [...RTEBasic.FORMATS, 'file', 'mention'],
      modules: this.modules,
      onUpload: this.handleUpload
    };

    return <RTEFile {...rest} {...props} />;
  }
}
