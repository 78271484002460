import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Input from 'antd/lib/input';
import { InputRef } from 'rc-input';

import './EditableContent.scss';
import { DefaultButton, IconButton, PrimaryButton } from 'components';

/**/
type Props = {
  errorMessage?: string;
  onCancel?: Function;
  onChange?: Function;
  onSave: Function;
  text: string;
  title: string;
};
type State = {
  isActive: boolean;
  value: string;
};

/**
 *
 */
class EditableContent extends React.PureComponent<Props, State> {
  static propTypes;
  state: State = {
    isActive: false,
    value: this.props.text
  };

  input: InputRef;

  handleChange = ({ target: { value } }) => {
    const { onChange } = this.props;
    this.setState({ value });
    typeof onChange === 'function' && onChange(value);
  };

  handleCancel = () => {
    const { onCancel, text } = this.props;
    typeof onCancel === 'function' && onCancel();
    this.setState({
      isActive: false,
      value: text
    });
  };

  handleEdit = () => {
    this.setState({ isActive: true });
    setTimeout(() => this.input?.focus());
  };

  handleSave = () => {
    this.props.onSave(this.state.value);
    this.setState({ isActive: false });
  };

  render() {
    const { errorMessage, title } = this.props;
    const { isActive, value } = this.state;

    const cls = classnames('EditableContent', {
      'EditableContent--active': isActive,
      'EditableContent--error': errorMessage
    });
    const inputCls = classnames('EditableContent__input', {
      'EditableContent__input--active': isActive
    });
    const isDisabled: boolean = value.trim() === '' || !!errorMessage;

    const controls: ReactNode = (
      <div className="EditableContent__btns" data-testid="controls">
        <DefaultButton onClick={this.handleCancel}>Cancel</DefaultButton>

        <PrimaryButton
          disabled={isDisabled}
          onClick={this.handleSave}
          className="app-ml-1"
        >
          Save
        </PrimaryButton>
      </div>
    );

    return (
      <div className={cls}>
        <div className="EditableContent__row">
          <Input
            className={inputCls}
            disabled={!isActive}
            onChange={this.handleChange}
            placeholder={title}
            ref={(el) => (this.input = el)}
            size="small"
            value={value}
          />

          {isActive ? null : (
            <IconButton
              className="FleetsEditorItem__button"
              data-testid="edit"
              icon="pencil-create"
              onClick={this.handleEdit}
              size="XS"
              title={title}
            />
          )}
        </div>

        {errorMessage && (
          <div className="ant-form-explain app-error-msg">{errorMessage}</div>
        )}

        {isActive ? controls : null}
      </div>
    );
  }
}

/**
 *
 */
EditableContent.propTypes = {
  errorMessage: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  text: PropTypes.string,
  title: PropTypes.string
};

export { EditableContent };
