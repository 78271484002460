import React, { FC } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { DefaultButton, IconCustom } from 'components';

/**/
type Props = {
  className?: string;
  onClick: () => void;
  text: string;
};

/**
 *
 */
const AddButton: FC<Props> = ({ className, onClick, text }) => {
  const cls = classnames('AddButton', className);
  const PlusIcon = <IconCustom type="plus" />;

  return (
    <DefaultButton className={cls} icon={PlusIcon} size="S" onClick={onClick}>
      {text}
    </DefaultButton>
  );
};

/**/
AddButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
};

export { AddButton };
