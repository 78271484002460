import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { useDispatch, useSelector } from 'react-redux';

import './ArticleForm.scss';
import { ArticleFormFields } from './helpers';
import ArticleFormInner from './ArticleFormInner';
// import { fetchCompaniesAction } from 'store/companies/companiesActions';
// import { fetchFarmsAction } from 'store/farm/farmsActions';
// import { fetchProvidersAction } from 'store/provider/providers/providersActions';
// import { fetchVesselsAction } from 'store/vessel/vessels/vesselsActions';

import { EUserAuthority, TLanguage } from 'types';
import { TState } from 'store';

/**/
type OwnProps = {
  fields: ArticleFormFields;
  languages?: TLanguage[];
  onChange: (key: string, value: string) => void;
  onFormRef: (form: FormInstance) => void;
  onRemove: (fileId: number) => void;
  onUpload: (file: File, onSuccess) => void;
};

type Props = OwnProps & {
  // fetchData: () => void;
  hasTrialPermission: boolean;
};

/**
 *
 */
class Component extends React.PureComponent<Props> {
  /**/
  // componentDidMount() {
  //   this.props.fetchData();
  // }

  /**/
  render() {
    return <ArticleFormInner {...this.props} />;
  }
}

/**
 *
 */
function ArticleForm(ownProps: OwnProps) {
  // const dispatch = useDispatch();
  const { user } = useSelector((state: TState) => ({
    user: state.session.user
  }));

  // Remove!!!
  // const fetchData = () => {
  //   dispatch(fetchCompaniesAction());
  //   dispatch(fetchFarmsAction());
  //   dispatch(fetchProvidersAction());
  //   dispatch(fetchVesselsAction());
  // };

  const hasTrialPermission = user?.userInfo.authorities.includes(
    EUserAuthority.TRIAL_POPUP
  );

  return (
    <Component
      {...ownProps}
      // fetchData={fetchData}
      hasTrialPermission={hasTrialPermission}
    />
  );
}

/**/
export { ArticleForm };
