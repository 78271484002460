import { ENV } from 'other';
import { readData, writeData } from './storage';

class SettingsService {
  public static BUILD_INFO = 'BUILD_INFO';
  public static BUILD_RELOAD_TIMESTAMP = 'BUILD_RELOAD_TIMESTAMP';
  public static IS_DARK_THEME = 'IS_DARK_THEME';
  public static IS_FIREBASE_TOKEN_SENT = 'IS_FIREBASE_TOKEN_SENT';
  public static IS_SLOW_INTERNET = 'IS_SLOW_INTERNET';
  public static IS_VISUAL_TESTING = 'IS_VISUAL_TESTING';
  public static MAP_ENTITIES = 'MAP_ENTITIES';
  public static MAP_SETTINGS = 'MAP_SETTINGS';
  public static NAVIGATION = 'NAVIGATION';
  public static TRACK_PERIOD = 'TRACK_PERIOD';

  public static COMPANIES_FILTER = 'COMPANIES_FILTER';
  public static HISTORICAL_TRACK_FILTER = 'HISTORICAL_TRACK_FILTER';
  public static MAIN_FILTER = ENV.MAIN_FILTER;
  public static PROVIDERS_FILTER = 'PROVIDERS_FILTER';
  public static SELECTED_AREAS = 'SELECTED_AREAS';

  public static clearSettings() {
    return writeData(ENV.STORE_SETTINGS_KEY, {});
  }

  public static readSettings() {
    return readData(ENV.STORE_SETTINGS_KEY) || {};
  }

  public static writeSettings(data) {
    const current = readData(ENV.STORE_SETTINGS_KEY);
    return writeData(ENV.STORE_SETTINGS_KEY, { ...current, ...data });
  }

  public static getMapSettings() {
    return SettingsService.readSettings()[SettingsService.MAP_SETTINGS] || {};
  }
}

export enum ESettingsSelectOptions {
  AUTO = 'AUTO',
  HIDE = 'HIDE',
  SHOW = 'SHOW',
  TRACK_ONLY = 'TRACK_ONLY'
}

export { SettingsService };
