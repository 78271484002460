import React, { FC, ReactElement } from 'react';

import './CompanyCardBasic.scss';
import { ItemCard, TCardItem } from 'components';
import { MEDIA_BASE_URL, PLACEHOLDER, ROUTES } from 'other';
import { TCompanyAF, TCompanyOfGroup, TPhotoSet } from 'types';

type Props = {
  children?: ReactElement;
  item: TCompanyAF;
};

export function getCompanyImgSrc(item: TCompanyAF | TCompanyOfGroup): string {
  if (!item.photos || item.photos.length === 0) return null;

  const photos = [...item.photos].sort(
    (a: TPhotoSet, b: TPhotoSet): number => a.position - b.position
  );

  return `${MEDIA_BASE_URL}/${photos[0].thumbnail.path}`;
}

function companiesReducer(sum: string, current: TCompanyOfGroup): string {
  return current.companyId ? `${sum},${current.companyId}` : sum;
}

/**/
const CompanyCardBasic: FC<Props> = (props) => {
  const { children, item } = props;
  const path = `${ROUTES.COMPANY}/${item.id}`;

  const companies: string = item.companies
    ? item.companies.reduce(companiesReducer, '').slice(1)
    : '';

  const testProps = {
    'data-companies': companies,
    'data-flag': item.country?.iso,
    'data-name': item.name
  };

  const _item: TCardItem = {
    flag: item.country?.iso,
    name: item.name,
    placeholder: item['isGroup'] ? PLACEHOLDER.GROUP : PLACEHOLDER.COMPANY,
    src: getCompanyImgSrc(item)
  };

  return (
    <div className="CompanyCardBasic" {...testProps}>
      <ItemCard item={_item} path={path}>
        {children}
      </ItemCard>
    </div>
  );
};

export { CompanyCardBasic };
