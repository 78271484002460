import { AnyAction } from 'redux';

import { appInitialState, TState } from 'store';
import { RESET_STORE } from './rootConstants';

export function rootReducer(state: TState, action: AnyAction): TState {
  switch (action.type) {
    case RESET_STORE: {
      return { ...appInitialState, router: state.router };
    }

    default:
      return state;
  }
}
