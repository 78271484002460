import React, { PureComponent } from 'react';
import classnames from 'classnames';

import './AnnualReports.scss';
import { TCompanyAF } from 'types';
import {
  TAccount,
  TAccountGroup,
  TAnnualReport,
  TFinancialReport,
  TYearReport
} from 'pages/company/types';

/**/
const CALCULATED = 'CALCULATED';

type Props = {
  annualReport: TAnnualReport;
  company: TCompanyAF;
};

/**/
class AnnualReports extends PureComponent<Props> {
  static propTypes;

  static renderName(item: TFinancialReport) {
    const colSpan = Object.keys(item.reports).length;
    return (
      <th
        className="app-info-table-align-center"
        colSpan={colSpan}
        key={item.company.id}
      >
        {item.company.name}
      </th>
    );
  }

  static renderReports(item: TFinancialReport) {
    return item.reports.map((year: TYearReport[]) =>
      year.map((period: TYearReport) => {
        const value = `1,000 ${period.currency}`;
        return (
          <th
            className="app-info-table-align-center AnnualReports__th"
            key={Math.random()}
          >
            <div className="AnnualReports__title">
              {period.year} <br />
              {period.period} <br />
            </div>

            <em className="app-info-table-text-sm AnnualReports__em">
              {value}
            </em>
          </th>
        );
      })
    );
  }

  renderValues(acc: TAccount) {
    return this.props.annualReport.items.map((item: TFinancialReport) =>
      item.reports.map((year: TYearReport[]) =>
        year.map((period: TYearReport) => {
          const value = period[acc.key];
          return (
            <td className="app-info-table-align-center" key={Math.random()}>
              {value ? value.toLocaleString('en') : ''}
            </td>
          );
        })
      )
    );
  }

  renderFields(group: TAccountGroup) {
    if (!group || !group.accounts) return null;

    return group.accounts.map((acc: TAccount) => {
      const cls = classnames('app-info-table-col-w50', {
        'account-name-calculated': acc.type === CALCULATED
      });

      return (
        <tr key={Math.random()}>
          <td className={cls} key={acc.key}>
            {acc.name}
          </td>
          {this.renderValues(acc)}
        </tr>
      );
    });
  }

  renderAccount = (group: TAccountGroup) => (
    <React.Fragment key={Math.random()}>
      <tr>
        <td className="account-group-name">{group.name}</td>
      </tr>
      {this.renderFields(group)}
    </React.Fragment>
  );

  render() {
    const {
      annualReport: { accounts = [], items }
    } = this.props;

    return (
      <table className="app-info-table AnnualReports">
        <thead>
          <tr className="app-info-table-head app-info-table-border-bottom">
            <th className="app-info-table-col-w50">Account</th>
            {items.map(AnnualReports.renderName)}
          </tr>
          <tr className="app-info-table-border-bottom">
            <th className="app-info-table-col-w50" />
            {items.map(AnnualReports.renderReports)}
          </tr>
        </thead>

        <tbody>{accounts.map(this.renderAccount)}</tbody>
      </table>
    );
  }
}

/**/
export { AnnualReports };
