import React from 'react';
import { ButtonProps } from 'antd/lib/button';

import './IconButtonPrimary.scss';
import { AppButton } from '../helpers';
import { IconCustom } from 'components/common/IconCustom/IconCustom';

/**/
type Props = Omit<ButtonProps, 'icon' | 'size'> & {
  icon: string;
  size?: 'S' | 'M';
};

/**
 *
 */
export function IconButtonPrimary(props: Props) {
  const { icon, ...rest } = props;
  return (
    <AppButton
      mainClassName="IconButtonPrimary"
      shape="circle"
      type="primary"
      {...rest}
    >
      <IconCustom className="IconButtonPrimary__icon" type={icon} />
    </AppButton>
  );
}
