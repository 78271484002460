import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import './VesselTitle.scss';
import { Flag } from 'components';

type Props = {
  /** The path/URL to the country representing image. */
  flag: string;
  /** The vessel name. */
  name: string;
};

/**
 * A vessel title block.
 * @param flagSrc
 * @param name
 * @constructor
 */
const VesselTitle = ({ flag, name }: Props): ReactElement => (
  <h1 className="VesselTitle app-h1">
    <Flag flagIso={flag} className="VesselTitle__flag" />
    {name}
  </h1>
);

VesselTitle.propTypes = {
  flag: PropTypes.string,
  name: PropTypes.string
};

export { VesselTitle };
