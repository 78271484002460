import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { APP_CONTENT } from 'other';

const ScrollToTopOnIdChange: FC = () => {
  const { id }: any = useParams();

  useEffect(() => {
    document.getElementById(APP_CONTENT).scrollTo(0, 0);
  }, [id]);

  return null;
};

export { ScrollToTopOnIdChange };
