// Utilized CSS colors:
//
// black
// cyan
// MediumBlue
// green
// lime
// magenta
// orange
// red
// SaddleBrown
// violet
// white
// yellow

export const countryColorMap = {
  AN: ['SaddleBrown', 'SaddleBrown'],
  CL: ['black', 'black'],
  CN: ['white', 'white'],
  DE: ['Lime', 'Lime'],
  DK: ['yellow', 'yellow'],
  EE: ['black', 'cyan'],
  ES: ['black', 'MediumBlue'],
  FO: ['green', 'green'],
  GB: ['magenta', 'magenta'],
  GL: ['black', 'green'],
  IE: ['orange', 'orange'],
  IS: ['MediumBlue', 'MediumBlue'],
  KR: ['black', 'lime'],
  LT: ['black', 'magenta'],
  LV: ['black', 'orange'],
  NL: ['black', 'red'],
  NO: ['red', 'red'],
  PL: ['black', 'SaddleBrown'],
  PT: ['black', 'violet'],
  RU: ['black', 'white'],
  SW: ['black', 'yellow'],
  FR: ['cyan', 'cyan'],
  UA: ['cyan', 'MediumBlue'],
  VU: ['cyan', 'green'],
  AU: ['cyan', 'lime'],
  BZ: ['cyan', 'magenta'],
  CA: ['cyan', 'orange'],
  JP: ['cyan', 'red'],
  NZ: ['cyan', 'SaddleBrown'],
  ZA: ['cyan', 'violet'],
  US: ['cyan', 'white'],
  UY: ['cyan', 'yellow'],
  BS: ['MediumBlue', 'green'],
  MT: ['MediumBlue', 'lime'],
  AR: ['MediumBlue', 'magenta'],
  CR: ['MediumBlue', 'orange'],
  CY: ['MediumBlue', 'red'],
  DO: ['MediumBlue', 'SaddleBrown'],
  FI: ['MediumBlue', 'violet'],
  GE: ['MediumBlue', 'white'],
  GR: ['MediumBlue', 'yellow'],
  IT: ['lime', 'magenta'],
  MY: ['lime', 'orange'],
  MR: ['lime', 'red'],
  MD: ['lime', 'SaddleBrown'],
  MZ: ['lime', 'violet'],
  OM: ['lime', 'white'],
  PE: ['lime', 'yellow'],
  RS: ['magenta', 'orange'],
  TW: ['magenta', 'red'],
  TR: ['magenta', 'SaddleBrown'],
  BE: ['magenta', 'violet'],
  BG: ['magenta', 'white'],
  CO: ['magenta', 'yellow'],
  HR: ['magenta'],
  EC: ['orange', 'red'],
  MX: ['orange', 'SaddleBrown'],
  MA: ['orange', 'violet'],
  NA: ['orange', 'white'],
  PA: ['orange', 'yellow'],
  RO: ['red', 'SaddleBrown'],
  'GB-SCT': ['red', 'violet'],
  SI: ['red', 'white'],
  'AU-TAS': ['red', 'yellow'],
  AE: ['SaddleBrown', 'violet'],
  VN: ['SaddleBrown', 'white'],
  SC: ['SaddleBrown', 'yellow']
};

export const vesselTypesColorMap = {
  1: ['black', 'cyan'],
  2: ['green', 'green'],
  3: ['red', 'red'],
  4: ['MediumBlue', 'MediumBlue'],
  5: ['yellow', 'yellow'],
  6: ['black', 'black'],
  7: ['white', 'white'],
  8: ['cyan', 'cyan'],
  9: ['magenta', 'magenta'],
  10: ['orange', 'orange'],
  11: ['Lime', 'Lime'],
  12: ['SaddleBrown', 'SaddleBrown'],
  13: ['violet', 'violet'],
  14: ['black', 'MediumBlue'],
  15: ['black', 'green'],
  16: ['black', 'lime'],
  17: ['black', 'magenta'],
  18: ['black', 'orange'],
  19: ['black', 'red'],
  20: ['black', 'SaddleBrown'],
  21: ['black', 'violet'],
  22: ['black', 'white'],
  23: ['black', 'yellow'],
  24: ['cyan', 'MediumBlue'],
  25: ['cyan', 'green'],
  26: ['cyan', 'lime'],
  27: ['cyan', 'magenta'],
  28: ['cyan', 'orange'],
  29: ['cyan', 'red'],
  30: ['cyan', 'SaddleBrown'],
  31: ['cyan', 'violet'],
  32: ['cyan', 'white'],
  33: ['cyan', 'yellow']
};

export const providerBranchColors = [
  '#B3F95B',
  '#20FFAF',
  '#9FD700',
  '#02BCC8',
  '#068504',
  '#00C2FF',
  '#4E74FC',
  '#2044FF',
  '#0216C8',
  '#15127B',
  '#BA9052',
  '#932323',
  '#EDFF20',
  '#FFD525',
  '#FF8717',
  '#FF2020',
  '#FFDADA',
  '#FF86B9',
  '#FF2098',
  '#CC12C5',
  '#8D0088',
  '#C095FA',
  '#9D20FF',
  '#771DBE',
  '#610345',
  '#000000',
  '#004B73',
  '#BE2C00',
  '#33FF00',
  '#00737A',
  '#4B237D',
  '#716D13',
  '#00B13C',
  '#00A1A0',
  '#FFB0EF',
  '#FF01FB',
  '#B4C5FF',
  '#9483FF',
  '#A4FFE7',
  '#16F8FF',
  '#FFFFFF',
  '#FEFAB5',
  '#C4BA72',
  '#FFA68B',
  '#FF5376',
  '#9B7484',
  '#D0D0D0',
  '#424C55'
  // '#8D8E8B',
  // '#727272',
  // '#8D89A6'

  // 'cyan',
  // 'MediumBlue',
  // 'green',
  // 'lime',
  // 'magenta',
  // 'orange',
  // 'red',
  // 'SaddleBrown',
  // 'violet',
  // 'yellow'
];

export const farmColorMap = {
  1: '#ff6744',
  2: '#98ADFA',
  3: '#7eef39',
  4: '#F315F8'
};

export const farmColorMapOpacity = {
  1: 'rgba(255, 103, 68, 0.3)',
  2: 'rgb(152, 173, 250, 0.3)',
  3: 'rgb(126, 239, 57, 0.3)',
  4: 'rgb(243, 21, 248, 0.3)'
};
