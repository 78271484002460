import { API_URL, ENV } from 'other';
import { autoLoginAction } from 'store/login/loginActions';
import {
  getToken,
  Http,
  saveToken,
  THttpResponse,
  THttpRequestOptions,
  THttpConfig
} from 'services';
import { store } from 'store';
import { storeRedirectPathAction } from 'store/session/sessionActions';

export type THTTPConfig = THttpConfig & {
  skipToken?: boolean;
};

export class HttpError extends Error {
  response: THttpResponse<any>;

  constructor(message: string, response: THttpResponse<any>) {
    super(message);
    this.response = response;
  }
}

class HTTP extends Http {
  constructor() {
    super(API_URL, HTTP.handleResponse as any);
  }

  private static handleError<T>(res: THttpResponse<T>): never {
    const { message, status, statusText, url } = res;
    const msg = `HttpError: ${url} -- ${status} ${statusText || message}`;
    throw new HttpError(msg, res);
  }

  private static handleResponse<T>(
    res: THttpResponse<T>
  ): THttpResponse<T> | never {
    if (res.status === 401) {
      store.dispatch(storeRedirectPathAction());
      store.dispatch(autoLoginAction());
      saveToken();
      HTTP.handleError(res);
    }

    res.ok || HTTP.handleError(res);
    return res;
  }

  send(
    options: string | Partial<THttpRequestOptions>,
    config: THTTPConfig = {}
  ): Promise<any> {
    const token: string = getToken();
    const headers = {
      ...(config.headers || {}),
      ...(config.skipToken ? null : { 'X-Auth-Token': token }),
      'X-Application': ENV.APP_NAME.toUpperCase()
    };

    if (!token && (!config || !config.skipToken)) {
      window.console.warn(`Invalid authentication token: ${token}`);
    }

    return super.send(options, {
      ...config,
      headers: headers
    });
  }
}

export const http = new HTTP();
