import React, { FC, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input, { InputProps } from 'antd/lib/input';

type Props = InputProps & {
  isHidden: boolean;
};

const RejectReasonCustomInput: FC<Props> = ({
  isHidden,
  value,
  onChange,
  ...props
}) => {
  useEffect(() => {
    return () => {
      if (value !== null && isHidden) {
        onChange(null);
      }
    };
  }, [value, isHidden, onChange]);

  return <Input {...props} value={value} onChange={onChange} />;
};

RejectReasonCustomInput.propTypes = {
  isHidden: PropTypes.bool.isRequired
};

export { RejectReasonCustomInput };
