import React, { FC } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Select, { SelectProps } from 'antd/lib/select';

import './ProbabilitySelect.scss';
import { EProbability } from 'types';

const { Option } = Select;

const ProbabilitySelect: FC<SelectProps<EProbability>> = ({
  className,
  ...selectProps
}) => (
  <Select {...selectProps} className={classnames('StatusSelect', className)}>
    <Option value={EProbability.HIGH}>
      <div className="ProbabilitySelect__option ProbabilitySelect__option--success">
        {EProbability.HIGH}
      </div>
    </Option>
    <Option value={EProbability.MEDIUM}>
      <div className="ProbabilitySelect__option ProbabilitySelect__option--warning">
        {EProbability.MEDIUM}
      </div>
    </Option>
    <Option value={EProbability.LOW}>
      <div className="ProbabilitySelect__option ProbabilitySelect__option--danger">
        {EProbability.LOW}
      </div>
    </Option>
  </Select>
);

ProbabilitySelect.propTypes = {
  className: PropTypes.string
};

export { ProbabilitySelect };
