import React, { useState, FC } from 'react';
import PropTypes from 'prop-types';

import './ContactUsButton.scss';
import { PrimaryButton } from 'components';
import { sendRequest } from './helper';

type Props = {
  serviceProviderId: number;
};

/**
 *
 */
const ContactUsButton: FC<Props> = ({ serviceProviderId }) => {
  const [isPending, setIsPending] = useState(false);

  const handleClick = async () => {
    setIsPending(true);
    await sendRequest(serviceProviderId);
    setIsPending(false);
  };

  return (
    <PrimaryButton
      className="ContactUsButton"
      disabled={isPending}
      size="M"
      onClick={handleClick}
    >
      Contact us
    </PrimaryButton>
  );
};

/**/
ContactUsButton.propTypes = {
  serviceProviderId: PropTypes.number.isRequired
};

export { ContactUsButton };
