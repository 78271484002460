import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchCompaniesAction } from 'store/companies/companiesActions';
import { MultiSelect, selectCompanies, selectPropTypes } from 'components';
import { setFiltersAction } from 'store/filters/filtersActions';

import { TSelectProps } from 'types';
import { TState } from 'store';

type Props = TSelectProps<number> & {
  fetchCompanies: () => void;
};

class CompaniesFilter extends React.Component<Props> {
  static propTypes;

  componentDidMount(): void {
    this.props.fetchCompanies();
  }

  render() {
    const { fetchCompanies, options, onChange, value, ...rest } = this.props;
    return (
      <MultiSelect<number>
        options={options}
        value={value}
        onChange={onChange}
        {...rest}
      />
    );
  }
}

CompaniesFilter.propTypes = {
  ...selectPropTypes,
  fetchCompanies: PropTypes.func.isRequired
};

const mapStateToProps = ({
  companies: { companies },
  filters: { companyValue }
}: TState) => ({
  allOptionLabel: 'All companies',
  options: selectCompanies(companies),
  value: companyValue
});

const mapDispatchToProps = (dispatch) => ({
  fetchCompanies: () => dispatch(fetchCompaniesAction()),
  onChange: (value: number[]) =>
    dispatch(setFiltersAction({ companyValue: value }))
});

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesFilter);
