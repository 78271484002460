import React from 'react';
import PropTypes from 'prop-types';

import './ItemControls.scss';
import { IconButton } from 'components';
import { TId } from 'types';

/**/
type Props<T> = {
  isPermitted: boolean;
  isInFavourites: boolean;
  item: T;
  onFav: (id: number) => void;
  showOnMap: (id: number) => void;
  label: string;
};

const pointer = { cursor: 'pointer' };
const yellowAndPointer = {
  color: '#FFDC24',
  cursor: 'pointer'
};

/**
 *
 */
function ItemControls<T extends TId>({
  isPermitted,
  isInFavourites,
  item,
  label,
  onFav,
  showOnMap
}: Props<T>) {
  const iconName = isInFavourites ? 'star-filled' : 'star';
  const style = isInFavourites ? yellowAndPointer : pointer;

  const handleClick = (e) => {
    e.preventDefault();
    showOnMap(item.id);
  };

  const handleFav = () => onFav(item.id);

  return (
    <div className="ItemControls">
      <IconButton
        className="ItemControls__button"
        icon={iconName}
        onClick={handleFav}
        size="S"
        style={style}
        tabIndex={0}
        title={`Add ${label} to fleet`}
      />

      {isPermitted && (
        <IconButton
          className="ItemControls__button"
          icon="map-point"
          onClick={handleClick}
          onTouchEnd={handleClick}
          size="S"
          style={pointer}
          title={`Show ${label} on map`}
        />
      )}
    </div>
  );
}

/**
 *
 */
ItemControls.LABEL = {
  FARM: 'farm',
  VESSEL: 'vessel'
};

ItemControls.propTypes = {
  isPermitted: PropTypes.bool,
  isInFavourites: PropTypes.bool,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  onFav: PropTypes.func.isRequired,
  showOnMap: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

export { ItemControls };
