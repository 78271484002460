import React, { ReactElement, FC } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './CardWrapper.scss';
import { EditDeletePopover } from 'components';

type Props = {
  /**  React children or a child. */
  children: ReactElement;
  /** a CSS class name. */
  className?: string;
  /** the wrapped entity ID. */
  id: number;
  /** edit click handler. */
  onEdit: (id: number) => void;
  /** remove click handler. */
  onRemove: (id: number) => void;
};

/**
 * Wraps the given component with the ellipsis menu.
 * @param children
 * @param className
 * @param id
 * @param onEdit
 * @param onRemove
 * @param hideActions
 * @constructor
 */

const CardWrapper: FC<Props> = ({
  children,
  className,
  id,
  onEdit,
  onRemove
}): ReactElement => {
  const cls = classnames('CardWrapper', className);
  const handleEdit = () => onEdit(id);
  const handleRemove = () => onRemove(id);

  return (
    <div className={cls}>
      <EditDeletePopover onDelete={handleRemove} onEdit={handleEdit} />
      {children}
    </div>
  );
};

CardWrapper.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  id: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

export { CardWrapper };
