import React from 'react';
import './ModalEditorHeader.scss';
import { CloseButton } from 'components';

type Props = {
  onCancel: () => void;
  title: string;
};

/**/
function ModalEditorHeader({ onCancel, title }: Props) {
  return (
    <header className="ModalEditorHeader">
      <h3 className="ModalEditorHeader__title app-h3">{title}</h3>
      <CloseButton
        className="ModalEditorHeader__cancel"
        onClick={onCancel}
        title="Cancel"
      />
    </header>
  );
}

/**/
export { ModalEditorHeader };
