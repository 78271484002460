import React from 'react';

import './PbiReportPage.scss';
import { fetchToken, TPBIReportData } from './helpers';
import { LoaderOverlay } from 'components';
import PbiReport from './PbiReport';

/**/
type Props = {
  reportId: string;
  workspaceId?: string;
};
type State = {
  data: TPBIReportData;
  isLoading: boolean;
};

/**
 *
 */
class PbiReportPage extends React.PureComponent<Props, State> {
  state: State = {
    data: null,
    isLoading: true
  };

  /**/
  componentDidMount() {
    const { reportId, workspaceId } = this.props;
    fetchToken(reportId, workspaceId, this.handleResponse, this.handleError);
  }

  /**/
  handleError = (): void => this.setState({ isLoading: false });

  /**/
  handleResponse = (data: TPBIReportData): void =>
    this.setState({
      data: data,
      isLoading: false
    });

  /**/
  render() {
    const { data, isLoading } = this.state;

    return (
      <LoaderOverlay isLoading={isLoading}>
        {data ? (
          <PbiReport data={data} reportId={this.props.reportId} />
        ) : (
          <h3 className="app-pt-3 app-pl-4">
            Failed to load. Please retry in a while.
          </h3>
        )}
      </LoaderOverlay>
    );
  }
}

/**
 *
 */
export { PbiReportPage };
