import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './PostInfo.scss';
import { getDate, ROUTES } from '../../../../other';

const PostInfo = ({ date, source }) => {
  const { id, name } = source || {};
  const companyLink = `${ROUTES.SERVICE_PROVIDER}/${id}`;
  const _date = getDate(date);

  return (
    <div className="PostInfo app-overline-2">
      {source && (
        <span className="PostInfo__issuer">
          Posted by{' '}
          <Link className="app-overline-2--underline" to={companyLink}>
            {name}
          </Link>{' '}
          ∙{' '}
        </span>
      )}
      <span className="PostInfo__date">{_date}</span>
    </div>
  );
};

PostInfo.propTypes = {
  date: PropTypes.string.isRequired,
  source: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })
};

export default PostInfo;
