import { crmStatusFinder } from 'pages/CRM/helpers/useTargetEntityLink';
import { ROUTES } from 'other';
import { SearchUtil } from 'store/search/SearchUtil';

import {
  ESearchCategory,
  TIdLocationDict,
  TSearchItem,
  TSearchResult
} from 'types';
import { http, THttpResponse } from 'services';

/**/
export type TMentionMenuItem = TMentionItem | TMentionCategoryName;

/**/
export type TMentionItem = {
  id: number;
  path: string;
  type: ESearchCategory;
  value: string;
};

/**/
export type TMentionCategoryName = {
  disabled: true;
  value: string;
};

/**/
export type TMentionCategory = {
  items: TMentionItem[];
  value: string;
};

/**
 *
 */
class FetchMentions {
  private readonly crmCompanyStatusId?: number;
  private readonly crmFarmStatusId?: number;
  private readonly crmVesselStatusId?: number;

  constructor(
    companyStatuses: ReadonlyArray<TIdLocationDict>,
    fishFarmStatuses: ReadonlyArray<TIdLocationDict>,
    vesselStatuses: ReadonlyArray<TIdLocationDict>
  ) {
    this.crmCompanyStatusId = companyStatuses.find(crmStatusFinder)?.id;
    this.crmFarmStatusId = fishFarmStatuses.find(crmStatusFinder)?.id;
    this.crmVesselStatusId = vesselStatuses.find(crmStatusFinder)?.id;
  }

  /**/
  public find(text: string): Promise<void | TMentionMenuItem[]> {
    return http
      .send(SearchUtil.getRequestParams(text))
      .then(({ data }: THttpResponse<TSearchResult[]>): TMentionMenuItem[] =>
        this.getPayload(data)
      )
      .catch(window.console.error);
  }

  /**/
  private getPayload(results: TSearchResult[]): TMentionMenuItem[] {
    return results
      .flatMap((data: TSearchResult): TMentionMenuItem[] =>
        this.getMapperByType(data.type)(data.items as any)
      )
      .filter(Boolean);
  }

  /**/
  private getMapperByType(
    type: ESearchCategory
  ): (items: TSearchItem[]) => TMentionMenuItem[] {
    switch (type) {
      case ESearchCategory.COMPANY:
        return this.mapCompanies;
      case ESearchCategory.FARM:
        return this.mapFarms;
      case ESearchCategory.PERSON:
        return this.mapPersons;
      case ESearchCategory.SERVICE_PROVIDER:
        return this.mapServices;
      case ESearchCategory.VESSEL:
        return this.mapVessels;
    }
  }

  /**/
  private mapCompanies = (items: TSearchItem[]): TMentionMenuItem[] => {
    const arr = items
      .map((item: TSearchItem): TMentionItem => {
        const { id, statusId, text } = item;
        if (this.crmCompanyStatusId && this.crmCompanyStatusId === statusId)
          return;

        return {
          id: id,
          path: `${ROUTES.COMPANY}/${id}`,
          type: ESearchCategory.COMPANY,
          value: text
        };
      })
      .filter(Boolean);

    return arr.length > 0 && [{ disabled: true, value: 'Companies' }, ...arr];
  };

  /**/
  private mapFarms = (items: TSearchItem[]): TMentionMenuItem[] => {
    const arr = items
      .map((item: TSearchItem): TMentionItem => {
        const { id, statusId, text } = item;
        if (this.crmFarmStatusId && this.crmFarmStatusId === statusId) return;

        return {
          id: id,
          path: `${ROUTES.FARM}/${id}`,
          type: ESearchCategory.FARM,
          value: text
        };
      })
      .filter(Boolean);

    return arr.length > 0 && [{ disabled: true, value: 'Facilities' }, ...arr];
  };

  /**/
  private mapVessels = (items: TSearchItem[]): TMentionMenuItem[] => {
    const arr = items
      .map((item: TSearchItem): TMentionItem => {
        const { id, statusId, text } = item;
        if (this.crmVesselStatusId && this.crmVesselStatusId === statusId)
          return;

        return {
          id: id,
          path: `${ROUTES.VESSEL}/${id}`,
          type: ESearchCategory.VESSEL,
          value: text
        };
      })
      .filter(Boolean);

    return arr.length > 0 && [{ disabled: true, value: 'Vessels' }, ...arr];
  };

  /**/
  private mapPersons = (items: TSearchItem[]): TMentionMenuItem[] => {
    const arr = items
      .map(
        (item: TSearchItem): TMentionItem => ({
          id: item.id,
          path: `${ROUTES.PERSON}/${item.id}`,
          type: ESearchCategory.PERSON,
          value: item.text
        })
      )
      .filter(Boolean);

    return arr.length > 0 && [{ disabled: true, value: 'Persons' }, ...arr];
  };

  /**/
  private mapServices = (items: TSearchItem[]): TMentionMenuItem[] => {
    const arr = items
      .map(
        (item: TSearchItem): TMentionItem => ({
          id: item.id,
          path: `${ROUTES.SERVICE_PROVIDER}/${item.id}`,
          type: ESearchCategory.SERVICE_PROVIDER,
          value: item.text
        })
      )
      .filter(Boolean);

    return (
      arr.length > 0 && [{ disabled: true, value: 'Service Providers' }, ...arr]
    );
  };
}

/**/
export default FetchMentions;
