import React, { useState, FC } from 'react';
import PropTypes from 'prop-types';
import ImgCrop from 'antd-img-crop';
import message from 'antd/lib/message';
import Upload from 'antd/lib/upload';

import './AvatarUploader.scss';
import { isIPhone } from 'components';
import { PLACEHOLDER } from 'other';

const MAX_SIZE = {
  label: '20MB',
  value: 20971520
};

function getBase64(img, callback) {
  const reader = new FileReader();

  reader.addEventListener('load', () => callback(reader.result));
  reader.addEventListener('error', message.error as any);
  reader.readAsDataURL(img);
}

type Props = {
  onFileLoad: (file: File) => void;
  defaultImageUrl?: string;
};

/**
 *
 */
const AvatarUploader: FC<Props> = ({ onFileLoad, defaultImageUrl }) => {
  const [imageUrl, setImageUrl] = useState(defaultImageUrl);
  // should return false to suspend default API calls
  const beforeUpload = (file: File): void => {
    if (file.size > MAX_SIZE.value) {
      message.error(`File size should be less than ${MAX_SIZE.label}!`);
    }

    onFileLoad(file);
    getBase64(file, setImageUrl);
  };

  const beforeCrop = (file: File): boolean => {
    if (isIPhone()) {
      beforeUpload(file);
      return false;
    }
    return true;
  };

  return (
    <ImgCrop
      beforeCrop={beforeCrop}
      cropShape="round"
      modalClassName="AvatarUploader__modal"
      rotationSlider
    >
      <Upload
        accept="image/*"
        beforeUpload={beforeUpload}
        className="AvatarUploader"
        listType="picture-card"
        multiple={false}
        name="avatar"
        showUploadList={false}
      >
        {imageUrl ? <img alt="avatar" src={imageUrl} /> : 'Upload'}
      </Upload>
    </ImgCrop>
  );
};

AvatarUploader.propTypes = {
  defaultImageUrl: PropTypes.string,
  onFileLoad: PropTypes.func.isRequired
};

AvatarUploader.defaultProps = {
  defaultImageUrl: PLACEHOLDER.CONTACT
};

export { AvatarUploader };
