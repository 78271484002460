import { createSelector } from 'reselect';
import { TArticle, TProvider, TUser } from 'types';

type Args = {
  articleId: number;
  news: ReadonlyArray<TArticle>;
};

function dataSelector(news: ReadonlyArray<TArticle>, articleId: number): Args {
  return { articleId, news };
}

export const selectNews = createSelector(dataSelector, selector);

// The current article can be among the recent articles,
// so we need to filter it out.
function selector({ articleId, news }: Args): ReadonlyArray<TArticle> {
  // prettier-ignore
  return articleId
    ? news.filter(({ id }: TArticle) => id !== articleId)
    : news;
}

export function isOwner(user: TUser, provider: TProvider): boolean {
  const providerIds = user ? user.userInfo.serviceProvidersId : [];
  return provider && providerIds.includes(provider.id);
}
