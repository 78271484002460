import React, { PureComponent, ReactElement } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import './ProposalCard.scss';
import {
  CrmItemCard,
  FileList,
  ProbabilityIndicator,
  ScrollToItem,
  StatusSelect
} from 'components';
import { DATE_FORMAT_DMY, getCurrencySymbol } from 'other';
import RejectReasonIndicator from 'pages/CRM/components/RejectReasonIndicator/RejectReasonIndicator';
import { EProposalStatus, TProposal, PTProposal } from 'types';

type Props = {
  onDelete: (proposal: TProposal) => void;
  onEdit: (proposal: TProposal) => void;
  onStatusChange: (status: EProposalStatus, proposal: TProposal) => void;
  showModal: () => void;
  proposal: TProposal;
};

class ProposalCard extends PureComponent<Props> {
  static propTypes;

  handleEdit = (): void => {
    const { onEdit, showModal, proposal } = this.props;

    onEdit(proposal);
    showModal();
  };

  handleDelete = () => {
    const { onDelete, proposal } = this.props;

    onDelete(proposal);
  };

  handleStatusChange = (status: EProposalStatus) => {
    const { onStatusChange, proposal } = this.props;

    onStatusChange(status, proposal);
  };

  render(): ReactElement {
    const {
      proposal: {
        amount,
        currency,
        probability,
        status,
        date,
        id,
        createdBy: { lastName, firstName, username, id: authorId } = {},
        title,
        crmContact,
        files,
        description,
        rejectReasonId,
        rejectReasonCustom
      }
    } = this.props;

    const author =
      lastName && firstName ? `${firstName} ${lastName}` : username;
    const contact =
      crmContact && `${crmContact.firstName} ${crmContact?.lastName || ''}`;
    const isStatusDisplayed = status !== EProposalStatus.HISTORY;

    return (
      <ScrollToItem itemId={id.toString()}>
        {/*@ts-ignore*/}
        <CrmItemCard
          authorId={authorId}
          onDelete={this.handleDelete}
          onEdit={this.handleEdit}
        >
          <article className="ProposalCard app-body-2">
            <div className="ProposalCard__info">
              {date && (
                <span className="ProposalCard__date">
                  {dayjs(date).format(DATE_FORMAT_DMY)}
                </span>
              )}

              <span className="ProposalCard__author">{author}</span>

              {isStatusDisplayed && (
                <StatusSelect
                  className="ProposalCard__statusSelect"
                  value={status}
                  onChange={this.handleStatusChange}
                />
              )}
            </div>

            <h6 className="ProposalCard__header app-subtitle-1">{title}</h6>

            {contact && (
              <div className="ProposalCard__itemWrapper">
                <span className="ProposalCard__itemLabel">Contact:</span>

                <span className="ProposalCard__item">{contact}</span>
              </div>
            )}

            <div className="ProposalCard__info">
              {!!amount && (
                <div className="ProposalCard__itemWrapper ProposalCard__amount">
                  <span className="ProposalCard__itemLabel">Total amount:</span>

                  <span className="ProposalCard__item ProposalCard__item--bold">
                    {getCurrencySymbol(currency?.id)} {amount}
                  </span>
                </div>
              )}

              {!!probability && (
                <div className="ProposalCard__itemWrapper">
                  <span className="ProposalCard__itemLabel">Probability:</span>

                  <ProbabilityIndicator probability={probability} />
                </div>
              )}
            </div>

            {rejectReasonId && (
              <div className="ProposalCard__itemWrapper">
                <span className="ProposalCard__itemLabel">
                  Reason of rejection:
                </span>

                <RejectReasonIndicator
                  rejectReasonCustom={rejectReasonCustom}
                  rejectReasonId={rejectReasonId}
                />
              </div>
            )}

            {description && (
              <div
                className="ProposalCard__description app-body-1"
                dangerouslySetInnerHTML={{
                  __html: description
                }}
              />
            )}

            {files && (
              <FileList files={files.slice().reverse()} proposalId={id} />
            )}
          </article>
        </CrmItemCard>
      </ScrollToItem>
    );
  }
}

ProposalCard.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  proposal: PropTypes.exact(PTProposal),
  showModal: PropTypes.func.isRequired
};

export { ProposalCard };
