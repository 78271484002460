import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import FarmCard from 'pages/farm/FarmsList/Card/FarmCard';
import { TFarm, TFarmOfCompany } from 'types';

type Props = {
  favFarmIds: number[];
  onFavClick: (id: number) => void;
  farm: TFarmOfCompany;
};

const ShortFarm = ({ favFarmIds, onFavClick, farm }: Props): ReactElement => {
  const handleFav = () => onFavClick(farm.fishFarmId);

  const { country, name, fishFarmId, mediaPath } = farm;
  const photo = mediaPath
    ? {
        thumbnail: {
          path: mediaPath
        }
      }
    : null;

  const v = {
    country: country.iso,
    id: fishFarmId,
    name,
    photo
  } as TFarm;

  return <FarmCard favFarmIds={favFarmIds} item={v} onFavClick={handleFav} />;
};

ShortFarm.propTypes = {
  className: PropTypes.string,
  farm: PropTypes.object.isRequired,
  favFarmIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onFavClick: PropTypes.func.isRequired
};

export { ShortFarm };
