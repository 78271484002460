import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import './FleetsMenu.scss';
import { CreateFleet, FleetItem, ModalEditorHeader } from 'components';
import { ENV } from 'other';

import { PTFleet, TFleet, TFleetItems } from 'types';

type Props = {
  candidates: TFleetItems;
  fleets: TFleet[];
  onCancel: () => void;
  onCreate: (fleetName: string) => void;
  onToggle: (candidates: TFleetItems, fleetId: number) => void;
};

const FleetsMenu = ({
  candidates,
  fleets,
  onCancel,
  onCreate,
  onToggle
}: Props): ReactElement => (
  <div className="FleetsMenu">
    <ModalEditorHeader
      onCancel={onCancel}
      title={`Manage associated ${ENV.FLEETS}`}
    />
    <div className="FleetsMenu__subheader app-body-2">
      Select {ENV.FLEETS} to associate with.
    </div>

    <ul className="FleetsMenu__list">
      {fleets.map((fleet: TFleet) => (
        <FleetItem
          candidates={candidates}
          fleet={fleet}
          key={fleet.id}
          onToggle={onToggle}
        />
      ))}

      <li className="FleetsMenu__btn">
        <CreateFleet onCreate={onCreate} />
      </li>
    </ul>
  </div>
);

FleetsMenu.propTypes = {
  candidates: PropTypes.exact({
    companies: PropTypes.arrayOf(PropTypes.number),
    farms: PropTypes.arrayOf(PropTypes.number),
    vessels: PropTypes.arrayOf(PropTypes.number)
  }),
  fleets: PropTypes.arrayOf(PropTypes.exact(PTFleet)).isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired
};

export { FleetsMenu };
