import React, { FC, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';

import './TrialForm.scss';
import { MESSAGES, PrimaryButton, RULES } from 'components';

/**/
type Props = {
  responseStatus?: string;
  onSubmit: (values: any) => void;
};

/**
 *
 */
const TrialForm: FC<Props> = ({ responseStatus, onSubmit }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (responseStatus && form) {
      form.setFields([{ errors: [responseStatus], name: 'email' }]);
    }
  }, [responseStatus, form]);

  return (
    <Form className="TrialForm" form={form} onFinish={onSubmit}>
      <Form.Item name="email" rules={RULES}>
        <Input
          allowClear={true}
          autoComplete="off"
          placeholder={MESSAGES.PLACEHOLDER}
          size="large"
        />
      </Form.Item>

      <Form.Item>
        <PrimaryButton htmlType="submit" size="M">
          Get Started
        </PrimaryButton>
      </Form.Item>
    </Form>
  );
};

/**/
TrialForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  responseStatus: PropTypes.string
};

export { TrialForm };
