import React from 'react';
import PropTypes from 'prop-types';

import './FavManager.scss';
import { AddButton, TInjectedWithFleetsProps } from 'components';
import { ENV } from 'other';
import withFleets from 'components/common/vessel/withFleets/withFleets';

import { TFleetItems } from 'types';

type Props = TInjectedWithFleetsProps & {
  candidates: Partial<TFleetItems>;
};

const Component = ({ candidates, onFavClick }: Props) => {
  const handleAdd = () => onFavClick(candidates);

  return (
    <div className="FavManager">
      <AddButton label={`Add all to my ${ENV.FLEET}`} onAdd={handleAdd} />
    </div>
  );
};

Component.propTypes = {
  onFavClick: PropTypes.func.isRequired,
  vessels: PropTypes.array
};

export const FavManager = withFleets(Component);
