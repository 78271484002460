import React, { ReactElement } from 'react';

import AbstractNotificationContent from './AbstractNotificationContent';
import { PROVIDER_IMAGE_URL, ROUTES } from 'other';
import { TPromotionNotification } from './helpers';

/**
 *
 */
class PromoNotificationObject extends AbstractNotificationContent<TPromotionNotification> {
  protected defaultIcon = 'newspaper';

  /**/
  public getContent(): ReactElement {
    try {
      const { articleName, sourceName } = this.body;

      return (
        <span>
          <span>
            <b>{sourceName}</b> published the article{' '}
          </span>
          <span className="NotificationListItem__link">{articleName}</span>
        </span>
      );
    } catch (e) {
      window.console.error(e);
      return <span>Something went wrong.</span>;
    }
  }

  /**/
  public getIcon(): ReactElement {
    const { sourceLogo } = this.body;
    if (!sourceLogo) return this.getDefaultIcon();

    const src = PROVIDER_IMAGE_URL + '/' + sourceLogo.path;
    return <img src={src} alt="Logo" className="NotificationListItem__logo" />;
  }

  /**/
  public getPath(): string {
    return ROUTES.NEWS + '/' + this.body.articleId;
  }
}

/**/
export default PromoNotificationObject;
