/**
 * Hides the virtual keyboard on mobile devices.
 */
import { PLACEHOLDER } from 'other';

export function hideKeyboard(): void {
  const field = document.createElement('input');
  field.setAttribute('type', 'text');
  field.style.position = 'fixed';
  field.style.left = '-1000px';
  document.body.appendChild(field);

  setTimeout(() => {
    field.focus();
    setTimeout(() => {
      field.setAttribute('style', 'display:none;');
    }, 50);
    setTimeout(() => {
      document.body.removeChild(field);
    }, 50);
  }, 50);
}

/** 'All' option for antd Select. */
export const ALL_VALUE = 'All' as any;
export const NONE_VALUE = 'None' as any;

/**
 * A callback involved by antd Select to filter select options each time user hits keyboard.
 * @param inputValue
 * @param option
 */
export function filterSelectOption(inputValue: string, option: any): boolean {
  const { children, label, value } = option;
  return (
    (children && children.toLowerCase().includes(inputValue.toLowerCase())) ||
    (label && label.toLowerCase().includes(inputValue.toLowerCase())) ||
    (typeof value === 'string' &&
      value.toLowerCase().includes(inputValue.toLowerCase()))
  );
}

export function getFarmPlaceholder(id: number): string {
  switch (id) {
    case 1:
      return PLACEHOLDER.FISHFARM;
    case 2:
      return PLACEHOLDER.SMOLT;
    case 4:
      return PLACEHOLDER.FACTORY;
    default:
      return PLACEHOLDER.BROOD;
  }
}
