import React from 'react';
import ReactQuill from 'react-quill';
import { formats, modules, RTEBasicPropTypes } from 'components';

/**/
type TRTEBasicProps = {
  emptyStringAlias?: string;
  formats?: string[];
  modules?: { [key: string]: any };
  onChange?: (markup: string) => void;
  value?: string;
};

type State = {};

/**
 *
 */
export class RTEBasic<P, S> extends React.PureComponent<
  TRTEBasicProps & P,
  State & S
> {
  static defaultProps;
  static EMPTY_STRING_ALIAS = '<span />';
  static FORMATS = formats;
  static MODULES = modules;
  static propTypes;
  protected quillRef: any;

  assignQuill = (el) => (this.quillRef = el);

  getValue = (): string => {
    const { emptyStringAlias, value } = this.props;
    return value || emptyStringAlias;
  };

  handleChange = (value: string): void => {
    const { emptyStringAlias, onChange } = this.props;
    const change = value === emptyStringAlias ? '' : value;
    typeof onChange === 'function' && onChange(change);
  };

  render() {
    const { formats, modules } = this.props;
    const _value = this.getValue();

    return (
      <ReactQuill
        className="RTEBasic"
        formats={formats}
        modules={modules}
        onChange={this.handleChange}
        placeholder="Article body"
        ref={this.assignQuill}
        value={_value}
      />
    );
  }
}

/**
 *
 */
RTEBasic.propTypes = RTEBasicPropTypes;

RTEBasic.defaultProps = {
  emptyStringAlias: RTEBasic.EMPTY_STRING_ALIAS,
  formats: RTEBasic.FORMATS,
  modules: RTEBasic.MODULES
};
