import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

import { ROUTES } from 'other';
import {
  EEntityType,
  TDictionaries,
  TIdLocationDict,
  TTargetEntity
} from 'types';
import { TState } from 'store';

export const crmStatusFinder = ({ value: { en_GB } }: TIdLocationDict) =>
  en_GB.toUpperCase() === 'CRM';

export const selectStatuses = createSelector(
  (state: TState) => state.dictionaries,
  ({ fishFarmStatuses, vesselStatuses, companyStatuses }: TDictionaries) => [
    vesselStatuses.find(crmStatusFinder)?.id,
    companyStatuses.find(crmStatusFinder)?.id,
    fishFarmStatuses.find(crmStatusFinder)?.id
  ]
);

const getEntityLink = (
  targetEntity: TTargetEntity,
  [crmVesselStatusId, crmCompanyStatusId, crmFarmStatusId]: number[]
): string => {
  switch (targetEntity.type) {
    case EEntityType.COMPANY:
      return targetEntity.statusId === crmCompanyStatusId
        ? ROUTES.CRM_COMPANY
        : ROUTES.COMPANY;

    case EEntityType.FISHFARM:
      return targetEntity.statusId === crmFarmStatusId
        ? ROUTES.CRM_FARM
        : ROUTES.FARM;

    case EEntityType.SERVICE_PROVIDER:
      return ROUTES.SERVICE_PROVIDER;

    case EEntityType.VESSEL:
      return targetEntity.statusId === crmVesselStatusId
        ? ROUTES.CRM_VESSEL
        : ROUTES.VESSEL;

    default:
      throw new TypeError(`Unknown entity type: ${targetEntity.type}`);
  }
};

export const useTargetEntityLink = (targetEntity: TTargetEntity): string => {
  const statusIds = useSelector(selectStatuses);
  return targetEntity ? getEntityLink(targetEntity, statusIds) : '';
};
