import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import Popover from 'antd/lib/popover';

import './FleetsEditorItem.scss';
import {
  DefaultButton,
  EditableContent,
  IconButton,
  PrimaryButton
} from 'components';
import { ENV, toTitleCase } from 'other';
import { TFleet } from 'types';

/**/
type Props = {
  fleet: TFleet;
  onRemove: (fleetId: number) => void;
  onRename: (fleet: TFleet, newName: string) => void;
};
type State = {
  errorMessage: string;
  isEdited: boolean;
  isPromptVisible: boolean;
};

/**
 *
 */
class FleetsEditorItem extends React.PureComponent<Props, State> {
  static propTypes;
  state: State = {
    errorMessage: null,
    isEdited: false,
    isPromptVisible: false
  };

  clearErrorMessage = () => this.setState({ errorMessage: null });

  handleChange = (name: string) => {
    const errorMessage =
      name.length > 30
        ? `${toTitleCase(
            ENV.FLEET
          )} name shouldn't be longer than 30 characters!`
        : null;
    this.setState({ errorMessage });
  };

  handleSave = (_name: string) => {
    if (this.state.errorMessage) return;

    const { fleet, onRename } = this.props;
    const name = _name.trim();

    if (name && name !== fleet.name) {
      onRename(fleet, name);
    }
  };

  handleRemove = () => this.props.onRemove(this.props.fleet.id);

  handleVisibleChange = (isPromptVisible) => this.setState({ isPromptVisible });

  showPrompt = () => this.setState({ isPromptVisible: true });

  hidePrompt = () => this.setState({ isPromptVisible: false });

  prompt: ReactNode = (
    <div className="FleetsEditorItem__popover" data-testid="fleet-prompt">
      <DefaultButton onClick={this.hidePrompt}>Cancel</DefaultButton>

      <PrimaryButton onClick={this.handleRemove} className="app-ml-1">
        Remove
      </PrimaryButton>
    </div>
  );

  render() {
    const { fleet } = this.props;
    const { errorMessage } = this.state;
    const title = `Sure removing '${fleet.name}' ${ENV.FLEET}?`;

    return (
      <li className="FleetsEditorItem">
        <EditableContent
          errorMessage={errorMessage}
          onCancel={this.clearErrorMessage}
          onChange={this.handleChange}
          onSave={this.handleSave}
          text={fleet.name}
          title={`Edit ${ENV.FLEET} name`}
        />

        <Popover
          rootClassName="FleetsEditorItem__content"
          content={this.prompt}
          title={title}
          trigger="click"
          open={this.state.isPromptVisible}
          onOpenChange={this.handleVisibleChange}
          placement="topRight"
        >
          <IconButton
            className="FleetsEditorItem__button"
            icon="delete"
            onClick={this.showPrompt}
            size="XS"
            title={`Remove ${ENV.FLEET}`}
          />
        </Popover>
      </li>
    );
  }
}

/**/
FleetsEditorItem.propTypes = {
  fleet: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired
};

export { FleetsEditorItem };
