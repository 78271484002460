import React, { FC } from 'react';

import './CompanyCard.scss';
import {
  CompanyCardBasic,
  IconCustom,
  TInjectedWithFleetsProps
} from 'components';
import { TCompanyAF } from 'types';

type Props = TInjectedWithFleetsProps & {
  item: TCompanyAF;
};

const CompanyCard: FC<Props> = (props) => {
  const { favCompanyIds, item, onFavClick } = props;
  if (!item) return null;

  const handleFav = () => onFavClick({ companies: [item.id] });
  const iconName = favCompanyIds?.includes(item.id) ? 'star-filled' : 'star';

  return (
    <div className="CompanyCard">
      <CompanyCardBasic item={item}>
        <div className="CompanyCard__child">
          <span className="CompanyCard__fav" onClick={handleFav}>
            <IconCustom type={iconName} />
          </span>
        </div>
      </CompanyCardBasic>
    </div>
  );
};

export { CompanyCard };
