import { createAsyncActions, TProposalsState } from 'store';

export enum EProposalsActions {
  FETCH_PROPOSALS = 'proposals/FETCH_PROPOSALS',
  EDIT_PROPOSAL = 'proposals/EDIT_PROPOSAL',
  DELETE_PROPOSAL = 'proposals/DELETE_PROPOSAL',
  DELETE_RELATED_PROPOSALS = 'proposals/DELETE_RELATED_PROPOSALS',
  UPDATE_RELATED_PROPOSALS = 'proposals/UPDATE_RELATED_PROPOSALS',
  ADD_PROPOSAL = 'proposals/ADD_PROPOSAL'
}

export const fetchSet = createAsyncActions<TProposalsState>(
  EProposalsActions.FETCH_PROPOSALS
);
