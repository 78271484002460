import React, { ChangeEvent, FC } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Input from 'antd/lib/input';

import './TableSearch.scss';
import { IconCustom } from 'components';

const searchIcon = <IconCustom className="TableSearch__icon" type="search" />;

type Props = {
  className?: string;
  value: string;
  onChange: (query: string) => void;
};

const TableSearch: FC<Props> = ({ className, value, onChange }) => {
  const handleChange = ({
    target: { value: query }
  }: ChangeEvent<HTMLInputElement>): void => onChange(query);

  return (
    <Input
      className={classnames('TableSearch', className)}
      placeholder="Search"
      size="large"
      suffix={searchIcon}
      value={value}
      onChange={handleChange}
    />
  );
};

TableSearch.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export { TableSearch };
