import { EMapIceLayer } from 'types';

export const ICE_LAYER_MAP = {
  [EMapIceLayer.ANTARCTIC_1]: {
    label: 'Antarctic 1',
    imageLayerName: 'ice:ice_antarctic_1',
    borderLayerName: 'ice:ice_antarctic_1_scans_borders'
  },
  [EMapIceLayer.ANTARCTIC_2]: {
    label: 'Antarctic 2',
    imageLayerName: 'ice:ice_antarctic_2',
    borderLayerName: 'ice:ice_antarctic_2_scans_borders'
  },
  [EMapIceLayer.ANTARCTIC_3]: {
    label: 'Antarctic 3',
    imageLayerName: 'ice:ice_antarctic_3',
    borderLayerName: 'ice:ice_antarctic_3_scans_borders'
  },
  [EMapIceLayer.BARENTS_SEA]: {
    label: 'Barents Sea',
    imageLayerName: 'ice:ice_barents_sea',
    borderLayerName: 'ice:ice_barents_sea_scans_borders'
  },
  [EMapIceLayer.EAST_GREENLAND]: {
    label: 'East Greenland',
    imageLayerName: 'ice:ice_greenland',
    borderLayerName: 'ice:ice_greenland_scans_borders'
  },
  [EMapIceLayer.WEST_GREENLAND]: {
    label: 'West Greenland / Canada',
    imageLayerName: 'ice:ice_greenland_west',
    borderLayerName: 'ice:ice_greenland_west_scans_borders'
  },
  [EMapIceLayer.SVALBARD]: {
    label: 'Svalbard',
    imageLayerName: 'ice:ice_svalbard',
    borderLayerName: 'ice:ice_svalbard_scans_borders'
  }
};
