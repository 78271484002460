import { ComponentType } from 'react';
import { connect } from 'react-redux';

import { setFiltersAction } from 'store/filters/filtersActions';
import { EFarm } from 'types';
import { TState } from 'store';

const mapStateToProps = ({ dictionaries, filters }: TState) => ({
  allOptionLabel: 'All facilities',
  options: dictionaries.fishFarmTypes,
  value: filters.facilityValue
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (value: EFarm[]) =>
    dispatch(setFiltersAction({ facilityValue: value }))
});

export function buildFacilityFilter(Component: ComponentType) {
  return connect(mapStateToProps, mapDispatchToProps)(Component);
}
