import React, { ReactElement, createRef } from 'react';
import classnames from 'classnames';
import Popover from 'antd/lib/popover';

import './withEllipsisMenu.scss';
import { getDisplayName } from '../../helpers/helpers';
import { IconButton } from 'components';

/**/
type Options = {
  buttonClass?: string;
  popoverClass?: string;
};

/**
 * HOC that wraps the EllipsisButton component with a given menu element.
 * @param MenuElement: menu element.
 * @param options: corresponding CSS classes.
 */
export function withEllipsisMenu(
  MenuElement: ReactElement,
  options?: Options
): ReactElement {
  class WithEllipsisMenu extends React.PureComponent {
    static displayName = `WithEllipsisMenu(${getDisplayName(MenuElement)})`;
    private popover = createRef<HTMLDivElement>();

    state = { isVisible: false };

    handleClick = () => this.setState({ isVisible: !this.state.isVisible });

    hide = () => this.setState({ isVisible: false });

    render() {
      const { buttonClass, popoverClass } = options || {};
      const content = <div onClick={this.hide}>{MenuElement}</div>;

      const _buttonClass: string = classnames(
        'EllipsisMenu__button',
        buttonClass
      );

      const _popoverClass: string = classnames(
        'EllipsisMenu__popover',
        popoverClass
      );

      return (
        <div className="EllipsisMenu" ref={this.popover}>
          <Popover
            content={content}
            overlayClassName={_popoverClass}
            placement="bottomRight"
            title={null}
            trigger="click"
            open={this.state.isVisible}
          >
            <IconButton
              className={_buttonClass}
              data-testid="EllipsisButton"
              icon="ellipsis"
              onClick={this.handleClick}
              size="S"
              title="Show menu"
            />
          </Popover>
        </div>
      );
    }
  }

  return WithEllipsisMenu as any;
}
