import { addKeyAndReverse } from 'pages/CRM/helpers/helpers';
import {
  deleteRelatedProposals,
  updateRelatedProposals
} from '../Proposals/ProposalsActions';
import { editCrmCompany } from '../CrmCompanies/CrmCompaniesActions';
import { ENDPOINTS, updateArrayItem } from 'other';
import { getEntity } from 'components/CRM/helpers';
import { selectStatuses } from '../../../pages/CRM/helpers/useTargetEntityLink';
import { updateRelatedCrmContacts } from '../CrmContacts/CrmContactsActions';

import { EEntityType, TCrmVessel, TCrmCompany, TOwner } from 'types';
import { ECrmVesselsActions, fetchSet } from './CrmVesselsConstants';
import { TState } from 'store';

function findVesselCompany(crmCompanies: TCrmCompany[], vesselId: number) {
  return crmCompanies.find(({ vessels }) =>
    vessels.find(({ id }) => id === vesselId)
  );
}

export function fetchCrmVessels() {
  return async (dispatch) => {
    dispatch(fetchSet.request());

    try {
      const vessels = await getEntity<TCrmVessel[]>(ENDPOINTS.CRM_VESSELS);

      dispatch(
        fetchSet.success({
          crmVessels: vessels ? addKeyAndReverse(vessels) : [],
          isPending: false
        })
      );
    } catch (e) {
      dispatch(fetchSet.error(e));
    }
  };
}

export function addCrmVessel(newVessel: TCrmVessel) {
  return (dispatch, getState) => {
    const {
      crmVessels: { crmVessels }
    }: TState = getState();

    if (crmVessels) {
      dispatch({
        payload: {
          crmVessels: [newVessel, ...crmVessels]
        },
        type: ECrmVesselsActions.ADD_VESSEL
      });
    }
  };
}

export function editCrmVessel(updatedVessel: TCrmVessel) {
  return (dispatch, getState) => {
    const [crmVesselStatusId] = selectStatuses(getState());
    const {
      crmVessels: { crmVessels },
      crmCompanies: { crmCompanies },
      crmContacts: { crmContacts },
      proposals: { proposals }
    }: TState = getState();

    const updatedEntity = {
      country: updatedVessel.flag,
      id: updatedVessel.id,
      name: updatedVessel.name,
      statusId: crmVesselStatusId,
      type: EEntityType.VESSEL
    };

    if (crmVessels) {
      dispatch({
        payload: {
          crmVessels: updateArrayItem(updatedVessel, crmVessels)
        },
        type: ECrmVesselsActions.EDIT_VESSEL
      });
    }

    if (crmCompanies) {
      const vesselCompany = findVesselCompany(crmCompanies, updatedVessel.id);

      vesselCompany.vessels = updateArrayItem(
        updatedVessel,
        vesselCompany.vessels
      );

      dispatch(editCrmCompany(vesselCompany));
    }

    if (crmContacts) {
      dispatch(
        updateRelatedCrmContacts(
          updatedVessel.id,
          EEntityType.VESSEL,
          updatedEntity
        )
      );
    }

    if (proposals) {
      dispatch(
        updateRelatedProposals(
          updatedVessel.id,
          EEntityType.VESSEL,
          updatedEntity
        )
      );
    }
  };
}

export function deleteCrmVessel(vesselId: number) {
  return (dispatch, getState) => {
    const {
      crmVessels: { crmVessels },
      crmCompanies: { crmCompanies },
      crmContacts: { crmContacts },
      proposals: { proposals }
    }: TState = getState();

    if (crmVessels) {
      dispatch({
        payload: {
          crmVessels: crmVessels.filter(({ id }) => id !== vesselId)
        },
        type: ECrmVesselsActions.DELETE_VESSEL
      });
    }

    if (crmCompanies) {
      const vesselCompany = findVesselCompany(crmCompanies, vesselId);

      vesselCompany.vessels = vesselCompany.vessels.filter(
        ({ id }) => id !== vesselId
      );

      dispatch(editCrmCompany(vesselCompany));
    }

    if (proposals) {
      dispatch(deleteRelatedProposals(vesselId, EEntityType.VESSEL));
    }

    if (crmContacts) {
      dispatch(updateRelatedCrmContacts(vesselId, EEntityType.VESSEL));
    }
  };
}

export function updateCrmVesselsOwner(
  ownerId: number,
  owner?: Partial<TOwner>
) {
  return (dispatch, getState) => {
    const {
      crmVessels: { crmVessels }
    }: TState = getState();

    if (!crmVessels) return;

    const updatedCrmVessels = crmVessels.map((vessel) => {
      const { id } = vessel.owner || {};

      if (id === ownerId) {
        vessel.owner = owner ? { ...vessel.owner, ...owner } : null;
      }

      return vessel;
    });

    dispatch({
      payload: {
        proposals: updatedCrmVessels
      },
      type: ECrmVesselsActions.UPDATE_RELATED_VESSELS
    });
  };
}
