import { basicInitialState } from '../../_utils/basicInitialState';
import { TProviderEditorState } from 'store';

export const providerEditorInitialState: TProviderEditorState = {
  ...basicInitialState,
  addresses: null,
  banner: null,
  branches: null,
  contacts: null,
  eventIds: null,
  handledObject: null,
  handledObjectType: null,
  id: null,
  isModified: false,
  isNotFound: false,
  logo: null,
  longDescription: null,
  name: null,
  photos: null,
  products: null,
  published: false,
  settings: null,
  shortDescription: null,
  videos: null,
  website: null,
  uploadProgress: null
};
