import React, { ReactElement, FC, memo } from 'react';
import PropTypes from 'prop-types';

import './CrmSection.scss';
import {
  DefaultButton,
  ModalWrapper,
  TInjectedModalStateProps
} from 'components';
import { IconCustom } from 'components/common/IconCustom/IconCustom';
import { withModalState } from 'components/withModalState';

/**/
const PlusIcon = <IconCustom type="plus" />;

type Props = TInjectedModalStateProps & {
  addButtonTitle: string;
  children: (
    hideModal: () => void,
    showModal: () => void
  ) => ReactElement | ReactElement[];
  modalContent: (
    hideModal: () => void,
    showModal: () => void
  ) => ReactElement | ReactElement[];
  modalProps?: Record<string, any>;
  modalTitle: string;
};

const defaultProps = {
  className: 'CrmSection__modal',
  width: '625px'
};

/**
 *
 */
const Component: FC<Props> = ({
  addButtonTitle,
  modalProps,
  modalTitle,
  modalContent,
  children,
  isModalVisible,
  onModalHide,
  onModalShow
}) => {
  return (
    <section className="CrmSection">
      <div className="CrmSection__modalHolder">
        <DefaultButton icon={PlusIcon} size="M" onClick={onModalShow}>
          {addButtonTitle}
        </DefaultButton>

        <ModalWrapper
          isOpen={isModalVisible}
          modalProps={{ ...defaultProps, ...modalProps }}
          title={modalTitle}
          onCancel={onModalHide}
        >
          {modalContent(onModalHide, onModalShow)}
        </ModalWrapper>
      </div>

      <div className="CrmSection__content">
        {children(onModalHide, onModalShow)}
      </div>
    </section>
  );
};

Component.propTypes = {
  addButtonTitle: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  modalContent: PropTypes.func.isRequired,
  modalProps: PropTypes.object,
  modalTitle: PropTypes.string.isRequired,
  onModalHide: PropTypes.func.isRequired,
  onModalShow: PropTypes.func.isRequired
};

export const CrmSection = memo(withModalState(Component));
