import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';

import { getDisplayName } from '../helpers/helpers';
import { EUserAuthority } from 'types';
import { TSessionState, TState } from 'store';

export type TInjectedWithConnection = {
  isConnectionPoor: boolean;
};

/**/
function withConnection<P extends TInjectedWithConnection>(
  WrappedComponent: ComponentType<P>
): ComponentType<Omit<P, keyof TInjectedWithConnection>> {
  function HOC(props: Omit<P, keyof TInjectedWithConnection>) {
    const session = useSelector(
      (state: TState): TSessionState => state.session
    );

    const isConnectionController = session.user?.userInfo.authorities.includes(
      EUserAuthority.POOR_CONNECTION
    );

    const isConnectionPoor = session.isConnectionPoor && isConnectionController;

    return (
      <WrappedComponent isConnectionPoor={isConnectionPoor} {...(props as P)} />
    );
  }

  HOC.displayName = `withConnection(${getDisplayName(WrappedComponent)})`;
  return HOC;
}

/**/
export { withConnection };
