import React, { LazyExoticComponent, Suspense } from 'react';
import message from 'antd/lib/message';
import { loader } from './Loading/LoadingSimple';

/**/
type State = { isReady: boolean };

/**
 *
 * @param WrappedComponent
 * @param includeWindy
 */
function withMap<P>(
  WrappedComponent: LazyExoticComponent<any>,
  includeWindy?: boolean
) {
  return class MapLoader extends React.PureComponent<P, State> {
    static displayName = 'MapLoader';
    static LEAFLET_URL = 'https://unpkg.com/leaflet@1.4.0/dist/leaflet.js';
    static WINDY_URL = 'https://api4.windy.com/assets/libBoot.js';

    state: State = { isReady: false };

    /**/
    componentDidMount() {
      this.checkLeafLet();
    }

    /**/
    reportError(): void {
      message.error('Failed loading. Please refresh the page');
      window.console.error(`Error loading '${this['src']}'`);
    }

    /**/
    checkLeafLet() {
      if (window.L) return this.checkWindy();

      const script = document.createElement('script');
      script.src = MapLoader.LEAFLET_URL;
      script.onload = () => this.onLeafletOk();
      script.onerror = this.reportError;
      document.body.appendChild(script);

      const styles = document.createElement('link');
      styles.rel = 'stylesheet';
      styles.href = 'https://unpkg.com/leaflet@1.4.0/dist/leaflet.css';
      document.head.appendChild(styles);
    }

    /**/
    checkWindy() {
      if (window['windyInit'] || !includeWindy) {
        return this.onLoad();
      }

      const script = document.createElement('script');
      script.src = MapLoader.WINDY_URL;
      script.onload = () => this.onLoad();
      script.onerror = this.reportError;
      document.body.appendChild(script);
    }

    /**/
    onLeafletOk() {
      this.checkWindy();
      tweakLeaflet();
    }

    /**/
    onLoad() {
      this.setState({ isReady: true });
    }

    /**/
    render() {
      return this.state.isReady ? (
        <Suspense fallback={loader}>
          <WrappedComponent {...(this.props as P)} />
        </Suspense>
      ) : (
        loader
      );
    }
  };
}

/**
 *
 */
function tweakLeaflet() {
  window.L['ClickableTooltip'] = window.L.Tooltip.extend({
    onAdd: function (map) {
      window.L.Tooltip.prototype.onAdd.call(this, map);
      const el = this.getElement();
      el.addEventListener('click', () => this.fire('click'));
      el.style.pointerEvents = 'auto';
    }
  });

  window.L['Popup2'] = window.L.Popup.extend({
    _initLayout: function () {
      const prefix = 'leaflet-popup';
      const container = (this._container = window.L.DomUtil.create(
        'div',
        prefix + ' ' + (this.options.className || '') + ' leaflet-zoom-animated'
      ));

      const wrapper = (this._wrapper = window.L.DomUtil.create(
        'div',
        prefix + '-content-wrapper',
        container
      ));

      this._contentNode = window.L.DomUtil.create(
        'div',
        prefix + '-content',
        wrapper
      );

      window.L.DomEvent.disableClickPropagation(wrapper);
      window.L.DomEvent.on(
        wrapper,
        'contextmenu',
        window.L.DomEvent.stopPropagation
      );

      this._tipContainer = window.L.DomUtil.create(
        'div',
        prefix + '-tip-container',
        container
      );

      this._tip = window.L.DomUtil.create(
        'div',
        prefix + '-tip',
        this._tipContainer
      );

      if (this.options.closeButton) {
        const closeButton = (this._closeButton = window.L.DomUtil.create(
          'a',
          prefix + '-close-button',
          container
        ));
        closeButton.href = '#close';
        closeButton.innerHTML = '&#215;';

        window.L.DomEvent.on(
          closeButton,
          'click',
          this._onCloseButtonClick,
          this
        );
      }
    }
  });
}

/**/
export { withMap };
