import React, { FC } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import './NoteCard.scss';
import { CrmItemCard } from 'components';
import { DATE_FORMAT_DMY } from 'other';

import { EEntityType, TNote } from 'types';

type Props = {
  onEdit: (noteId: number) => void;
  onRemove: (noteId: number) => void;
  note: TNote;
  showModal: () => void;
};

const NoteCard: FC<Props> = ({
  onEdit,
  onRemove,
  note: { title, description, createdBy, createdAt, id },
  showModal
}) => {
  const { lastName, firstName, username } = createdBy || {};
  const author = lastName && firstName ? `${firstName} ${lastName}` : username;
  const handleEdit = () => {
    onEdit(id);
    showModal();
  };
  const handleDelete = () => onRemove(id);

  return (
    // @ts-ignore
    <CrmItemCard
      authorId={createdBy?.id}
      onDelete={handleDelete}
      onEdit={handleEdit}
    >
      <article className="NoteCard">
        {createdAt && (
          <span className="NoteCard__date">
            {dayjs(createdAt).format(DATE_FORMAT_DMY)}
          </span>
        )}

        {author && <span className="NoteCard__author">{author}</span>}

        <header className="NoteCard__header">{title}</header>

        <div
          className="NoteCard__description"
          dangerouslySetInnerHTML={{
            __html: description
          }}
        />
      </article>
    </CrmItemCard>
  );
};

NoteCard.propTypes = {
  note: PropTypes.exact({
    createdAt: PropTypes.string,
    createdBy: PropTypes.exact({
      firstName: PropTypes.string,
      id: PropTypes.number.isRequired,
      lastName: PropTypes.string,
      username: PropTypes.string
    }).isRequired,
    description: PropTypes.string,
    id: PropTypes.number.isRequired,
    targetId: PropTypes.number,
    title: PropTypes.string,
    type: PropTypes.oneOf(Object.values(EEntityType))
  }),
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired
};

export { NoteCard };
