import React from 'react';
import classnames from 'classnames';
import Popover from 'antd/lib/popover';

import './PopoverButton.scss';
import { ActionButtonReached, IconCustom, isMobile } from 'components';

/**/
type Props = {
  children: React.ReactElement;
  icon?: string;
  label: string | React.ReactElement;
  placement?: 'bottom' | 'bottomLeft' | 'bottomRight';
};

/**/
type State = { isVisible: boolean };

/**
 *
 */
class PopoverButton extends React.PureComponent<Props, State> {
  private isMobile = isMobile();
  state: State = { isVisible: false };

  /**/
  handleClose = () => this.setState({ isVisible: false });

  /**/
  handleFilterVisibility = (isVisible) => this.setState({ isVisible });

  /**/
  renderLabel() {
    const { icon, label } = this.props;
    const cls = classnames('PopoverButton__icon', {
      'PopoverButton__icon--active': this.state.isVisible
    });

    return (
      <span className="PopoverButton__label">
        {icon && <IconCustom className={cls} type={icon} />}
        <span className="PopoverButton__text">{label}</span>
      </span>
    );
  }

  /**/
  render() {
    const { children, placement } = this.props;
    const { isVisible } = this.state;
    const place = this.isMobile ? 'bottom' : placement;

    const cls = classnames('PopoverButton__button', {
      'PopoverButton__button--visible': isVisible
    });

    const innerCls = classnames('PopoverButton__inner', {
      'PopoverButton__inner--mobile': this.isMobile
    });

    return (
      <div className="PopoverButton">
        <div className="PopoverButton__container">
          <Popover
            arrow={!this.isMobile}
            content={children}
            trigger="click"
            open={isVisible}
            onOpenChange={this.handleFilterVisibility}
            overlayClassName={innerCls}
            placement={place}
          >
            <ActionButtonReached
              className={cls}
              iconRight={this.isMobile ? void 0 : 'caret-down'}
              size="S"
            >
              {this.renderLabel()}
            </ActionButtonReached>
          </Popover>
        </div>
      </div>
    );
  }
}

/**/
export { PopoverButton };
