import React, { Component, ReactElement } from 'react';
import PropTypes from 'prop-types';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';

import './NoteForm.scss';
import { RTEBasic, SaveCancelGroup } from 'components';

import { TNote } from 'types';

type TFormData = {
  title: string;
  description?: string;
};

type Props = {
  hideModal: () => void;
  onFormSubmit: (formData: TFormData) => void;
  initialValues: TNote;
  onFormClose: () => void;
};

class NoteForm extends Component<Props> {
  static propTypes;

  componentWillUnmount(): void {
    this.props.onFormClose();
  }

  render(): ReactElement {
    const { onFormSubmit, hideModal, initialValues } = this.props;
    const handleFinish = (formData: TFormData) => {
      onFormSubmit(formData);
      hideModal();
    };

    return (
      <Form
        className="NoteEditor"
        initialValues={initialValues}
        layout="vertical"
        name="basic"
        onFinish={handleFinish}
      >
        <Form.Item label="title" name="title">
          <Input />
        </Form.Item>

        <Form.Item label="description" name="description">
          <RTEBasic />
        </Form.Item>

        <Form.Item>
          <SaveCancelGroup onCancel={hideModal} />
        </Form.Item>
      </Form>
    );
  }
}

NoteForm.propTypes = {
  hideModal: PropTypes.func.isRequired,
  initialValues: PropTypes.any,
  onFormSubmit: PropTypes.func.isRequired
};

export { NoteForm };
