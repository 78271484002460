import React, { FC } from 'react';
import PropTypes from 'prop-types';
import message from 'antd/lib/message';
import Upload from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';

import './FileUploader.scss';
import { DefaultButton } from 'components/common/buttons/DefaultButton/DefaultButton';
import { IconCustom } from 'components/common/IconCustom/IconCustom';

/**/
const MAX_SIZE = {
  label: '20MB',
  value: 20971520
};

const icon = <IconCustom type="upload" />;

type Props = {
  fileList: UploadFile[];
  setFileList: (fileList: UploadFile[]) => void;
};

/**
 *
 */
const FileUploader: FC<Props> = ({ setFileList, fileList }) => {
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const beforeUpload = (file: File): boolean => {
    if (file.size > MAX_SIZE.value) {
      message.error(`File size should be less than ${MAX_SIZE.label}!`);
    }
    return false;
  };

  return (
    <Upload
      beforeUpload={beforeUpload}
      className="FileUploader"
      fileList={fileList}
      name="file"
      onChange={handleChange}
    >
      <DefaultButton icon={icon}>Click to Upload</DefaultButton>
    </Upload>
  );
};

/**/
FileUploader.propTypes = {
  fileList: PropTypes.array.isRequired,
  setFileList: PropTypes.func.isRequired
};

export { FileUploader };
