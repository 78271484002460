import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import isequal from 'lodash.isequal';

import { FarmsFilter, FilterWrapper } from 'components';
import { filtersBlankState, TFiltersModel, TState } from 'store';
import { resetFiltersAction } from 'store/filters/filtersActions';

type Props = {
  isFilterSet: boolean;
  resetFilter: () => void;
};

const Component: React.FC<Props> = ({ isFilterSet, resetFilter }) => (
  <FilterWrapper isFilterSet={isFilterSet} resetFilter={resetFilter}>
    <FarmsFilter />
  </FilterWrapper>
);

Component.propTypes = {
  isFilterSet: PropTypes.bool,
  resetFilter: PropTypes.func.isRequired
};

function isFarmFilterSet(settings: TFiltersModel): boolean {
  const { companyValue, countryValue, facilityValue, fleetValue, specieValue } =
    filtersBlankState;

  const blank = {
    companyValue,
    countryValue,
    facilityValue,
    fleetValue,
    specieValue
  };

  const current = {
    companyValue: settings.companyValue,
    countryValue: settings.countryValue,
    facilityValue: settings.facilityValue || facilityValue,
    fleetValue: settings.fleetValue,
    specieValue: settings.specieValue
  };

  return !isequal(blank, current);
}

const mapStateToProps = ({ filters }: TState) => ({
  isFilterSet: isFarmFilterSet(filters)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ resetFilter: resetFiltersAction }, dispatch);

export const FarmsPageFilter = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
