export enum ECrmComponentTabs {
  LEAD = 'lead',
  MEETING_BOOKED = 'meeting_booked',
  MEETING_HELD = 'meeting_held',
  ONGOING = 'proposal',
  DEAL = 'deal',
  INVOICED = 'invoiced',
  HISTORY = 'history',
  CONTACTS = 'contacts',
  NOTES = 'notes'
}
