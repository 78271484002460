import { minMaxLenValidator } from 'other';

const titleRules = minMaxLenValidator('Title', 160, 5);
const shortDescRules = minMaxLenValidator('Short description', 1000, 5);
const languageRules = [{ required: true, message: 'Please select language!' }];

export type ArticleFormFields = {
  languageIso?: string;
  longDescription: string;
  shortDescription: string;
  title: string;
};

export const articleFormRules = { languageRules, shortDescRules, titleRules };
