import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './ItemCard.scss';
import { Flag, Placeholder } from 'components';

export type TCardItem = {
  flag: string;
  name: string;
  placeholder: string;
  src?: string;
};

type Props = {
  children?: ReactElement;
  item: TCardItem;
  path: string;
};

function ItemCard({ children, item, path }: Props) {
  return (
    <div className="ItemCard">
      {children}

      <div className="app-photo-container">
        <figure className="ItemCard__photo">
          <Link className="app-fixed-ratio-container ItemCard__link" to={path}>
            <div className="app-fixed-ratio-content">
              <Placeholder
                alt={item.name}
                placeholder={item.placeholder}
                src={item.src}
              />
            </div>
          </Link>
        </figure>
      </div>

      <div className="ItemCard__caption">
        <Flag className="ItemCard__flag" flagIso={item.flag} />
        <h6 className="ItemCard__name app-h6">
          <Link to={path}> {item.name}</Link>
        </h6>
      </div>
    </div>
  );
}

ItemCard.propTypes = {
  item: PropTypes.shape({
    flag: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    src: PropTypes.string
  }),
  path: PropTypes.string.isRequired
};

export { ItemCard };
