import React from 'react';
import classnames from 'classnames';
import Popover from 'antd/lib/popover';

import './RateSelect.scss';
import { isTouchDevice } from 'other';
import {
  EPlayRate,
  IconCustom,
  isApp,
  isIPhone,
  isIPad,
  LinkButton,
  TextButton
} from 'components';

/**/
type Props = {
  onChange: (rate: number) => void;
  playRate: EPlayRate;
};
type State = {
  isVisible: boolean;
};

/**/
const PLAY_RATES = [
  { label: 'normal', key: 1 },
  { label: '2x', key: 2 },
  { label: '4x', key: 4 },
  { label: '8x', key: 8 }
];

/**
 *
 */
class RateSelect extends React.PureComponent<Props, State> {
  private _isTouchDevice = isTouchDevice();
  state: State = { isVisible: false };

  /**/
  handleVisibility = (isVisible) => this.setState({ isVisible });

  /**/
  renderButton = ({ label, key }) => {
    const { onChange, playRate } = this.props;

    const handleClick = () => {
      this.handleVisibility(false);
      onChange(key);
    };

    if (isIPhone() || isApp()) {
      const cls = classnames('ant-btn RateSelect__item', {
        'RateSelect__item--link': playRate === key
      });
      return (
        <div className={cls} key={key} onMouseOver={handleClick}>
          {label}
        </div>
      );
    }

    return playRate === key ? (
      <LinkButton
        className="RateSelect__button"
        key={key}
        onClick={handleClick}
        onTouchEnd={handleClick}
      >
        {label}
      </LinkButton>
    ) : (
      <TextButton
        className="RateSelect__button"
        key={key}
        onClick={handleClick}
        onTouchEnd={handleClick}
      >
        {label}
      </TextButton>
    );
  };

  /**/
  getTrigger(): string | string[] {
    if (!this._isTouchDevice) return 'click';
    if (isIPad()) return ['click', 'focus'];
    return ['hover', 'focus'];
  }

  /**/
  render() {
    const content = (
      <div className="RateSelect__menu" data-testid="fleet-prompt">
        {PLAY_RATES.map(this.renderButton)}
      </div>
    );

    return (
      <Popover
        content={content}
        placement="topRight"
        title={null}
        trigger={this.getTrigger() as any}
        open={this.state.isVisible}
        onOpenChange={this.handleVisibility}
      >
        <LinkButton title="Select rate">
          {this.props.playRate}x
          <IconCustom type="chevron-up" />
        </LinkButton>
      </Popover>
    );
  }
}

/**/
export { RateSelect };
