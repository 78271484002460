import { isDictionary } from 'other';

/**
 * Creates a GET request string out of given dictionary of parameters.
 * TODO: skip empty arrays and dictionaries.
 */
export function buildQueryString(params: {}): string {
  const paramArr = Object.entries(params)
    .filter(
      ([key, value]) => value !== void 0 && value !== null && value !== ''
    )
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return `${key}=${value.join(',')}`;
      } else if (isDictionary(value)) {
        return buildQueryString(value);
      }
      return `${key}=${value}`;
    });
  return paramArr.join('&');
}
