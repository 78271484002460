import { createAsyncActions, TVesselsLocationsState } from 'store';

export enum EVesselsLocationsActions {
  FETCH_LOCATIONS = 'vesselsLocations/FETCH_LOCATIONS',
  START_WATCHING_LOCATIONS = 'vesselsLocations/START_WATCHING_LOCATIONS',
  STOP_WATCHING_LOCATIONS = 'vesselsLocations/STOP_WATCHING_LOCATIONS'
}

export const locationsSet = createAsyncActions<TVesselsLocationsState>(
  EVesselsLocationsActions.FETCH_LOCATIONS
);
