import React, { Component, ComponentType } from 'react';
import { SettingsService } from 'services';

export type TInjectedWithLocalStorageProps = {
  localStorageState: any;
  resetLocalStorage: () => void;
  writeLocalStorage: <T>(key: string, value: T) => void;
};

const withLocalStorage = <P extends TInjectedWithLocalStorageProps>(
  WrappedComponent: ComponentType<P>,
  localStorageKey: string,
  initialState: Record<string, any>
): ComponentType =>
  class HOC extends Component {
    constructor(props) {
      super(props);

      this.state = {
        [localStorageKey]: {
          ...initialState,
          ...SettingsService.readSettings()[localStorageKey]
        }
      };
    }

    resetLocalStorage = () => {
      const initialSettings = {
        [localStorageKey]: { ...initialState }
      };

      this.setState(initialSettings, () =>
        SettingsService.writeSettings(initialSettings)
      );
    };

    writeLocalStorage = (key, value) => {
      const newSettings = {
        [localStorageKey]: {
          ...this.state[localStorageKey],
          [key]: value
        }
      };

      this.setState(newSettings, () =>
        SettingsService.writeSettings(newSettings)
      );
    };

    render() {
      const updatedProps = {
        ...(this.props as P),
        localStorageState: this.state[localStorageKey],
        resetLocalStorage: this.resetLocalStorage,
        writeLocalStorage: this.writeLocalStorage
      };

      return <WrappedComponent {...updatedProps} />;
    }
  };

export default withLocalStorage;
