import React, { ComponentType, ReactElement } from 'react';
import PropTypes from 'prop-types';
import CellRendererParams from 'react-virtualized/dist/commonjs/Grid';
import Grid from 'react-virtualized/dist/commonjs/Grid';

import './VirtualList.scss';
import { GotoTopButton, isMobile, TInjectedWithFleetsProps } from 'components';
import withFleets from '../withFleets/withFleets';

import { TId } from 'types';

type Props<T extends TId> = TInjectedWithFleetsProps & {
  component: ComponentType<T>;
  height: number;
  items: T[];
  scrollToRow: number;
  width: number;
  onItemClick: (rowIndex: number) => void;
};

/**/
class Component<T extends TId> extends React.PureComponent<Props<T>> {
  static propTypes;
  private static CELL_RATIO = 1.3;
  private static MIN_CARD_WIDTH = 220;
  private static SCROLL_BAR_WIDTH = 16;

  getAdjustedWidth(): number {
    const { width } = this.props;
    return isMobile() ? width : width - Component.SCROLL_BAR_WIDTH;
  }

  getColCount(): number {
    return Math.floor(this.getAdjustedWidth() / Component.MIN_CARD_WIDTH);
  }

  /**
   * Returns a set of parameters expected by 'react-virtualized' Grid component.
   * We set them depending on the viewport width and the list items count.
   */
  getGridParams() {
    const adjustedWidth = this.getAdjustedWidth();
    const columnCount = this.getColCount() || 1;
    const columnWidth = Math.floor(adjustedWidth / columnCount) || 0;

    return {
      columnCount,
      columnWidth,
      rowCount: Math.ceil(this.props.items.length / columnCount),
      rowHeight: Math.round(columnWidth / Component.CELL_RATIO)
    };
  }

  renderCell = (cellProps: CellRendererParams): ReactElement => {
    const {
      component: Card,
      height,
      items,
      width,
      onItemClick,
      ...restOfProps
    } = this.props;

    const { columnIndex, key, rowIndex, style } = cellProps;
    const index = rowIndex * this.getColCount() + columnIndex;
    const item = items[index];
    const handleClick = () => onItemClick(rowIndex);

    return (
      <div key={key} style={style} onClick={handleClick}>
        {/*@ts-ignore*/}
        <Card item={item} {...restOfProps} />
      </div>
    );
  };

  render() {
    const { height, scrollToRow, width } = this.props;
    const { columnCount, columnWidth, rowCount, rowHeight } =
      this.getGridParams();
    const _height = Math.ceil(height);
    const _width = Math.ceil(width);

    return (
      <>
        <Grid
          cellRenderer={(x: CellRendererParams) => this.renderCell(x)}
          className="VirtualList"
          columnCount={columnCount}
          columnWidth={columnWidth}
          height={_height}
          id="VirtualList"
          rowHeight={rowHeight}
          rowCount={rowCount}
          scrollToAlignment="center"
          scrollToRow={scrollToRow}
          width={_width}
        />

        <GotoTopButton elementId="VirtualList" />
      </>
    );
  }
}

/**/
Component.propTypes = {
  component: PropTypes.elementType.isRequired,
  favVesselIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  height: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onFavClick: PropTypes.func.isRequired,
  width: PropTypes.number
};

export const VirtualList = withFleets(Component);
