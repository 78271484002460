import React, { MouseEventHandler } from 'react';

import './PopoverGroup.scss';
import { LinkButton } from '../LinkButton/LinkButton';

/**/
type TPopoverContentItem = {
  handler: MouseEventHandler<HTMLElement>;
  label: string;
};
/**/
type Props = {
  items: TPopoverContentItem[];
};

/**
 *
 */
export function PopoverGroup({ items }: Props) {
  const content = items?.map(({ handler, label }: TPopoverContentItem) => (
    <React.Fragment key={label}>
      <LinkButton className="PopoverGroup__button" onClick={handler}>
        {label}
      </LinkButton>

      <div className="PopoverGroup__divider" />
    </React.Fragment>
  ));

  return <div className="PopoverGroup">{content}</div>;
}
