import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import Select from 'antd/lib/select';

import './MultiSelect.scss';
import { ALL_VALUE, filterSelectOption } from 'components/helpers/utils';

import { TSelectOption, TSelectProps } from 'types';

/** Custom component on top of AntDesign Select */
class MultiSelect<T extends string | number> extends React.Component<
  TSelectProps<T>
> {
  static ALL_VALUE = 'All' as any;
  static defaultProps;
  static propTypes;

  /** Filters out the ALL_VALUE from given array. */
  static removeAllOption(values: any[], allOption: string = ALL_VALUE): any[] {
    return values.filter((x) => x !== allOption);
  }

  static getAllOption(label: string): TSelectOption<any> {
    return {
      label: label,
      value: MultiSelect.ALL_VALUE
    };
  }

  getOptions(): TSelectOption<T>[] {
    const { allOptionLabel, options } = this.props;
    return allOptionLabel
      ? ([MultiSelect.getAllOption(allOptionLabel), ...options] as any)
      : options;
  }

  getValue(): T[] {
    const { value } = this.props;
    return value?.length > 0 ? value : [MultiSelect.ALL_VALUE];
  }

  handleChange = (selectValue: T[]): void => {
    const { onChange } = this.props;
    const _value = this.getValue();
    // Selected ALL or removed each
    if (
      (selectValue.includes(MultiSelect.ALL_VALUE) &&
        !_value.includes(MultiSelect.ALL_VALUE)) ||
      selectValue.length === 0
    ) {
      onChange([]);
    } else {
      onChange(MultiSelect.removeAllOption(selectValue));
    }
  };

  render(): ReactElement {
    const { allOptionLabel, fullValue, ...rest } = this.props;
    const _options = this.getOptions();
    const _value: T[] = this.getValue();

    return (
      <Select
        {...rest}
        className="MultiSelect"
        filterOption={filterSelectOption}
        listHeight={192}
        mode="multiple"
        optionFilterProp="value"
        options={_options}
        value={_value}
        onChange={this.handleChange}
      />
    );
  }
}

export const selectPropTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  )
};

MultiSelect.defaultProps = { value: [] };
MultiSelect.propTypes = selectPropTypes;

export { MultiSelect };
