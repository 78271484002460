import { createSelector } from 'reselect';
import message from 'antd/lib/message';
import { UploadFile } from 'antd/lib/upload/interface';

import { API_URL, ENDPOINTS } from 'other';
import { getToken } from 'services';

import { TAttachedFile } from 'types';
import { TState } from 'store';

const checkFileTypeAbilityToOpen = (type: string): boolean =>
  type.includes('image/') ||
  type.includes('application/pdf') ||
  type.includes('text/');

const getFileBlob = async (
  proposalId: number,
  fileId: number
): Promise<Blob> => {
  const response = await fetch(
    `${API_URL}${ENDPOINTS.CRM_PROPOSALS}/${proposalId}/file/${fileId}`,
    { credentials: 'include', headers: { 'X-Auth-Token': getToken() } }
  );

  return await response.blob();
};

export const downloadOrOpenFile = async (
  proposalId: number,
  fileId: number,
  fileName: string,
  openInNewTab = false
): Promise<void> => {
  try {
    const fileBlob = await getFileBlob(proposalId, fileId);

    // cross-origin downloads hack.
    const fileUrl = window.URL.createObjectURL(fileBlob);
    const link = document.createElement('a');

    link.setAttribute('href', fileUrl);

    if (openInNewTab && checkFileTypeAbilityToOpen(fileBlob.type)) {
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noreferrer noopener');
    } else {
      link.setAttribute('download', fileName);
    }

    link.click();
    // releases an existing object URL
    URL.revokeObjectURL(fileUrl);
  } catch ({ message: errorMessage }) {
    message.error(errorMessage);
  }
};

export function getInitialFileList(files: TAttachedFile[]): UploadFile[] {
  return files
    ?.map(({ id, name, size }) => ({
      name,
      originFileObj: null,
      size: size || 0,
      type: name.split('.')[1],
      uid: id.toString()
    }))
    .reverse();
}

export const selectRejectReasons = createSelector(
  (state: TState) => state.dictionaries,
  ({ proposalRejectReasons }) => {
    return proposalRejectReasons.map(({ id, value: { en_GB } }) => ({
      label: en_GB,
      value: id
    }));
  }
);
