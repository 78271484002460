export const deleteAndInsertOnTop = <T extends { id: number }>(
  editedElement: T,
  array: T[]
): T[] => {
  const filteredArray = array.filter((item: T) => item.id !== editedElement.id);

  filteredArray.unshift(editedElement);

  return filteredArray;
};

/** separates already loaded files and creates queues
 * to load new files or delete existing ones
 * @param files files attached to proposal
 * @param fileList comes from upload component
 */
export const getLoadDeleteQueues = <
  T extends { id: number },
  F extends { uid: string }
>(
  files: T[],
  fileList: F[]
): {
  deleteQueue: T[];
  loadQueue: F[];
  unmodifiedFiles: T[];
} => {
  const deleteQueue = [...files];
  const unmodifiedFiles = [];
  const loadQueue = fileList.filter(({ uid }) => {
    const existingItemIndex = deleteQueue.findIndex(
      ({ id }) => id.toString() === uid
    );

    if (existingItemIndex >= 0) {
      const [unmodifiedFile] = deleteQueue.splice(existingItemIndex, 1);
      unmodifiedFiles.push(unmodifiedFile);

      return false;
    }

    return true;
  });

  return {
    deleteQueue,
    loadQueue,
    unmodifiedFiles
  };
};
