import React, { FC, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import Image from 'antd/lib/image';

import './Flag.scss';
import { TooltipCustom } from 'components';

import { TState } from 'store';

const selectCountries = createSelector(
  (state: TState) => state.dictionaries,
  ({ countries }) => {
    const mappedCountries = new Map(
      countries.map((item) => [item.value, item])
    );
    return Array.from(mappedCountries.values());
  }
);

type Props = {
  className?: string;
  flagIso: string;
};

const Component: FC<Props> = ({ flagIso, className }) => {
  const flagClassName = classNames('Flag', className);
  const path = `/assets/flags/${flagIso}.png`;
  const countries = useSelector(selectCountries);
  const { label } = countries.find(({ value }) => value === flagIso) || {
    label: ''
  };

  return (
    <TooltipCustom title={label}>
      <Image
        alt={label}
        className={flagClassName}
        src={path}
        preview={false}
        fallback="/assets/flags/Default.png"
      />
    </TooltipCustom>
  );
};

Component.propTypes = {
  className: PropTypes.string,
  flagIso: PropTypes.string.isRequired
};

export const Flag = memo(Component);
