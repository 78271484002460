import message from 'antd/lib/message';

import { ENDPOINTS, ERR_MESSAGE_DURATION } from 'other';
import { http, THttpResponse } from 'services';

/**/
export type TPBIReportData = {
  token: string;
  url: string;
};

/**/
const PACKAGE_URL =
  'https://cdn.jsdelivr.net/npm/powerbi-client@2.22.2/dist/powerbi.min.js';

/**/
export function fetchToken(
  reportId: string,
  workspaceId: string,
  onSuccess: (data: TPBIReportData) => void,
  onError: () => void
): void {
  http
    .send({
      method: 'POST',
      url: ENDPOINTS.POWER_BI_TOKEN + `/${workspaceId}/report/${reportId}`
    })
    .then(({ data }: THttpResponse<TPBIReportData>): void => {
      const script = document.createElement('script');
      script.src = PACKAGE_URL;
      document.body.appendChild(script);

      script.onload = () => onSuccess(data);
      script.onerror = (e) => {
        onError();
        handleError(e);
      };
    })
    .catch((e) => {
      onError();
      handleError(e);
    });
}

/**/
function handleError(e): void {
  window.console.error(e);
  message.error(`Failed loading report: ${e.message}`, ERR_MESSAGE_DURATION);
}
