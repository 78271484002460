import React, { ReactElement } from 'react';

import './VesselCardBasic.scss';
import { ItemCard, TCardItem } from 'components';
import { MEDIA_BASE_URL, PLACEHOLDER } from 'other';
import { TVessel } from 'types';

type Props = {
  /**  React children or a child. */
  children?: ReactElement;
  /** link to vessel */
  vesselRoute: string;
  /** a vessel object. */
  vessel: TVessel;
};

/**
 * A vessel thumbnail block.
 * @param children
 * @param getPaths
 * @param vessel
 * @constructor
 */
const VesselCardBasic = ({
  children,
  vesselRoute,
  vessel
}: Props): ReactElement => {
  if (!vessel) return null;

  const testProps = {
    'data-build-year': vessel.buildYear,
    'data-engine-name': vessel.engine ? vessel.engine.manufacturerName : '',
    'data-engine-power': vessel.engine ? vessel.engine.power : '',
    'data-fishery-id': vessel.vesselType ? vessel.vesselType.id : '',
    'data-flag': vessel.flag
  };
  const item: TCardItem = {
    flag: vessel.flag,
    name: vessel.name,
    placeholder: PLACEHOLDER.VESSEL_CARD,
    src:
      vessel?.photo?.thumbnail &&
      `${MEDIA_BASE_URL}/${vessel.photo.thumbnail.path}`
  };

  return (
    <div className="VesselCard" {...testProps}>
      <ItemCard item={item} path={vesselRoute}>
        {children}
      </ItemCard>
    </div>
  );
};

export { VesselCardBasic };
