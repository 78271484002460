import React from 'react';

import './PlayerControls.scss';
import { RateSelect } from './RateSelect/RateSelect';
import { TIMELINE_ID } from './helpers';

import {
  EPlayMode,
  EPlayRate,
  IconButtonOutlined,
  TTimeEngineState
} from 'components';

/**/
type Props = {
  pause: (state?: Partial<TTimeEngineState>) => void;
  play: (state?: Partial<TTimeEngineState>) => void;
  playMode: EPlayMode;
  playRate: EPlayRate;
  setPlayRate: (rate: EPlayRate) => void;
};

/**
 *
 */
class PlayerControls extends React.PureComponent<Props> {
  /**/
  handlePlay = () => {
    const { pause, play, playMode } = this.props;
    if (playMode === EPlayMode.PLAY) {
      pause();
    } else {
      play();
    }
  };

  /**/
  render() {
    const { playMode, playRate, setPlayRate } = this.props;
    const iconType = playMode === EPlayMode.PLAY ? 'pause' : 'play';

    return (
      <section className="PlayerControls">
        <IconButtonOutlined
          className="PlayerControls__button"
          icon={iconType}
          size="M"
          title="Play/Pause/Stop"
          onClick={this.handlePlay}
        />

        <div className="PlayerControls__timeline" id={TIMELINE_ID} />
        <RateSelect onChange={setPlayRate} playRate={playRate} />
      </section>
    );
  }
}

/**/
export { PlayerControls };
