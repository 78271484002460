import React, { FC, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select, { SelectProps } from 'antd/lib/select';
import { TSelectOption } from 'types';

type Props = SelectProps<number> & {
  isHidden: boolean;
  options: TSelectOption<number>[];
};

const Component: FC<Props> = ({
  isHidden,
  options,
  value,
  onChange,
  ...props
}) => {
  const firstOption = options[0];
  useEffect(() => {
    if (value === null && !isHidden) {
      onChange(firstOption.value, firstOption);
    }

    return () => {
      if (value !== null && isHidden) {
        onChange(null, null);
      }
    };
  }, [isHidden, value, onChange, firstOption]);

  return (
    <Select {...props} options={options} value={value} onChange={onChange} />
  );
};

Component.propTypes = {
  isHidden: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      value: PropTypes.number,
      label: PropTypes.string
    })
  )
};

export const RejectReasonSelect = memo(Component);
