import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CompaniesFilter, FilterWrapper, selectCompanies } from 'components';
import {
  resetCompaniesFilterAction,
  setCompaniesFilterAction
} from 'store/companies/companiesActions';

import {
  PTCompaniesFilter,
  TCompaniesFilter,
  TIdName,
  TSelectOption
} from 'types';
import { TState } from 'store';

type Props = {
  companies: TSelectOption<number>[];
  countries: TSelectOption<string>[];
  fleets: TSelectOption<number>[];
  isFilterSet: boolean;
  resetFilter: () => void;
  setFilter: (value) => void;
  settings: TCompaniesFilter;
};

const Component: FC<Props> = ({
  companies,
  countries,
  fleets,
  isFilterSet,
  resetFilter,
  setFilter,
  settings
}) => {
  const setFilterCompanies = (companies: number[]) =>
    setFilter({ companyValue: companies });

  const setFilterCountries = (countries: string[]) =>
    setFilter({ countryValue: countries });

  const setFilterFleets = (fleets: number[]) =>
    setFilter({ fleetValue: fleets });

  return (
    <FilterWrapper isFilterSet={isFilterSet} resetFilter={resetFilter}>
      <CompaniesFilter
        companies={companies}
        countries={countries}
        fleets={fleets}
        setFilterCompanies={setFilterCompanies}
        setFilterCountries={setFilterCountries}
        setFilterFleets={setFilterFleets}
        settings={settings}
      />
    </FilterWrapper>
  );
};

Component.propTypes = {
  companies: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  isFilterSet: PropTypes.bool.isRequired,
  resetFilter: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  settings: PropTypes.exact(PTCompaniesFilter).isRequired
};

const mapStateToProps = ({
  companies: { filterSettings, companies },
  dictionaries,
  session
}: TState) => {
  const fleets: TIdName[] = session.user
    ? session.user.userInfo.fleets
    : ([] as any);

  const options = fleets.map(
    (f: TIdName): TSelectOption<number> => ({
      label: f.name,
      value: f.id
    })
  );

  return {
    companies: selectCompanies(companies),
    countries: dictionaries.countries,
    fleets: options,
    isFilterSet: filterSettings.isFilterSet,
    settings: filterSettings
  };
};

const mapDispatchToProps = {
  resetFilter: resetCompaniesFilterAction,
  setFilter: setCompaniesFilterAction
};

export const CompaniesPageFilter = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
