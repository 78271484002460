import React from 'react';
import Radio from 'antd/lib/radio';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CollapseProps } from 'antd/lib/collapse';

import './IceLayerSet.scss';
import {
  DEFAULT_ACTIVE_KEY,
  LayerControlCollapse
} from './LayerControlCollapse';
import { ENV } from 'other';
import { ICE_LAYER_MAP } from './helpers';
import { IconCustom } from 'components';

import { EMapIceLayer, EMapLayer } from 'types';
import { TMapOptionsState } from 'store';

/**/
type Props = {
  currentMapLayer: EMapLayer;
  iceLayers: EMapIceLayer[];
  updateMapOptions: (options: Partial<TMapOptionsState>) => void;
};
/**/

/**
 *
 */
function IceLayerSet({ currentMapLayer, iceLayers, updateMapOptions }: Props) {
  const handleChange = (e: CheckboxChangeEvent) => {
    const { id } = e.target as any;
    const update = iceLayers.includes(id)
      ? iceLayers.filter((itm) => itm !== id)
      : [...iceLayers, id];
    updateMapOptions({ iceLayers: update });
  };

  const layers = Object.entries(ICE_LAYER_MAP).map(([key, value]) => {
    const checked = iceLayers.includes(key as any);
    return (
      <div className="IceLayerSet__item" key={key}>
        <Checkbox
          checked={checked}
          className="IceLayerSet__check"
          id={key}
          onChange={handleChange}
        />
        <label className="IceLayerSet__label app-caption" htmlFor={key}>
          {value.label}
        </label>
      </div>
    );
  });

  const items: CollapseProps['items'] = [
    {
      key: currentMapLayer === EMapLayer.ICE && DEFAULT_ACTIVE_KEY,
      label: (
        <>
          <div className="LayerControl__icon">
            <IconCustom type="ice" />
          </div>
          <span className="LayerControl__text app-caption">Ice</span>
        </>
      ),
      children: layers
    }
  ];

  return (
    <Radio.Button
      className="LayerControl__collapse--wrapper"
      value={EMapLayer.ICE}
    >
      <LayerControlCollapse items={items} />
    </Radio.Button>
  );
}

/**
 *
 */
function BasicLayerSet() {
  return (
    <>
      <Radio.Button value={EMapLayer.EEZ}>
        <div className="LayerControl__icon LayerControl__icon--large">
          <IconCustom type="eez" />
        </div>
        <span className="LayerControl__text app-caption">
          Exclusive economic zones
        </span>
      </Radio.Button>

      {ENV.IS_FF && (
        <Radio.Button value={EMapLayer.ICES}>
          <div className="LayerControl__icon LayerControl__icon--large">
            <IconCustom type="ices" />
          </div>
          <span className="LayerControl__text app-caption">ICES areas</span>
        </Radio.Button>
      )}

      <Radio.Button value={EMapLayer.CONTOUR}>
        <div className="LayerControl__icon">
          <IconCustom type="contour" />
        </div>
        <span className="LayerControl__text app-caption">Sea depth curves</span>
      </Radio.Button>

      {ENV.IS_FF && (
        <Radio.Button value={EMapLayer.SEABED}>
          <div className="LayerControl__icon">
            <IconCustom type="seabed-2" />
          </div>
          <span className="LayerControl__text app-caption">Sea bed</span>
        </Radio.Button>
      )}
    </>
  );
}

/**
 *
 */
function WindyLayerSet() {
  return (
    <>
      <Radio.Button value={EMapLayer.TEMP}>
        <div className="LayerControl__icon">
          <IconCustom type="thermometer" />
        </div>
        <span className="LayerControl__text app-caption">Temperature</span>
      </Radio.Button>

      <Radio.Button value={EMapLayer.WIND}>
        <div className="LayerControl__icon">
          <IconCustom type="wind-sign" />
        </div>
        <span className="LayerControl__text app-caption">Wind</span>
      </Radio.Button>

      <Radio.Button value={EMapLayer.WAVES}>
        <div className="LayerControl__icon">
          <IconCustom type="water-waves" />
        </div>
        <span className="LayerControl__text app-caption">Waves</span>
      </Radio.Button>

      <Radio.Button value={EMapLayer.CURR}>
        <div className="LayerControl__icon">
          <IconCustom type="currents" />
        </div>
        <span className="LayerControl__text app-caption">Currents</span>
      </Radio.Button>

      <Radio.Button value={EMapLayer.GUSTS}>
        <div className="LayerControl__icon">
          <IconCustom type="gusts" />
        </div>
        <span className="LayerControl__text app-caption">Wind gusts</span>
      </Radio.Button>
    </>
  );
}

/**
 *
 */
function SalinitySurfacePlanktonSet() {
  return (
    <>
      <Radio.Button value={EMapLayer.SALINITY}>
        <div className="LayerControl__icon">
          <IconCustom type="salinity" />
        </div>
        <span className="LayerControl__text app-caption">Salinity</span>
      </Radio.Button>

      <Radio.Button value={EMapLayer.SURFACETEMP}>
        <div className="LayerControl__icon">
          <IconCustom type="sea-surface-temp" />
        </div>
        <span className="LayerControl__text app-caption">
          Sea surface temperature
        </span>
      </Radio.Button>

      <Radio.Button value={EMapLayer.SEATEMP}>
        <div className="LayerControl__icon">
          <IconCustom type="sea-subsurface-temp" />
        </div>
        <span className="LayerControl__text app-caption">
          Sub sea temperature
        </span>
      </Radio.Button>

      <Radio.Button value={EMapLayer.ZOOPLANKTON}>
        <div className="LayerControl__icon">
          <IconCustom type="zooplankton" />
        </div>
        <span className="LayerControl__text app-caption">Zooplankton</span>
      </Radio.Button>

      <Radio.Button value={EMapLayer.PHYTOPLANKTON}>
        <div className="LayerControl__icon">
          <IconCustom type="phytoplankton" />
        </div>
        <span className="LayerControl__text app-caption">Phytoplankton</span>
      </Radio.Button>
    </>
  );
}

/**/
export {
  BasicLayerSet,
  IceLayerSet,
  SalinitySurfacePlanktonSet,
  WindyLayerSet
};
