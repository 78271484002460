import React, { FC } from 'react';
import PropTypes from 'prop-types';

import './FileList.scss';
import { DefaultButton, IconCustom, LinkButton } from 'components';
import { downloadOrOpenFile } from '../../Proposals/helpers/helpers';
import { TAttachedFile } from 'types';

/**/
type Props = {
  files: TAttachedFile[];
  proposalId: number;
};

/**
 *
 */
const FileList: FC<Props> = ({ files, proposalId }) => {
  const getFileCard = ({ name, id: fileId }: TAttachedFile) => {
    const openFile = () => downloadOrOpenFile(proposalId, fileId, name, true);
    const downloadFile = () => downloadOrOpenFile(proposalId, fileId, name);

    return (
      <li className="FileList__item" key={fileId}>
        <IconCustom className="FileList__icon" type="paperclip" />

        <LinkButton onClick={openFile}>{name}</LinkButton>

        <DefaultButton
          className="FileList__button"
          download={name}
          onClick={downloadFile}
        >
          <IconCustom type="down" /> Download
        </DefaultButton>
      </li>
    );
  };

  return (
    <div className="FileList__wrapper">
      <ul className="FileList">{files.map(getFileCard)}</ul>
    </div>
  );
};

/**/
FileList.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.exact({
      createdAt: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      size: PropTypes.number
    })
  ),
  proposalId: PropTypes.number.isRequired
};

export { FileList };
