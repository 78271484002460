import React from 'react';
import PropTypes from 'prop-types';

import './EditorControls.scss';
import { CloseButton, DefaultButton, PrimaryButton } from 'components';

/**/
type Props = {
  form?: string;
  isDisabled?: boolean;
  onCancel: () => void;
  onDraft: () => void;
  onPublish?: () => void;
};

/**
 *
 */
class EditorControls extends React.PureComponent<Props> {
  static propTypes;

  render() {
    const { form, isDisabled, onCancel, onDraft, onPublish } = this.props;
    const submitProps = {
      ...(form && { form: form }),
      disabled: isDisabled,
      htmlType: 'submit',
      onClick: onPublish,
      size: 'M',
      className: 'EditorControls__button'
    } as any;

    return (
      <section className="EditorControls">
        <DefaultButton
          disabled={isDisabled}
          onClick={onDraft}
          size="M"
          className="EditorControls__button"
        >
          Save as draft
        </DefaultButton>

        <PrimaryButton {...submitProps}>Publish</PrimaryButton>

        <CloseButton className="EditorControls__cancel" onClick={onCancel} />
      </section>
    );
  }
}

/**/
EditorControls.propTypes = {
  htmlFor: PropTypes.string,
  isDisabled: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onDraft: PropTypes.func.isRequired,
  onPublish: PropTypes.func
};

export { EditorControls };
