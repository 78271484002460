import { minMaxLenValidator, phonePattern } from 'other';

export const firstNameRules = minMaxLenValidator('First name', 50, 2);

export const lastNameRules = minMaxLenValidator('Last name', 50, 2, false);
export const positionRules = minMaxLenValidator('Position', 50, 2, false);
export const socialRules = minMaxLenValidator('Social', 50, 2, false);
export const noteRules = minMaxLenValidator('Note', 300, 2, false);
export const customFieldKeyRules = minMaxLenValidator('Field key', 50, 1, true);
export const hobbyRules = minMaxLenValidator('Hobby', 50, 2, false);

export const customFieldValueRules = minMaxLenValidator(
  'Field value',
  300,
  1,
  false
);

export const phoneRules = [
  {
    message: "Phone number doesn't match pattern!",
    pattern: phonePattern
  },
  ...minMaxLenValidator('Phone number', 15, 7, false)
];

export const emailRules = [
  {
    message: "Email value doesn't match pattern!",
    type: 'email'
  }
] as any;
