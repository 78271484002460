import React, { FC } from 'react';
import PropTypes from 'prop-types';

import './CompanyFarms.scss';
import { AddButtonStar, ShortFarm, TInjectedWithFleetsProps } from 'components';
import withFleets from 'components/common/vessel/withFleets/withFleets';
import { TFarmOfCompany } from 'types';

type Props = TInjectedWithFleetsProps & {
  farms: TFarmOfCompany[];
};

const Component: FC<Props> = ({ favFarmIds, onFavClick, farms }) => {
  if (!farms || farms.length === 0) return null;

  const handleFav = (ids: number[]) => onFavClick({ farms: ids });

  const handleFavButch = () =>
    handleFav(farms.map(({ fishFarmId }: TFarmOfCompany) => fishFarmId));

  const handleFavSingle = (id: number) => handleFav([id]);

  const farmsList = farms.map((farm) => {
    return (
      <ShortFarm
        farm={farm}
        favFarmIds={favFarmIds}
        key={farm.fishFarmId}
        onFavClick={handleFavSingle}
      />
    );
  });

  return (
    <div className="CompanyFarms">
      <header className="CompanyFarms__header">
        <AddButtonStar label="Add all to fleet" onAdd={handleFavButch} />
      </header>

      <div className="CompanyFarms__content">{farmsList}</div>
    </div>
  );
};

Component.propTypes = {
  farms: PropTypes.array,
  favVesselIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onFavClick: PropTypes.func.isRequired
};

export const CompanyFarms = withFleets(Component);
