import React, { FC, ReactElement, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

type Props = {
  children: ReactElement | ReactElement[];
  itemId: string;
};

const ScrollToItem: FC<Props> = ({ children, itemId }) => {
  const ref = useRef(null);
  // @ts-ignore
  const { itemId: routeItemId } = useParams();

  useEffect(() => {
    if (itemId === routeItemId) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ref, itemId, routeItemId]);

  return <div ref={ref}>{children}</div>;
};

ScrollToItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  itemId: PropTypes.string.isRequired
};

export { ScrollToItem };
