import { createAsyncActions, TSearchState } from 'store';

export enum ESearchActions {
  SEARCH_TYPED = 'search/SEARCH_TYPED',
  SEARCH = 'search/SEARCH'
}

export const searchSet = createAsyncActions<TSearchState>(
  ESearchActions.SEARCH
);
