import React from 'react';

import './FarmsFilter.scss';
import {
  BannerCarousel,
  buildFacilityFilter,
  FleetsManager,
  MultiSelect
} from 'components';
import CompaniesFilter from 'components/common/filters/CompaniesFilter/CompaniesFilter';
import CountriesFilter from 'components/common/filters/CountriesFilter/CountriesFilter';
import { ENV } from 'other';
import FleetsFilter from 'components/common/filters/FleetsFilter/FleetsFilter';
import SpeciesFilter from 'components/common/filters/SpeciesFilter/SpeciesFilter';

import { EBannerType } from 'types';

function FarmsFilter() {
  const FacilityFilter = buildFacilityFilter(MultiSelect);
  const label = ENV.IS_AF ? 'My List' : 'My Fleets';

  return (
    <div className="FarmsFilter">
      <div className="BasicFilter__row">
        <label className="BasicFilter__label--bold">{label}</label>
        <FleetsManager />
        <FleetsFilter />
      </div>

      <div className="BasicFilter__row">
        <label className="BasicFilter__label--bold">Country</label>
        <CountriesFilter />
      </div>

      <div className="BasicFilter__row">
        <label className="BasicFilter__label--bold">Company</label>
        <CompaniesFilter />
      </div>

      <div className="BasicFilter__row">
        <label className="BasicFilter__label--bold">Facility</label>
        <FacilityFilter />
      </div>

      <div className="BasicFilter__row">
        <label className="BasicFilter__label--bold">Species</label>
        <SpeciesFilter />
      </div>

      <div className="BasicFilter__row">
        <div className="BasicFilter__banner">
          <BannerCarousel type={EBannerType.FILTERS} />
        </div>
      </div>
    </div>
  );
}

export { FarmsFilter };
