import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './CategoryListItem.scss';
import { CommonBanner } from 'components';
import { PTCategoryListItem, TCategoryListItem } from 'types';

type Props = {
  category: TCategoryListItem;
};

/**/
function CategoryListItem({ category: { imageSrc, path, title } }: Props) {
  return (
    <Link
      className="CategoryListItem"
      to={{
        pathname: path,
        state: {
          id: title
        }
      }}
    >
      <CommonBanner src={imageSrc} />
      <h6 className="CategoryListItem__name app-h6">{title}</h6>
    </Link>
  );
}

/**/
CategoryListItem.propTypes = {
  category: PropTypes.exact(PTCategoryListItem).isRequired
};

export { CategoryListItem };
