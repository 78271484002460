import React from 'react';

import AbstractCard from 'pages/Map/components/AbstractCard';
import { L, TVessel, TVesselLocation, TVesselTrackPoint } from 'types';

/**/
type Props = {
  location: TVesselTrackPoint;
  map: L.Map;
  onClose: () => void;
  vessel: TVessel | TVesselLocation;
};

/**/
const DATE_SPEED_ELEMENT_ID = 'PointCard__text';

/**
 *
 */
class PointCard extends React.PureComponent<Props> {
  protected popup: L.Popup;

  /**/
  componentDidMount() {
    this.create();
  }

  /**/
  componentDidUpdate() {
    const { location, vessel } = this.props;
    const data: any = location || vessel;
    if (!data.latitude || !data.longitude) return;

    if (!this.popup) {
      return this.create();
    }

    setTimeout(() => this.popup.setLatLng([data.latitude, data.longitude]));
  }

  /**/
  componentWillUnmount() {
    this.popup?.remove();
  }

  /**/
  private create(): void {
    const { location, map, vessel } = this.props;
    const data: any = location || vessel;

    if (!data.latitude || !data.longitude || !location) return;

    this.popup = window.L.popup({
      autoClose: false,
      autoPan: true,
      autoPanPadding: [40, 150],
      closeButton: false,
      closeOnEscapeKey: false,
      closeOnClick: false,
      pane: 'popupPane'
    })
      .setLatLng([data.latitude, data.longitude])
      .setContent(this.getContent())
      .on('click', this.handleClick as any)
      .addTo(map);
  }

  /**/
  private handleClick = (e: Event) => {
    const target = e.target as any;
    if (target.closest('.' + AbstractCard.CLASS_NAMES.CLOSE)) {
      this.popup.remove();
      this.props.onClose();
    }
  };

  /**/
  private getContent(): HTMLElement {
    const { location, vessel } = this.props;
    const popup = document.createElement('DIV');
    if (!location) return null;

    popup.classList.add('MapEntityCard2');
    popup.addEventListener('click', this.handleClick, false);
    popup.addEventListener('touchend', this.handleClick, false);

    popup.innerHTML = `
      <div class="MapEntityCard2__container">
        ${AbstractCard.getHeader(vessel.name, vessel.flag, true)}
      </div>
      <div class="MapEntityText" id="${DATE_SPEED_ELEMENT_ID}" />
    `;

    return popup;
  }

  /**/
  render() {
    return null;
  }
}

/**/
export { DATE_SPEED_ELEMENT_ID, PointCard };
