import React, { FC, ReactElement } from 'react';
import PropTypes from 'prop-types';

import './EmptyList.scss';

type Props = {
  imageClassName?: string;
  placeholder: string;
  placeholderImgUrl: string;
};

const EmptyList: FC<Props> = ({
  imageClassName,
  placeholder,
  placeholderImgUrl
}): ReactElement => (
  <section className="ServiceProvider__group">
    <div className="EmptyList__invitation">
      <img
        alt={placeholder}
        className={imageClassName}
        src={placeholderImgUrl}
      />
      <b>{placeholder}</b>
    </div>
  </section>
);

EmptyList.propTypes = {
  imageClassName: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  placeholderImgUrl: PropTypes.string.isRequired
};

export { EmptyList };
