import React from 'react';
import isequal from 'lodash.isequal';

import './CustomArea.scss';
import {
  AREA_BORDER,
  AREA_FILL_DEFAULT_COLOR,
  AREA_FILL_DEFAULT_OPACITY,
  getAreaFillOpacity
} from './helpers';
import { EAreaType, L, TArea, TAreaPoint, TZone } from 'types';

/**/
type Props = {
  area: TArea | TZone;
  map: L.Map;
};

/**
 *
 */
class CustomArea extends React.Component<Props> {
  private polygonHidden: L.Polygon;
  private polygonVisible: L.Polygon;

  /**/
  private static getTooltipContent(name: string): HTMLElement {
    const div = document.createElement('DIV');
    div.innerHTML = `<div class="ellipsis CustomArea">${name}</div>`;
    return div;
  }

  /**/
  shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    const { area, map } = this.props;
    return !(isequal(area, nextProps.area) && map === nextProps.map);
  }

  /**/
  componentDidMount() {
    this.create();
  }

  /**/
  componentDidUpdate() {
    this.polygonHidden?.removeFrom(this.props.map);
    this.polygonVisible?.removeFrom(this.props.map);
    this.create();
  }

  /**/
  componentWillUnmount() {
    this.polygonHidden?.removeFrom(this.props.map);
    this.polygonVisible?.removeFrom(this.props.map);
  }

  /**/
  create(): void {
    const { area, map } = this.props;
    if (!map) return;

    const borderWeight =
      area.area.type === EAreaType.PRODUCTION
        ? AREA_BORDER.PUBLIC
        : AREA_BORDER.CUSTOM;

    const points = area.area.points.map((p: TAreaPoint) => [
      p.latitude,
      p.longitude
    ]);

    this.polygonHidden = window.L.polygon(points as any, {
      color: 'transparent',
      fillColor: 'transparent',
      pane: 'markerPane'
    })
      .bindTooltip(CustomArea.getTooltipContent(area.name), {
        direction: 'top'
      })
      .addTo(map);

    this.polygonVisible = window.L.polygon(points as any, {
      color: this.getFillColor(),
      fillColor: this.getFillColor(),
      fillOpacity: this.getFillOpacity(),
      weight: borderWeight,
      pane: 'tilePane'
    }).addTo(map);
  }

  /**/
  getFillColor(): string {
    const { area } = this.props;
    const { fillColor } = area.area['appearance'] || {};

    if (area.area.type === EAreaType.PRODUCTION) {
      return AREA_FILL_DEFAULT_COLOR.PUBLIC;
    }

    const color = fillColor || AREA_FILL_DEFAULT_COLOR.CUSTOM;
    return color.substring(0, 7);
  }

  /**/
  getFillOpacity(): number {
    const { area } = this.props;
    const { fillColor } = area.area['appearance'] || {};

    if (area.area.type === EAreaType.PRODUCTION) {
      return AREA_FILL_DEFAULT_OPACITY.PUBLIC;
    }

    return fillColor
      ? getAreaFillOpacity(fillColor)
      : AREA_FILL_DEFAULT_OPACITY.CUSTOM;
  }

  /**/
  render() {
    return null;
  }
}

/**/
export { CustomArea };
