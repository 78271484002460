import React, { ReactElement } from 'react';
import './FilterWrapper.scss';
import { ActionButton, FilterDrawer } from 'components';

/**/
type State = { isVisible: boolean };
type Props = {
  children: ReactElement | ReactElement[];
  isFilterSet: boolean;
  resetFilter: () => void;
};

/**
 * Wraps a filter drawer and its control button to pass it
 * a page specific filter.
 */
class FilterWrapper extends React.PureComponent<Props, State> {
  static propTypes;
  state: State = { isVisible: false };

  handleClose = () => this.setState({ isVisible: false });
  handleOpen = () => this.setState({ isVisible: true });

  render() {
    const { children, isFilterSet, resetFilter } = this.props;

    return (
      <>
        <div className="FilterWrapper__trigger">
          <div className="FilterWrapper__container">
            {isFilterSet && <div className="FilterWrapper__indicator" />}
          </div>

          <ActionButton
            className="FilterWrapper__button"
            icon="filters"
            onClick={this.handleOpen}
            size="L"
          />
        </div>

        <FilterDrawer
          isVisible={this.state.isVisible}
          onClose={this.handleClose}
          onReset={resetFilter}
        >
          {children}
        </FilterDrawer>
      </>
    );
  }
}

/**/
export { FilterWrapper };
