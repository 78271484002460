import React from 'react';

import './TextButton.scss';
import { AppButton } from '../helpers';
import { ButtonProps } from 'antd/lib/button';

/**/
type Props = Omit<ButtonProps, 'icon' | 'size'>;

/**
 *
 */
export function TextButton(props: Props) {
  return <AppButton mainClassName="TextButton" type="link" {...props} />;
}
