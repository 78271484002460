import { createAsyncActions, TCrmContactsState } from 'store';

export enum ECrmContactsActions {
  FETCH_CONTACTS = 'crmContacts/FETCH_CONTACTS',
  EDIT_CONTACT = 'crmContacts/EDIT_CONTACT',
  DELETE_CONTACT = 'crmContacts/DELETE_CONTACT',
  UPDATE_RELATED_CONTACTS = 'crmContacts/UPDATE_RELATED_CONTACTS',
  ADD_CONTACT = 'crmContacts/ADD_CONTACT'
}

export const fetchSet = createAsyncActions<TCrmContactsState>(
  ECrmContactsActions.FETCH_CONTACTS
);
