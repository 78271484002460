import React from 'react';
import { ENV, PLACEHOLDER } from 'other';
import './ReportPagePlaceholder.scss';

/**
 *
 */
function ReportPagePlaceholder() {
  const email = ENV.IS_AF ? 'hs@aquafacts.com' : 'hs@fishfacts.com';
  const href = `mailto:${email}`;

  return (
    <div className="app-page-text ReportPagePlaceholder">
      <div className="ReportPagePlaceholder__container">
        <div className="ReportPagePlaceholder__content">
          <img
            className="ReportPagePlaceholder__placeholder"
            src={PLACEHOLDER.ACCESS}
          />

          <h2 className="app-h2 ReportPagePlaceholder__title">
            You do not have permission to access
          </h2>

          <p className="app-body-2 ReportPagePlaceholder__text">
            For more information contact <a href={href}>{email}</a>
          </p>
        </div>
      </div>
    </div>
  );
}

/**/
export { ReportPagePlaceholder };
