import { ColorFactory } from 'antd/lib/color-picker/color';

export const AREA_FILL_DEFAULT_COLOR = {
  CUSTOM: '#F9DA3680',
  PUBLIC: '#3388ff'
};

export const AREA_FILL_DEFAULT_OPACITY = {
  CUSTOM: 0.5,
  PUBLIC: 0.2
};

export const AREA_BORDER = {
  CUSTOM: 3,
  PUBLIC: 1.5
};

/**
 *
 * @param color HEX color (+ opacity) string.
 */
export function getAreaFillOpacity(color: string): number {
  return new ColorFactory(color)['metaColor'].roundA;
}
