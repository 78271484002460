import React, { FC, memo } from 'react';
import { Link } from 'react-router-dom';

import './CompanyData.scss';
import { LOCALE, ROUTES } from 'other';

import { TCompany, TCompanyAF } from 'types';

// TODO: need to unite TCompany and TCompanyAF
type Props = {
  company: TCompany | TCompanyAF;
  pathBase?: string;
};

const Component: FC<Props> = ({ company, pathBase }) => {
  if (!company) return null;
  const {
    address,
    boardMembers,
    city,
    chairman,
    directors,
    id,
    registrationNumber,
    postcode,
    name,
    country
  } = company;

  const renderedDirectors =
    directors &&
    directors.map(({ firstName, lastName, personId }) => (
      <tr key={`director_${personId}`}>
        <td>Director</td>
        <td>
          <Link className="link-underlined" to={`${ROUTES.PERSON}/${personId}`}>
            {`${firstName}  ${lastName}`}
          </Link>
        </td>
      </tr>
    ));

  const renderedBoardMembers =
    boardMembers &&
    boardMembers.map(({ firstName, lastName, personId }) => (
      <tr key={`boardMember_${personId}`}>
        <td>Board Members</td>
        <td>
          <Link className="link-underlined" to={`${ROUTES.PERSON}/${personId}`}>
            {`${firstName}  ${lastName}`}
          </Link>
        </td>
      </tr>
    ));

  return (
    <table className="CompanyData">
      <tbody>
        {registrationNumber && (
          <tr key="registrationNumber">
            <td>Reg. No.</td>
            <td>
              <Link className="link-underlined" to={`${pathBase}/${id}`}>
                {registrationNumber}
              </Link>
            </td>
          </tr>
        )}

        <tr key="companyName">
          <td>Company Name</td>
          <td>
            <Link className="link-underlined" to={`${pathBase}/${id}`}>
              {name}
            </Link>
          </td>
        </tr>

        {country && (
          <tr key="country">
            <td>Country</td>
            <td>
              {typeof country === 'string' ? country : country.value[LOCALE]}
            </td>
          </tr>
        )}

        {address && (
          <tr key="address">
            <td>Address</td>
            <td>{address}</td>
          </tr>
        )}

        {city && (
          <tr key="city">
            <td>City</td>
            <td>{city}</td>
          </tr>
        )}

        {postcode && (
          <tr key="postcode">
            <td>Postcode</td>
            <td>{postcode}</td>
          </tr>
        )}

        {renderedDirectors}

        {chairman && (
          <tr key="chairman">
            <td>Chairman</td>
            <td>
              <Link
                className="link-underlined"
                to={`${ROUTES.PERSON}/${chairman.personId}`}
              >
                {`${chairman.firstName}  ${chairman.lastName}`}
              </Link>
            </td>
          </tr>
        )}

        {renderedBoardMembers}
      </tbody>
    </table>
  );
};

Component.defaultProps = {
  pathBase: ROUTES.COMPANY
};

export const CompanyData = memo(Component);
