import React from 'react';
import Checkbox from 'antd/lib/checkbox';

import './HCYearList.scss';
import { YEAR_LIST } from '../../helpers';

/**/
type Props = {
  onChange: (years: number[]) => void;
  years: number[];
};

/**
 *
 */
class HCYearList extends React.PureComponent<Props> {
  /**/
  handleYear = (year: number) => {
    const { onChange, years } = this.props;

    if (years.includes(year)) {
      onChange(years.filter((y) => y !== year));
    } else {
      onChange([...years, year]);
    }
  };

  /**/
  renderItem = (year: number, idx: number) => {
    const handleChange = () => this.handleYear(year);
    const cls = `HCYearList__checkbox HCYearList__checkbox--${idx}`;

    return (
      <li className="HCYearList__item" key={year}>
        <Checkbox
          checked={this.props.years.includes(year)}
          className={cls}
          onChange={handleChange}
        >
          <span className="app-body-2">{year}</span>
        </Checkbox>
      </li>
    );
  };

  /**/
  render() {
    return (
      <div className="HCYearList">
        <ul className="HCYearList__list">{YEAR_LIST.map(this.renderItem)}</ul>
      </div>
    );
  }
}

/**/
export default HCYearList;
