import message from 'antd/lib/message';
import { History } from 'history';

import { deleteConfirm } from 'components';
import { HISTORY_LENGTH_ON_SITE_LOAD, ROUTES } from 'other';
import { http } from 'services';

export async function getEntity<T>(url: string): Promise<T> {
  try {
    const { data: entity } = await http.send(url);

    return entity as T;
  } catch ({ message: errorMessage }) {
    message.error(errorMessage);

    throw new Error(errorMessage);
  }
}

export async function addEntity<T, F = T>(
  url: string,
  formData: Partial<T>
): Promise<F> {
  try {
    const { data: entity } = await http.send({
      body: formData,
      method: 'POST',
      url
    });

    return entity as F;
  } catch ({ message: errorMessage }) {
    message.error(errorMessage);
    throw new Error(errorMessage);
  }
}

export async function deleteEntity(
  url: string,
  body: { [key: string]: string } = null
): Promise<void> {
  try {
    await http.send({
      method: 'DELETE',
      ...(body && { body }),
      url
    });
  } catch ({ message: errorMessage }) {
    message.error(errorMessage);
    throw new Error(errorMessage);
  }
}

export function deleteEntityWithConfirmModal(
  url: string,
  messageText: string,
  { push, goBack, length: historyLength }: History,
  body: { [key: string]: string } = null,
  onDelete?: () => void
): void {
  deleteConfirm(messageText, async () => {
    try {
      await deleteEntity(url, body);

      onDelete && onDelete();

      historyLength < HISTORY_LENGTH_ON_SITE_LOAD
        ? push(ROUTES.HOME)
        : goBack();
    } catch ({ message: errorMessage }) {
      throw new Error(errorMessage);
    }
  });
}

export async function editEntity<T>(
  url: string,
  formData: Partial<T>
): Promise<T> {
  try {
    const { data: entity } = await http.send({
      body: formData,
      method: 'PUT',
      url
    });

    return entity;
  } catch ({ message: errorMessage }) {
    message.error(errorMessage);

    throw new Error(errorMessage);
  }
}

export async function addFile<T, R = T>(
  url: string,
  photo: File,
  additionalFormData: { [key: string]: string | number } = {}
): Promise<R> {
  const formData = new FormData();
  formData.append('file', photo);

  Object.entries(additionalFormData).forEach(([key, value]) =>
    formData.append(key, value?.toString())
  );

  try {
    const { data } = await http.send({
      body: formData,
      method: 'POST',
      url
    });

    return data;
  } catch ({ message: errorMessage }) {
    message.error(errorMessage);
  }
}
