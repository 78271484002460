import React from 'react';
import classnames from 'classnames';

import './NavItem.scss';
import { IconCustom } from 'components';
import { TNavigationGroup } from 'types';

/**/
type Props = {
  isActive: boolean;
  isCollapsed: boolean;
  isLoggedIn: boolean;
  item: TNavigationGroup;
};

/**
 *
 */
class NavGroupHeader extends React.PureComponent<Props> {
  /**/
  render() {
    const { isActive, isCollapsed, item } = this.props;
    const { icon, title } = item;

    const cls = classnames(['NavItem', 'NavItem--bold', 'NavItem__nofocus'], {
      'NavItem--active': isActive,
      'NavItem--collapsed': isCollapsed
    });

    return (
      <div className="Navigation__item">
        <div className={cls}>
          <div className="NavItem__box">
            <IconCustom className="NavItem__icon" type={icon} />
          </div>

          {isCollapsed || title}
        </div>
      </div>
    );
  }
}

/**
 *
 */
export default NavGroupHeader;
