import React from 'react';
import { DefaultButton, IconCustom } from 'components';

type Props = {
  label?: string;
  onAdd: (event) => void;
};

/**
 *
 */
export const AddButtonStar = ({ label, onAdd }: Props) => (
  <DefaultButton onClick={onAdd} size="M">
    <IconCustom type="star" />
    {label}
  </DefaultButton>
);
