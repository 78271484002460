import React, { FC, ReactElement } from 'react';
import PropTypes from 'prop-types';

import './VesselFilter.scss';
import {
  BannerCarousel,
  buildVesselTypeFilter,
  FleetsManager,
  MultiSelect,
  VesselTechFilter
} from 'components';
import CompaniesFilter from 'components/common/filters/CompaniesFilter/CompaniesFilter';
import CountriesFilter from 'components/common/filters/CountriesFilter/CountriesFilter';
import { ENV } from 'other';
import FleetsFilter from 'components/common/filters/FleetsFilter/FleetsFilter';

import { EBannerType } from 'types';

type Props = {
  children?: ReactElement | ReactElement[];
  closeAside?: () => void;
};

const VesselFilter: FC<Props> = ({ closeAside }) => {
  const VesselTypesFilter = buildVesselTypeFilter(MultiSelect);
  const label = ENV.IS_AF ? 'My List' : 'My Fleets';

  return (
    <div className="VesselFilter">
      <div className="BasicFilter__row">
        <label className="BasicFilter__label--bold">{label}</label>
        <FleetsManager />
        <FleetsFilter />
      </div>

      <div className="BasicFilter__row">
        <label className="BasicFilter__label--bold">COUNTRY</label>
        <CountriesFilter />
      </div>

      <div className="BasicFilter__row">
        <label className="BasicFilter__label--bold">Company</label>
        <CompaniesFilter />
      </div>

      <div className="BasicFilter__row">
        <label className="BasicFilter__label--bold">VESSEL TYPE</label>
        <VesselTypesFilter />
      </div>

      <div className="BasicFilter__row">
        <div className="BasicFilter__banner">
          <BannerCarousel type={EBannerType.FILTERS} onItemClick={closeAside} />
        </div>
      </div>

      <VesselTechFilter />
    </div>
  );
};

VesselFilter.propTypes = { closeAside: PropTypes.func };

export { VesselFilter };
