import React, { FC, ReactElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Select, { SelectProps } from 'antd/lib/select';

import './StatusSelect.scss';
import { EProposalStatus } from 'types';

const { Option } = Select;

function getOption(value: string, status: EProposalStatus): ReactElement {
  return (
    <Option value={status}>
      <div className={`StatusSelect__option StatusSelect__option--${status}`}>
        {value}
      </div>
    </Option>
  );
}

const StatusSelect: FC<SelectProps<EProposalStatus>> = ({
  className,
  ...selectProps
}) => (
  <Select
    {...selectProps}
    className={classnames('StatusSelect', className)}
    size="small"
  >
    {getOption('lead', EProposalStatus.LEAD)}
    {getOption('meeting booked', EProposalStatus.MEETING_BOOKED)}
    {getOption('meeting held', EProposalStatus.MEETING_HELD)}
    {getOption('proposal', EProposalStatus.ONGOING)}
    {getOption('deal', EProposalStatus.DEAL)}
    {getOption('invoiced', EProposalStatus.INVOICED)}
    {getOption('rejected', EProposalStatus.REJECTED)}
  </Select>
);

StatusSelect.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.oneOf([
    EProposalStatus.ONGOING,
    EProposalStatus.REJECTED,
    EProposalStatus.HISTORY,
    EProposalStatus.DEAL
  ]),
  disabled: PropTypes.bool
};

export { StatusSelect };
