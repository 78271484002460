import { ReactElement } from 'react';
import { TFormFields, TSelectOption } from 'types';

export const PROMOTION_FORM = 'PROMOTION_FORM';

export type PromotionFormProps = {
  children?: ReactElement;
  countries: TSelectOption<string>[];
  fields: TFormFields;
  onChange: (values: { [fieldName: string]: any }) => void;
  onSubmit: (values: {}) => void;
  serviceOptions: TSelectOption<number>[];
  vesselTypesOptions: TSelectOption<number>[];
};

export const startRules = [
  {
    message: 'Start date is required!',
    required: true
  }
];
export const endRules = [
  {
    message: 'End date is required!',
    required: true
  }
];

export const datePickerProps = {
  allowClear: false,
  format: 'YYYY-MM-DD HH:mm',
  showTime: { format: 'HH' },
  showToday: false
};
