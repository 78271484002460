import React, { ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';

import './FleetsEditor.scss';
import { CreateFleet, FleetsEditorItem, ModalEditorHeader } from 'components';
import { ENV, PLACEHOLDER } from 'other';
import { PTFleet, TFleet } from 'types';

type Props = {
  fleets: TFleet[];
  onCancel: () => void;
  onCreate: (fleetName: string) => void;
  onRemove: (fleetId: number) => void;
  onRename: (fleet: TFleet, newName: string) => void;
};

const FleetsEditor = ({
  fleets,
  onCancel,
  onCreate,
  onRemove,
  onRename
}: Props): ReactElement => {
  const isEmpty: boolean = !fleets || fleets.length === 0;

  const list = fleets.map(
    (fleet: TFleet): ReactNode => (
      <FleetsEditorItem
        fleet={fleet}
        key={fleet.id}
        onRemove={onRemove}
        onRename={onRename}
      />
    )
  );

  const placeholder: ReactNode = (
    <div className="FleetsEditor__placeholder">
      <div className="FleetsEditor__invocation">
        Create your first {ENV.FLEET}
      </div>
      <img src={PLACEHOLDER.FLEETS} alt="" className="FleetsEditor__image" />
    </div>
  );

  return (
    <div className="FleetsEditor">
      <ModalEditorHeader onCancel={onCancel} title={`Manage ${ENV.FLEETS}`} />

      <ul className="FleetsEditor__list">
        {isEmpty ? placeholder : list}

        <li className="FleetsList__btn">
          <CreateFleet onCreate={onCreate} />
        </li>
      </ul>
    </div>
  );
};

FleetsEditor.propTypes = {
  fleets: PropTypes.arrayOf(PropTypes.exact(PTFleet)).isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired
};

export { FleetsEditor };
