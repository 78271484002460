import './ShipIcon.scss';
import { countryColorMap } from 'pages/Map/utils/colors';
import { DEFAULT_COLORS } from 'pages/Map/utils/constants';
import { L } from 'types';

/**
 *
 */
function getColors(flag: string): [string, string] {
  return (countryColorMap as any)[flag] || DEFAULT_COLORS;
}

/**
 *
 */
export function getShipIcon(rotation: number, flag: string): L.DivIcon {
  const colors = getColors(flag);

  const icon = `
    <div class="ShipMarker" style="transform: rotate(${rotation + 90}deg)">
      <div class="ShipMarker__left" style="background-color: ${
        colors[0]
      }"></div>
      <div class="ShipMarker__right" style="background-color: ${
        colors[1]
      }"></div>
    </div>
  `;

  return window.L.divIcon({
    className: 'ShipMarker__root',
    html: icon
  });
}
