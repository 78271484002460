import React, { FC, ReactNode } from 'react';

import './InfoAndGallery.scss';
import { VesselPhotos } from 'components';
import { TPhotoItem } from 'types';

type Props = {
  children: ReactNode | ReactNode[];
  photos: TPhotoItem[];
  placeholderSrc?: string;
};

const InfoAndGallery: FC<Props> = ({ children, photos, placeholderSrc }) => (
  <div className="InfoAndGallery">
    <div className="InfoAndGallery__info">{children}</div>

    <div className="InfoAndGallery__gallery app-d-sm-none">
      <VesselPhotos photos={photos} placeholder={placeholderSrc} />
    </div>
  </div>
);

export { InfoAndGallery };
