import React from 'react';
import classnames from 'classnames';
import Form, { FormInstance } from 'antd/lib/form';
import Input from 'antd/lib/input';
import Select from 'antd/lib/select';

import './ArticleForm.scss';
import { ArticleFormFields, articleFormRules } from './helpers';
import { QUILL_MENTION_FREE_TRIAL_VALUE, RTE } from 'components';

import { TFormField, TLanguage } from 'types';

/**/
const { languageRules, shortDescRules, titleRules } = articleFormRules;

type Props = {
  fields: ArticleFormFields;
  hasTrialPermission: boolean;
  languages?: TLanguage[];

  onChange: (key: string, value: string) => void;
  onFormRef: (form: FormInstance) => void;
  onRemove: (fileId: number) => void;
  onUpload: (file: File, onSuccess) => void;
};

/**
 *
 */
class ArticleFormInner extends React.PureComponent<Props> {
  private SHORT_MAX = 1000;
  private TITLE_MAX = 160;

  /**/
  handleChange = (changedFields: TFormField[]) => {
    if (!changedFields[0]) return;
    const { name, value } = changedFields[0];
    this.props.onChange(name[0], value);
  };

  render() {
    const {
      fields,
      hasTrialPermission,
      languages,
      onFormRef,
      onRemove,
      onUpload
    } = this.props;

    const { shortDescription, title } = fields;
    const trialOption = hasTrialPermission && QUILL_MENTION_FREE_TRIAL_VALUE;

    const titleCls = classnames('EditModeForm__counter', {
      'EditModeForm__counter--warning': title.length > this.TITLE_MAX
    });

    const shortCls = classnames('EditModeForm__counter', {
      'EditModeForm__counter--warning': shortDescription.length > this.SHORT_MAX
    });

    const languageSelect = languages && (
      <Form.Item name="languageIso" rules={languageRules}>
        <Select options={languages} placeholder="Language" />
      </Form.Item>
    );

    return (
      <div className="ArticleForm EditModeForm">
        <Form
          className="ArticleForm__content"
          initialValues={fields}
          onFieldsChange={this.handleChange as any}
          ref={onFormRef}
        >
          <div className="EditModeForm__title">
            <Form.Item name="title" rules={titleRules}>
              <Input className="ArticleForm__area" placeholder="Title" />
            </Form.Item>
            <span className={titleCls}>
              {title.length}/{this.TITLE_MAX}
            </span>
          </div>

          <div className="EditModeForm__title">
            <Form.Item name="shortDescription" rules={shortDescRules}>
              <Input.TextArea
                className="ArticleForm__input"
                placeholder="Brief summary"
              />
            </Form.Item>
            <span className={shortCls}>
              {shortDescription.length}/{this.SHORT_MAX}
            </span>
          </div>

          <div className="ArticleForm__select">{languageSelect}</div>

          <Form.Item name="longDescription">
            <RTE
              trialOption={trialOption}
              onRemove={onRemove}
              onUpload={onUpload}
            />
          </Form.Item>
        </Form>
      </div>
    );
  }
}

/**/
export default ArticleFormInner;
