import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Drawer from 'antd/lib/drawer';

import { FilterHeader } from 'components';

type Props = {
  className?: string;
  children: ReactElement | ReactElement[];
  isVisible: boolean;
  rootClassName?: string;
  title?: string;
  onClose: () => void;
  onReset: () => void;
};

/**/
function getContainer() {
  return document.getElementById('app');
}

/**/
const FilterDrawer: React.FC<Props> = ({
  className,
  children,
  isVisible,
  title,
  onClose,
  onReset,
  rootClassName
}: Props): ReactElement => (
  <Drawer
    className={classnames('RightSideDrawer__main', className)}
    closable={false}
    getContainer={getContainer}
    destroyOnClose={true}
    open={isVisible}
    onClose={onClose}
    placement="right"
    rootClassName={classnames('RightSideDrawer', rootClassName)}
  >
    <FilterHeader onClose={onClose} onReset={onReset} title={title} />

    <div className="RightSideDrawer__content">{children}</div>
  </Drawer>
);

/**/
FilterDrawer.defaultProps = {
  title: 'FILTERS'
};

FilterDrawer.propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired
};

export { FilterDrawer };
