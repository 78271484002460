import React, { FC } from 'react';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';

import './CustomFields.scss';
import {
  customFieldKeyRules,
  customFieldValueRules,
  LinkButton
} from 'components';
import { IconCustom } from 'components/common/IconCustom/IconCustom';

/**/
const CustomFieldsName = ['customFields', 'customFields'];
const PlusIcon = <IconCustom type="plus" />;

/**
 *
 */
const CustomFields: FC = () => {
  const renderedFields = (fields, { add, remove }) => (
    <>
      {fields.map((field) => (
        <div className="CustomFields__wrapper" key={field.key}>
          <div className="CustomFields">
            <label className="CustomFields__label">Custom field</label>
            <Form.Item
              label="Field title"
              {...field}
              fieldKey={[field.fieldKey, 'key']}
              key={`${field.fieldKey}-key`}
              name={[field.name, 'key']}
              rules={customFieldKeyRules}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Field content"
              {...field}
              fieldKey={[field.fieldKey, 'value']}
              key={`${field.fieldKey}-value`}
              name={[field.name, 'value']}
              rules={customFieldValueRules}
            >
              <Input.TextArea />
            </Form.Item>
          </div>

          <IconCustom
            className="CustomFields__controls"
            type="minus"
            onClick={() => remove(field.name)}
          />
        </div>
      ))}

      <Form.Item className="CustomFields__addButtonWrapper">
        <LinkButton icon={PlusIcon} onClick={() => add()}>
          Add custom field
        </LinkButton>
      </Form.Item>
    </>
  );

  return <Form.List name={CustomFieldsName}>{renderedFields}</Form.List>;
};

export { CustomFields };
