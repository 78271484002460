import { connect } from 'react-redux';

import { setFiltersAction } from 'store/filters/filtersActions';
import { TechFilter } from '../TechFilter/TechFilter';
import { TState } from 'store';

const mapStateToProps = ({ dictionaries, filters }: TState) => ({
  ranges: dictionaries.filterRanges,
  values: {
    lengthValue: filters.lengthValue,
    powerValue: filters.enginePowerValue,
    tonnageValue: filters.tonnageValue,
    yearValue: filters.buildYearValue
  }
});

const mapDispatchToProps = (dispatch) => ({
  handlers: {
    selectLength: (value) => dispatch(setFiltersAction({ lengthValue: value })),
    selectPower: (value) =>
      dispatch(setFiltersAction({ enginePowerValue: value })),
    selectTonnage: (value) =>
      dispatch(setFiltersAction({ tonnageValue: value })),
    selectYear: (value) => dispatch(setFiltersAction({ buildYearValue: value }))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TechFilter as any);
