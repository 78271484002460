import React, { ReactElement, PureComponent } from 'react';

import {
  ContactCard,
  ContactEditor,
  CrmSection,
  deleteConfirm,
  EmptyList,
  LoaderOverlay
} from 'components';
import { ContactsContext } from './ContactsContext';
import { PLACEHOLDER } from 'other';
import { TCrmContact } from 'types';

type State = {
  editableContact: TCrmContact;
};

class Contacts extends PureComponent<null, State> {
  static propTypes;
  static contextType = ContactsContext;

  state: State = {
    editableContact: null
  };

  handleFormSubmit = async (
    formData: Partial<TCrmContact>,
    photo: File
  ): Promise<void> => {
    const { addContact, editContact } = this.context as any;
    const { editableContact } = this.state;

    editableContact
      ? await editContact({ id: editableContact.id, ...formData }, photo)
      : await addContact(formData, photo);
  };

  handleEditorClose = (): void => this.setState({ editableContact: null });

  renderContactEditor = (hideModal: () => void): ReactElement => (
    <ContactEditor
      editableContact={this.state.editableContact}
      hideModal={hideModal}
      onEditorClose={this.handleEditorClose}
      onFormSubmit={this.handleFormSubmit}
    />
  );

  handleEdit = (contactId: number): void =>
    this.setState({
      editableContact: (this.context as any).contacts.find(
        ({ id }) => id === contactId
      )
    });

  handleDelete = (proposalId: number): void => {
    deleteConfirm('Remove this contact permanently?', async () => {
      await (this.context as any).deleteContact(proposalId);
    });
  };

  render(): ReactElement {
    const { isLoading, contacts } = this.context as any;
    const contactsList = (
      hideModal: () => void,
      showModal: () => void
    ): ReactElement | ReactElement[] => {
      if (contacts?.length > 0) {
        return contacts.map((contact) => (
          <ContactCard
            contact={contact}
            key={contact.id}
            showModal={showModal}
            onDelete={this.handleDelete}
            onEdit={this.handleEdit}
          />
        ));
      }

      return (
        <EmptyList
          placeholder="Add your first contact"
          placeholderImgUrl={PLACEHOLDER.CONTACT_CARD}
        />
      );
    };

    return (
      <LoaderOverlay isLoading={isLoading} isTransparent={true}>
        <CrmSection
          addButtonTitle="Add contact"
          modalContent={this.renderContactEditor}
          modalTitle="Contact"
        >
          {contactsList}
        </CrmSection>
      </LoaderOverlay>
    );
  }
}

export { Contacts };
