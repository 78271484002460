import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import Table, { TableProps } from 'antd/lib/table';

import './TableAutoSizer.scss';
import { isMobile } from 'components';

const pagination = {
  defaultPageSize: 20,
  pageSizeOptions: ['20', '50', '100']
};

const styles = { width: '100%' };

type Props<T> = TableProps<T> & {
  offsetY?: number;
};

const TableAutoSizer = <T extends Record<string, unknown>>(
  props: Props<T>
): ReactElement => {
  const tableProps = {
    pagination: pagination,
    ...props
  };

  return isMobile() ? (
    <Table {...props} />
  ) : (
    <AutoSizer className="TableAutoSizer" style={styles}>
      {({ height }) => (
        <Table
          {...tableProps}
          scroll={{
            x: props.scroll.x,
            y: height - (props.offsetY || 132)
          }}
        />
      )}
    </AutoSizer>
  );
};

TableAutoSizer.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array.isRequired,
  dataSource: PropTypes.array.isRequired,
  footer: PropTypes.func,
  offsetY: PropTypes.number,
  scroll: PropTypes.object
};

export { TableAutoSizer };
