import {
  addVesselSet,
  createSet,
  fetchSet,
  removeSet,
  removeVesselSet,
  renameSet
} from './fleetsConstants';
import { ENDPOINTS } from 'other';
import { FleetUpdateUtil } from './FleetUpdateUtil';
import { http, THttpResponse } from 'services';
import { setFiltersAction } from '../filters/filtersActions';
import { updateSessionAction } from '../session/sessionActions';

import { TFleet, TFleetItems, TIdName } from 'types';
import { TState } from 'store';

/**
 * Retrieves fleets.
 */
export function fetchFleetsAction() {
  return (dispatch, getState) => {
    const { fleets } = getState() as TState;
    if (fleets.fleets.length > 0) return;

    dispatch(fetchSet.request());

    http
      .send(ENDPOINTS.FLEET)
      .then(({ data }: THttpResponse<TFleet[]>) =>
        dispatch(fetchSet.success({ fleets: data }))
      )
      .catch((e) => dispatch(fetchSet.error(e)));
  };
}

/**
 * Creates a new fleet with given name.
 */
export function createFleetAction(name: string) {
  return (dispatch, getState) => {
    const {
      fleets: { fleets }
    } = getState() as TState;

    dispatch(createSet.request());

    http
      .send({
        body: { name: name },
        method: 'POST',
        url: ENDPOINTS.FLEET
      })
      .then(({ data: fleet }: THttpResponse<TIdName>) => {
        const newFleet: TFleet = {
          ...fleet,
          companyIds: [],
          fishFarmIds: [],
          vesselIds: []
        };
        dispatch(
          createSet.success({
            fleets: fleets.concat([newFleet])
          })
        );
        // add the new fleet to the user's profile.
        dispatch(updateSessionAction());
      })
      .catch((e) => dispatch(createSet.error(e)));
  };
}

/**
 * Removes a fleet by given ID.
 */
export function removeFleetAction(fleetId: number) {
  return (dispatch, getState) => {
    dispatch(removeSet.request());

    http
      .send({
        credentials: 'include',
        method: 'DELETE',
        url: `${ENDPOINTS.FLEET}/${fleetId}`
      })
      .then(() => {
        const {
          filters: { fleetValue },
          fleets: { fleets }
        } = getState() as TState;

        dispatch(
          removeSet.success({
            fleets: fleets.filter((f: TFleet) => f.id !== fleetId)
          })
        );
        // delete the fleet from the vesselFilter if it was there
        if (fleetValue.includes(fleetId)) {
          const fleetsIds: number[] = fleetValue.filter(
            (id: number) => id !== fleetId
          );
          dispatch(setFiltersAction({ fleetValue: fleetsIds }));
        }

        // delete the fleet from the user's profile.
        dispatch(updateSessionAction());
      })
      .catch((e) => dispatch(removeSet.error(e)));
  };
}

/**
 * Add/remove vessel to/from the fleet.
 */
// export function toggleVesselAction(ids: number[], fleet: TFleet) {
export function toggleVesselAction(candidates: TFleetItems, fleetId: number) {
  return (dispatch, getState) => {
    const { fleets } = getState() as TState;
    const fleet = fleets.fleets.find(({ id }: TFleet) => id === fleetId);
    const action: Function = areIncluded(candidates, fleet)
      ? removeVesselAction
      : addVesselAction;

    dispatch(action(candidates, fleet));
  };
}

export function areIncluded(candidates: TFleetItems, fleet: TFleet): boolean {
  const companiesInFleet =
    candidates.companies?.length > 0 &&
    candidates.companies.every((id: number) => fleet.companyIds.includes(id));

  const farmsInFleet =
    candidates.farms?.length > 0 &&
    candidates.farms.every((id: number) => fleet.fishFarmIds.includes(id));

  const vesselsInFleet =
    candidates.vessels?.length > 0 &&
    candidates.vessels.every((id: number) => fleet.vesselIds.includes(id));

  return companiesInFleet || farmsInFleet || vesselsInFleet;
}

/**
 * Add a vessel to the fleet.
 */
// export function addVesselAction(vesselIds: number[], fleet: TFleet) {
export function addVesselAction(candidates: TFleetItems, fleet: TFleet) {
  return (dispatch, getState) => {
    const {
      fleets: { fleets }
    } = getState() as TState;

    const util = new FleetUpdateUtil(fleet, fleets, candidates);
    const params = util.getAddOperationRequestParams();
    dispatch(addVesselSet.request());

    http
      .send(params)
      .then(() => dispatch(addVesselSet.success(util.getAddOperationPayload())))
      .catch((e) => dispatch(addVesselSet.error(e)));
  };
}

/**
 * Remove a vessel from the fleet.
 */
export function removeVesselAction(candidates: TFleetItems, fleet: TFleet) {
  return (dispatch, getState) => {
    const {
      fleets: { fleets }
    } = getState() as TState;

    const util = new FleetUpdateUtil(fleet, fleets, candidates);
    const params = util.getRemoveOperationRequestParams();
    dispatch(removeVesselSet.request());

    http
      .send(params)
      .then(() =>
        dispatch(removeVesselSet.success(util.getRemoveOperationPayload()))
      )
      .catch((e) => dispatch(removeVesselSet.error(e)));
  };
}

/**
 * Change a fleet name.
 */
export function renameFleetAction(fleet: TFleet, name: string) {
  return (dispatch, getState) => {
    const {
      fleets: { fleets }
    } = getState() as TState;

    const util = new FleetUpdateUtil(fleet, fleets);
    const params = util.getRenameOperationRequestParams(name);
    dispatch(renameSet.request());

    http
      .send(params)
      .then(() =>
        dispatch(renameSet.success(util.getRenameOperationPayload(name)))
      )
      .catch((e) => dispatch(renameSet.error(e)));
  };
}
