import { createAsyncActions, TAreaState } from 'store';

export enum EAreasActions {
  CREATE_AREAS = 'areas/CREATE_AREAS',
  FETCH_AREAS = 'areas/FETCH_AREAS',
  FETCH_ZONES = 'areas/FETCH_ZONES',
  REMOVE_AREA = 'areas/REMOVE_AREA',
  RESET_SELECTED = 'areas/TOGGLE_DISABLED_MODE',
  SELECT_AREA = 'areas/SELECT_AREA',
  UPDATE_AREA = 'areas/UPDATE_AREA'
}

export const fetchSet = createAsyncActions<TAreaState>(
  EAreasActions.FETCH_AREAS
);

export const createSet = createAsyncActions<TAreaState>(
  EAreasActions.CREATE_AREAS
);

export const removeSet = createAsyncActions<TAreaState>(
  EAreasActions.REMOVE_AREA
);

export const updateSet = createAsyncActions<TAreaState>(
  EAreasActions.UPDATE_AREA
);

export const fetchZonesSet = createAsyncActions<TAreaState>(
  EAreasActions.FETCH_ZONES
);
