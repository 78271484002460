import React, { ReactElement } from 'react';
import { BaseOptionType } from 'antd/lib/select';

import { LoadingSimple } from 'components/common/Loading/LoadingSimple';
import { queryString } from 'other';
import { TSearchItemExt, TSearchResultExt } from 'types';

export function getEmptyOption(
  query: string,
  isLoading: boolean
): {}[] /*: OptionGroupData[]*/ {
  if (!query) return null;

  const text = isLoading ? (
    <LoadingSimple />
  ) : (
    <>
      No matches for <b>{query}</b>
    </>
  );
  const label = <div className="Search__empty">{text}</div>;

  return [
    {
      label,
      options: []
    }
  ];
}

function renderTitle(title: string): ReactElement {
  return (
    <span className="Search__group--title app-overline-2--bold" key={title}>
      {title}
    </span>
  );
}

export function getSearchOptions(
  arr: ReadonlyArray<TSearchResultExt>,
  query: string,
  isLoading: boolean
): {}[] /*: OptionGroupData[]*/ {
  if (arr && !arr.length) return getEmptyOption(query, isLoading);

  const getGroup = ({ type, items }: TSearchResultExt): {} => {
    const getItem = (item: TSearchItemExt) => {
      return {
        label: <div className="Search__item">{item.text}</div>,
        value: queryString.build(item)
      };
    };

    return {
      label: renderTitle(type),
      options: items.map(getItem)
    };
  };

  return arr && arr.map(getGroup);
}

/*eslint-disable*/
export function dropdownRender(
  options: BaseOptionType[],
  query: string,
  isLoading: boolean,
  onClick: (value: string) => void
) {
  return (): ReactElement => {
    if (!options) return <>getEmptyOption(query, isLoading)</>;

    return (
      <>
        {options.map((group: BaseOptionType) => renderGroup(group, onClick))}
        <br />
      </>
    );
  };
}

function renderGroup(
  // @ts-ignore
  { label, options }: BaseOptionType,
  onClick: (value: string) => void
): ReactElement {
  return (
    <React.Fragment key={label.key}>
      <div className="ant-select-item ant-select-item-group">
        {renderTitle(label)}
      </div>

      {options.map(({ label, value }) => {
        const handleClick = () => onClick(value);
        return (
          <div
            aria-selected="false"
            className="ant-select-item ant-select-item-option ant-select-item-option-grouped"
            key={value}
            onClick={handleClick}
          >
            <div className="ant-select-item-option-content">{label}</div>
            <span
              className="ant-select-item-option-state Search__nonSelect"
              unselectable="on"
              aria-hidden="true"
            />
          </div>
        );
      })}
    </React.Fragment>
  );
}
