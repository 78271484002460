import React, { useState, FC } from 'react';
import PropTypes from 'prop-types';
import Input from 'antd/lib/input';
import Space from 'antd/lib/space';

import { SaveCancelGroup } from 'components';

const { TextArea } = Input;

type Props = {
  onSubmit: (rejectReasonCustom: string) => void;
  onCancel: () => void;
};

const RejectReasonCustom: FC<Props> = ({ onSubmit, onCancel }) => {
  const [rejectReasonCustom, setRejectReasonCustom] = useState('');
  const handleChange = ({ target: { value } }) => setRejectReasonCustom(value);
  const handleSave = () => onSubmit(rejectReasonCustom);

  return (
    <>
      <Space size="middle" direction="vertical">
        <TextArea value={rejectReasonCustom} onChange={handleChange} />
        <SaveCancelGroup onSave={handleSave} onCancel={onCancel} />
      </Space>
    </>
  );
};

RejectReasonCustom.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export { RejectReasonCustom };
