import { createAsyncActions, TCrmVesselsState } from 'store';

export enum ECrmVesselsActions {
  FETCH_VESSELS = 'crmVessels/FETCH_VESSELS',
  EDIT_VESSEL = 'crmVessels/EDIT_VESSEL',
  DELETE_VESSEL = 'crmVessels/DELETE_VESSEL',
  ADD_VESSEL = 'crmVessels/ADD_VESSEL',
  UPDATE_RELATED_VESSELS = 'crmVessels/UPDATE_RELATED_VESSELS'
}

export const fetchSet = createAsyncActions<TCrmVesselsState>(
  ECrmVesselsActions.FETCH_VESSELS
);
