import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'antd/lib/checkbox';
import { PTFleet, TFleet, TFleetItems } from 'types';

type Props = {
  candidates: TFleetItems;
  fleet: TFleet;
  onToggle: (candidates: TFleetItems, fleetId: number) => void;
};

class FleetItem extends React.PureComponent<Props> {
  static propTypes;

  handleToggle = () => {
    const { candidates, fleet, onToggle } = this.props;
    onToggle(candidates, fleet.id);
  };

  /**
   * Decides whether all the vessels of current fleet `fleetVesselIds`
   * are included into overall list of the favourite vessels `favVesselIds`.
   */
  isInFavs(): boolean {
    const { candidates, fleet } = this.props;
    const { companies, farms, vessels } = candidates;
    const { companyIds, fishFarmIds, vesselIds } = fleet;

    if (companies?.length <= companyIds.length && companies.length > 0) {
      return companies.every((id: number) => companyIds.includes(id));
    }
    if (farms?.length <= fishFarmIds.length && farms.length > 0) {
      return farms.every((id: number) => fishFarmIds.includes(id));
    }
    if (vessels?.length <= vesselIds.length && vessels.length > 0) {
      return vessels.every((id: number) => vesselIds.includes(id));
    }
    return false;
  }

  render() {
    return (
      <li className="FleetsMenu__item">
        <Checkbox checked={this.isInFavs()} onChange={this.handleToggle}>
          {this.props.fleet.name}
        </Checkbox>
      </li>
    );
  }
}

FleetItem.propTypes = {
  candidates: PropTypes.exact({
    companies: PropTypes.arrayOf(PropTypes.number),
    farms: PropTypes.arrayOf(PropTypes.number),
    vessels: PropTypes.arrayOf(PropTypes.number)
  }).isRequired,
  fleet: PropTypes.exact(PTFleet).isRequired,
  onToggle: PropTypes.func.isRequired
};

export { FleetItem };
