import { connect } from 'react-redux';

import { MultiSelect } from 'components';
import { setFiltersAction } from 'store/filters/filtersActions';

import { TIdName, TSelectOption } from 'types';
import { TState } from 'store';

const mapStateToProps = ({ filters, session }: TState) => {
  const fleets: TIdName[] = session.user
    ? session.user.userInfo.fleets
    : ([] as any);

  const options = fleets.map(
    (f: TIdName): TSelectOption<number> => ({
      label: f.name,
      value: f.id
    })
  );

  return {
    allOptionLabel: 'All',
    options: options,
    value: filters.fleetValue
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: (value: number[]) =>
    dispatch(setFiltersAction({ fleetValue: value }))
});

export default connect(mapStateToProps, mapDispatchToProps)(MultiSelect);
