import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import './NewsCard.scss';
import { ARTICLE_IMAGE_URL, ENV, ROUTES } from 'other';
import { CommonBanner, GAStatistic, PromoButton } from 'components';
import ImpressionTracker from './ImpressionTracker';
import PostInfo from '../PostInfo/PostInfo';

import { EPromoStatus, TArticle } from 'types';

type Props = {
  article: Readonly<TArticle>;
  horizontal?: boolean;
  onClick?: () => void;
  status?: EPromoStatus;
};

/**/
/**/
class NewsCard extends React.PureComponent<Props> {
  static propTypes;
  private elem: HTMLDivElement;

  componentDidMount(): void {
    new ImpressionTracker(this.elem, this.props.article.id);
  }

  assignRef = (el: HTMLDivElement): HTMLDivElement => (this.elem = el);

  render(): ReactElement {
    const { article, horizontal, onClick, status } = this.props;
    const {
      analytics,
      banner,
      id,
      published,
      shortDescription,
      source,
      time,
      title
    } = article;

    const bannerUrl = banner ? `${ARTICLE_IMAGE_URL}/${banner.path}` : null;
    const isHorizontal = horizontal !== undefined ? horizontal : false;
    const path = `${ROUTES.NEWS}/${id}`;
    const _source = isHorizontal ? null : source;

    const mainCls: string = classnames('NewsCard', {
      'NewsCard--horizontal': isHorizontal
    });

    const titleCls: string = classnames('NewsCard__title app-h4', {
      'NewsCard__title--draft': !published
    });

    const descCls: string = classnames('NewsCard__description', {
      'NewsCard__description--draft': !published
    });

    return (
      <article className={mainCls} onClick={onClick} ref={this.assignRef}>
        <CommonBanner
          className="NewsCard__banner"
          isDraft={!published}
          path={path}
          src={bannerUrl}
        />

        <div className="NewsCard__holder">
          <div className="NewsCard__header">
            {title && (
              <Link className="NewsCard__text" to={path}>
                <h4 className={titleCls}>{title}</h4>
              </Link>
            )}

            <PostInfo date={time} source={_source} />
          </div>

          {shortDescription && (
            <Link className="NewsCard__text" to={path}>
              <p className={descCls}>{shortDescription}</p>
            </Link>
          )}

          <div className="NewsCard__promo">
            <PromoButton articleId={id} size="small" status={status} />
          </div>

          {analytics && ENV.IS_FF && (
            <GAStatistic data={analytics} smallView={true} />
          )}
        </div>
      </article>
    );
  }
}

NewsCard.propTypes = {
  article: PropTypes.shape({
    banner: PropTypes.shape({
      path: PropTypes.string.isRequired
    }),
    id: PropTypes.number.isRequired,
    logo: PropTypes.shape({
      path: PropTypes.string.isRequired
    }),
    published: PropTypes.bool.isRequired,
    shortDescription: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  horizontal: PropTypes.bool,
  status: PropTypes.string
};

export default NewsCard;
