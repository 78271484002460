import { connect } from 'react-redux';

import { MultiSelect } from 'components';
import { setFiltersAction } from 'store/filters/filtersActions';
import { TState } from 'store';

const mapStateToProps = ({ dictionaries: { countries }, filters }: TState) => ({
  allOptionLabel: 'All countries',
  options: countries || [],
  value: filters.countryValue
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (value: string[]) =>
    dispatch(setFiltersAction({ countryValue: value }))
});

export default connect(mapStateToProps, mapDispatchToProps)(MultiSelect);
