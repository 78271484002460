import React, { FC } from 'react';
import PropTypes from 'prop-types';

type Props = {
  className?: string;
  itemsArray: string[];
  header: string;
};

const MultipleValuesRow: FC<Props> = ({ className, itemsArray, header }) => {
  const renderItem = (item) => {
    switch (header) {
      case 'Phone':
        return <a href={`tel:+${item}`}>+{item}</a>;
      case 'Email':
        return <a href={`mailto:${item}`}>{item}</a>;
      default:
        return item;
    }
  };

  return (
    <>
      {itemsArray.map((item, index) => (
        <tr className={className} key={`${item}-${index}`}>
          <th> {!!index || header}</th>
          <td>{renderItem(item)}</td>
        </tr>
      ))}
    </>
  );
};

MultipleValuesRow.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string.isRequired,
  itemsArray: PropTypes.arrayOf(PropTypes.string).isRequired
};

export { MultipleValuesRow };
