import React from 'react';
import PropTypes from 'prop-types';
import dayjs, { Dayjs } from 'dayjs';

import './PromotionDetails.scss';
import { EPaymentStatus } from 'types';

export type TPromotionDetails = {
  countries: string | string[];
  end: string | Dayjs;
  serviceCategories: string | string[];
  start: string | Dayjs;
  status?: EPaymentStatus;
  utcOffset: string;
  vesselTypes: string | string[];
};
type Props = {
  details: TPromotionDetails;
};

function formatDate(date: string | Dayjs): string {
  return dayjs(date).format('YYYY-MM-DD HH:mm');
}

const PromotionDetails = ({ details }: Props) => {
  const {
    countries,
    end,
    serviceCategories,
    start,
    status,
    utcOffset,
    vesselTypes
  } = details;

  const _countries = Array.isArray(countries)
    ? countries.join(', ')
    : countries;
  const _vesselTypes = Array.isArray(vesselTypes)
    ? vesselTypes.join(', ')
    : vesselTypes;
  const _serviceCategories = Array.isArray(serviceCategories)
    ? serviceCategories.join(', ')
    : serviceCategories;

  return (
    <section className="PromotionDetails">
      <h3 className="PromotionDetails__title app-h3">Audience</h3>

      <table className="PromotionDetails__table">
        <tbody>
          <tr>
            <td>Countries:</td>
            <td>{_countries}</td>
          </tr>
          <tr>
            <td>Vessel types:</td>
            <td>{_vesselTypes}</td>
          </tr>
          <tr>
            <td>Service categories:</td>
            <td>{_serviceCategories}</td>
          </tr>
        </tbody>
      </table>

      <h3 className="PromotionDetails__title app-h3">Duration</h3>
      <table className="PromotionDetails__table">
        <tbody>
          <tr>
            <td>Start date (UTC):</td>
            <td>
              <span id="start">{formatDate(start)}</span>
            </td>
          </tr>
          <tr>
            <td>End date (UTC):</td>
            <td>
              <span id="end">{formatDate(end)}</span>
            </td>
          </tr>
          <tr>
            <td>UTC offset:</td>
            <td>{utcOffset}</td>
          </tr>
          {status && (
            <tr>
              <td>Payment status:</td>
              <td>{status}</td>
            </tr>
          )}
        </tbody>
      </table>
    </section>
  );
};

PromotionDetails.propTypes = {
  details: PropTypes.object.isRequired
};

export { PromotionDetails };
