import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'antd/lib/modal';
import { RadioChangeEvent } from 'antd/lib/radio/interface';

import './RejectReasonModal.scss';
import { PROPOSAL_OTHER_REJECT_REASON_ID } from 'other';
import { RejectReasons, RejectReasonCustom } from 'components';
import { selectRejectReasons } from '../../helpers/helpers';

import { TState } from 'store';
import { TSelectOption } from 'types';

type Props = {
  onCancel: () => void;
  onReasonSelect: (rejectReasonId: number, rejectReasonCustom?: string) => void;
  isModalVisible: boolean;
  rejectReasons: TSelectOption<number>[];
};

type State = {
  isCustomReasonOpen: boolean;
};

class RejectReasonModalComponent extends PureComponent<Props, State> {
  static propTypes;

  state: State = { isCustomReasonOpen: false };

  handleCustomReasonClose = () => this.setState({ isCustomReasonOpen: false });

  handleModalClose = () => {
    this.props.onCancel();
    this.handleCustomReasonClose();
  };

  handleReasonSelect = ({
    target: { value: rejectReasonId }
  }: RadioChangeEvent) => {
    if (rejectReasonId === PROPOSAL_OTHER_REJECT_REASON_ID) {
      return this.setState({ isCustomReasonOpen: true });
    }

    this.props.onReasonSelect(rejectReasonId);
    this.handleModalClose();
  };

  handleCustomReasonSelect = (rejectReasonCustom) => {
    this.props.onReasonSelect(
      PROPOSAL_OTHER_REJECT_REASON_ID,
      rejectReasonCustom
    );
    this.handleModalClose();
  };

  render() {
    const { isModalVisible, rejectReasons } = this.props;

    return (
      <>
        <Modal
          className="RejectReasonModal"
          title="Please select a reason why the proposal has been rejected"
          open={isModalVisible}
          onCancel={this.handleModalClose}
          footer={null}
          closable={false}
          destroyOnClose={true}
          width={400}
        >
          {this.state.isCustomReasonOpen ? (
            <RejectReasonCustom
              onCancel={this.handleCustomReasonClose}
              onSubmit={this.handleCustomReasonSelect}
            />
          ) : (
            <RejectReasons
              rejectReasons={rejectReasons}
              onReasonSelect={this.handleReasonSelect}
            />
          )}
        </Modal>
      </>
    );
  }
}

RejectReasonModalComponent.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onReasonSelect: PropTypes.func.isRequired,
  isModalVisible: PropTypes.bool.isRequired
};

const mapStateToProps = (state: TState) => ({
  rejectReasons: selectRejectReasons(state)
});

export const RejectReasonModal = connect(mapStateToProps)(
  RejectReasonModalComponent
);
