import PropTypes from 'prop-types';

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
export const modules = {
  toolbar: [
    [{ header: [2, 3, 4, false] }],
    [{ size: ['small', false, 'large'] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ align: [] }],
    [{ script: 'sub' }, { script: 'super' }],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }
    ],
    ['link', 'image', 'video']
    // [{ color: [] }, { background: [] }]
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
};

/*
 * Quill editor FORMATS
 * See https://quilljs.com/docs/formats/
 */
export const formats = [
  'header',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'align',
  'script',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video'
];

/**/
export const RTEBasicPropTypes = {
  emptyStringAlias: PropTypes.string,
  formats: PropTypes.arrayOf(PropTypes.string),
  modules: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.string
};
