import React from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';

import './NavItem.scss';
import { IconCustom, NewTag } from 'components';
import { ROUTES } from 'other';

import { TNavigationItem } from 'types';

/**/
type Props = {
  closeAside: (path: string) => void;
  isCollapsed: boolean;
  isLoggedIn: boolean;
  item: TNavigationItem;
};

/**/
function isActive(title) {
  return (match, location) => {
    if (!match) return false;
    return location.state?.id === title;
  };
}

/**
 *
 */
function NavItem(props: Props) {
  const { closeAside, isCollapsed, isLoggedIn, item } = props;
  const { extra, icon, path, title } = item;
  const handler = () => closeAside(path);

  const cls = classnames('NavItem', {
    'NavItem--bold': icon,
    'NavItem--collapsed': isCollapsed
  });

  const _props = isLoggedIn
    ? {
        exact: path === ROUTES.HOME,
        to: path
      }
    : {
        isActive: isActive(title),
        to: {
          pathname: path,
          state: { id: title }
        }
      };

  return (
    <NavLink
      activeClassName="NavItem--active"
      className={cls}
      onClick={handler}
      {..._props}
    >
      <div className="NavItem__box">
        {icon && <IconCustom className="NavItem__icon" type={icon} />}
      </div>

      {isCollapsed || title}
      {isCollapsed || (extra && <NewTag />)}
    </NavLink>
  );
}

/**
 *
 */
export default NavItem;
