import { ComponentType } from 'react';
import { connect } from 'react-redux';

import { selectVesselTypesOptions } from 'components';
import { setFiltersAction } from 'store/filters/filtersActions';
import { TState } from 'store';

const mapStateToProps = ({
  filters,
  dictionaries: { vesselTypes }
}: TState) => ({
  allOptionLabel: 'All types',
  options: selectVesselTypesOptions(vesselTypes),
  value: filters.vesselTypeValue
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (value: number[]) =>
    dispatch(setFiltersAction({ vesselTypeValue: value }))
});

export function buildVesselTypeFilter(Component: ComponentType) {
  return connect(mapStateToProps, mapDispatchToProps)(Component);
}
