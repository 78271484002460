import React, { FC } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import './BrandLogo.scss';
import { PROVIDER_IMAGE_URL } from 'other';
import { TMedia } from 'types';

type Props = {
  className?: string;
  logo?: TMedia;
  url: string;
};

const Component: FC<Props> = ({ className, logo, url }) => {
  if (!logo) return null;

  const cls: string = classnames('BrandLogo', className);
  const imgUrl = `${PROVIDER_IMAGE_URL}/${logo.path}`;

  return (
    <Link className={cls} to={url}>
      <img alt="BrandLogo" className="BrandLogo__image" src={imgUrl} />
    </Link>
  );
};

Component.propTypes = {
  className: PropTypes.string,
  // @ts-ignore
  logo: PropTypes.shape({
    path: PropTypes.string.isRequired
  }),
  url: PropTypes.string.isRequired
};

export const BrandLogo = React.memo(Component);
