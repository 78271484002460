import React, { FC, ReactElement } from 'react';
import PropTypes from 'prop-types';
import Form from 'antd/lib/form';
import { Rule } from 'antd/lib/form';

import './FormList.scss';
import { IconButton } from 'components';

/**/
type Props = {
  children: ReactElement | ReactElement[];
  label?: string;
  name: string | string[];
  rules?: Rule[];
};

/**
 *
 */
const FormList: FC<Props> = ({ label, name, rules, children }) => {
  const renderedFields = (fields, { add, remove }) =>
    fields.map((field, index) => {
      const isLastItem = index + 1 === fields.length;
      const onAdd = () => add();
      const onRemove = () => remove(field.name);

      return (
        <div className="FormList__item" key={field.fieldKey}>
          <Form.Item {...field} fieldKey={field.fieldKey} rules={rules}>
            {children}
          </Form.Item>

          {isLastItem ? (
            <IconButton icon="plus" onClick={onAdd} size="S" />
          ) : (
            <IconButton icon="minus" onClick={onRemove} size="S" />
          )}
        </div>
      );
    });

  return (
    <Form.Item className="FormList" label={label}>
      <Form.List name={name}>{renderedFields}</Form.List>
    </Form.Item>
  );
};

/**/
FormList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  label: PropTypes.string,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  rules: PropTypes.array
};

export { FormList };
