import { basicInitialState } from 'store/_utils/basicInitialState';
import { SettingsService } from 'services/settings';
import { TCompaniesFilter } from 'types';
import { TCompaniesState } from 'store';

export const companiesFilterInitialSettings: TCompaniesFilter = {
  companyValue: [],
  countryValue: [],
  fleetValue: [],
  isFilterSet: false
};
const settings = SettingsService.readSettings();
const filterSettings: TCompaniesFilter =
  settings[SettingsService.COMPANIES_FILTER] || companiesFilterInitialSettings;

export const companiesInitialState: TCompaniesState = {
  ...basicInitialState,
  companies: [],
  filterSettings
};
