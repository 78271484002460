import { isDictionary } from 'other';

/**
 * S stands for domain state, T - for action type.
 */
export type TAction<S, T extends string> = {
  type: T;
  payload?: Partial<S>;
  isPending?: boolean;
  error?: string;
};

export type Reducer<State> = (
  state: State,
  action: TAction<Partial<State>, string>
) => State;

type TStore = any;

/**
 * Returns a reducer which selects actions by given namespace.
 * The reducer accepts only top-level domain state, ready to
 * just merge into the previous domain state snapshot with no modification expected.
 * @param namespace
 */
function reducerAtomic(namespace: string): Reducer<TStore> {
  return (
    state = {},
    action: TAction<Partial<TStore>, string>
  ): Partial<TStore> => {
    const { type, payload } = action;
    const [_namespace] = type.split('/');
    if (_namespace !== namespace) return state;

    return isDictionary(payload) ? { ...state, ...payload } : state;
  };
}

/**
 * Converts a list of namespaces into a map of atomic reducers.
 * @param namespaces
 */
export function namespaces2reducers(
  namespaces: string[]
): Record<string, Reducer<TStore>> {
  const reducers = {};
  namespaces.forEach(
    (ns: string): Function => (reducers[ns] = reducerAtomic(ns))
  );
  return reducers;
}
