import React, { FC } from 'react';
import PropTypes from 'prop-types';

import './CompaniesFilter.scss';
import { BannerCarousel, FleetsManager, MultiSelect } from 'components';
import { ENV } from 'other';

import {
  EBannerType,
  PTCompaniesFilter,
  TCompaniesFilter,
  TSelectOption
} from 'types';

type Props = {
  companies: TSelectOption<number>[];
  countries: TSelectOption<string>[];
  fleets: TSelectOption<number>[];
  setFilterCompanies: (companies: number[]) => void;
  setFilterCountries: (countries: string[]) => void;
  setFilterFleets: (fleets: number[]) => void;
  settings: TCompaniesFilter;
};

const CompaniesFilter: FC<Props> = ({
  companies,
  countries,
  fleets,
  setFilterCompanies,
  setFilterCountries,
  setFilterFleets,
  settings
}) => {
  return (
    <div className="CompaniesFilter">
      <FleetsManager />

      <div className="BasicFilter__row">
        <label className="BasicFilter__label--bold">My {ENV.FLEET}</label>
        <MultiSelect<number>
          allOptionLabel={`All ${ENV.FLEETS}`}
          options={fleets}
          value={settings.fleetValue}
          onChange={setFilterFleets}
        />
      </div>

      <div className="BasicFilter__row">
        <label className="BasicFilter__label--bold">Country</label>
        <MultiSelect<string>
          allOptionLabel="All countries"
          options={countries}
          value={settings.countryValue}
          onChange={setFilterCountries}
        />
      </div>

      <div className="BasicFilter__row">
        <label className="BasicFilter__label--bold">Company</label>
        <MultiSelect<number>
          allOptionLabel="All companies"
          options={companies}
          value={settings.companyValue}
          onChange={setFilterCompanies}
        />
      </div>

      <div className="BasicFilter__row">
        <div className="BasicFilter__banner">
          <BannerCarousel type={EBannerType.FILTERS} />
        </div>
      </div>
    </div>
  );
};

CompaniesFilter.propTypes = {
  companies: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  setFilterCompanies: PropTypes.func.isRequired,
  setFilterCountries: PropTypes.func.isRequired,
  setFilterFleets: PropTypes.func.isRequired,
  settings: PropTypes.exact(PTCompaniesFilter).isRequired
};

export { CompaniesFilter };
