export const timezones = [
  {
    label: '-12:00',
    value: '-12:00'
  },
  {
    label: '-11:00',
    value: '-11:00'
  },
  {
    label: '-10:00',
    value: '-10:00'
  },
  {
    label: '-09:00',
    value: '-09:00'
  },
  {
    label: '-08:00',
    value: '-08:00'
  },
  {
    label: '-07:00',
    value: '-07:00'
  },
  {
    label: '-06:00',
    value: '-06:00'
  },
  {
    label: '-05:00',
    value: '-05:00'
  },
  {
    label: '-04:30',
    value: '-04:30'
  },
  {
    label: '-04:00',
    value: '-04:00'
  },
  {
    label: '-03:30',
    value: '-03:30'
  },
  {
    label: '-03:00',
    value: '-03:00'
  },
  {
    label: '-02:00',
    value: '-02:00'
  },
  {
    label: '-01:00',
    value: '-01:00'
  },
  {
    label: '+00:00',
    value: '+00:00'
  },
  {
    label: '+01:00',
    value: '+01:00'
  },
  {
    label: '+02:00',
    value: '+02:00'
  },
  {
    label: '+03:00',
    value: '+03:00'
  },
  {
    label: '+03:30',
    value: '+03:30'
  },
  {
    label: '+04:00',
    value: '+04:00'
  },
  {
    label: '+04:30',
    value: '+04:30'
  },
  {
    label: '+05:00',
    value: '+05:00'
  },
  {
    label: '+05:30',
    value: '+05:30'
  },
  {
    label: '+05:45',
    value: '+05:45'
  },
  {
    label: '+06:00',
    value: '+06:00'
  },
  {
    label: '+06:30',
    value: '+06:30'
  },
  {
    label: '+07:00',
    value: '+07:00'
  },
  {
    label: '+08:00',
    value: '+08:00'
  },
  {
    label: '+08:45',
    value: '+08:45'
  },
  {
    label: '+09:00',
    value: '+09:00'
  },
  {
    label: '+09:30',
    value: '+09:30'
  },
  {
    label: '+10:00',
    value: '+10:00'
  },
  {
    label: '+10:30',
    value: '+10:30'
  },
  {
    label: '+11:00',
    value: '+11:00'
  },
  {
    label: '+11:30',
    value: '+11:30'
  },
  {
    label: '+12:00',
    value: '+12:00'
  },
  {
    label: '+12:45',
    value: '+12:45'
  },
  {
    label: '+13:00',
    value: '+13:00'
  },
  {
    label: '+14:00',
    value: '+14:00'
  }
];
