import React, { createContext, Component, ReactElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import message from 'antd/lib/message';
import { UploadFile } from 'antd/lib/upload/interface';

import {
  addEntity,
  deleteAndInsertOnTop,
  deleteEntity,
  editEntity,
  getEntity,
  getLoadDeleteQueues
} from '../helpers';
import {
  addProposal,
  deleteProposal,
  editProposal
} from 'store/CRM/Proposals/ProposalsActions';
import { addProposalPhoto, deleteProposalPhoto, getProposal } from 'components';
import { ENDPOINTS, updateArrayItem } from 'other';

import {
  EEntityType,
  EProposalStatus,
  TCrmCompany,
  TCrmCompanyAF,
  TCrmVessel,
  TProposal
} from 'types';

type State = { proposals: TProposal[]; isLoading: boolean };
export type ContextState = State & {
  addProposal: (proposal: TProposal, fileList: UploadFile[]) => Promise<void>;
  changeStatus: (
    proposalId: number,
    status: EProposalStatus,
    rejectReasonId?: number,
    rejectReasonCustom?: string
  ) => Promise<void>;
  editProposal: (
    proposal: TProposal,
    fileList: UploadFile[],
    insertOnTop?: boolean
  ) => Promise<void>;
  deleteProposal: (proposalId: number) => Promise<void>;
};

export const ProposalsContext = createContext<ContextState | undefined>(
  undefined
);

// TODO: need to unite TCrmCompanyAF and TCrmCompany
type Props = {
  children: React.ReactNode;
  entity: TCrmVessel | TCrmCompany | TCrmCompanyAF;
  entityType: EEntityType;
  deleteProposal: (proposalId: number) => void;
  editProposal: (proposal: TProposal) => void;
  addProposal: (
    proposal: TProposal,
    entityType: EEntityType,
    entity: TCrmVessel | TCrmCompany | TCrmCompanyAF
  ) => void;
};

class ContextProviderComponent extends Component<Props, State> {
  static propTypes;

  state: State = {
    isLoading: true,
    proposals: []
  };

  componentDidMount() {
    this.fetchProposals();
  }

  fetchProposals = async (): Promise<void> => {
    const { entity, entityType } = this.props;

    try {
      const proposals = await getEntity<TProposal[]>(
        `${ENDPOINTS.CRM_PROPOSALS}?entityId=${entity.id}&type=${entityType}`
      );

      this.setState({
        isLoading: false,
        proposals: proposals.reverse()
      });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  addProposal = async (
    proposal: TProposal,
    fileList: UploadFile[]
  ): Promise<void> => {
    this.setState({ isLoading: true });
    const { entity, entityType, addProposal } = this.props;
    const newProposal = {
      ...proposal,
      targetEntityId: entity.id,
      type: entityType
    };

    try {
      let addedProposal = await addEntity<TProposal>(
        ENDPOINTS.CRM_PROPOSALS,
        newProposal
      );

      if (fileList.length > 0) {
        addedProposal = await this.getProposalWithUpdatedFileList(
          addedProposal,
          fileList
        );
      }

      addProposal(addedProposal, entityType, entity);

      this.setState({
        isLoading: false,
        proposals: [addedProposal, ...this.state.proposals]
      });
    } catch {
      this.setState({ isLoading: false });
    }
  };

  editProposal = async (
    proposal: TProposal,
    fileList: UploadFile[]
  ): Promise<void> => {
    const { entity, editProposal } = this.props;

    this.setState({ isLoading: true });
    // some fields should not be presented in the PUT request
    /* eslint-disable */
    const {
      createdBy,
      crmContact,
      files,
      targetId,
      targetEntity,
      ...editableProposal
    } = proposal;
    /* eslint-enable */
    try {
      let editedProposal = await editEntity<TProposal>(
        `${ENDPOINTS.CRM_PROPOSALS}/${proposal.id}`,
        {
          ...editableProposal,
          targetEntityId: entity.id
        }
      );

      if (fileList) {
        editedProposal = await this.getProposalWithUpdatedFileList(
          editedProposal,
          fileList
        );
      }

      editProposal(editedProposal);

      this.setState({
        isLoading: false,
        proposals: updateArrayItem(editedProposal, this.state.proposals)
      });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  // existing files might be deleted on proposal edit.
  // post proposal photo endpoint returns updated proposal.
  getProposalWithUpdatedFileList = async (
    proposal: TProposal,
    fileList: UploadFile[]
  ): Promise<TProposal> => {
    const { id: proposalId, files } = proposal;
    const { deleteQueue, loadQueue } = getLoadDeleteQueues(files, fileList);

    if (loadQueue.length > 0 || deleteQueue.length > 0) {
      const allSettledResult = await Promise.allSettled<Promise<TProposal>>([
        ...loadQueue.map((file) => addProposalPhoto(proposalId, file)),
        ...deleteQueue.map((file) => deleteProposalPhoto(proposalId, file))
      ]);

      allSettledResult.forEach((proposal) => {
        if (proposal.status === 'rejected') {
          message.error(proposal.reason.message);
        }
      });

      return await getProposal(proposalId);
    }

    return proposal;
  };

  deleteProposal = async (proposalId: number): Promise<void> => {
    const { deleteProposal } = this.props;
    this.setState({ isLoading: true });

    try {
      await deleteEntity(`${ENDPOINTS.CRM_PROPOSALS}/${proposalId}`);
      deleteProposal(proposalId);

      const proposals = this.state.proposals.filter(
        ({ id }) => id !== proposalId
      );

      this.setState({ isLoading: false, proposals });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  changeStatus = async (
    proposalId: number,
    status: EProposalStatus,
    rejectReasonId?: number,
    rejectReasonCustom?: string
  ): Promise<void> => {
    this.setState({ isLoading: true });

    try {
      const editedProposal = await editEntity<TProposal>(
        `${ENDPOINTS.CRM_PROPOSALS}/${proposalId}/status`,
        {
          rejectReasonCustom,
          rejectReasonId,
          status
        }
      );

      this.props.editProposal(editedProposal);

      this.setState({
        isLoading: false,
        proposals: deleteAndInsertOnTop(editedProposal, this.state.proposals)
      });

      await this.fetchProposals();
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  render(): ReactElement {
    const { proposals, isLoading } = this.state;

    const value = {
      addProposal: this.addProposal,
      changeStatus: this.changeStatus,
      deleteProposal: this.deleteProposal,
      editProposal: this.editProposal,
      isLoading,
      proposals
    };

    return (
      <ProposalsContext.Provider value={value}>
        {this.props.children}
      </ProposalsContext.Provider>
    );
  }
}

ContextProviderComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  entity: PropTypes.object.isRequired,
  entityType: PropTypes.oneOf(Object.values(EEntityType)).isRequired
};

const mapDispatchToProps = {
  addProposal,
  deleteProposal,
  editProposal
};

export const ProposalsContextProvider = connect(
  null,
  mapDispatchToProps
)(ContextProviderComponent);
