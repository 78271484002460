import React, { FC } from 'react';
import PropTypes from 'prop-types';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import PhoneInput from 'react-phone-input-2';

import './ContactForm.scss';
import {
  CustomFields,
  emailRules,
  firstNameRules,
  FormList,
  hobbyRules,
  lastNameRules,
  noteRules,
  phoneRules,
  positionRules,
  SaveCancelGroup,
  socialRules
} from 'components';

import { TCrmContact } from 'types';

const positionName = ['customFields', 'position'];
const phonesName = ['customFields', 'phones'];
const socialsName = ['customFields', 'socials'];
const notesName = ['customFields', 'notes'];
const emailsName = ['customFields', 'emails'];
const hobbyName = ['customFields', 'hobby'];

type Props = {
  initialValues: Partial<TCrmContact>;
  onFormSubmit: (values: any) => void;
  onCancel: () => void;
};

const ContactForm: FC<Props> = ({ initialValues, onFormSubmit, onCancel }) => (
  <Form
    className="ContactForm"
    initialValues={initialValues}
    layout="vertical"
    name="basic"
    onFinish={onFormSubmit}
  >
    <Form.Item label="First name" name="firstName" rules={firstNameRules}>
      <Input />
    </Form.Item>

    <Form.Item label="Last name" name="lastName" rules={lastNameRules}>
      <Input />
    </Form.Item>

    <Form.Item label="Position" name={positionName} rules={positionRules}>
      <Input />
    </Form.Item>

    {initialValues.company && (
      <Form.Item label="Company" name="company">
        <Input disabled={true} />
      </Form.Item>
    )}

    <FormList label="Phone Number" name={phonesName} rules={phoneRules}>
      <PhoneInput enableTerritories={true} placeholder="" />
    </FormList>

    <FormList label="Email" name={emailsName} rules={emailRules}>
      <Input />
    </FormList>

    <FormList label="Social" name={socialsName} rules={socialRules}>
      <Input />
    </FormList>

    <FormList label="Hobby" name={hobbyName} rules={hobbyRules}>
      <Input />
    </FormList>

    <FormList label="Notes" name={notesName} rules={noteRules}>
      <Input.TextArea />
    </FormList>

    <CustomFields />

    <Form.Item>
      <SaveCancelGroup onCancel={onCancel} />
    </Form.Item>
  </Form>
);

ContactForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired
};

export { ContactForm };
