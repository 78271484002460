import React from 'react';
import {
  Line,
  LineChart,
  XAxis,
  YAxis,
  ReferenceArea,
  ResponsiveContainer
} from 'recharts';

import './HCPlot.scss';
import { DAY_ARRAY, DEFAULT_PROPS } from './helpers';
import { getColourByYear } from '../helpers';

/**/
type Props = {
  data: any[];
  end: number;
  start: number;
  years: number[];
};

/**
 *
 */
class HCPlot extends React.PureComponent<Props> {
  /**/
  renderAreas() {
    const { end, start } = this.props;

    if (start < end) {
      return <ReferenceArea x1={start} x2={end} {...DEFAULT_PROPS.REF_AREA} />;
    }

    return (
      <>
        <ReferenceArea x1={1} x2={end} {...DEFAULT_PROPS.REF_AREA} />
        <ReferenceArea x1={start} x2={365} {...DEFAULT_PROPS.REF_AREA} />
      </>
    );
  }

  /**/
  renderZone() {
    return (
      <LineChart data={DAY_ARRAY} {...DEFAULT_PROPS.ZONE_CHART}>
        <XAxis dataKey="day" {...DEFAULT_PROPS.XAXIS} />
        <YAxis {...DEFAULT_PROPS.YAXIS} />
        {this.renderAreas()}
      </LineChart>
    );
  }

  /**/
  renderCurves() {
    const { data, years } = this.props;

    return (
      <LineChart data={data} {...DEFAULT_PROPS.CURVE_CHART}>
        <XAxis dataKey="week" {...DEFAULT_PROPS.XAXIS} />
        <YAxis {...DEFAULT_PROPS.YAXIS} />

        {years.map((y) => (
          <Line
            type="monotone"
            dataKey={String(y)}
            dot={false}
            key={String(y)}
            stroke={getColourByYear(y)}
            strokeWidth={2}
          />
        ))}
      </LineChart>
    );
  }

  /**/
  render() {
    return (
      <div className="HCPlot">
        <div className="HCPlot__item HCPlot__back">
          <ResponsiveContainer width="100%" height="100%">
            {this.renderZone()}
          </ResponsiveContainer>
        </div>

        <div className="HCPlot__item HCPlot__front">
          <ResponsiveContainer width="100%" height="100%">
            {this.renderCurves()}
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}

/**/
export default HCPlot;
