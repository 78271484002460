import React, { ReactElement } from 'react';

import AbstractNotificationContent from './AbstractNotificationContent';
import { ROUTES } from 'other';

/**
 *
 */
class AdminNotificationObject extends AbstractNotificationContent<string> {
  protected defaultIcon = 'speed';

  /**/
  protected parseBody(): void {
    this.body = this.notification.body;
  }

  /**/
  public getContent(): ReactElement {
    return <span>{this.body}</span>;
  }

  /**/
  public getIcon(): ReactElement {
    return this.getDefaultIcon();
  }

  /**/
  public getPath(): string {
    return ROUTES.NEWS;
  }
}

/**/
export default AdminNotificationObject;
