import React from 'react';
import Popover from 'antd/lib/popover';

import './SharingGroup.scss';
import { IconButtonOutlined } from '../IconButtonOutlined/IconButtonOutlined';
import { SharingService, TShareInfo } from 'services';

/**/
type Props = {
  info: TShareInfo;
};
type State = {
  isVisible: boolean;
};

/**
 *
 */
class SharingGroup extends React.PureComponent<Props, State> {
  private static text = (
    <p className="app-body-2">Link copied to clipboard</p>
  );

  state: State = { isVisible: false };

  /**/
  handleCopy = () => {
    this.setState({ isVisible: true });
    setTimeout(() => this.setState({ isVisible: false }), 2000);

    window.navigator.clipboard
      .writeText(window.location.href)
      .catch(window.console.error);
  };

  /**/
  render() {
    const { info } = this.props;

    return (
      <div className="SharingGroup">
        <div className="SharingGroup__content">
          <div className="app-overline-1 SharingGroup__text">Share:</div>

          <IconButtonOutlined
            className="SharingGroup__button"
            icon="facebook-f"
            onClick={() => SharingService.shareOnFB(info)}
            size="M"
            title="Share on Facebook"
          />

          <IconButtonOutlined
            className="SharingGroup__button"
            icon="linkedin-in"
            onClick={() => SharingService.shareOnLinkedIn(info)}
            size="M"
            title="Share on LinkedIn"
          />

          <Popover
            content={SharingGroup.text}
            open={this.state.isVisible}
            rootClassName="SharingGroup__popover"
            trigger="click"
          >
            <IconButtonOutlined
              className="SharingGroup__button"
              icon="link"
              onClick={this.handleCopy}
              size="M"
              title="Copy link"
            />
          </Popover>
        </div>
      </div>
    );
  }
}

/**/
export { SharingGroup };
