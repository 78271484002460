import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';

import './NotificationList.scss';
import { EmptyNotificationList } from './EmptyNotificationList';
import { Loading } from '../../common/Loading/Loading';
import { NotificationListItem } from './NotificationListItem/NotificationListItem';
import { ROUTES } from 'other';
import { TextButtonUnderlined } from '../../common/buttons/TextButtonUnderlined/TextButtonUnderlined';

import { TNotification } from 'types';

/**/
type Props = {
  hasMore: boolean;
  notifications: TNotification[];
  onClear: () => void;
  onClose: () => void;
  onItemClick: (id: number) => void;
  onMore: () => void;
};

/**/
const loader = <Loading className="NotificationList__loader" />;

/**
 *
 */
class NotificationList extends React.PureComponent<Props> {
  /**/
  private static CONTENT = 'NotificationList__list';

  /**/
  renderList() {
    const { hasMore, notifications, onItemClick, onMore } = this.props;
    const isEmpty = !notifications || notifications.length === 0;

    if (isEmpty) {
      return <EmptyNotificationList className="EmptyNotificationList__small" />;
    }

    return (
      <InfiniteScroll
        dataLength={notifications.length}
        hasMore={hasMore}
        loader={loader}
        next={onMore}
        scrollableTarget={NotificationList.CONTENT}
      >
        {notifications.map((n: TNotification) => (
          <NotificationListItem
            key={n.id}
            notification={n}
            onItemClick={onItemClick}
          />
        ))}
      </InfiniteScroll>
    );
  }

  /**/
  render() {
    const { notifications, onClear, onClose } = this.props;
    const isEmpty = !notifications || notifications.length === 0;

    return (
      <div className="NotificationList">
        <header className="NotificationList__header">
          <h6 className="app-h6 NotificationList__title">Notifications</h6>

          {isEmpty || (
            <div className="NotificationList__control" onClick={onClose}>
              <Link to={ROUTES.NOTIFICATIONS}>
                <TextButtonUnderlined className="NotificationList__button">
                  View all
                </TextButtonUnderlined>
              </Link>

              <TextButtonUnderlined
                className="NotificationList__button"
                onClick={onClear}
              >
                Clear all
              </TextButtonUnderlined>
            </div>
          )}
        </header>

        <main className="NotificationList__list" id={NotificationList.CONTENT}>
          {this.renderList()}
        </main>
      </div>
    );
  }
}

/**/
export default NotificationList;
