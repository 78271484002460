import isequal from 'lodash.isequal';

import './MultiSelect.scss';
import { MultiSelect } from 'components';
import { TSelectOption } from 'types';

/** Custom component based on top of AntDesign Select */
class MultiSelectNone<T extends string | number> extends MultiSelect<T> {
  static FULL_VALUE = [];
  static NONE_VALUE = 'None' as any;
  static defaultProps = { fullValue: MultiSelectNone.FULL_VALUE };
  static NONE_OPTION = {
    label: MultiSelectNone.NONE_VALUE,
    value: MultiSelectNone.NONE_VALUE
  };

  static options2values<T extends number | string>(
    arr: TSelectOption<T>[]
  ): T[] {
    if (!arr) return [];
    return arr.map(({ value }: TSelectOption<T>): T => value);
  }

  /** Filters out the NONE_VALUE from given array. */
  static removeNoneOption(values: any[]): any[] {
    return values.filter((x) => x !== MultiSelectNone.NONE_VALUE);
  }

  getOptions(): TSelectOption<T>[] {
    const { allOptionLabel, options } = this.props;
    return allOptionLabel
      ? ([
          MultiSelectNone.getAllOption(allOptionLabel),
          MultiSelectNone.NONE_OPTION,
          ...options
        ] as any)
      : [MultiSelectNone.NONE_OPTION, ...options];
  }

  getValue(): T[] {
    const { value } = this.props;
    return !value
      ? [MultiSelectNone.NONE_VALUE]
      : value.length > 0
      ? value
      : [MultiSelect.ALL_VALUE];
  }

  handleChange = (values: T[]): void => {
    const { fullValue, onChange } = this.props;
    const value = this.getValue();

    if (values.length === 0) {
      return onChange(fullValue);
    }
    // NONE choice
    else if (
      !isequal(value, [MultiSelectNone.NONE_VALUE]) &&
      values.includes(MultiSelectNone.NONE_VALUE)
    ) {
      return onChange(null);
    }
    // ALL case
    else if (
      (!isequal(value, [MultiSelect.ALL_VALUE]) &&
        values.includes(MultiSelect.ALL_VALUE)) ||
      isequal(MultiSelectNone.options2values(this.getOptions()), values)
    ) {
      return onChange([]);
    }

    onChange(
      MultiSelect.removeAllOption(MultiSelectNone.removeNoneOption(values))
    );
  };
}

export { MultiSelectNone };
