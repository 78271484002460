import React, { useContext, memo, FC } from 'react';
import Select from 'antd/lib/select';

import { ContactsContext } from 'components/CRM/Contacts/ContactsContext';
import { typeSearchFilter } from 'other';

const Component: FC = (props) => {
  const { contacts } = useContext(ContactsContext);
  const _value = (props as any).value;
  const value = _value ? String(_value) : void 0;

  const contactOptions = contacts.map(({ id, firstName, lastName }) => ({
    label: `${firstName} ${lastName || ''}`,
    value: String(id)
  }));

  return (
    <Select
      {...props}
      filterOption={typeSearchFilter}
      optionFilterProp="children"
      options={contactOptions}
      placeholder="Select a person"
      showSearch
      value={value}
    />
  );
};

export const ContactsSelect = memo(Component);
