import React, { ReactElement } from 'react';

import './ArticleEditorBanner.scss';
import { ArticleLogo, ImageInput, ProgressLine } from 'components';
import { TArticleSource } from 'types';

/**/
type Props = {
  imgUrl: string;
  onUpload: (file: File) => void;
  source: TArticleSource;
  showUploadList?: boolean;
  uploadProgress?: number;
};

/**
 *
 */
const ArticleEditorBanner = ({
  imgUrl,
  onUpload,
  source,
  uploadProgress
}: Props): ReactElement => (
  <div className="ArticleEditorBanner">
    <div className="app-fixed-ratio-container">
      <div className="app-fixed-ratio-content">
        <div className="ArticleEditorBanner__content">
          <ImageInput imgUrl={imgUrl} onUpload={onUpload}>
            <ProgressLine progress={uploadProgress} />
          </ImageInput>

          {source && <ArticleLogo source={source} />}
        </div>
      </div>
    </div>
  </div>
);

/**/
export { ArticleEditorBanner };
