import React, { lazy } from 'react';

import HCSwipeMenu from './HistoricalCatchControls/HCSwipeMenu';
import HistoricalCatchControls from './HistoricalCatchControls/HistoricalCatchControls';
import { withMap } from 'components';

import {
  PeriodSettings,
  SelectedOptions,
  TCatchFilterOptions,
  WEIGHT_RANGES,
  YEAR_LIST
} from './helpers';
import { TSelectOption } from 'types';

/**/
type Props = {
  options: TCatchFilterOptions;
};

type State = SelectedOptions & PeriodSettings;

/**/
const Map = withMap(
  lazy(() => import('./HistoricalCatchMap/HistoricalCatchMap'))
);

/**
 *
 */
class HistoricalCatch extends React.PureComponent<Props, State> {
  state: State = {
    equipment: [],
    end: 365,
    species: [],
    start: 1,
    vessels: [],
    weights: WEIGHT_RANGES.slice(2).map((w: TSelectOption<number>) => w.value),
    years: YEAR_LIST.slice(0, 3)
  };

  /**/
  handleOptions = (options: Partial<PeriodSettings | SelectedOptions>) => {
    // console.log(options);
    this.setState({
      ...this.state,
      ...options
    });
  };

  /**/
  render() {
    return (
      <div className="HistoricalCatch__content">
        <Map />

        <HistoricalCatchControls
          onChanges={this.handleOptions}
          options={this.props.options}
          values={this.state}
        />

        <HCSwipeMenu onChange={this.handleOptions} values={this.state} />
      </div>
    );
  }
}

/**/
export default HistoricalCatch;
