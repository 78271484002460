/**/
export const MONTH_LIST = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

/**/
export const testValues1 = [
  {
    interval: { index: 5 },
    weight: 2e4,
    year: 2024
  },
  {
    interval: { index: 6 },
    weight: 2.543e4,
    year: 2024
  },
  {
    interval: { index: 7 },
    weight: 2.98e4,
    year: 2024
  },
  {
    interval: { index: 8 },
    weight: 2.1e4,
    year: 2024
  },
  {
    interval: { index: 21 },
    weight: 4.8e3,
    year: 2024
  },
  {
    interval: { index: 22 },
    weight: 4.1e3,
    year: 2024
  },
  {
    interval: { index: 23 },
    weight: 5.9e3,
    year: 2024
  },
  {
    interval: { index: 24 },
    weight: 7.8e3,
    year: 2024
  },
  {
    interval: { index: 25 },
    weight: 4.2e3,
    year: 2024
  },
  {
    interval: { index: 26 },
    weight: 6.3e3,
    year: 2024
  },
  {
    interval: { index: 27 },
    weight: 5.5e3,
    year: 2024
  },
  {
    interval: { index: 28 },
    weight: 6e3,
    year: 2024
  },
  {
    interval: { index: 33 },
    weight: 3e3,
    year: 2024
  },
  {
    interval: { index: 34 },
    weight: 3.9e3,
    year: 2024
  },
  {
    interval: { index: 35 },
    weight: 3.4e3,
    year: 2024
  },
  {
    interval: { index: 36 },
    weight: 2.8e3,
    year: 2024
  },
  {
    interval: { index: 37 },
    weight: 3.9e3,
    year: 2024
  },
  {
    interval: { index: 38 },
    weight: 4.2e3,
    year: 2024
  }
];

/**/
export const testValues2 = [
  {
    interval: { index: 5 },
    weight: 2e3,
    year: 2023
  },
  {
    interval: { index: 6 },
    weight: 2.543e3,
    year: 2023
  },
  {
    interval: { index: 7 },
    weight: 2.98e3,
    year: 2023
  },
  {
    interval: { index: 8 },
    weight: 2.1e3,
    year: 2023
  },
  {
    interval: { index: 21 },
    weight: 4.8e4,
    year: 2023
  },
  {
    interval: { index: 22 },
    weight: 4.1e4,
    year: 2023
  },
  {
    interval: { index: 23 },
    weight: 5.9e4,
    year: 2023
  },
  {
    interval: { index: 24 },
    weight: 7.8e4,
    year: 2023
  },
  {
    interval: { index: 25 },
    weight: 4.2e4,
    year: 2023
  },
  {
    interval: { index: 26 },
    weight: 6.3e4,
    year: 2023
  },
  {
    interval: { index: 27 },
    weight: 5.5e4,
    year: 2023
  },
  {
    interval: { index: 28 },
    weight: 6e4,
    year: 2023
  },
  {
    interval: { index: 33 },
    weight: 3e4,
    year: 2023
  },
  {
    interval: { index: 34 },
    weight: 3.9e4,
    year: 2023
  },
  {
    interval: { index: 35 },
    weight: 3.4e4,
    year: 2023
  },
  {
    interval: { index: 36 },
    weight: 2.8e4,
    year: 2023
  },
  {
    interval: { index: 37 },
    weight: 3.9e4,
    year: 2023
  },
  {
    interval: { index: 38 },
    weight: 4.2e4,
    year: 2023
  }
];

/**/
const ZERO = 1;
const DAYS_IN_YEAR = 365;
const WEEKS_IN_YEAR = 52;

/**
 *
 */
class Record {
  week: number;

  constructor(week: number, years: number[]) {
    this.week = week;
    years.forEach((y) => this.addValue(y));
  }

  addValue(year: number, value: number = ZERO) {
    this[String(year)] = value;
  }
}

/**
 *
 */
function mapCatchData(data, years: number[]) {
  const map = new Map();

  Array(WEEKS_IN_YEAR)
    .fill(null)
    .forEach((_, idx) => map.set(idx + 1, new Record(idx + 1, years)));

  data.forEach(({ interval, weight, year }) =>
    map.get(interval.index).addValue(year, weight || ZERO)
  );

  return Array.from(map.values());
}

/**/
export const DAY_ARRAY = Array(DAYS_IN_YEAR)
  .fill(null)
  .map((_, idx) => ({ day: idx + 1 }));

/**/
export const TEST_CATCH_DATA = mapCatchData(
  [...testValues1, ...testValues2],
  [2024, 2023]
);

/**/
export const DEFAULT_PROPS = {
  CURVE_CHART: {
    margin: {
      right: 4,
      left: 4,
      top: 1
    }
  },
  DATE_PICKER: {
    allowClear: false,
    className: 'HCPeriodSelect__picker',
    format: 'DD MMM',
    getPopupContainer: () => document.querySelector('.Main__content'),
    rootClassName: 'HCPeriodSelect__calendar',
    superNextIcon: null,
    superPrevIcon: null
  },
  ZONE_CHART: {
    margin: {
      right: 4,
      left: 4,
      top: -1
    }
  },
  REF_AREA: {
    y1: 1,
    y2: 7e4,
    fill: 'red',
    fillOpacity: 0.1,
    ifOverflow: 'extendDomain',
    stroke: 'red',
    strokeDasharray: '4 4',
    strokeOpacity: 0.3
  },
  XAXIS: {
    height: 2,
    stroke: '#e7e7e7',
    tickLine: false
  },
  YAXIS: {
    axisLine: false,
    scale: 'log',
    domain: ['auto', 'auto'],
    width: 0
  }
} as any;
