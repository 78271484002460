import React, { FC } from 'react';
import PropTypes from 'prop-types';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';

import './ContactCard.scss';
import { CrmItemCard, MultipleValuesRow, Placeholder } from 'components';
import { MEDIA_BASE_URL, PLACEHOLDER } from 'other';

import { EEntityType, PTCrmContact, TCrmContact } from 'types';

type Props = {
  contact: TCrmContact;
  onEdit: (contactId: number) => void;
  onDelete: (contactId: number) => void;
  showModal: () => void;
};

const ContactCard: FC<Props> = ({
  contact: {
    id,
    photo,
    createdBy,
    firstName,
    lastName,
    customFields,
    targetEntities
  },
  onEdit,
  onDelete,
  showModal
}) => {
  const handleEdit = (): void => {
    onEdit(id);
    showModal();
  };
  const handleDelete = () => onDelete(id);
  const name = `${firstName} ${lastName || ''}`;

  const src = photo && `${MEDIA_BASE_URL}/${photo.path}`;
  const title = photo?.title || name;

  const companies = targetEntities
    .filter(({ type }) => type === EEntityType.COMPANY)
    .map(({ name }) => name);

  const {
    position,
    phones,
    emails,
    socials,
    hobby,
    notes,
    customFields: contactCustomFields
  } = customFields || {};

  return (
    // @ts-ignore
    <CrmItemCard
      authorId={createdBy?.id}
      onDelete={handleDelete}
      onEdit={handleEdit}
    >
      <Row className="ContactCard" wrap={false}>
        <Col flex="none">
          <figure className="ContactCard__photo">
            <Placeholder
              alt={title}
              placeholder={PLACEHOLDER.CONTACT}
              src={src}
            />
          </figure>
        </Col>

        <Col className="ContactCard__tableWrapper" flex="auto">
          <table className="ContactCard__table">
            <tbody>
              <tr>
                <th>Name</th>
                <td>{name}</td>
              </tr>

              {position && (
                <tr>
                  <th>Position</th>
                  <td>{position}</td>
                </tr>
              )}

              {companies && (
                <MultipleValuesRow header="Company" itemsArray={companies} />
              )}

              {phones && (
                <MultipleValuesRow header="Phone" itemsArray={phones} />
              )}

              {emails && (
                <MultipleValuesRow header="Email" itemsArray={emails} />
              )}

              {socials && (
                <MultipleValuesRow header="Social" itemsArray={socials} />
              )}

              {hobby && <MultipleValuesRow header="Hobby" itemsArray={hobby} />}

              {notes && <MultipleValuesRow header="Notes" itemsArray={notes} />}

              {contactCustomFields &&
                contactCustomFields.map(({ key, value }, index) => (
                  <tr key={`${key}-${index}`}>
                    <th>{key}</th>
                    <td>{value}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </CrmItemCard>
  );
};

ContactCard.propTypes = {
  contact: PTCrmContact.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired
} as any;

export { ContactCard };
