import React from 'react';
import Tooltip from 'antd/lib/tooltip';

import './AreaNotes.scss';
import { IconCustom } from 'components';

/**/
const alignConfig = {
  // points: ['tr', 'tl'],        // align top left point of sourceNode with top right point of targetNode
  offset: [0, 8], // the offset sourceNode by 10px in x and 20px in y,
  // targetOffset: ['30%','40%'], // the offset targetNode by 30% of targetNode width in x and 40% of targetNode height in y,
  overflow: { adjustX: false, adjustY: false } // auto adjust position when sourceNode is overflowed
};

/**
 *
 */
export default function AreaNotes({ notes }) {
  return (
    notes && (
      <Tooltip
        align={alignConfig}
        className="AreaNotes"
        // open={true}
        overlayClassName="AreaNotes__card"
        placement="top"
        title={notes}
      >
        <span>
          <IconCustom className="AreaNotes__icon" type="info" />
        </span>
      </Tooltip>
    )
  );
}
