import React, { FC, ReactElement } from 'react';
import PropTypes from 'prop-types';
import Tooltip, { TooltipProps } from 'antd/lib/tooltip';

import { isMobile } from '../../helpers/helpers';

type Props = TooltipProps & {
  hideTooltipForMobiles?: boolean;
};

const TooltipCustom: FC<Props> = ({
  hideTooltipForMobiles = false,
  ...props
}) => {
  if (hideTooltipForMobiles && isMobile()) {
    return props.children as ReactElement;
  }

  return <Tooltip {...props}>{props.children}</Tooltip>;
};

TooltipCustom.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  hideTooltipForMobiles: PropTypes.bool
};

TooltipCustom.defaultProps = {
  getPopupContainer: () => document.body
};

export { TooltipCustom };
