import { addCrmCompany } from '../CrmCompanies/CrmCompaniesActions';
import { addCrmVessel } from '../CrmVessels/CrmVesselsActions';
import { addKeyAndReverse } from 'pages/CRM/helpers/helpers';
import { ENDPOINTS, updateArrayItem } from 'other';
import { getEntity } from 'components/CRM/helpers';

import {
  EEntityType,
  TCrmVessel,
  TProposal,
  TTargetEntity,
  TCrmCompany
} from 'types';
import { EProposalsActions, fetchSet } from './ProposalsConstants';
import { TState } from 'store';

export function fetchProposals() {
  return async (dispatch) => {
    try {
      dispatch(fetchSet.request());
      const proposals = await getEntity<TProposal[]>(
        `${ENDPOINTS.CRM_PROPOSALS}`
      );

      dispatch(
        fetchSet.success({
          isPending: false,
          proposals: proposals ? addKeyAndReverse(proposals) : []
        })
      );
    } catch (e) {
      dispatch(fetchSet.error(e));
    }
  };
}

export function addProposal(
  newProposal: TProposal,
  entityType: EEntityType,
  entity: TCrmCompany | TCrmVessel
) {
  return (dispatch, getState) => {
    const {
      proposals: { proposals },
      crmCompanies: { crmCompanies },
      crmVessels: { crmVessels }
    }: TState = getState();

    if (proposals) {
      dispatch({
        payload: {
          proposals: [newProposal, ...proposals]
        },
        type: EProposalsActions.ADD_PROPOSAL
      });
    }

    if (crmVessels && entityType === EEntityType.VESSEL) {
      const isVesselMissing = crmVessels.every(({ id }) => id !== entity.id);

      if (isVesselMissing) {
        dispatch(addCrmVessel(entity as TCrmVessel));
      }
    }

    if (crmCompanies && entityType === EEntityType.COMPANY) {
      const isCompanyMissing = crmCompanies.every(({ id }) => id !== entity.id);

      if (isCompanyMissing) {
        dispatch(addCrmCompany(entity as TCrmCompany));
      }
    }
  };
}

export function editProposal(updatedProposal: TProposal) {
  return (dispatch, getState) => {
    const {
      proposals: { proposals }
    }: TState = getState();

    if (proposals) {
      dispatch({
        payload: {
          proposals: updateArrayItem(updatedProposal, proposals)
        },
        type: EProposalsActions.EDIT_PROPOSAL
      });
    }
  };
}

export function deleteProposal(proposalId: number) {
  return (dispatch, getState) => {
    const {
      proposals: { proposals }
    }: TState = getState();

    if (proposals) {
      dispatch({
        payload: {
          proposals: proposals.filter(({ id }) => id !== proposalId)
        },
        type: EProposalsActions.DELETE_PROPOSAL
      });
    }
  };
}

export function deleteRelatedProposals(
  entityId: number,
  entityType: EEntityType
) {
  return (dispatch, getState) => {
    const {
      proposals: { proposals }
    }: TState = getState();

    if (!proposals) return;

    const updatedProposals = proposals.filter(({ targetEntity }) => {
      const { id, type } = targetEntity || {};

      return !(type === entityType && id === entityId);
    });

    dispatch({
      payload: {
        proposals: updatedProposals
      },
      type: EProposalsActions.DELETE_RELATED_PROPOSALS
    });
  };
}

export function updateRelatedProposals(
  entityId: number,
  entityType: EEntityType,
  updatedEntity?: TTargetEntity
) {
  return (dispatch, getState) => {
    const {
      proposals: { proposals }
    }: TState = getState();

    if (!proposals) return;

    const updatedProposals = proposals.map((proposal) => {
      const { id, type } = proposal.targetEntity || {};

      if (type === entityType && id === entityId) {
        proposal.targetEntity = updatedEntity;
      }

      return proposal;
    });

    dispatch({
      payload: {
        proposals: updatedProposals
      },
      type: EProposalsActions.UPDATE_RELATED_PROPOSALS
    });
  };
}
