import { ENDPOINTS } from 'other';
import { http } from '../../../services';
import { newsRecentInitialState, TPagination, TState } from 'store';

import { ENewsRecentActions, fetchSet } from './newsRecentConstants';
import { TArticle } from 'types';

type Response = TPagination & {
  data: TArticle[];
};

/**
 * Retrieves a list of recent news by provider ID.
 * The endpoint returns 4 news, and one of them might be the current article
 * @param {number} providerId
 * @param publishedOnly
 * @param fetchMore
 */
export function fetchRecentNewsAction(
  providerId: number,
  publishedOnly = true,
  fetchMore = false
) {
  return (dispatch, getState) => {
    const {
      newsRecent: { page: currentPage, news: currentNews, size }
    }: TState = getState();

    const page = fetchMore ? currentPage + 1 : currentPage;
    const url = `${ENDPOINTS.NEWS}/provider/${providerId}?page=${page}&publishedOnly=${publishedOnly}&size=${size}`;
    dispatch(fetchSet.request());

    http
      .send(url)
      .then(({ data, page, size, total }: Response) => {
        const result = fetchMore ? [...currentNews, ...data] : data;

        dispatch(
          fetchSet.success({
            news: result,
            page,
            size,
            total
          })
        );
      })
      .catch((e) => dispatch(fetchSet.error(e)));
  };
}

/**
 * Clears recent news.
 */
export const newsRecentClear = {
  type: ENewsRecentActions.NEWS_RECENT_CLEAR,
  payload: newsRecentInitialState
};
