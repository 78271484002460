import React from 'react';
import * as Component from 'antd/lib/slider';

import { arr2TypeMinMax, typeMinMax2Arr } from '../filters/TechFilter/helpers';
import { TMinMax } from 'types';

type Props = Omit<
  Component.SliderRangeProps,
  'range' | 'onChange' | 'value'
> & {
  onChange: (value: TMinMax) => void;
  value: TMinMax;
};
type State = {
  value: [number, number];
};

class Slider extends React.PureComponent<Props, State> {
  state: State = {
    value: this.getInitialValue()
  };

  handleAfterChange = () =>
    this.props.onChange(arr2TypeMinMax(this.state.value));

  handleChange = (value: [number, number]) => this.setState({ value });

  getInitialValue(): [number, number] {
    const { max, min, value } = this.props;
    return value && 'min' in value && 'max' in value
      ? typeMinMax2Arr(value)
      : ([min, max] as any);
  }

  getValue(): [number, number] {
    return this.state.value
      ? this.state.value
      : typeMinMax2Arr(this.props.value);
  }

  render() {
    return (
      <Component.default
        range
        {...this.props}
        onAfterChange={this.handleAfterChange}
        onChange={this.handleChange}
        value={this.getValue()}
      />
    );
  }
}

export { Slider };
