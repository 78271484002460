import { addKeyAndReverse } from 'pages/CRM/helpers/helpers';
import { ENDPOINTS, updateArrayItem } from 'other';
import { getEntity } from 'components/CRM/helpers';

import { EEntityType, TCrmContact, TTargetEntity } from 'types';
import { ECrmContactsActions, fetchSet } from './CrmContactsConstants';
import { TState } from 'store';

export function fetchCrmContacts() {
  return async (dispatch) => {
    dispatch(fetchSet.request());

    try {
      const contacts = await getEntity<TCrmContact[]>(ENDPOINTS.CRM_CONTACTS);

      dispatch(
        fetchSet.success({
          crmContacts: contacts ? addKeyAndReverse(contacts) : [],
          isPending: false
        })
      );
    } catch (e) {
      dispatch(fetchSet.error(e));
    }
  };
}

export function addCrmContact(newContact: TCrmContact) {
  return (dispatch, getState) => {
    const {
      crmContacts: { crmContacts }
    }: TState = getState();

    if (!crmContacts) return;

    dispatch({
      payload: {
        crmContacts: [newContact, ...crmContacts]
      },
      type: ECrmContactsActions.ADD_CONTACT
    });
  };
}

export function editCrmContact(updatedContact: TCrmContact) {
  return (dispatch, getState) => {
    const {
      crmContacts: { crmContacts }
    }: TState = getState();

    if (!crmContacts) return;

    dispatch({
      payload: {
        crmContacts: updateArrayItem(updatedContact, crmContacts)
      },
      type: ECrmContactsActions.EDIT_CONTACT
    });
  };
}

export function deleteCrmContact(contactId: number) {
  return (dispatch, getState) => {
    const {
      crmContacts: { crmContacts }
    }: TState = getState();

    if (!crmContacts) return;

    dispatch({
      payload: {
        crmContacts: crmContacts.filter(({ id }) => id !== contactId)
      },
      type: ECrmContactsActions.DELETE_CONTACT
    });
  };
}

export function updateRelatedCrmContacts(
  entityId: number,
  entityType: EEntityType,
  updatedEntity?: TTargetEntity
) {
  return (dispatch, getState) => {
    const {
      crmContacts: { crmContacts }
    }: TState = getState();

    if (!crmContacts) return;

    const payload = crmContacts.map((contact) => {
      const entityIndex = contact.targetEntities.findIndex(
        ({ id, type }) => id === entityId && type === entityType
      );

      if (entityIndex >= 0) {
        contact.targetEntities.splice(
          entityIndex,
          1,
          ...(updatedEntity ? [updatedEntity] : [])
        );
      }

      return contact;
    });

    dispatch({
      payload,
      type: ECrmContactsActions.UPDATE_RELATED_CONTACTS
    });
  };
}
