import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { DefaultButton, IconCustom, TextButton } from 'components';

/**/
type Props = RouteComponentProps & {
  pathBack?: string;
  type?: 'text' | 'primary';
};

/**
 *
 */
const Component = ({
  history: { goBack, length, push },
  pathBack,
  type = 'primary'
}: Props) => {
  const handleClick = () => (pathBack ? push(pathBack) : goBack());
  if (length <= 1) return null;

  if (type === 'text') {
    return (
      <TextButton onClick={handleClick}>
        <IconCustom type="arrow-left" />
        Back
      </TextButton>
    );
  }

  return (
    <DefaultButton onClick={handleClick} size="M">
      <IconCustom type="arrow-left" />
      Back
    </DefaultButton>
  );
};

// @ts-ignore
export const GoBackButton = withRouter(Component);
export { Component as GoBackBtn };
