import { ENV } from 'other';
import { readData, removeItem, writeData } from 'services';

import { TCredentials } from 'types';

enum EAuthKeys {
  CREDENTIALS = 'CREDENTIALS',
  KEEP_LOGGED = 'KEEP_LOGGED',
  TOKEN = 'TOKEN'
}

export const URL_AUTH_PARAMS = {
  AUTH_TOKEN: 'auth_token',
  USER_NAME: 'username'
};

/**
 * Compulsory logging out on leaving the site.
 */
export function scheduleLogout() {
  window.addEventListener(
    'beforeunload',
    () => shouldKeepLogged() || clearAuthSettings()
  );
}

/**
 * Sets keep logged flag.
 * @param keepLogged
 */
export function setKeepLogged(keepLogged: boolean): void {
  writeSettings({ [EAuthKeys.KEEP_LOGGED]: keepLogged });
}
export function shouldKeepLogged(): boolean {
  return readAuthSettings()[EAuthKeys.KEEP_LOGGED] === true;
}

export function saveToken(token?: string): void {
  writeSettings({ [EAuthKeys.TOKEN]: token });
}
export function getToken(): string {
  const written = readAuthSettings()[EAuthKeys.TOKEN];
  if (written) return written;
}

export function getCredentialsFromURL() {
  const search = new URLSearchParams(window.location.search);
  const token = search.get(URL_AUTH_PARAMS.AUTH_TOKEN);
  const username = search.get(URL_AUTH_PARAMS.USER_NAME);

  if (token && username) {
    username && saveCredentials({ username: username } as any);
    return { username: username, password: token } as any;
  }
}

export function saveCredentials(credentials: TCredentials): void {
  writeSettings({ [EAuthKeys.CREDENTIALS]: credentials });
}
export function getCredentials(): TCredentials | void {
  const credentials = readAuthSettings()[EAuthKeys.CREDENTIALS];
  if (Object.keys(credentials).length) {
    return credentials;
  }
}

export function getCredentialsIfPreserved(): TCredentials | void {
  const credentialsFromURL = getCredentialsFromURL();

  if (credentialsFromURL) {
    return credentialsFromURL;
  }

  if (!shouldKeepLogged()) return;
  return getCredentials();
}

/**
 * A couple of basic methods.
 */
export function readAuthSettings(): any {
  return readData(ENV.STORE_AUTH_KEY) || {};
}
export function writeSettings(data: { [key: string]: any }): any {
  const current = readAuthSettings();
  return writeData(ENV.STORE_AUTH_KEY, { ...current, ...data });
}
export function clearAuthSettings(): void {
  removeItem(ENV.STORE_AUTH_KEY);
}
