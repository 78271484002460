import AbstractCard from 'pages/Map/components/AbstractCard';
import { L } from 'types';

/**/
export type Point = {
  flag: string;
  lastUpdate: string;
  name: string;
  speed: number;
};

/**
 *
 */
class TrackTooltip {
  private tooltip: L.Tooltip;

  /**/
  public static getContent(point: Point, id?: string): HTMLElement {
    const { flag, lastUpdate, name, speed } = point;
    const popup = document.createElement('DIV');
    popup.classList.add(
      'MapEntityCard2',
      'MapEntityCard2__container',
      'TrackTooltip'
    );
    id && popup.setAttribute('id', id);

    popup.innerHTML = `
      ${AbstractCard.getHeader(name, flag)}
      <div class="${AbstractCard.CLASS_NAMES.TEXT}">
        <div>
          <span class="MapEntityText__label">Speed</span>
          <data value="speed">${TrackTooltip.renderSpeed(speed)}</data>
        </div>
        <div>
          <span class="MapEntityText__label">Time</span>
          <data value="date">${TrackTooltip.renderDateTime(lastUpdate)}</data>
        </div>
      </div>
    `;

    return popup;
  }

  /**/
  public static renderSpeed(speed: number): string {
    return ` ${speed}kn`;
  }

  /**/
  public static renderDateTime(lastUpdate: string): string {
    const time = new Date(lastUpdate).toLocaleTimeString();
    const date = new Date(lastUpdate).toLocaleDateString('en-GB');
    return ` ${date} ${time}`;
  }

  /**/
  constructor(map: L.Map, position: L.LatLng, point: Point) {
    this.tooltip = window.L.tooltip({
      direction: 'top',
      opacity: 1,
      permanent: true
    })
      .setLatLng(position)
      .setContent(TrackTooltip.getContent(point))
      .addTo(map);
  }

  /**/
  remove(): void {
    this.tooltip.remove();
  }
}

/**/
export { TrackTooltip };
