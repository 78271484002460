import React, { FC } from 'react';
import Col from 'antd/lib/col';
import DatePicker from 'antd/lib/date-picker';
import dayjs from 'dayjs';
import Form from 'antd/lib/form';
import Row from 'antd/lib/row';

import './RemindersFormList.scss';
import { AddButton } from 'pages/CRM/components/AddButton';
import { IconButton, RecurrenceSelect } from 'components';
import { ERecurrence } from 'types';

/**/
const DateRules = [{ message: 'Date is required!', required: true }];
const gutter = { md: 10 };
const disabledDate = (current) => current && current < dayjs().startOf('day');

/**
 *
 */
const RemindersFormList: FC = () => (
  <Form.List name="reminders">
    {(fields, { add, remove }) => (
      <>
        <Form.Item>
          <Row align="middle" justify="space-between">
            <Col className="RemindersFormList__header app-subtitle-2">
              Set Reminder
            </Col>
            <Col>
              <AddButton onClick={() => add()} text="Add reminder" />
            </Col>
          </Row>
        </Form.Item>

        {/*@ts-ignore*/}
        {fields.map(({ key, name, fieldKey, ...restField }) => (
          <Row align="middle" gutter={gutter} key={key}>
            <Col md={10} xs={20}>
              <Form.Item
                {...restField}
                className="RemindersFormList__datePicker"
                fieldKey={[fieldKey, 'start']}
                label="Date"
                name={[name, 'start']}
                rules={DateRules}
              >
                <DatePicker disabledDate={disabledDate} showTime={true} />
              </Form.Item>
            </Col>
            <Col md={7} xs={20}>
              <Form.Item
                {...restField}
                fieldKey={[fieldKey, 'recurrence']}
                initialValue={ERecurrence.ONCE}
                label="recurrence"
                name={[name, 'recurrence']}
              >
                <RecurrenceSelect />
              </Form.Item>
            </Col>
            <Col className="RemindersFormList__deleteButtonContainer">
              <IconButton
                className="RemindersFormList__deleteButton"
                icon="delete"
                onClick={() => remove(name)}
              />
            </Col>
          </Row>
        ))}
      </>
    )}
  </Form.List>
);

/**/
export { RemindersFormList };
