import { push } from 'connected-react-router';

import { ENDPOINTS, ROUTES, stringComparator } from 'other';
import { http, SettingsService, THttpResponse } from 'services';
import { isMap } from 'components';
import {
  purgeMapEntitiesAction,
  removeVesselEntriesAction
} from 'store/map/mapEntities/mapEntitiesActions';
import { purgeTracksAction } from 'store/map/tracks/tracksActions';
import {
  unwatchLocationsAction,
  watchLocationsAction
} from 'store/map/actionMediator';

import {
  EProvidersActions,
  fetchBranchesSet,
  fetchProvidersSet
} from './providersConstants';
import {
  TProvider,
  TProvidersFilter,
  TSelectOption,
  TServiceBranchGroup
} from 'types';
import { providersFilterInitialSettings, TState } from 'store';

export function fetchProvidersDataAction() {
  return (dispatch) => {
    dispatch(fetchProvidersAction());
    dispatch(fetchServiceCategoriesAction());
  };
}

/**
 * Simply retrieves providers.
 */
export function fetchProvidersAction() {
  return (dispatch, getState) => {
    const {
      providers: { providers }
    } = getState() as TState;

    if (providers.length > 0) return;
    dispatch(fetchProvidersSet.request());

    http
      .send(ENDPOINTS.SERVICE_PROVIDER)
      .then(({ data }: THttpResponse<TProvider[]>) => {
        data.sort((a: TProvider, b: TProvider) =>
          stringComparator(a.name, b.name)
        );
        dispatch(fetchProvidersSet.success({ providers: data }));
      })
      .catch((e) => dispatch(fetchProvidersSet.error(e)));
  };
}

/**
 * Defines whether current settings are different from the initial ones
 * disregarding `isFilterSet` field value. Relates to the Service providers page.
 * @param settings
 */
export function isListFilterSet(settings: TProvidersFilter): boolean {
  const { listFilterCategories, listFilterCountries } = settings;
  return listFilterCategories.length > 0 || listFilterCountries.length > 0;
}

/**
 * Defines whether current settings are different from the initial ones
 * disregarding `isFilterSet` field value. Relates to the Map page.
 * @param settings
 */
export function isMapFilterSet(settings: TProvidersFilter): boolean {
  const { mapFilterCategories } = settings;
  return mapFilterCategories !== null;
}

/**
 * Sets filter for `providersModel.addressArray`, which are displayed on the map.
 * @param action
 * @param values
 * @returns {Function}
 */
export function setProvidersFilterAction(
  action: EProvidersActions,
  values: number[] | string[]
) {
  return (dispatch, getState) => {
    const {
      providers: { filterSettings }
    } = getState() as TState;

    // It's called 'partial' because we still need to update 'isFilterSet'.
    const partialSettingsUpdate: TProvidersFilter = {
      ...filterSettings,
      ...getFilterSettingsPartialUpdate(action, values)
    };
    const _isListFilterSet = isListFilterSet(partialSettingsUpdate);
    const _isMapFilterSet = isMapFilterSet(partialSettingsUpdate);

    const settingsUpdate: TProvidersFilter = {
      ...filterSettings,
      ...partialSettingsUpdate,
      isListFilterSet: _isListFilterSet,
      isMapFilterSet: _isMapFilterSet
    };

    SettingsService.writeSettings({
      [SettingsService.PROVIDERS_FILTER]: settingsUpdate
    });
    dispatch({
      type: EProvidersActions.SET_FILTER,
      payload: { filterSettings: settingsUpdate }
    });

    if (!isMap()) return;
    // If no service categories are selected, show vessels.
    // Otherwise remove vessels and tracks.
    if (
      !_isMapFilterSet ||
      partialSettingsUpdate.mapFilterCategories === null
    ) {
      dispatch(watchLocationsAction());
    } else {
      dispatch(unwatchLocationsAction());
      dispatch(purgeMapEntitiesAction());
      dispatch(purgeTracksAction());
      dispatch(removeVesselEntriesAction());
    }
  };
}

/**
 * Resets the filter state and lets all the provider addresses.
 */
export function clearProvidersFilterAction() {
  SettingsService.writeSettings({
    [SettingsService.PROVIDERS_FILTER]: providersFilterInitialSettings
  });
  return {
    type: EProvidersActions.SET_FILTER,
    payload: { filterSettings: providersFilterInitialSettings }
  };
}

/**
 * Sets 'All services'.
 */
export function showAllProvidersAction() {
  return (dispatch, getState) => {
    const { providers } = getState() as TState;
    const update = {
      ...providers.filterSettings,
      mapFilterCategories: []
    };

    SettingsService.writeSettings({
      [SettingsService.PROVIDERS_FILTER]: update
    });
    return {
      type: EProvidersActions.SET_FILTER,
      payload: { filterSettings: update }
    };
  };
}

export function getFilterSettingsPartialUpdate(
  actionType: EProvidersActions,
  values: any
): Partial<TProvidersFilter> {
  switch (actionType) {
    case EProvidersActions.SET_CATEGORIES_LIST:
      return { listFilterCategories: values };
    case EProvidersActions.SET_CATEGORIES_MAP:
      return { mapFilterCategories: values };
    case EProvidersActions.SET_COUNTRIES_LIST:
      return { listFilterCountries: values };
    default:
      throw new Error('Unknown ProvidersFilter action type: ' + actionType);
  }
}

/**
 * Fetches service branches and transforms them into categories list.
 */
export function fetchServiceCategoriesAction() {
  return (dispatch, getState) => {
    const { providers } = getState() as TState;

    if (providers.categories.length > 0) return;
    dispatch(fetchBranchesSet.request());

    http
      .send(ENDPOINTS.SERVICE_BRANCHES)
      .then((resp: THttpResponse<TServiceBranchGroup[]>) =>
        dispatch(
          fetchBranchesSet.success({
            categories: branches2Categories(resp.data)
          })
        )
      )
      .catch((e) => dispatch(fetchBranchesSet.error(e)));
  };
}

/**
 * Converts branches into a list of categories.
 */
function branches2Categories(
  branches: TServiceBranchGroup[]
): TSelectOption<number>[] {
  const categories = [];
  branches.forEach((b: TServiceBranchGroup) => {
    const subs = b.subBranches.map(mapBranch);
    categories.push({
      label: b.branch.value.en_GB,
      value: b.branch.id
    });
    categories.push(...subs);
  });

  return categories.sort((a: TSelectOption<number>, b: TSelectOption<number>) =>
    stringComparator(a.label, b.label)
  );
}
const mapBranch = ({ branch }: TServiceBranchGroup): TSelectOption<number> => ({
  label: branch.value.en_GB,
  value: branch.id
});

/**
 * Resets filter settings, related to the Service list page.
 */
export function resetProvidersFilterAction() {
  return (dispatch, getState) => {
    const { providers } = getState() as TState;
    const update = {
      ...providers.filterSettings,
      isListFilterSet: false,
      listFilterCategories: [],
      listFilterCountries: []
    };
    SettingsService.writeSettings({
      [SettingsService.PROVIDERS_FILTER]: update
    });

    dispatch({
      type: EProvidersActions.RESET_FILTER,
      payload: { filterSettings: update }
    });
  };
}

/** Redirects to the editor page of the current provider. */
export const editProviderAction = (providerId: number) =>
  push(`${ROUTES.SERVICE_EDITOR}/${providerId}`);

export const showProviderPageAction = (providerId: number) =>
  push(`${ROUTES.SERVICE_PROVIDER}/${providerId}`);
