import merge from 'lodash.merge';
import { ThemeConfig } from 'antd/lib/config-provider/context';

import { BODY_DARK_CLASS, BODY_LIGHT_CLASS, ENV } from 'other';
import common from 'styles/cssVariables/common.json';
import commonDark from 'styles/cssVariables/commonDark.json';
import commonDefault from 'styles/cssVariables/commonDefault.json';
import { commonVariables } from 'styles/cssVariables/common';
import { darkCommonVariables } from '../../styles/cssVariables/darkCommon';
import { lightCommonVariables } from '../../styles/cssVariables/lightCommon';
import { history, miscInitialState, store } from 'store';
import { SettingsService } from 'services';
import { EMiscActions } from './miscConstants';

let prevLocation = miscInitialState.prevLocation as any;

/**/
export const getThemeState = (): Record<string, boolean> => {
  const isDarkTheme =
    SettingsService.readSettings()[SettingsService.IS_DARK_THEME];

  const { theme } = miscInitialState;
  const isDark = isDarkTheme === void 0 ? theme.isDark : isDarkTheme;

  return {
    isDark: isDark,
    isDarkDefault: theme.isDark
  };
};

/**/
const toggleTheme = (isDark: boolean): void => {
  applyCssVars(isDark ? commonDark : commonDefault);
  SettingsService.writeSettings({ [SettingsService.IS_DARK_THEME]: isDark });

  if (isDark) {
    document.body.classList.add(BODY_DARK_CLASS);
    document.body.classList.remove(BODY_LIGHT_CLASS);
  } else {
    document.body.classList.remove(BODY_DARK_CLASS);
    document.body.classList.add(BODY_LIGHT_CLASS);
  }
};

/**/
export const initializeThemeAction = () => {
  return (dispatch) => {
    const { isDark } = getThemeState();
    applyCssVars(common);
    applyCssVars(ENV.CSS_VARS);
    toggleTheme(isDark);
    watchLocation();

    dispatch({
      type: EMiscActions.INIT_THEME,
      payload: {
        theme: {
          isDark: isDark,
          settings: composeTheme(isDark)
        }
      }
    });
  };
};

/**/
export const setTheme = (isDark: boolean) => {
  return (dispatch) => {
    toggleTheme(isDark);

    dispatch({
      type: EMiscActions.SET_THEME,
      payload: {
        theme: {
          isDark: isDark,
          settings: composeTheme(isDark)
        }
      }
    });
  };
};

/**/
function applyCssVars(vars: Record<string, string>): void {
  const root = document.documentElement.style;
  Object.entries(vars).forEach(([key, value]) => root.setProperty(key, value));
}

/**/
function composeTheme(isDark: boolean): ThemeConfig {
  return merge(
    {},
    commonVariables,
    ENV.THEME,
    isDark ? darkCommonVariables : lightCommonVariables
  );
}

/**/
function watchLocation(): void {
  history.listen((location) => {
    store.dispatch({
      type: EMiscActions.SAVE_PREV_LOCATION,
      payload: { prevLocation: prevLocation }
    });
    prevLocation = location;
  });
}
