import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import { VesselCard } from 'components';

import { TVessel, TVesselOfCompany } from 'types';

type Props = {
  favVesselIds: number[];
  onFavClick: (id: number) => void;
  vessel: TVesselOfCompany;
};

const ShortVessel = ({
  favVesselIds,
  onFavClick,
  vessel
}: Props): ReactElement => {
  const handleFav = () => onFavClick(vessel.vesselId);

  const { mediaPath, vesselId, vesselName, flag } = vessel;
  const photo = mediaPath
    ? mediaPath.includes('thumbnails')
      ? { thumbnail: { path: mediaPath } }
      : { thumbnail: { path: `vessels/thumbnails/${mediaPath}` } }
    : null;

  const v = {
    flag: flag.iso,
    id: vesselId,
    name: vesselName,
    photo
  } as TVessel;

  return (
    <VesselCard favVesselIds={favVesselIds} item={v} onFavClick={handleFav} />
  );
};

ShortVessel.title = 'vessel';
ShortVessel.propTypes = {
  className: PropTypes.string,
  favVesselIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onFavClick: PropTypes.func.isRequired,
  vessel: PropTypes.object.isRequired
};

export { ShortVessel };
