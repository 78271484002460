import React, { FC } from 'react';
import PropTypes from 'prop-types';
import Radio, { RadioChangeEvent } from 'antd/lib/radio';
import Space from 'antd/lib/space';
import { TSelectOption } from 'types';

/**/
const { Group, Button } = Radio;

type Props = {
  rejectReasons: TSelectOption<number>[];
  onReasonSelect: (e: RadioChangeEvent) => void;
};

/**
 *
 */
const RejectReasons: FC<Props> = ({ rejectReasons, onReasonSelect }) => (
  <Group
    optionType="button"
    size="large"
    onChange={onReasonSelect}
    value={null}
  >
    <Space direction="vertical" size="middle">
      {rejectReasons.map(({ value, label }) => (
        <Button
          checked={false}
          className="DefaultButton"
          key={value}
          value={value}
        >
          {label}
        </Button>
      ))}
    </Space>
  </Group>
);

/**/
RejectReasons.propTypes = {
  onReasonSelect: PropTypes.func.isRequired,
  rejectReasons: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.number
    })
  ).isRequired
};

export { RejectReasons };
