import React from 'react';
import Collapse, { CollapseProps } from 'antd/lib/collapse';

import { getExpandIcon } from '../../filters/helpers';

/**/
type Props = {
  items: CollapseProps['items'];
};

/**/
const DEFAULT_ACTIVE_KEY = '1';

/**
 *
 */
function LayerControlCollapse({ items }: Props) {
  return (
    <Collapse
      accordion={true}
      className="LayerControl__collapse"
      defaultActiveKey={DEFAULT_ACTIVE_KEY}
      expandIcon={getExpandIcon}
      expandIconPosition="end"
      items={items}
    />
  );
}

/**/
export { DEFAULT_ACTIVE_KEY, LayerControlCollapse };
