import { basicInitialState } from '../_utils/basicInitialState';
import { promoInitialFields } from '../promo/promoInitialState';
import { SettingsService } from 'services/settings';

import { EBannerType, ECampaignFormStep } from 'types';
import { TCampaignState } from 'store';

const isVisualTesting =
  SettingsService.readSettings()[SettingsService.IS_VISUAL_TESTING];

// Giving the testing tools a chance to approach all the steps without filling in the forms.
const availableStep = isVisualTesting
  ? ECampaignFormStep.RECAP
  : ECampaignFormStep.UPLOAD;

export const campaignInitialState: TCampaignState = {
  ...basicInitialState,
  articles: [],
  availableStep: availableStep,
  campaigns: {
    [EBannerType.FILTERS]: [],
    [EBannerType.MENU]: [],
    [EBannerType.MOBILE]: [],
    [EBannerType.NEWS]: []
  },
  //   {
  //   FILTERS: [
  //     { imagePath: '/assets/banner/fishfacts/analytics/222x42.jpg', targetPath: '/service/provider/85' },
  //     { imagePath: '/assets/banner/fishfacts/analytics/222x42.jpg', targetPath: '/service/provider/85' }
  //   ],
  //   MOBILE: [
  //     { imagePath: '/assets/banner/fishfacts/analytics/344x90.jpg', targetPath: '/service/provider/85' },
  //     { imagePath: '/assets/banner/fishfacts/analytics/344x90.jpg', targetPath: '/service/provider/85' },
  //   ],
  //   NEWS: [
  //     { imagePath: '/assets/banner/fishfacts/analytics/1010x60.jpg', targetPath: '/service/provider/85' },
  //     { imagePath: '/assets/banner/fishfacts/analytics/1010x60.jpg', targetPath: '/service/provider/85' },
  //   ],
  //   MENU: [
  //     { imagePath: '/assets/banner/fishfacts/analytics/150x150.jpg', targetPath: '/service/provider/85' },
  //     { imagePath: '/assets/banner/fishfacts/analytics/150x150.jpg', targetPath: '/service/provider/85' },
  //   ]
  // },
  cost: null,
  currentStep: ECampaignFormStep.UPLOAD,
  fields: promoInitialFields,
  products: [],
  promotion: null,
  report: null,
  uploadProgress: null,

  [EBannerType.MENU]: {
    data: null,
    uploadProgress: null
  },
  [EBannerType.NEWS]: {
    data: null,
    uploadProgress: null
  },
  [EBannerType.FILTERS]: {
    data: null,
    uploadProgress: null
  },
  [EBannerType.MOBILE]: {
    data: null,
    uploadProgress: null
  }
};
