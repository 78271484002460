import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';

import './RecentArticles.scss';
import {
  fetchRecentNewsAction,
  newsRecentClear
} from 'store/news/newsRecent/newsRecentActions';
import { isOwner, Loading, selectNews } from 'components';
import { APP_CONTENT } from 'other';
import NewsCard from 'pages/news/NewsList/articles/NewsCard';

import { TArticle, TProvider } from 'types';
import { TState } from 'store';

type Props = {
  clearNews: () => void;
  fetchNews: (
    providerId: number,
    publishedOnly?: boolean,
    fetchMore?: boolean
  ) => void;
  news: TArticle[];
  name: string;
  providerId: number;
  publishedOnly: boolean;
  total: number;
};
type OwnProps = {
  articleId?: number;
  provider?: TProvider;
};

/**/
class Component extends React.PureComponent<Props> {
  static propTypes;

  componentDidMount() {
    const { fetchNews, providerId, publishedOnly } = this.props;
    fetchNews(providerId, publishedOnly);
  }

  componentWillUnmount() {
    this.props.clearNews();
  }

  fetchMoreData = () => {
    const { fetchNews, providerId, publishedOnly } = this.props;
    fetchNews(providerId, publishedOnly, true);
  };

  render() {
    const { total, news, name } = this.props;
    if (!news?.length) return null;

    const hasMore = news.length < total;
    const loader = <Loading className="RecentArticles__loader" />;

    return (
      <div className="app-page-narrow RecentArticles">
        <h3 className="RecentArticles__title app-h3">More news from {name}</h3>

        <InfiniteScroll
          dataLength={news.length}
          hasMore={hasMore}
          loader={loader}
          next={this.fetchMoreData}
          scrollableTarget={APP_CONTENT}
        >
          {news.map((article: TArticle) => (
            <NewsCard article={article} horizontal key={article.id} />
          ))}
        </InfiniteScroll>
      </div>
    );
  }
}

Component.propTypes = {
  clearNews: PropTypes.func,
  fetchNews: PropTypes.func,
  name: PropTypes.string,
  news: PropTypes.arrayOf(PropTypes.object),
  providerId: PropTypes.number,
  publishedOnly: PropTypes.bool,
  total: PropTypes.number
};

const mapStateToProps = (state: TState, ownProps: OwnProps) => {
  const {
    newsRecent: { news, page, size, total },
    session: { user }
  } = state;
  const { articleId, provider, ...restOwnProps } = ownProps;
  const filtered = selectNews(news, ownProps.articleId);
  const _total = total - (news.length - filtered.length);

  return {
    news: filtered,
    page,
    size,
    total: _total,
    publishedOnly: !isOwner(user, provider),
    ...restOwnProps
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearNews: () => dispatch(newsRecentClear),
  fetchNews: (providerId: number, publishedOnly: boolean, fetchMore: boolean) =>
    dispatch(fetchRecentNewsAction(providerId, publishedOnly, fetchMore))
});

export const RecentArticles = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component) as any;
