import React from 'react';

import AreaItem from './AreaItem';
import { TArea } from 'types';

type Props = {
  areas: TArea[];
  onRemove: (id: number) => void;
  onSelect: (id: number) => void;
  selectedAreas: number[];
  userId: number;
};

/**/
function AreaList(props: Props) {
  const { areas, onRemove, onSelect, selectedAreas, userId } = props;
  if (!areas) return null;

  return (
    <ul className="AreaList__list">
      {areas.map((a: TArea) => {
        const isChecked = selectedAreas.includes(a.id);
        const handleSelect = () => onSelect(a.id);
        const handleRemove = () => onRemove(a.id);

        return (
          <AreaItem
            area={a}
            isChecked={isChecked}
            key={a.id}
            userId={userId}
            onRemove={handleRemove}
            onSelect={handleSelect}
          />
        );
      })}
    </ul>
  );
}

export default AreaList;
