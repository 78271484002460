export const toolbar = [
  [{ header: [2, 3, 4, false] }],
  [{ size: ['small', false, 'large'] }],
  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
  [{ align: [] }],
  [{ script: 'sub' }, { script: 'super' }],
  [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
  ['link', 'image', 'video', 'file']
  // [{ color: [] }, { background: [] }]
];

export type TFileRestriction = {
  extensions: string[];
  maxSize: number;
  message: string;
};
