import React from 'react';

import './TrackControlHead.scss';
import { IconCustom } from 'components';

const SLIDER_TRIGGER = 'slider-trigger';
type Props = { label: string };

/**/
class TrackControlHead extends React.PureComponent<Props> {
  handleClick = () => document.getElementById(SLIDER_TRIGGER).click();

  render() {
    return (
      <div className="TrackControlHead" onClick={this.handleClick}>
        <IconCustom className="TrackControlHead__arrow" type="arrow-left" />
        &nbsp;
        <h6 className="TrackControlHead__text app-h6">{this.props.label}</h6>
      </div>
    );
  }
}

/**/
export { SLIDER_TRIGGER, TrackControlHead };
