import { addKeyAndReverse } from 'pages/CRM/helpers/helpers';
import {
  deleteRelatedProposals,
  updateRelatedProposals
} from '../Proposals/ProposalsActions';
import { ENDPOINTS, updateArrayItem } from 'other';
import { getEntity } from 'components/CRM/helpers';
import { updateCrmVesselsOwner } from '../CrmVessels/CrmVesselsActions';
import { updateRelatedCrmContacts } from '../CrmContacts/CrmContactsActions';

import { EEntityType, TCrmCompany } from 'types';
import { ECrmCompaniesActions, fetchSet } from './CrmCompaniesConstants';
import { TAction, TCrmCompaniesState, TState } from 'store';

export function fetchCrmCompanies() {
  return async (dispatch) => {
    dispatch(fetchSet.request());

    try {
      const companies = await getEntity<TCrmCompany[]>(ENDPOINTS.CRM_COMPANIES);

      dispatch(
        fetchSet.success({
          crmCompanies: companies ? addKeyAndReverse(companies) : []
        })
      );
    } catch (e) {
      dispatch(fetchSet.error(e));
    }
  };
}

export function addCrmCompany(newCompany: TCrmCompany) {
  return (dispatch, getState) => {
    const {
      crmCompanies: { crmCompanies }
    }: TState = getState();

    if (crmCompanies) {
      dispatch({
        payload: {
          crmCompanies: [newCompany, ...crmCompanies]
        },
        type: ECrmCompaniesActions.ADD_COMPANY
      });
    }
  };
}

export function editCrmCompany(updatedCompany: TCrmCompany) {
  return (dispatch, getState) => {
    const {
      crmCompanies: { crmCompanies },
      crmContacts: { crmContacts },
      crmVessels: { crmVessels },
      proposals: { proposals }
    }: TState = getState();

    const updatedEntity = {
      country: updatedCompany.country.iso,
      id: updatedCompany.id,
      name: updatedCompany.name,
      statusId: updatedCompany.status.id,
      type: EEntityType.COMPANY
    };

    if (crmCompanies) {
      dispatch({
        payload: {
          crmCompanies: updateArrayItem(updatedCompany, crmCompanies)
        },
        type: ECrmCompaniesActions.EDIT_COMPANY
      });
    }

    if (crmVessels) {
      dispatch(
        updateCrmVesselsOwner(updatedCompany.id, {
          address: updatedCompany.address,
          city: updatedCompany.city,
          country: updatedCompany.country,
          id: updatedCompany.id,
          name: updatedCompany.name,
          postcode: updatedCompany.postcode,
          registrationNumber: updatedCompany.registrationNumber
        })
      );
    }

    if (crmContacts) {
      dispatch(
        updateRelatedCrmContacts(
          updatedCompany.id,
          EEntityType.COMPANY,
          updatedEntity
        )
      );
    }

    if (proposals) {
      dispatch(
        updateRelatedProposals(
          updatedCompany.id,
          EEntityType.COMPANY,
          updatedEntity
        )
      );
    }
  };
}

export function deleteCrmCompany(companyId: number) {
  return (dispatch, getState) => {
    const {
      crmCompanies: { crmCompanies },
      crmContacts: { crmContacts },
      crmVessels: { crmVessels },
      proposals: { proposals }
    }: TState = getState();

    if (crmCompanies) {
      dispatch({
        payload: {
          crmCompanies: crmCompanies.filter(({ id }) => id !== companyId)
        },
        type: ECrmCompaniesActions.DELETE_COMPANY
      });
    }

    if (proposals) {
      dispatch(deleteRelatedProposals(companyId, EEntityType.COMPANY));
    }

    if (crmContacts) {
      dispatch(updateRelatedCrmContacts(companyId, EEntityType.COMPANY));
    }

    if (crmVessels) {
      dispatch(updateCrmVesselsOwner(companyId));
    }
  };
}

/**/
export function clearCrmCompaniesAction() {
  return <TAction<TCrmCompaniesState, ECrmCompaniesActions>>{
    type: ECrmCompaniesActions.CLEAR_COMPANIES,
    payload: {
      crmCompanies: null
    }
  };
}
