import { EventHandler } from 'react';

/**/
class ContextMenuHandler {
  private readonly handler: EventHandler<any>;
  private timer = null;
  private timeout = 600;

  constructor(handler) {
    this.handler = handler;
  }

  public onTouchStart = (e) => {
    this.timer = setTimeout(() => this.handler(e), this.timeout);
  };

  public clearTimer = () => clearTimeout(this.timer);
}

/**/
export default ContextMenuHandler;
