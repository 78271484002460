import React from 'react';
import { createRoot } from 'react-dom/client';
import Modal from 'antd/lib/modal';

import './ModalPhoto.scss';
import { IconCustom } from 'components';

const closeIcon = <IconCustom title="Close preview" type="close" />;

/**
 * Renders a modal view of a picture with given `src`;
 */
export class ModalPhoto {
  private container: HTMLDivElement;
  private root;
  private readonly src: string;
  private static readonly testId = 'modal-photo';
  private static readonly altText = 'Illustration';

  /**/
  constructor(src: string) {
    if (!src || typeof src !== 'string') {
      throw new TypeError('ModalPhoto: `src` must be a string!');
    }

    this.destroy = this.destroy.bind(this);
    this.src = src;
    this.render();
  }

  /**/
  private render(): void {
    this.container = document.createElement('div');
    this.container.dataset.testid = ModalPhoto.testId;
    document.body.appendChild(this.container);
    document.documentElement.addEventListener('keyup', this.close, false);

    const modal = (
      <Modal
        centered={true}
        closeIcon={closeIcon}
        destroyOnClose={true}
        footer={null}
        onCancel={this.destroy}
        open={true}
        wrapClassName="ModalPhoto"
      >
        <img
          className="ModalPhoto__img"
          src={this.src}
          alt={ModalPhoto.altText}
        />
      </Modal>
    );

    this.root = createRoot(this.container);
    this.root.render(modal);
  }

  /**/
  private close(e) {
    e.keyCode === 27 && this.destroy();
  }

  /**/
  destroy(): void {
    this.root.unmount(this.container);
    document.documentElement.removeEventListener('keyup', this.close, false);
    this.container.parentNode?.removeChild(this.container);
  }
}
