import { Quill } from 'react-quill';
import './FileCard.scss';
import { TFileDescription } from 'types';

export const CARD_CLASS = 'FileCard';

export function getFileCardContent({
  id,
  name,
  src
}: TFileDescription): HTMLElement {
  const container = document.createElement('section');

  container.innerHTML = `
    <div class="${CARD_CLASS}" data-file-id="${id}">
      <img alt="File" class="FileCard__icon" src="/assets/icons/clip.svg" />
      <a 
        download="${name}"
        href="${src}"
        rel="noopener noreferrer nofollow"
        target="_blank"
        title="Click to download"
       >${name}</a>
    </div>
  `;

  return container;
}

/**
 * Creates and registers FileBlot as a custom Quill format.
 */
(function registerFileBlot(): void {
  const Embed = Quill.import('blots/embed');

  class FileBlot extends Embed {
    static blotName = 'file';
    static className = CARD_CLASS;
    static tagName = 'div';

    static create(value) {
      return value; // expects a domNode as return value
    }

    static value(domNode) {
      return domNode;
    }
  }

  Quill.register('formats/file', FileBlot);
})();
