export enum EPlayRate {
  NORMAL = 1,
  '2x' = 2,
  '4x' = 4,
  '8x' = 8
}

export enum EPlayMode {
  PAUSE = 'Pause',
  PLAY = 'Play',
  STOP = 'Stop'
}
