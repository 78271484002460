import React from 'react';
import message from 'antd/lib/message';
import { TPBIReportData } from './helpers';

type Props = {
  data: TPBIReportData;
  reportId: string;
};

/**/
/**/
class PbiReport extends React.PureComponent<Props> {
  private container: HTMLDivElement;

  /**/
  componentDidMount() {
    this.configReport();
  }

  /**/
  configReport(): void {
    const models = window['powerbi-client'].models;
    const { data, reportId } = this.props;

    const config = {
      accessToken: data.token,
      embedUrl: data.url,
      id: reportId,
      permissions: models.Permissions.All,
      tokenType: models.TokenType.Embed,
      type: 'report',

      settings: {
        background: models.BackgroundType.Transparent,
        panes: {
          filters: { visible: false },
          pageNavigation: { visible: true }
        }
      }
    };

    this.embedReport(config);
  }

  /**/
  embedReport(config): void {
    const report = window['powerbi'].embed(this.container, config);
    report.off('loaded');
    report.off('rendered');
    report.off('error');

    report.on('loaded', () => report.off('loaded'));
    report.on('rendered', () => report.off('rendered'));

    report.on('error', (event) => {
      message.error(event.detail);
      window.console.error(event.detail);
    });
  }

  /**/
  handleRef = (el) => el && (this.container = el);

  /**/
  render() {
    return <section className="PbiReportPage__main" ref={this.handleRef} />;
  }
}

/**/
export default PbiReport;
