import message from 'antd/lib/message';

import { ENDPOINTS } from 'other';
import { http } from 'services';

import { TMedia } from 'types';

export const addContactPhoto = async (
  contactId: number,
  photo: File
): Promise<TMedia> => {
  const formData = new FormData();
  formData.append('file', photo);
  formData.append('contactId', contactId.toString());

  try {
    const { data } = await http.send({
      body: formData,
      method: 'POST',
      url: `${ENDPOINTS.CRM_CONTACTS}/${contactId}/photo`
    });

    return data;
  } catch ({ message: errorMessage }) {
    message.error(errorMessage);
  }
};
