import React from 'react';
import classnames from 'classnames';

import './EditDeleteGroup.scss';
import { IconButton } from 'components';

/**/
type Props = {
  className?: string;
  onDelete: () => void;
  onEdit: () => void;
};

/**
 *
 */
export function EditDeleteGroup({ className, onDelete, onEdit }: Props) {
  const cls = classnames('EditDeleteGroup', className);

  return (
    <div className={cls}>
      <IconButton
        className="EditDeleteGroup__button"
        icon="pencil-create"
        onClick={onEdit}
        size="XS"
      />
      <IconButton
        className="EditDeleteGroup__button"
        icon="delete"
        onClick={onDelete}
        size="XS"
      />
    </div>
  );
}
