import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './AreaList.scss';
import AreaList from './AreaList';
import { IconCustom, LinkButton, LoaderOverlay } from 'components';
import {
  removeAreaAction,
  selectAreaAction,
  resetSelectedAction
} from 'store/areas/areasActions';
import { ROUTES } from 'other';
import { withAuth } from 'components/withAuth';

import { TArea, TUserInfo } from 'types';
import { TAreaState, TState } from 'store';

/**/
type OwnProps = {
  userInfo: TUserInfo;
};

type Props = {
  areas: TAreaState;
  userId: number;
  onRemove: (id: number) => void;
  onReset: () => void;
  onSelect: (id: number) => void;
};

/**
 *
 */
function AreaListWidget(props: Props) {
  const {
    areas: { areas, isPending, selectedAreas },
    userId,
    onRemove,
    onReset,
    onSelect
  } = props;

  const selected = (areas || []).filter(
    (a: TArea) => !a.public && selectedAreas.includes(a.id)
  );

  const isDisabled = !selectedAreas || selected.length === 0;

  return (
    <section className="AreaList">
      <header className="AreaList__header">
        <h6 className="LayerControl__title app-h6">Custom areas</h6>

        <div className="AreaList__controls">
          <LinkButton
            className="AreaList__reset"
            disabled={isDisabled}
            onClick={onReset}
          >
            <IconCustom type="rotate-ccw" />
            Reset
          </LinkButton>

          <Link to={ROUTES.AREA_EDITOR}>
            <LinkButton className="AreaList__add">
              <IconCustom type="plus" />
              Add
            </LinkButton>
          </Link>
        </div>
      </header>

      <LoaderOverlay
        className="AreaList__loader"
        isLoading={isPending}
        isTransparent={!!areas}
      >
        <AreaList
          areas={areas}
          userId={userId}
          onRemove={onRemove}
          onSelect={onSelect}
          selectedAreas={selectedAreas}
        />
      </LoaderOverlay>
    </section>
  );
}

/**
 *
 */
function AreaListConnected({ userInfo }: OwnProps) {
  const dispatch = useDispatch();
  const areas = useSelector((state: TState): TAreaState => state.areas);

  const handleRemove = (id: number) => dispatch(removeAreaAction(id));
  const handleSelect = (id: number) => dispatch(selectAreaAction(id));
  const handleReset = () => dispatch(resetSelectedAction());

  return (
    <AreaListWidget
      areas={areas}
      userId={userInfo.id}
      onRemove={handleRemove}
      onReset={handleReset}
      onSelect={handleSelect}
    />
  );
}

/**/
export default withAuth(AreaListConnected);
