import React, { FC } from 'react';
import PropTypes from 'prop-types';

import './CompanyCompanies.scss';
import { CompanyCardDescription, getCompanyImgSrc } from 'components';
import { PTCompanyOfGroup, TCompanyOfGroup } from 'types';

type Props = {
  companies: TCompanyOfGroup[];
};

const CompanyCompanies: FC<Props> = ({ companies }) => {
  if (!companies || companies.length === 0) return null;

  const companiesList = companies.map((company) => {
    const imgSrc = getCompanyImgSrc(company);
    return (
      <CompanyCardDescription
        imgSrc={imgSrc}
        item={company}
        key={company.companyId}
      />
    );
  });

  return (
    <div className="CompanyCompanies">
      <div className="CompanyCompanies__content">{companiesList}</div>
    </div>
  );
};

CompanyCompanies.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.exact(PTCompanyOfGroup)).isRequired
};

export { CompanyCompanies };
