import React, { FC } from 'react';
import PropTypes from 'prop-types';
import Button from 'antd/lib/button';

import './EditDeletePopover.scss';
import { LinkButton, withEllipsisMenu } from 'components';

/**/
type Props = {
  onDelete: () => void;
  onEdit: () => void;
};

const options = {
  buttonClass: 'EditDeletePopover__ellipsisButton'
};

/**
 *
 */
const EditDeletePopover: FC<Props> = ({ onEdit, onDelete }) => {
  const actions = (
    <div className="EditDeletePopover">
      <LinkButton className="EditDeletePopover__button" onClick={onEdit}>
        Edit
      </LinkButton>

      <div className="EditDeletePopover__divider" />

      <LinkButton className="EditDeletePopover__button" onClick={onDelete}>
        Delete
      </LinkButton>
    </div>
  );

  const Menu = withEllipsisMenu(actions, options);
  // @ts-ignore
  return <Menu />;
};

/**/
EditDeletePopover.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired
};

export { EditDeletePopover };
