import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import './ExpanderHeader.scss';
import { DefaultButton } from '../buttons/DefaultButton/DefaultButton';
import { IconButtonOutlined } from '../buttons/IconButtonOutlined/IconButtonOutlined';
import { IconCustom } from 'components/common/IconCustom/IconCustom';
import { isMobile } from '../../helpers/helpers';

/**/
type Props = {
  /** control button title. */
  buttonText?: string;
  /**  button onClick handler. */
  onAdd?: () => void;
  /** the element title. */
  title: string;
};

/**
 *
 */
const ExpanderHeader: React.FC<Props> = ({
  buttonText,
  onAdd,
  title
}): ReactElement => {
  const props = {
    className: 'ExpanderHeader__btn',
    size: 'M' as any,
    onClick: (e) => {
      e.stopPropagation();
      onAdd();
    }
  };

  const button = isMobile() ? (
    <IconButtonOutlined {...props} icon="plus" />
  ) : (
    <DefaultButton {...props}>
      <IconCustom type="plus" />
      {buttonText}
    </DefaultButton>
  );

  return (
    <header className="ExpanderHeader">
      <h3 className="ExpanderHeader__title app-h3">{title}</h3>
      {onAdd && button}
    </header>
  );
};

/**/
ExpanderHeader.propTypes = {
  buttonText: PropTypes.string,
  onAdd: PropTypes.func,
  title: PropTypes.string.isRequired
};

export { ExpanderHeader };
