import { TProvider, TServiceBranch } from 'types';

/**
 * Filters an array by applying filters from the given filter map.
 * @param arr - Array<I>
 * @param settings - filter settings of <S> type
 * @param filterMap
 */
export function applyFiltersByMap<I, S>(
  arr: I[],
  settings: S,
  filterMap: Record<string, Function>,
  dictionary?: Record<string, Array<{}>>
): I[] {
  if (!arr || arr.length === 0) return [];

  try {
    Object.keys(settings).forEach((key: string) => {
      let filterFunc = filterMap[key];
      if (!filterFunc || typeof filterFunc !== 'function') {
        filterFunc = () => () => true;
        // window.console.warn(`Wrong filter function for key '${key}'`);
      }

      arr = arr.filter(filterFunc(settings[key], dictionary));
    });
  } catch (e) {
    window.console.error("Error in 'applyFiltersByMap':\n", e);
    return arr;
  }

  return arr;
}

/**
 * Filters providers by given service categories.
 * @param categoryIds
 */
export const serviceCategoryFilter =
  (categoryIds: number[]): Function =>
  (item: TProvider): boolean => {
    if (!categoryIds) return false; // selected: 'None'
    if (!categoryIds.length) return true; // selected: 'All'
    if (!item.branches.length) return false;

    const branchIds: number[] = item.branches.map(
      ({ id }: TServiceBranch) => id
    );
    return branchIds.some((branchId: number) => categoryIds.includes(branchId));
  };
