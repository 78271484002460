import { TDD, TDDM, TDMS } from 'types';

export type TDDMShort = {
  deg: string;
  min: string;
};

export type TDMSShort = {
  deg: string;
  min: string;
  sec: string;
};

export type TDDMSingle = {
  deg: string;
  min: string;
  hem: 'S' | 'N' | 'E' | 'W';
};

export type TDMSSingle = {
  deg: string;
  min: string;
  sec: string;
  hem: 'S' | 'N' | 'E' | 'W';
};

/**
 * Coordinates converter.
 */
export class CC {
  private static readonly PRECISION = 1e6;
  private static readonly REVERSE_PRECISION = 1e8;

  // Covert DD to DDM
  public static DD2DDM({ lat, lng }: TDD): TDDM {
    return {
      lat: {
        ...CC.dd2ddm(lat),
        hem: lat.startsWith('-') ? 'S' : 'N'
      },
      lng: {
        ...CC.dd2ddm(lng),
        hem: lng.startsWith('-') ? 'W' : 'E'
      }
    };
  }

  private static dd2ddm(value: string): TDDMShort {
    return {
      deg: CC.getWhole(value),
      min:
        '' + (0 | ((CC.parseAbs(value) % 1) * 60 * CC.PRECISION)) / CC.PRECISION
    };
  }

  // Convert DD to DMS
  public static DD2DMS({ lat, lng }: TDD): TDMS {
    return {
      lat: {
        ...CC.dd2dms(lat),
        hem: lat.startsWith('-') ? 'S' : 'N'
      },
      lng: {
        ...CC.dd2dms(lng),
        hem: lng.startsWith('-') ? 'W' : 'E'
      }
    };
  }

  private static dd2dms(value: string): TDMSShort {
    return {
      deg: CC.getWhole(value),
      min: '' + parseInt('' + (CC.parseAbs(value) % 1) * 60),
      sec:
        '' +
        (0 | (((CC.parseAbs(value) * 60) % 1) * 60 * CC.PRECISION)) /
          CC.PRECISION
    };
  }

  // Convert DDM to DD
  public static DDM2DD(value: TDDM): TDD {
    return {
      lat: CC.ddm2dd(value.lat),
      lng: CC.ddm2dd(value.lng)
    };
  }

  private static ddm2dd({ deg, min, hem }: TDDMSingle): string {
    const x = parseInt(deg) + parseFloat(min) / 60;
    // @ts-ignore
    const res = parseInt(x * CC.REVERSE_PRECISION) / CC.REVERSE_PRECISION;

    return hem === 'S' || hem === 'W' ? '-' + res : '' + res;
  }

  // Convert DMS to DD
  public static DMS2DD(value: TDMS): TDD {
    return {
      lat: CC.dms2dd(value.lat),
      lng: CC.dms2dd(value.lng)
    };
  }

  private static dms2dd({ deg, min, sec, hem }: TDMSSingle): string {
    const x = parseInt(deg) + parseFloat(min) / 60 + parseFloat(sec) / 3600;
    // @ts-ignore
    const res = parseInt(x * CC.REVERSE_PRECISION) / CC.REVERSE_PRECISION;
    return hem === 'S' || hem === 'W' ? '-' + res : '' + res;
  }

  // Convert DMS to DDM
  public static DMS2DDM(value: TDMS): TDDM {
    return CC.DD2DDM(CC.DMS2DD(value));
  }

  // Convert DDM to DMS
  public static DDM2DMS(value: TDMS): TDDM {
    return CC.DD2DMS(CC.DDM2DD(value));
  }

  // Helpers
  private static parseAbs(value: string): number {
    return Math.abs(parseFloat(value));
  }

  private static getWhole(value: string): string {
    const whole = value.split('.')[0];
    return whole.startsWith('-') ? whole.slice(1) : whole;
  }
}
