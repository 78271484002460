import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'antd/lib/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import Form, { FormInstance } from 'antd/lib/form';
import Select from 'antd/lib/select';

import './PromotionForm.scss';
import {
  datePickerProps,
  endRules,
  MultiSelect,
  MultiSelectNone,
  PROMOTION_FORM,
  PromotionFormProps,
  startRules
} from 'components';
import { formFields2Dict } from 'other';
import { timezones } from 'pages/events/EventEditor/forms/timezones';
import { today } from 'store';

class PromotionForm extends React.PureComponent<PromotionFormProps> {
  static propTypes;
  private form: FormInstance;

  componentDidMount(): void {
    const { fields, onChange } = this.props;
    onChange(fields);
  }

  handleChange = (_, fields /*: TFormField[]*/) =>
    this.props.onChange(formFields2Dict(fields));

  handleRef = (form) => {
    if (form) {
      this.form = form;
    }
  };

  handleSubmit = () => this.form.validateFields().then(this.props.onSubmit);

  getDisabledStartRange = (date: Dayjs): boolean => {
    const endValue = this.form.getFieldValue('end');
    if (!endValue) return false;

    const dayBeforeEnd = endValue.clone().subtract(1, 'day');
    return (
      date.isBefore(today, 'day') ||
      date.isAfter(dayjs().add(1, 'year')) ||
      date.isAfter(dayBeforeEnd, 'day')
    );
  };

  getDisabledEndRange = (date: Dayjs): boolean => {
    const startValue = this.form.getFieldValue('start');
    if (!startValue) return false;

    const nextDayAfterStart = startValue.clone().add(1, 'day');
    return (
      date.isBefore(nextDayAfterStart, 'day') ||
      date.isAfter(nextDayAfterStart.add(1, 'year'))
    );
  };

  render() {
    const { children, countries, fields, serviceOptions, vesselTypesOptions } =
      this.props;

    if (!fields) return null;

    const fieldsArr = Object.values(fields) as any;

    return (
      <Form
        className="PromotionForm"
        fields={fieldsArr}
        id={PROMOTION_FORM}
        onFieldsChange={this.handleChange}
        onFinish={this.handleSubmit}
        ref={this.handleRef}
      >
        <div className="PromotionForm__main">
          <div className="PromotionForm__left">
            <h3 className="PromotionForm__title app-h3">Audience</h3>

            <div className="BasicFilter__row">
              <label className="BasicFilter__label">Countries</label>
              <Form.Item name="countries">
                {/* @ts-ignore*/}
                <MultiSelect<string>
                  allOptionLabel="All countries"
                  options={countries}
                />
              </Form.Item>
            </div>

            <div className="BasicFilter__row">
              <label className="BasicFilter__label">Vessel Types</label>
              <Form.Item name="vesselTypes">
                {/* @ts-ignore*/}
                <MultiSelectNone<number>
                  allOptionLabel="All types"
                  options={vesselTypesOptions}
                />
              </Form.Item>
            </div>

            <div className="BasicFilter__row">
              <label className="BasicFilter__label">Service Categories</label>
              <Form.Item name="serviceCategories">
                {/* @ts-ignore*/}
                <MultiSelectNone<number>
                  allOptionLabel="All categories"
                  options={serviceOptions}
                />
              </Form.Item>
            </div>
          </div>

          <div className="PromotionForm__right">
            <h3 className="PromotionForm__title app-h3">Duration</h3>

            <div className="PromotionForm__main">
              <div className="PromotionForm__subLeft">
                <div className="BasicFilter__row">
                  <label className="BasicFilter__label">Start</label>
                  <Form.Item name="start" rules={startRules}>
                    <DatePicker
                      disabledDate={this.getDisabledStartRange}
                      {...datePickerProps}
                    />
                  </Form.Item>
                </div>

                <div className="BasicFilter__row">
                  <label className="BasicFilter__label">End</label>
                  <Form.Item name="end" rules={endRules}>
                    <DatePicker
                      disabledDate={this.getDisabledEndRange}
                      {...datePickerProps}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="PromotionForm__subRight">
                <div className="BasicFilter__row">
                  <label className="BasicFilter__label">GMT shift</label>
                  <Form.Item name="utcOffset">
                    <Select options={timezones} />
                  </Form.Item>
                </div>
              </div>

              {children}
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

PromotionForm.propTypes = {
  fields: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  serviceOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  vesselTypesOptions: PropTypes.arrayOf(PropTypes.object).isRequired
};

export { PromotionForm };
