import React, { ComponentType, ReactElement, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'antd/lib/modal';

import './WithFleets.scss';
import {
  createFleetAction,
  toggleVesselAction
} from 'store/fleets/fleetsActions';
import { FleetsMenu, LoaderOverlay } from 'components';
import { getDisplayName } from 'components/helpers/helpers';

import { PTFleet, TFleet, TFleetItems } from 'types';
import { TState } from 'store';

type Props = {
  fleets: TFleet[];
  isLoading: boolean;
  onCreate: (fleetName: string) => void;
  onToggle: (candidates: TFleetItems, fleetId: number) => void;
};

export type TInjectedWithFleetsProps = Props & {
  favCompanyIds: number[];
  favFarmIds: number[];
  favVesselIds: number[];
  onFavClick: (items: Partial<TFleetItems>) => void;
};

type State = { candidates: TFleetItems };

const modalProps = {
  closable: false,
  destroyOnClose: true,
  footer: null,
  icon: null,
  maskClosable: true,
  title: null
};

const mapStateToProps = ({ fleets }: TState) => ({
  fleets: fleets.fleets,
  isLoading: fleets.isPending
});

const mapDispatchToProps = {
  onCreate: createFleetAction,
  onToggle: toggleVesselAction
};

/**
 * The fleet management modal menu. Manages the fleets, associated to the given vessels.
 * Also exposes create fleet functionality.
 * Exposes two extra properties to a wrapped component:
 * `favFarmIds: number[]` - a list of farm IDs that are associated with any user's fleets;
 * `favVesselIds: number[]` - a list of vessel IDs that are associated with any user's fleets;
 * `onFavClick(items: TFleetItems): void` - a callback to show the fleets management menu.
 * @param WrappedComponent
 */
function withFleets<P extends TInjectedWithFleetsProps>(
  WrappedComponent: ComponentType<P>
): ComponentType<Omit<P, keyof TInjectedWithFleetsProps>> {
  class HOC extends Component<Props, State> {
    static displayName = `withFleets(${getDisplayName(WrappedComponent)})`;
    static propTypes = withFleets.propTypes;
    state: State = { candidates: null };

    handleCancel = (): void => this.setState({ candidates: null });

    showMenu = (candidates: TFleetItems) => {
      if (!candidates) {
        throw TypeError('withFleets: vessels are not defined');
      }
      const items = {
        farms: [],
        vessels: [],
        ...candidates
      };
      this.setState({ candidates: items });
    };

    // Returns ids of all companies associated with any user's fleets.
    extractCompanies(): number[] {
      return this.props.fleets.flatMap(
        ({ companyIds }: TFleet): number[] => companyIds
      );
    }

    // Returns ids of all farms associated with any user's fleets.
    extractFarms(): number[] {
      return this.props.fleets.flatMap(
        ({ fishFarmIds }: TFleet): number[] => fishFarmIds
      );
    }

    // Returns ids of all vessels associated with any user's fleets.
    extractVessels(): number[] {
      return this.props.fleets.flatMap(
        ({ vesselIds }: TFleet): number[] => vesselIds
      );
    }

    render(): ReactElement {
      const { fleets, isLoading, onCreate, onToggle } = this.props;
      const { candidates } = this.state;

      const componentProps = {
        ...(this.props as P),
        favCompanyIds: this.extractCompanies(),
        favFarmIds: this.extractFarms(),
        favVesselIds: this.extractVessels(),
        onFavClick: this.showMenu
      };

      return (
        <>
          <WrappedComponent {...componentProps} />

          <Modal
            open={!!candidates}
            {...modalProps}
            className="modal-small"
            onCancel={this.handleCancel}
          >
            <LoaderOverlay
              className="WithFleets__loader"
              isLoading={isLoading}
              isTransparent={true}
            >
              <FleetsMenu
                candidates={candidates}
                fleets={fleets}
                onCancel={this.handleCancel}
                onCreate={onCreate}
                onToggle={onToggle}
              />
            </LoaderOverlay>
          </Modal>
        </>
      );
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(HOC as any);
}

withFleets.propTypes = {
  fleets: PropTypes.arrayOf(PropTypes.exact(PTFleet)).isRequired,
  isLoading: PropTypes.bool,
  onCreate: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired
};

export default withFleets;
export { withFleets };
