import React, { ComponentType } from 'react';
import { connect } from 'react-redux';

import { getDisplayName } from './helpers/helpers';
import { storeRedirectPathAction } from 'store/session/sessionActions';

import { EUserAuthority, TUserInfo } from 'types';
import { TState } from 'store';

/**/
export type TInjectedWithAuthProps = {
  canViewCompany: boolean;
  isAdmin: boolean;
  isDev: boolean;
  isLoggedIn: boolean;
  isNewsIssuer: boolean;
  isConnectionController: boolean;
  isProvider: boolean;
  rights: ReadonlyArray<EUserAuthority>;
  userInfo: TUserInfo;
};

/**
 *
 * @param WrappedComponent
 */
function withAuth<P extends TInjectedWithAuthProps>(
  WrappedComponent: ComponentType<P>
): ComponentType<Omit<P, keyof TInjectedWithAuthProps>> {
  function HOC(props: Omit<P, keyof TInjectedWithAuthProps>) {
    return <WrappedComponent {...(props as P)} />;
  }

  HOC.displayName = `withAuth(${getDisplayName(WrappedComponent)})`;
  return connect(mapStateToProps, mapDispatchToProps)(HOC);
}

/**
 *
 */
const mapStateToProps = (state: TState, ownProps) => {
  const {
    session: { user }
  } = state;
  const userInfo = user ? user.userInfo : {};
  // @ts-ignore
  const { authorities } = userInfo;
  const rights = authorities || [];

  return {
    ...ownProps,
    canViewCompany: rights.includes(EUserAuthority.VIEW_OWNER),
    isAdmin: rights.includes(EUserAuthority.ADMIN),
    isDev: rights.includes(EUserAuthority.DEVELOPER),
    isLoggedIn: !!(user && user.token),
    isNewsIssuer: rights.includes(EUserAuthority.CREATE_NEWS),
    isConnectionController: rights.includes(EUserAuthority.POOR_CONNECTION),
    isProvider: rights.includes(EUserAuthority.SERVICE_PROVIDER),
    rights,
    userInfo
  };
};

/**/
const mapDispatchToProps = (dispatch) => ({
  redirectTo: () => dispatch(storeRedirectPathAction())
});

export { withAuth };
