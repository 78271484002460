import { TMiscState } from 'store';

export const miscInitialState: TMiscState = {
  theme: {
    isDark: false,
    settings: {} as any
  },
  prevLocation: {
    hash: '',
    key: '',
    pathname: '',
    search: ''
  },
  // scrollTop or rowIndex, etc.
  scrollValue: null
};
