import React from 'react';
import { connect } from 'react-redux';

import './TrialLink.scss';
import { IconCustom } from '../IconCustom/IconCustom';
import { isCordova, ROUTES } from 'other';
import { PrimaryButton } from '../buttons/PrimaryButton/PrimaryButton';
import { showTrialModalAction } from 'store/trialModal/trialModalActions';
import { TState } from 'store';

/**/
type Props = {
  isLoggedIn: boolean;
  isPrimary?: boolean;
  showTrialModal: () => void;
};

/**/
const label = (
  <span>
    <span>Try Free Trial</span>&nbsp;&nbsp;
    <IconCustom className="TrialLink__icon" type="external-link" />
  </span>
);

/**
 *
 */
function Component({ isLoggedIn, isPrimary, showTrialModal }: Props) {
  if (isLoggedIn || isCordova()) return null;

  const text = 'Try free trial';

  if (isPrimary)
    return (
      <PrimaryButton
        className="TrialLink"
        size="S"
        title={text}
        onClick={showTrialModal}
      >
        {label}
      </PrimaryButton>
    );

  return (
    <PrimaryButton
      className="TrialLink"
      size="XL"
      title={text}
      onClick={showTrialModal}
    >
      {label}
    </PrimaryButton>
  );
}

/**
 *
 */
const mapDispatchToProps = {
  showTrialModal: showTrialModalAction
};

const mapStateToProps = ({ session: { user } }: TState) => ({
  isLoggedIn: !!(user && user.token)
});

export const TrialLink = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
