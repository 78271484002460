import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';

import './ContactEditor.scss';
import { AvatarUploader, ContactForm } from 'components';
import { ContactsContext } from '../../ContactsContext';
import { filterEmptyFormData } from '../../helpers/helpers';
import { MEDIA_BASE_URL, PLACEHOLDER } from 'other';

import { TCrmContact } from 'types';

type Props = {
  editableContact: TCrmContact;
  hideModal: () => void;
  onFormSubmit: (formData: Partial<TCrmContact>, photo: File) => void;
  onEditorClose: () => void;
};

type State = {
  photo: File;
};

class ContactEditor extends React.PureComponent<Props, State> {
  static propTypes;
  static contextType = ContactsContext;

  state: State = {
    photo: null
  };

  componentWillUnmount(): void {
    this.props.onEditorClose();
  }

  handleFileLoad = (file: File): void => this.setState({ photo: file });

  handleFormSubmit = ({
    firstName,
    lastName,
    customFields
  }: Partial<TCrmContact>): void => {
    const { onFormSubmit, hideModal } = this.props;
    const filteredFormData = {
      customFields: filterEmptyFormData(customFields),
      firstName,
      lastName
    };

    onFormSubmit(filteredFormData, this.state.photo);
    hideModal();
  };

  render(): ReactElement {
    const { editableContact, hideModal } = this.props;
    const { parentCompanyName } = this.context as any;

    const defaultImageUrl = editableContact?.photo?.path
      ? `${MEDIA_BASE_URL}/${editableContact.photo.path}`
      : PLACEHOLDER.CONTACT;

    const initialValues: Partial<TCrmContact> = {
      ...editableContact,
      company: parentCompanyName,
      customFields: {
        emails: [''],
        hobby: [''],
        notes: [''],
        phones: [''],
        socials: [''],
        ...editableContact?.customFields
      }
    };

    return (
      <Row className="ContactEditor">
        <Col>
          <AvatarUploader
            defaultImageUrl={defaultImageUrl}
            onFileLoad={this.handleFileLoad}
          />
        </Col>
        <Col flex="auto">
          <ContactForm
            initialValues={initialValues}
            onCancel={hideModal}
            onFormSubmit={this.handleFormSubmit}
          />
        </Col>
      </Row>
    );
  }
}

ContactEditor.propTypes = {
  editableContact: PropTypes.object,
  hideModal: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired
};

export { ContactEditor };
