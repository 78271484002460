import React from 'react';

import './Placeholder.scss';
import { withConnection } from '../withConnection';

type ImgProps = {
  alt?: string;
  className?: string;
  draggable?: boolean;
  src?: string;
};

type OwnProps = ImgProps & {
  placeholder: string;
};

type Props = OwnProps & {
  isConnectionPoor: boolean;
};

/**/
function Image(props: ImgProps) {
  const { alt, className, draggable, src } = props;

  const _props = {
    alt: alt || '',
    ...(className && { className: className }),
    ...(draggable && { draggable: draggable }),
    src: src
  };

  return <img {..._props} />;
}

/**/
function Component(props: Props) {
  const { isConnectionPoor, placeholder, src, ...rest } = props;

  if (isConnectionPoor) {
    return (
      <div className="Placeholder">
        {src && (
          <span className="Placeholder__msg">
            Image hidden due to poor connection
          </span>
        )}

        <Image {...rest} src={placeholder} />
      </div>
    );
  }

  return <Image {...rest} src={src || placeholder} />;
}

/**/
const Placeholder = withConnection(Component);
export { Placeholder, Component as PlaceholderCore };
