import { createAsyncActions, TMapOptionsState } from '../../index';

/**/
export enum EMapOptionsActions {
  FETCH_LAYER_INFO = 'mapOptions/FETCH_LAYER_INFO',
  MAP_SET_OPTIONS = 'mapOptions/MAP_SET_OPTIONS'
}

/**/
export const fetchInfoSet = createAsyncActions<TMapOptionsState>(
  EMapOptionsActions.FETCH_LAYER_INFO
);
