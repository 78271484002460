import React, { ComponentType } from 'react';
import PropTypes from 'prop-types';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';

import './VirtualListSized.scss';
import { EmptyList, TInjectedWithFleetsProps, VirtualList } from 'components';

import { TId } from 'types';

type Props<T extends TId> = TInjectedWithFleetsProps & {
  component: ComponentType<T>;
  items: T[];
  placeholderImgUrl: string;
  scrollToRow: number;
  onItemClick: (rowIndex: number) => void;
};

/**/
class VirtualListSized<T extends TId> extends React.Component<Props<T>> {
  static propTypes;

  static renderEmpty(placeholderImgUrl: string) {
    return (
      <EmptyList
        placeholder="No items match current filter settings."
        placeholderImgUrl={placeholderImgUrl}
      />
    );
  }

  /**/
  listRenderer = ({ height, width }) => (
    <VirtualList height={height} width={width} {...this.props} />
  );

  /**/
  renderList() {
    return (
      <div className="VirtualListSized">
        <div className="VirtualListSized__inner">
          <AutoSizer>{this.listRenderer}</AutoSizer>
        </div>
      </div>
    );
  }

  /**/
  render() {
    const { items, placeholderImgUrl } = this.props;
    return !items || !items.length
      ? VirtualListSized.renderEmpty(placeholderImgUrl)
      : this.renderList();
  }
}

/**/
VirtualListSized.propTypes = {
  component: PropTypes.elementType.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired
};

export { VirtualListSized };
