import React, { FC } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './GAStatistic.scss';
import { PTArticleAnalytic, TArticleAnalytic } from 'types';

type Props = {
  data: TArticleAnalytic;
  smallView?: boolean;
};

const GAStatistic: FC<Props> = ({
  data: { impressions, shares, uniqueViews, views },
  smallView
}) => {
  const cls = classnames('GAStatistic', {
    'GAStatistic--small': smallView
  });

  const transformedData = [
    { number: impressions, text: 'Impressions' },
    { number: views, text: 'Views' },
    { number: uniqueViews, text: 'Unique' },
    ...(!smallView ? [{ number: shares, text: 'Facebook shares' }] : [])
  ];

  const items = transformedData.map((item) => {
    return (
      <li className="GAStatistic__item" key={item.text}>
        <strong className="GAStatistic__title">{item.number || 0}</strong>
        <span className="GAStatistic__text">{item.text}</span>
      </li>
    );
  });

  return <ul className={cls}>{items}</ul>;
};

GAStatistic.propTypes = {
  data: PropTypes.exact(PTArticleAnalytic).isRequired,
  smallView: PropTypes.bool
};

export { GAStatistic };
