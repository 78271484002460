import { createAsyncActions, TCrmCompaniesState } from 'store';

export enum ECrmCompaniesActions {
  FETCH_COMPANIES = 'crmCompanies/FETCH_COMPANIES',
  CLEAR_COMPANIES = 'crmCompanies/CLEAR_COMPANIES',
  EDIT_COMPANY = 'crmCompanies/EDIT_COMPANY',
  DELETE_COMPANY = 'crmCompanies/DELETE_COMPANY',
  ADD_COMPANY = 'crmCompanies/ADD_COMPANY'
}

export const fetchSet = createAsyncActions<TCrmCompaniesState>(
  ECrmCompaniesActions.FETCH_COMPANIES
);
