import React from 'react';
import PropTypes from 'prop-types';

import './ArticleLogo.scss';
import { BrandLogo } from 'components';
import { ROUTES } from 'other';
import { TArticleSource } from 'types';

type Props = {
  source: TArticleSource;
};

/**/
const ArticleLogo = ({ source }: Props) => {
  if (!source.logo) {
    return <strong className="ArticleLogo__fallback">{source.name}</strong>;
  }

  const url = `${ROUTES.SERVICE_PROVIDER}/${source.id}`;
  return <BrandLogo className="ArticleLogo" logo={source.logo} url={url} />;
};

/**/
ArticleLogo.propTypes = {
  // @ts-ignore
  source: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    logo: PropTypes.shape({
      path: PropTypes.string.isRequired
    })
  })
};

export { ArticleLogo };
