import React, { FC } from 'react';
import Tabs from 'antd/lib/tabs';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import './CRM.scss';
import {
  Contacts,
  ContactsContextProvider,
  Notes,
  Proposals,
  ProposalsContextProvider
} from 'components';

import { ECrmComponentTabs } from './constants/constants';
import {
  EEntityType,
  EProposalStatus,
  TCrmCompany,
  TCrmCompanyAF,
  TCrmFarm,
  TCrmVessel
} from 'types';

const defaultTab = ECrmComponentTabs.CONTACTS;

type Props = {
  entity: TCrmCompany | TCrmCompanyAF | TCrmFarm | TCrmVessel;
  entityType?: EEntityType;
};

/**/
function getTab(text: string, key: ECrmComponentTabs, Component) {
  return {
    key: key,
    label: <div className="app-overline-1">{text}</div>,
    children: Component
  };
}

const Crm: FC<Props> = ({ entity, entityType }) => {
  const {
    push,
    location: { pathname }
  } = useHistory();
  const { search } = useLocation();
  const { tab }: any = useParams();

  const handleChange = (key: string) => {
    const [baseUrl] = pathname.split(`/${tab}`);
    push(`${baseUrl}/${key}${search}`);
  };

  const items = [
    getTab('Contacts', ECrmComponentTabs.CONTACTS, <Contacts />),
    getTab(
      'Lead',
      ECrmComponentTabs.LEAD,
      <Proposals status={EProposalStatus.LEAD} />
    ),
    getTab(
      'Meeting booked',
      ECrmComponentTabs.MEETING_BOOKED,
      <Proposals status={EProposalStatus.MEETING_BOOKED} />
    ),
    getTab(
      'Meeting held',
      ECrmComponentTabs.MEETING_HELD,
      <Proposals status={EProposalStatus.MEETING_HELD} />
    ),
    getTab(
      'Proposal',
      ECrmComponentTabs.ONGOING,
      <Proposals status={EProposalStatus.ONGOING} />
    ),
    getTab(
      'Deal',
      ECrmComponentTabs.DEAL,
      <Proposals status={EProposalStatus.DEAL} />
    ),
    getTab(
      'Invoiced',
      ECrmComponentTabs.INVOICED,
      <Proposals status={EProposalStatus.INVOICED} />
    ),
    getTab(
      'History',
      ECrmComponentTabs.HISTORY,
      <Proposals status={EProposalStatus.HISTORY} />
    ),
    getTab(
      'Notes',
      ECrmComponentTabs.NOTES,
      <Notes entityId={entity.id} entityType={entityType} />
    )
  ];

  return (
    <div className="CRM">
      <ProposalsContextProvider entity={entity} entityType={entityType}>
        <ContactsContextProvider entity={entity} entityType={entityType}>
          <Tabs
            activeKey={tab || defaultTab}
            items={items}
            type="card"
            onChange={handleChange}
          />
        </ContactsContextProvider>
      </ProposalsContextProvider>
    </div>
  );
};

Crm.defaultProps = {
  entityType: EEntityType.VESSEL
};

export { Crm as CRM };
