import React, { Component, createContext, ReactElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { addContactPhoto } from 'components';
import {
  addCrmContact,
  deleteCrmContact,
  editCrmContact
} from 'store/CRM/CrmContacts/CrmContactsActions';
import { addEntity, deleteEntity, editEntity, getEntity } from '../helpers';
import { ENDPOINTS, updateArrayItem } from 'other';

import {
  EEntityType,
  TCrmCompany,
  TCrmCompanyAF,
  TCrmContact,
  TCrmFarm,
  TCrmVessel
} from 'types';

type State = { contacts: TCrmContact[]; isLoading: boolean };

export const ContactsContext = createContext<State | undefined>(undefined);

// TODO: need to unite TCrmCompanyAF and TCrmCompany
type Props = {
  addContact: (newContact: TCrmContact) => void;
  children: React.ReactNode;
  editContact: (editedContact: TCrmContact) => void;
  deleteContact: (contactId: number) => void;
  entity: TCrmFarm | TCrmVessel | TCrmCompany | TCrmCompanyAF;
  entityType: EEntityType;
};

export class ContextProvider extends Component<Props, State> {
  static propTypes;

  state: State = {
    contacts: [],
    isLoading: false
  };

  async componentDidMount(): Promise<void> {
    const { entity, entityType } = this.props;

    this.setState({ isLoading: true });

    try {
      const contacts = await getEntity<TCrmContact[]>(
        `${ENDPOINTS.CRM_CONTACTS}?entityId=${entity.id}&entityType=${entityType}`
      );

      this.setState({
        contacts: contacts.reverse(),
        isLoading: false
      });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  }

  addContact = async (contact: TCrmContact, photo: File): Promise<void> => {
    const { entity, entityType, addContact } = this.props;
    this.setState({ isLoading: true });

    try {
      const newContact = await addEntity<TCrmContact>(
        `${ENDPOINTS.CRM_CONTACTS}?entityId=${entity.id}&entityType=${entityType}`,
        {
          ...contact,
          entityLinks: this.getEntityLinks()
        }
      );

      if (photo) {
        newContact.photo = await addContactPhoto(newContact.id, photo);
      }

      addContact({ ...newContact, key: newContact.id.toString() });

      return this.setState({
        contacts: [newContact, ...this.state.contacts],
        isLoading: false
      });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  editContact = async (contact: TCrmContact, photo: File): Promise<void> => {
    const { editContact } = this.props;
    this.setState({ isLoading: true });

    try {
      const editedContact = await editEntity<TCrmContact>(
        `${ENDPOINTS.CRM_CONTACTS}/${contact.id}`,
        contact
      );

      if (photo) {
        editedContact.photo = await addContactPhoto(editedContact.id, photo);
      }

      editContact(editedContact);

      return this.setState({
        contacts: updateArrayItem(editedContact, this.state.contacts),
        isLoading: false
      });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  deleteContact = async (contactId: number): Promise<void> => {
    const { deleteContact } = this.props;
    this.setState({ isLoading: true });

    try {
      await deleteEntity(`${ENDPOINTS.CRM_CONTACTS}/${contactId}`);
      deleteContact(contactId);

      const contacts = this.state.contacts.filter(({ id }) => id !== contactId);

      this.setState({ contacts, isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  getEntityLinks(): Array<{ entityId: number; entityType: EEntityType }> {
    const { entity, entityType } = this.props;

    const entityLinks = [
      {
        entityId: entity.id,
        entityType
      }
    ];

    if ((entity as TCrmFarm).owners) {
      entityLinks.push({
        entityId: (entity as TCrmFarm).owners[0]?.id,
        entityType: EEntityType.COMPANY
      });
    } else if ((entity as TCrmVessel).owner) {
      entityLinks.push({
        entityId: (entity as TCrmVessel).owner.id,
        entityType: EEntityType.COMPANY
      });
    }

    return entityLinks;
  }

  render(): ReactElement {
    const { entity, entityType } = this.props;
    const { contacts, isLoading } = this.state;
    const parentCompanyName =
      entityType === EEntityType.VESSEL
        ? (entity as TCrmVessel)?.owner?.name
        : entity.name;

    const value = {
      addContact: this.addContact,
      contacts,
      deleteContact: this.deleteContact,
      editContact: this.editContact,
      isLoading,
      parentCompanyName
    };

    return (
      <ContactsContext.Provider value={value}>
        {this.props.children}
      </ContactsContext.Provider>
    );
  }
}

ContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  entity: PropTypes.object.isRequired,
  entityType: PropTypes.oneOf(Object.values(EEntityType)).isRequired
};

const mapDispatchToProps = {
  addContact: addCrmContact,
  deleteContact: deleteCrmContact,
  editContact: editCrmContact
};
export const ContactsContextProvider = connect(
  null,
  mapDispatchToProps
)(ContextProvider);
