import { CombinedState, combineReducers } from 'redux';
import { connectRouter, LocationChangeAction } from 'connected-react-router';
import { History } from 'history';

import { namespaces2reducers } from './_utils/reducerCreator';
import { rootReducer } from './root/rootReducer';
import { TState } from 'store';

const namespaces = [
  // General section
  'companies',
  'dictionaries',
  'filters',
  'login',
  'notifications',
  'session',
  'search',
  'misc',
  // Vessels section
  'fleets',
  'player',
  'vessel',
  'vessels',
  'vesselsComparator',
  // News
  'news',
  'newsRecent',
  // Provider
  'product',
  'provider',
  'providerEditor',
  'providers',
  // Events
  'events',
  'eventEditor',
  // Map
  'mapEntities',
  'mapOptions',
  'tracks',
  'farmsLocations',
  'vesselsLocations',
  // Promotion & Campaign
  'campaign',
  'promo',

  // vessel (or client) related
  // 'contactList',
  // 'vessel',

  // CRM
  'crmCompanies',
  'crmContacts',
  'crmVessels',
  'proposals',

  // TrialModal,
  'trialModal',
  // Custom areas
  'areas',
  // Farms section
  'farms'
];

const createRootReducer =
  (history: History) =>
  (state: TState, action: LocationChangeAction): CombinedState<unknown> => {
    const intermediateState = rootReducer(state, action);

    return combineReducers({
      router: connectRouter(history),
      ...namespaces2reducers(namespaces)
    })(intermediateState, action);
  };

export default createRootReducer;
