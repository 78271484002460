import React from 'react';
import classnames from 'classnames';

import { CommonBannerCore, IconCustom } from 'components';
import { PlaceholderCore } from '../../banners/Placeholder';
import { TPhotoItem } from 'types';

/**/
export function renderItem(
  { description, fullscreen }: TPhotoItem,
  placeholder: string
) {
  return (
    <>
      <CommonBannerCore
        className="image-gallery-image"
        isConnectionPoor={true}
        placeholder={placeholder}
        src={fullscreen}
      />

      {description && (
        <span className="image-gallery-description">{description}</span>
      )}
    </>
  );
}

/**/
export function renderThumbInner(
  { thumbnail }: TPhotoItem,
  placeholder: string
) {
  return (
    <span className="image-gallery-thumbnail-inner">
      <PlaceholderCore
        className="image-gallery-thumbnail-image"
        isConnectionPoor={true}
        placeholder={placeholder}
        src={thumbnail}
      />
    </span>
  );
}

/**/
export function renderFullscreenButton(
  onClick: () => void,
  isFullscreen: boolean
) {
  const iconType = isFullscreen ? 'close' : 'maximize';
  const ariaLabel = isFullscreen ? 'Close Fullscreen' : 'Open Fullscreen';

  const buttonClassName = classnames(
    'image-gallery-icon image-gallery-fullscreen-button VesselPhotos__fullscreenButton',
    {
      'VesselPhotos__fullscreenButton--active': isFullscreen
    }
  );

  return (
    <button
      aria-label={ariaLabel}
      className={buttonClassName}
      onClick={onClick}
    >
      <IconCustom type={iconType} />
    </button>
  );
}
