import React, { FC } from 'react';
import Tabs from 'antd/lib/tabs';
import { useHistory, useParams, useLocation } from 'react-router';

import './CompanyTabs.scss';
import {
  CompanyCardDescription,
  CompanyCompanies,
  CompanyFarms,
  CompanyVessels
} from 'components';
import { MEDIA_BASE_URL, PLACEHOLDER, queryString, ROUTES } from 'other';

import { ECompanyTabs } from '../constants';
import { TCompanyAF, TCompanyOfGroup } from 'types';

const defaultTab = ECompanyTabs.VESSELS;
const SUBTAB_SEARCH_KEY = 'sub_tab';

/**/
function getTab(key: ECompanyTabs, Component) {
  return {
    key: key,
    label: key,
    children: Component
  };
}

/**/
function getGroupTab(company: TCompanyAF) {
  if (!company.group) return null;

  const imgSrc =
    company.group.photos && company.group.photos[0]
      ? `${MEDIA_BASE_URL}/${company.group.photos[0].thumbnail.path}`
      : PLACEHOLDER.GROUP;

  return getTab(
    ECompanyTabs.GROUP,
    <CompanyCardDescription imgSrc={imgSrc} item={company.group} />
  );
}

type Props = { company: TCompanyAF };

/**/
const CompanyTabs: FC<Props> = ({ company }) => {
  const { push } = useHistory();
  const { search } = useLocation();
  const { id }: any = useParams();
  const subTab = queryString.parse(search)[SUBTAB_SEARCH_KEY] as string;

  const handleChange = (value: ECompanyTabs) =>
    push(`${ROUTES.COMPANY}/${id}?${SUBTAB_SEARCH_KEY}=${value}`);

  if (!company) return null;

  const items = [
    company.isGroup &&
      getTab(
        ECompanyTabs.COMPANIES,
        <CompanyCompanies companies={company.companies} />
      ),
    company.vessels &&
      getTab(
        ECompanyTabs.VESSELS,
        <CompanyVessels vessels={company.vessels} />
      ),
    company.fishFarms &&
      getTab(
        ECompanyTabs.FISHFARMS,
        <CompanyFarms farms={company.fishFarms} />
      ),
    getGroupTab(company)
  ].filter(Boolean);

  return (
    <Tabs
      className="CompanyTabs"
      defaultActiveKey={subTab || defaultTab}
      items={items}
      size="large"
      onChange={handleChange}
      type="card"
    />
  );
};

/**/
export { CompanyTabs };
