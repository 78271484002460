import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, LinkButton } from 'components';
import { TMouseHandler } from 'types';

/**/
type Props = {
  /** a drawer-close event callback. */
  onClose: TMouseHandler;
  /** a reset button callback. */
  onReset: TMouseHandler;
  title: string;
};

/**
 *
 */
const FilterHeader = ({ onClose, onReset, title }: Props) => (
  <header className="RightSideDrawer__header">
    <div className="app-d-flex app-align-items-center">
      <IconButton
        className="RightSideDrawer__button"
        data-testid="filter-burger"
        icon="arrow-left"
        onClick={onClose}
        size="S"
      />

      <h6 className="app-h6">{title}</h6>
    </div>

    <LinkButton className="RightSideDrawer__reset" onClick={onReset}>
      Reset filters
    </LinkButton>
  </header>
);

/**/
FilterHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired
};

export { FilterHeader };
