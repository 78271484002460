import { ARTICLE_IMAGE_URL } from 'other';
import { createAsyncActions, store, TBasicState } from 'store';

enum ELMD {
  FETCH_DESCRIPTION = 'mediaDescription/FETCH_DESCRIPTION'
}

/**
 * An action creator aimed to fetch news\product article long media description html file.
 * Note, that the dispatched actions do not effect state -- they are for logging only.
 */
export function fetchLongMediaDescription(path: string): Promise<string> {
  const actions = createAsyncActions<TBasicState>(ELMD.FETCH_DESCRIPTION);
  store.dispatch(actions.request());

  return retrieveLongMediaDescription(path)
    .then((resp: string) => {
      store.dispatch(actions.success());
      return resp === '0' ? '' : resp;
    })
    .catch((e) => store.dispatch(actions.error(e)));
}

/**
 * Long description retriever.
 */
export function retrieveLongMediaDescription(path: string): Promise<string> {
  const url = `${ARTICLE_IMAGE_URL}/${path}?q=${Math.random()}`;

  return fetch(url, {
    credentials: 'omit'
  }).then((resp: Response) => resp.text());
}
