import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';

import './VesselPhotos.scss';
import { PLACEHOLDER } from 'other';
import {
  renderFullscreenButton,
  renderItem,
  renderThumbInner
} from './helpers';
import { withConnection, TInjectedWithConnection } from '../../withConnection';
import { TPhotoItem } from 'types';

type Props = TInjectedWithConnection & {
  /** An array of photo objects. */
  photos: TPhotoItem[];
  /** Placeholder src. */
  placeholder?: string;
};

/**/
function Component({ isConnectionPoor, photos, placeholder }: Props) {
  if (!photos || !photos.length) {
    return (
      <div className="VesselPhotos">
        <img
          alt="vessel placeholder"
          className="VesselPhotos__placeholder"
          src={placeholder}
        />
      </div>
    );
  }

  const props = {
    additionalClass: 'VesselPhotos__gallery',
    infinite: false,
    items: photos,
    showPlayButton: false,
    showThumbnails: photos.length > 1,
    renderFullscreenButton: renderFullscreenButton,

    renderItem: isConnectionPoor
      ? (item: TPhotoItem) => renderItem(item, placeholder)
      : void 0,

    renderThumbInner: isConnectionPoor
      ? (item: TPhotoItem) => renderThumbInner(item, placeholder)
      : void 0
  };

  return (
    <div className="VesselPhotos">
      <ImageGallery {...props} />
    </div>
  );
}

/**/
Component.defaultProps = {
  placeholder: PLACEHOLDER.VESSEL
};
Component.propTypes = {
  photos: PropTypes.array,
  placeholder: PropTypes.string
};

const VesselPhotos = withConnection(Component);
export { VesselPhotos };
