import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import './TechFilter.scss';
import { Slider } from 'components';

import { TFilterRangeDict } from 'types';
import { TTechFilterHandlers, TTechFilterValues } from './types';

type Props = {
  /**  a map of handlers */
  handlers: TTechFilterHandlers;
  /** a map of range limits */
  ranges: TFilterRangeDict;
  /** a map of current values */
  values: TTechFilterValues;
};

/**
 * A technical details vessel filter.
 * @param handlers
 * @param ranges
 * @param values
 * @constructor
 */
export const TechFilter = ({
  handlers,
  ranges,
  values
}: Props): ReactElement => {
  const { selectLength, selectPower, selectTonnage, selectYear } = handlers;
  const { lengthRange, powerRange, tonnageRange, yearRange } = ranges;
  const { lengthValue, powerValue, tonnageValue, yearValue } = values;

  // Power
  const powerMarks = {
    [powerRange.min]: powerRange.min,
    [powerRange.max]: powerRange.max,
    [powerValue.min]: powerValue.min,
    [powerValue.max]: powerValue.max
  };

  // Tonnage
  const tonnageMarks = {
    [tonnageRange.min]: tonnageRange.min,
    [tonnageRange.max]: tonnageRange.max,
    [tonnageValue.min]: tonnageValue.min,
    [tonnageValue.max]: tonnageValue.max
  };

  // Length
  const lengthMarks = {
    [lengthRange.min]: lengthRange.min,
    [lengthRange.max]: lengthRange.max,
    [lengthValue.min]: lengthValue.min,
    [lengthValue.max]: lengthValue.max
  };

  // Length
  const yearMarks = {
    [yearRange.min]: yearRange.min,
    [yearRange.max]: yearRange.max,
    [yearValue.min]: yearValue.min,
    [yearValue.max]: yearValue.max
  };

  return (
    <div className="TechFilter">
      <div className="TechFilter__row">
        <label className="TechFilter__label">Engine Power (kW)</label>
        <Slider
          key={JSON.stringify(powerValue)}
          marks={powerMarks}
          max={powerRange.max}
          min={powerRange.min}
          onChange={selectPower}
          value={powerValue}
        />
      </div>

      <div className="TechFilter__row">
        <label className="TechFilter__label">Gross Tonnage (t)</label>
        <Slider
          key={JSON.stringify(tonnageValue)}
          marks={tonnageMarks}
          max={tonnageRange.max}
          min={tonnageRange.min}
          onChange={selectTonnage}
          value={tonnageValue}
        />
      </div>

      <div className="TechFilter__row">
        <label className="TechFilter__label">Length (m)</label>
        <Slider
          key={JSON.stringify(lengthValue)}
          marks={lengthMarks}
          max={lengthRange.max}
          min={lengthRange.min}
          onChange={selectLength}
          value={lengthValue}
        />
      </div>

      <div className="TechFilter__row">
        <label className="TechFilter__label">Build year</label>
        <Slider
          key={JSON.stringify(yearValue)}
          marks={yearMarks}
          max={yearRange.max}
          min={yearRange.min}
          onChange={selectYear}
          value={yearValue}
        />
      </div>
    </div>
  );
};

TechFilter.propTypes = {
  handlers: PropTypes.shape({
    selectLength: PropTypes.func.isRequired,
    selectPower: PropTypes.func.isRequired,
    selectTonnage: PropTypes.func.isRequired,
    selectYear: PropTypes.func.isRequired
  }).isRequired,
  ranges: PropTypes.shape({
    lengthRange: PropTypes.object.isRequired,
    powerRange: PropTypes.object.isRequired,
    tonnageRange: PropTypes.object.isRequired,
    yearRange: PropTypes.object.isRequired
  }).isRequired,
  values: PropTypes.shape({
    lengthValue: PropTypes.object.isRequired,
    powerValue: PropTypes.object.isRequired,
    tonnageValue: PropTypes.object.isRequired,
    yearValue: PropTypes.object.isRequired
  }).isRequired
};
