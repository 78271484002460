import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'antd/lib/checkbox';
import classnames from 'classnames';

import './VesselCard.scss';
import { IconButton, IconCustom, isMobile, VesselCardBasic } from 'components';
import { ROUTES } from 'other';

import { TFleetItems, TVessel } from 'types';

type Props = {
  favVesselIds: number[];
  item: TVessel;
  onFavClick: (items: Partial<TFleetItems>) => void;
  vesselsComparator?: {
    vesselA: Partial<TVessel>;
    vesselB: Partial<TVessel>;
  };
  vesselComparisonAdd?: (vessel: { name: string; id: any }) => void;
  vesselComparisonRemove?: (vesselId: number) => void;
  pathBase?: string;
};

class VesselCard extends React.PureComponent<Props> {
  static defaultProps;
  static propTypes;

  handleFav = (): void => {
    const { item, onFavClick } = this.props;
    onFavClick({ vessels: [item.id] });
  };

  handleCheckbox = (event: Record<string, any>): void => {
    const { item, vesselComparisonAdd, vesselComparisonRemove } = this.props;
    const { name, id } = item;
    const shouldAdd = event.target.checked;

    event.stopPropagation();
    shouldAdd ? vesselComparisonAdd({ id, name }) : vesselComparisonRemove(id);
  };

  renderLabel(): ReactElement {
    const { vesselsComparator: c } = this.props;
    if (!this.shouldShowComparator()) return null;

    if (c.vesselA && c.vesselB) {
      return this.isBeingCompared() ? this.renderCheck() : null;
    }
    return this.renderCheck();
  }

  renderCheck(): ReactElement {
    const isOneOf = this.isBeingCompared();
    if (!this.shouldShowComparator()) return null;

    return (
      <div className="VesselCardWrapper__title">
        <Checkbox checked={isOneOf} onChange={this.handleCheckbox}>
          <span className="VesselCardWrapper__compareMsg">
            {isOneOf ? 'Remove from compare' : 'Add to compare'}
          </span>
        </Checkbox>
      </div>
    );
  }

  isBeingCompared(): boolean {
    const { item, vesselsComparator: c } = this.props;
    return (
      (c.vesselA && item.id === c.vesselA.id) ||
      (c.vesselB && item.id === c.vesselB.id) ||
      false
    );
  }

  shouldShowComparator(): boolean {
    return !isMobile() && this.props.vesselComparisonAdd instanceof Function;
  }

  render(): ReactElement {
    const { favVesselIds, item, pathBase } = this.props;
    if (!item) return null;

    const path = `${pathBase}/${item.id}`;
    const iconName = favVesselIds?.includes(item.id) ? 'star-filled' : 'star';

    const compClass: string = classnames('VesselCardWrapper__compare', {
      'VesselCardWrapper__compare--chosen': this.isBeingCompared()
    });

    return (
      <div className="VesselCardWrapper">
        <VesselCardBasic vessel={item} vesselRoute={path}>
          <div className={compClass}>
            {this.renderLabel()}

            <span className="VesselCardWrapper__fav" onClick={this.handleFav}>
              <IconButton
                className="VesselCardWrapper__button"
                icon={iconName}
                size="S"
              />
            </span>
          </div>
        </VesselCardBasic>
      </div>
    );
  }
}

VesselCard.propTypes = {
  favVesselIds: PropTypes.arrayOf(PropTypes.number),
  item: PropTypes.object,
  onFavClick: PropTypes.func,
  pathBase: PropTypes.string,
  vesselComparisonAdd: PropTypes.func,
  vesselComparisonRemove: PropTypes.func,
  vesselsComparator: PropTypes.shape({
    vesselA: PropTypes.object,
    vesselB: PropTypes.object
  })
};

VesselCard.defaultProps = {
  pathBase: ROUTES.VESSEL,
  vesselsComparator: {}
};

export { VesselCard };
