import { ROUTES } from './constants';
import { EUserAuthority } from '../types';

/**/
export const ROUTE_ITEMS = {
  HOME: {
    path: ROUTES.HOME,
    title: 'Home',
    icon: 'home'
  },
  MAP: {
    path: ROUTES.MAP,
    title: 'Map',
    icon: 'map'
  },
  HARVESTING: {
    path: ROUTES.HARVESTING,
    title: 'Harvesting'
  },
  VESSELS: {
    path: ROUTES.VESSELS,
    title: 'Vessels',
    icon: 'vessel'
  },
  HISTORICAL_CATCH: {
    path: ROUTES.HISTORICAL_CATCH,
    title: 'Historical Catch',
    extra: 'New',
    icon: 'historical-catch'
  },
  HISTORICAL_TRACK: {
    path: ROUTES.HISTORICAL_TRACK,
    title: 'Track'
  },
  HISTORICAL_TRACK_NEW: {
    path: ROUTES.HISTORICAL_TRACK,
    title: 'Historical Track',
    extra: 'New',
    icon: 'route'
  },
  BIOLYTICS: {
    path: ROUTES.BIOLYTICS,
    title: 'Biolytics'
  },
  ANALYTICS: {
    path: ROUTES.ANALYTICS,
    title: 'Finance'
  },
  FARMS: {
    path: ROUTES.FARMS,
    title: 'Facilities',
    icon: 'items'
  },
  COMPANIES: {
    path: ROUTES.COMPANIES,
    title: 'Companies',
    icon: 'companies'
  },
  SERVICES: {
    path: ROUTES.SERVICES,
    title: 'Companies'
  },
  EVENTS: {
    path: ROUTES.EVENTS,
    title: 'Events'
  },
  CRM: {
    path: ROUTES.CRM,
    title: 'CRM'
  },
  NEWS: {
    path: ROUTES.NEWS,
    title: 'News',
    icon: 'newspaper'
  },
  ACTIVITY_PROFILE: {
    path: ROUTES.ACTIVITY_PROFILE,
    title: 'Activity'
  },
  CUSTOM_AKER_BIOMARINE: {
    path: ROUTES.CUSTOM_REPORT,
    title: 'Aker BioMarine',
    icon: 'bar-chart',
    authorities: [EUserAuthority.VIEW_USER_CUSTOM_AKER_BIOMARINE]
  },
  CUSTOM_NORDIC_WILDFISH: {
    path: ROUTES.CUSTOM_REPORT,
    title: 'Nordic Wildfish',
    icon: 'bar-chart',
    authorities: [EUserAuthority.VIEW_USER_CUSTOM_NORDIC_WILDFISH]
  },
  CUSTOM_SCANBIO: {
    path: ROUTES.CUSTOM_REPORT,
    title: 'ScanBio',
    icon: 'bar-chart',
    authorities: [EUserAuthority.VIEW_USER_CUSTOM_SCANBIO]
  },
  CUSTOM_VROLIJK: {
    path: ROUTES.CUSTOM_REPORT,
    title: 'Vrolijk',
    icon: 'bar-chart',
    authorities: [EUserAuthority.VIEW_USER_CUSTOM_VROLIJK]
  },
  TEST: {
    path: ROUTES.TEST,
    title: 'Test Page'
  }
};
