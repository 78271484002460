import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import './Main.scss';
import { BREAKPOINTS, ROUTES } from 'other';
import { MainContainer } from './MainContainer';
import { TrialModal } from 'components';

/**/
type Props = RouteComponentProps & {
  isLoggedIn: boolean;
  prefetchData: () => void;
  setTimerTrialModal: () => void;
};

type State = {
  isAsideOpen: boolean;
};

/**
 *
 */
class Component extends React.Component<Props, State> {
  state: State = {
    isAsideOpen:
      window.innerWidth > BREAKPOINTS.LG &&
      !(!this.props.isLoggedIn && this.props.location.pathname === ROUTES.HOME)
  };

  /**/
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (
      !prevState.isAsideOpen &&
      window.innerWidth > BREAKPOINTS.LG &&
      !prevProps.isLoggedIn &&
      prevProps.location.pathname === ROUTES.HOME &&
      (this.props.location.pathname !== ROUTES.HOME || this.props.isLoggedIn)
    ) {
      this.setState({ isAsideOpen: true });
    }
  }

  /**/
  componentDidMount() {
    const { prefetchData, setTimerTrialModal } = this.props;
    prefetchData();
    setTimerTrialModal();
  }

  /**/
  toggleAside = () =>
    this.setState({
      isAsideOpen: !this.state.isAsideOpen
    });

  /**/
  render() {
    const { isLoggedIn, location } = this.props;

    return (
      <div className="Main">
        <MainContainer
          isAsideOpen={this.state.isAsideOpen}
          isLoggedIn={isLoggedIn}
          pathname={location.pathname}
          toggleAside={this.toggleAside}
        />

        <TrialModal isLoggedIn={isLoggedIn} />
      </div>
    );
  }
}

/**
 *
 */
export const Main = withRouter(Component);
