import React from 'react';

import { MultiSelect, PopoverButton } from 'components';
import { TSelectOption } from 'types';

/**/
type Props = {
  onChange: (vesselIds: number[]) => void;
  options: TSelectOption<number>[];
  value: number[];
};

/**
 *
 */
abstract class AbstractHCSelector extends React.PureComponent<Props> {
  protected abstract icon: string;
  protected abstract label: string;

  /**/
  getItemCounter(): string | React.ReactElement {
    const { value } = this.props;
    if (value.length === 0) return this.label + ': All';

    return (
      <span>
        {this.label}:&nbsp;
        <span className="HistoricalCatchControls__counter">{value.length}</span>
      </span>
    );
  }

  /**/
  renderDropdown(node) {
    return <div className="HistoricalCatchControls__dropdown">{node}</div>;
  }

  /**/
  render() {
    const { onChange, options, value } = this.props;

    return (
      <PopoverButton icon={this.icon} label={this.getItemCounter()}>
        <div className="HistoricalCatchControls__item">
          <MultiSelect<number>
            dropdownRender={this.renderDropdown}
            onChange={onChange}
            open={true}
            options={options}
            value={value}
          />
        </div>
      </PopoverButton>
    );
  }
}

/**/
export default AbstractHCSelector;
