import React from 'react';
import PropTypes from 'prop-types';

import './FarmCard.scss';
import FarmCardBasic from './FarmCardBasic';
import { IconCustom } from 'components';

import { PTFarm, TFarm, TFleetItems } from 'types';

type Props = {
  item: TFarm;
  favFarmIds: number[];
  onFavClick: (items: Partial<TFleetItems>) => void;
};

function FarmCard(props: Props) {
  const { favFarmIds, item, onFavClick } = props;
  if (!item) return null;

  const handleFav = () => onFavClick({ farms: [item.id] });
  const iconName = favFarmIds?.includes(item.id) ? 'star-filled' : 'star';

  return (
    <div className="FarmCard">
      <FarmCardBasic item={item}>
        <div className="FarmCard__child">
          <span className="FarmCard__fav" onClick={handleFav}>
            <IconCustom type={iconName} />
          </span>
        </div>
      </FarmCardBasic>
    </div>
  );
}

FarmCard.propTypes = {
  favFarmIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  item: PropTypes.shape(PTFarm),
  onFavClick: PropTypes.func.isRequired
};
export default FarmCard;
