import React, { FC } from 'react';
import PropTypes from 'prop-types';

import { getCurrencySymbol } from 'other';
import { TooltipCustom } from 'components';

type Props = {
  currency?: string;
  value?: number;
};

const CurrencyTableCell: FC<Props> = ({ value, currency }) => {
  if (!value || !currency) return null;
  const str = `${getCurrencySymbol(currency)} ${value.toLocaleString()}`;

  return <TooltipCustom title={str}>{str}</TooltipCustom>;
};

CurrencyTableCell.propTypes = {
  currency: PropTypes.string,
  value: PropTypes.number
};

export { CurrencyTableCell };
