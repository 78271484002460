import Modal, { ModalFuncProps } from 'antd/lib/modal';

/**
 * Modal confirmation dialog.
 * @param message
 * @param onOk
 */
export function deleteConfirm(
  message: string,
  onOk: (event) => void
): {
  destroy: () => void;
  update: (newConfig: ModalFuncProps) => void;
} {
  return Modal.confirm({
    cancelButtonProps: { className: 'DefaultButton' },
    cancelText: 'No',
    title: message,
    okButtonProps: { className: 'PrimaryButton' },
    // icon: <Icon type="exclamation-circle" />,
    okText: 'Yes',
    // @ts-ignore
    okType: 'primary',
    onOk: onOk
  });
}
