import { createSelector } from 'reselect';
import { TCompanyAF, TSelectOption } from 'types';

function select(companies: TCompanyAF[]): TSelectOption<number>[] {
  return companies.map(
    ({ id, name }: TCompanyAF): TSelectOption<number> => ({
      label: name,
      value: id
    })
  );
}

export const selectCompanies = createSelector(
  (companies: TCompanyAF[]): TCompanyAF[] => companies,
  select
);
