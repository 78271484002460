import { basicInitialState } from '../../_utils/basicInitialState';
import { dedupeEntities } from './helpers';
import { SettingsService } from 'services/settings';

import { EMarkerCategory } from 'types';
import { TMapEntitiesState } from 'store';

/**
 *
 */
function getEntities() {
  let ent = SettingsService.readSettings()[SettingsService.MAP_ENTITIES] || {};

  if (!(EMarkerCategory.CAGE in ent) || !ent[EMarkerCategory.CAGE]) {
    ent = {
      ...ent,
      [EMarkerCategory.CAGE]: []
    };
  }

  if (!(EMarkerCategory.VESSEL in ent) || !ent[EMarkerCategory.VESSEL]) {
    ent = {
      ...ent,
      [EMarkerCategory.VESSEL]: []
    };
  }

  return ent;
}

/**
 *
 */
export const mapEntitiesInitialState: TMapEntitiesState = {
  ...basicInitialState,
  [EMarkerCategory.AREA]: [],
  [EMarkerCategory.CAGE]: [],
  [EMarkerCategory.LAYER_VALUE]: [],
  [EMarkerCategory.LOCATION]: [],
  [EMarkerCategory.SERVICE]: [],
  [EMarkerCategory.VESSEL]: [],
  iceAreaPopups: [],
  ...dedupeEntities(getEntities() as any)
};

/**
 *
 */
export const mapEntitiesEmptyState: TMapEntitiesState = {
  ...basicInitialState,
  [EMarkerCategory.AREA]: [],
  [EMarkerCategory.CAGE]: [],
  [EMarkerCategory.LAYER_VALUE]: [],
  [EMarkerCategory.LOCATION]: [],
  [EMarkerCategory.SERVICE]: [],
  [EMarkerCategory.VESSEL]: [],
  iceAreaPopups: []
};
