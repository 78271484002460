import React, { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Col from 'antd/lib/col';
import DatePicker from 'antd/lib/date-picker';
import dayjs from 'dayjs';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import Row from 'antd/lib/row';
import { useSelector } from 'react-redux';
import { UploadFile } from 'antd/lib/upload/interface';

import './ProposalForm.scss';
import {
  contactRules,
  ContactsSelect,
  CurrencySelect,
  FileUploader,
  ProbabilitySelect,
  RejectReasonCustomInput,
  RejectReasonSelect,
  RemindersFormList,
  RTEBasic,
  SaveCancelGroup,
  StatusSelect,
  titleRules
} from 'components';
import { getInitialFileList, selectRejectReasons } from '../../helpers/helpers';
import { MAX_INT, PROPOSAL_OTHER_REJECT_REASON_ID } from 'other';

import { ECurrencyCode, EProbability, EProposalStatus, TProposal } from 'types';

/**/
const currencyName = ['currency', 'id'];
const rejectReasonCustomDependencies = ['status', 'rejectReasonId'];
const rejectReasonIdDependency = ['status'];

/**/
type Props = {
  hideModal: () => void;
  initialValues?: Partial<TProposal>;
  onFormClose: () => void;
  onFormSubmit: (formData: Partial<TProposal>, fileList: UploadFile[]) => void;
};

/**/
const ProposalForm: FC<Props> = ({
  initialValues,
  onFormSubmit,
  hideModal,
  onFormClose
}) => {
  useEffect(() => {
    return () => {
      onFormClose();
    };
  }, [onFormClose]);
  const initialFiles = getInitialFileList(initialValues?.files || []);
  const [fileList, setFileList] = useState(initialFiles);
  const rejectReasonOptions = useSelector(selectRejectReasons);
  const handleFinish = (formData: Partial<TProposal>): void => {
    onFormSubmit(formData, fileList);
    hideModal();
  };
  const rejectReasonIdDefault =
    initialValues?.status === EProposalStatus.REJECTED
      ? rejectReasonOptions[0].value
      : null;
  const isStatusSelectHidden =
    initialValues?.status === EProposalStatus.HISTORY ||
    initialValues?.status === EProposalStatus.DEAL;
  const formInitialValues = {
    contactId: initialValues?.crmContact?.id || null,
    rejectReasonId: rejectReasonIdDefault,
    status: EProposalStatus.ONGOING,
    ...initialValues,
    amount: initialValues?.amount || 0,
    currency: initialValues?.currency?.id
      ? initialValues.currency
      : { id: ECurrencyCode.EUR },
    date: dayjs(initialValues?.date),
    description: initialValues?.description,
    probability: EProbability.HIGH,
    reminders: initialValues?.reminders
      ? initialValues.reminders.map((reminder) => ({
          ...reminder,
          start: dayjs(dayjs.utc(reminder.start).toDate())
        }))
      : []
  };

  return (
    <Form
      className="ProposalForm"
      initialValues={formInitialValues}
      layout="vertical"
      name="basic"
      onFinish={handleFinish}
    >
      <Form.Item
        className="ProposalForm__statusSelect"
        hidden={isStatusSelectHidden}
        label="status"
        name="status"
      >
        <StatusSelect disabled={!initialValues || isStatusSelectHidden} />
      </Form.Item>

      <Form.Item dependencies={rejectReasonIdDependency} noStyle>
        {({ getFieldValue }) => {
          const status = getFieldValue('status');
          const isHidden = status !== EProposalStatus.REJECTED;

          return (
            <Form.Item
              hidden={isHidden}
              label="Reject reason"
              name="rejectReasonId"
              required
            >
              <RejectReasonSelect
                isHidden={isHidden}
                options={rejectReasonOptions}
              />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item dependencies={rejectReasonCustomDependencies} noStyle>
        {({ getFieldsValue }) => {
          const { status, rejectReasonId } = getFieldsValue([
            'status',
            'rejectReasonId'
          ]);
          const isOtherReasonChecked =
            rejectReasonId === PROPOSAL_OTHER_REJECT_REASON_ID;
          const isStatusRejected = status === EProposalStatus.REJECTED;
          const isHidden = !isOtherReasonChecked || !isStatusRejected;

          return (
            <Form.Item
              hidden={isHidden}
              label="Reject message"
              name="rejectReasonCustom"
              preserve={false}
              required
            >
              <RejectReasonCustomInput isHidden={isHidden} />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item label="title" name="title" rules={titleRules}>
        <Input />
      </Form.Item>

      <Row align="bottom" gutter={16}>
        <Col sm={6} xs={12}>
          <Form.Item label="date" name="date">
            <DatePicker />
          </Form.Item>
        </Col>

        <Col sm={5} xs={12}>
          <Form.Item label="Probability" name="probability">
            <ProbabilitySelect />
          </Form.Item>
        </Col>

        <Col sm={6} xs={12}>
          <Form.Item label="Amount" name="amount">
            <InputNumber max={MAX_INT} min={0} />
          </Form.Item>
        </Col>

        <Col sm={4} xs={12}>
          <Form.Item name={currencyName}>
            <CurrencySelect />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col sm={12} xs={24}>
          <Form.Item label="contact" name="contactId" rules={contactRules}>
            <ContactsSelect />
          </Form.Item>
        </Col>
      </Row>

      <RemindersFormList />

      <Form.Item label="description" name="description">
        <RTEBasic />
      </Form.Item>

      <Form.Item name="files">
        <FileUploader fileList={fileList} setFileList={setFileList} />
      </Form.Item>

      <Form.Item>
        <SaveCancelGroup onCancel={hideModal} />
      </Form.Item>
    </Form>
  );
};

ProposalForm.propTypes = {
  hideModal: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  onFormClose: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired
};

export { ProposalForm };
