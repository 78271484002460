import React from 'react';

import { shouldRunTracking } from 'services';
import TrackerCore from './TrackerCore';
import { TInjectedWithConnection, withConnection } from 'components';

/**/
function Tracker(props: TInjectedWithConnection) {
  return shouldRunTracking() ? <TrackerCore {...props} /> : null;
}

/**/
export default withConnection(Tracker);
