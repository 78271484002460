import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Modal from 'antd/lib/modal';

import './FleetsManager.scss';
import {
  createFleetAction,
  removeFleetAction,
  renameFleetAction
} from 'store/fleets/fleetsActions';
import { ENV } from 'other';
import { FleetsEditor, LinkButton, LoaderOverlay } from 'components';

import { TFleet } from 'types';
import { TState } from 'store';

/**/
type Props = {
  fleets: TFleet[];
  isLoading: boolean;
  onCreate: (fleetName: string) => void;
  onRemove: (fleetId: number) => void;
  onRename: (fleet: TFleet, newName: string) => void;
};
type State = {
  isVisible: boolean;
};

/**/
const modalProps = {
  closable: false,
  destroyOnClose: true,
  footer: null,
  icon: null,
  maskClosable: true,
  title: null,
  width: 414
};

/**
 *
 */
class Component extends React.Component<Props, State> {
  static propTypes;

  state: State = { isVisible: false };

  handleCancel = () => this.setState({ isVisible: false });

  showMenu = () => this.setState({ isVisible: true });

  render() {
    const { isLoading, ...restOfProps } = this.props;
    const { isVisible } = this.state;

    return (
      <div className="FleetsManager">
        <LinkButton id="FleetsManager" onClick={this.showMenu}>
          Manage {ENV.FLEETS}
        </LinkButton>

        <Modal
          open={isVisible}
          {...modalProps}
          onCancel={this.handleCancel}
          className="modal-small"
        >
          <LoaderOverlay
            className="FleetsManager__loader"
            isLoading={isLoading}
            isTransparent={true}
          >
            <FleetsEditor onCancel={this.handleCancel} {...restOfProps} />
          </LoaderOverlay>
        </Modal>
      </div>
    );
  }
}

/**/
const mapStateToProps = ({ fleets }: TState) => ({
  fleets: fleets.fleets,
  isLoading: fleets.isPending
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreate: createFleetAction,
      onRemove: removeFleetAction,
      onRename: renameFleetAction
    },
    dispatch
  );

export const FleetsManager = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
