import React, { FC, ReactElement } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { EditDeletePopover } from 'components';

import { TState } from 'store';

type Props = {
  authorId: number;
  children?: ReactElement;
  onDelete: () => void;
  onEdit: () => void;
};

const CrmItemCard: FC<Props> = ({ authorId, children, onDelete, onEdit }) => {
  const userId = useSelector(({ session }: TState) => session.user.userInfo.id);

  return (
    <div className="CardWrapper">
      {userId === authorId && (
        <EditDeletePopover onDelete={onDelete} onEdit={onEdit} />
      )}

      {children}
    </div>
  );
};

CrmItemCard.propTypes = {
  authorId: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired
};

export { CrmItemCard };
