import React from 'react';
import PropTypes from 'prop-types';
import message from 'antd/lib/message';

import './RTEFile.scss';

import {
  getFileCardContent,
  getFileExtension,
  RTEBasic,
  RTEBasicPropTypes,
  TFileRestriction,
  toolbar
} from 'components';

import { TFileDescription } from 'types';

/**/
type Props = {
  /**  a descriptor of the file to be inserted. */
  fileDescription?: TFileDescription;
  fileRestriction?: TFileRestriction;
  /** onUpload callback (fired when user chooses a file). */
  onUpload?: (file: File) => void;
  /** The prop types below are overriden (surprisingly not extended)
   * just with the purpose of documenting.
   */
  emptyStringAlias?: string;
  formats?: string[];
  modules?: { [key: string]: any };
  /** onChange event callback. */
  onChange?: (markup: string) => void;
  /** input value, the editor content. */
  value?: string;
};

/**
 *
 */
export class RTEFile extends RTEBasic<Props, null> {
  private inputRef: HTMLInputElement;
  private quill;
  static defaultProps;
  static propTypes;

  componentDidUpdate(prevProps: Props) {
    setTimeout(() => {
      this.registerFormats();
      if (
        this.props.fileDescription &&
        this.props.fileDescription !== prevProps.fileDescription
      ) {
        this.insertFileCard();
      }
    });
  }

  assignInput = (el) => (this.inputRef = el);

  // FILE SPECIFIC METHODS:
  insertFileCard() {
    const range = this.quill.getSelection();
    const content = getFileCardContent(this.props.fileDescription);
    this.quill.insertEmbed(range ? range.index : 0, 'file', content);
  }

  registerFormats() {
    if (!this.quillRef) return;
    if (typeof this.quillRef.getEditor !== 'function') return;
    if (this.quill) return;

    const quill = this.quillRef.getEditor();
    if (!quill) return;

    this.quill = quill;
    const toolbar = quill.getModule('toolbar');
    toolbar.addHandler('file', () => this.inputRef.click());
  }

  handleFile = (event) => {
    const { fileRestriction, onUpload } = this.props;
    const file = event.target.files[0];

    if (fileRestriction) {
      if (file.size > fileRestriction.maxSize) {
        return message.error(fileRestriction.message);
      } else if (
        !fileRestriction.extensions.includes(getFileExtension(file.name))
      ) {
        return message.error('Unsupported file extension.');
      }
    }

    onUpload(file);
  };

  render() {
    return (
      <div className="RTEFile">
        {super.render()}

        <input
          className="RTEFile__input"
          onChange={this.handleFile}
          ref={this.assignInput}
          type="file"
        />
      </div>
    );
  }
}

/**
 *
 */
RTEFile.propTypes = {
  ...RTEBasicPropTypes,
  fileDescription: PropTypes.object,
  onUpload: PropTypes.func.isRequired
};

RTEFile.defaultProps = {
  emptyStringAlias: RTEBasic.EMPTY_STRING_ALIAS,
  formats: [...RTEBasic.FORMATS, 'file'],
  modules: {
    ...RTEBasic.MODULES,
    toolbar: toolbar
  }
};
