import React from 'react';
import Checkbox from 'antd/lib/checkbox';
import classnames from 'classnames';
import dayjs from 'dayjs';
import Tag from 'antd/lib/tag';
import { useHistory } from 'react-router-dom';

import './AreaItem.scss';
import AreaNotes from './AreaNotes';
import { EditDeleteGroup } from 'components';
import { ROUTES } from 'other';
import { TArea } from 'types';

/**/
type Props = {
  area: TArea;
  isChecked: boolean;
  userId: number;
  onRemove: () => void;
  onSelect: () => void;
};

/**
 *
 */
function AreaItem(props: Props) {
  const { area, isChecked, userId, onRemove, onSelect } = props;
  const history = useHistory();
  const handleEdit = () => history.push(`${ROUTES.AREA_EDITOR}/${area.id}`);

  const isFresh =
    dayjs(area.end).isValid() &&
    dayjs(area.end || null).unix() * 1000 > Date.now();

  const isDisabled = !isFresh || area.public;
  const _isChecked = isChecked || area.public;
  const isEditable = userId === area.creatorId;

  const cls = classnames('AreaItem__name ellipsis', {
    'AreaItem__name--disabled': !isFresh
  });

  return (
    <li className="AreaItem">
      <div className="AreaItem__info">
        <Checkbox checked={_isChecked} disabled={isDisabled} onClick={onSelect}>
          <div className={cls}>{area.name}</div>
        </Checkbox>

        {isFresh || (
          <Tag className="AreaItem__tag" color="orange">
            Expired
          </Tag>
        )}

        <AreaNotes notes={area.notes} />
      </div>

      {isEditable && (
        <EditDeleteGroup
          className="AreaItem__controls"
          onDelete={onRemove}
          onEdit={handleEdit}
        />
      )}
    </li>
  );
}

/**/
export default AreaItem;
