import { connect } from 'react-redux';

import { MultiSelect } from 'components';
import { setFiltersAction } from 'store/filters/filtersActions';
import { TState } from 'store';

const mapStateToProps = ({
  dictionaries: { species },
  filters: { specieValue }
}: TState) => ({
  allOptionLabel: 'All species',
  options: species,
  value: specieValue
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (value: number[]) =>
    dispatch(setFiltersAction({ specieValue: value }))
});

export default connect(mapStateToProps, mapDispatchToProps)(MultiSelect);
