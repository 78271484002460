import React, { FC } from 'react';
import PropTypes from 'prop-types';

import './AddButtonStar.scss';
import { IconCustom, LinkButton } from 'components';

/**/
type Props = {
  label?: string;
  onAdd: () => void;
};

/**/
const AddButton: FC<Props> = ({ label, onAdd }) => (
  <LinkButton className="AddButton" onClick={onAdd}>
    <IconCustom type="plus" />
    {label}
  </LinkButton>
);

/**/
AddButton.propTypes = {
  label: PropTypes.string,
  onAdd: PropTypes.func.isRequired
};

export { AddButton };
