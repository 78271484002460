import React, { FC, ReactElement } from 'react';
import classnames from 'classnames';

import './TrialInfo.scss';
import { ENV } from 'other';

const TrialInfo: FC = (): ReactElement => {
  const cls = classnames(
    'TrialInfo__figure',
    `TrialInfo__figure--${ENV.APP_ABBR}`
  );
  const src = `/assets/trialpopup${ENV.APP_ABBR}.png`;
  const text = `To fully unlock the capabilities of ${ENV.APP_NAME}, please enter your email`;

  return (
    <div className="TrialInfo">
      <div className="TrialInfo__text">
        <h2 className="TrialInfo__title app-h2">Receive free trial</h2>
        <p className="app-body-1">{text}</p>
      </div>

      <figure className={cls}>
        <img alt="Trial popup" className="TrialInfo__img" src={src} />
      </figure>
    </div>
  );
};

export { TrialInfo };
