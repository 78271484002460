import { isTouchDevice } from '../../../other';

export const MOBILE_SCALE = 1.8;
export const MARKER_SCALE = isTouchDevice() ? MOBILE_SCALE : 1;
export const FARM_SCALE = MARKER_SCALE * 0.65;

export const ROTOR = Math.PI / 180;
export const HEIGHT = 14;
export const WIDTH = 6;
export const RADIUS = MARKER_SCALE * 5;
export const FARM_RADIUS = FARM_SCALE * 5;
export const DEFAULT_COLOR = 'Grey';
export const DEFAULT_COLORS = [DEFAULT_COLOR, DEFAULT_COLOR];
