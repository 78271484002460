import { ENDPOINTS, ENV, stringComparator } from 'other';
import { EVesselsActions, fetchSet } from './vesselsConstants';
import { ESupportedApps, TVessel } from 'types';
import { http, THttpResponse } from 'services';
import { TState } from '../../appStateModel';

export function fetchVesselsAction() {
  return (dispatch) => {
    dispatch(fetchSet.request());

    http
      .send(ENDPOINTS.VESSELS)
      .then(({ data: vessels }: THttpResponse<TVessel[]>) =>
        dispatch(
          fetchSet.success({
            vessels: handleVessels(vessels)
          })
        )
      )
      .catch((e) => dispatch(fetchSet.error(e)));
  };
}

/**
 * Filters vessels by the application type (AF\FF) and sorts.
 * @param vessels
 */
export function handleVessels(vessels: TVessel[]): TVessel[] {
  const appName = ENV.APP_NAME.toUpperCase() as ESupportedApps;

  return vessels
    .filter(({ vesselType }: TVessel) =>
      vesselType?.supportedApps.includes(appName)
    )
    .sort((a: TVessel, b: TVessel): number => stringComparator(a.name, b.name));
}

/**/
export function assignHistoricalVessel(vesselId: number) {
  return (dispatch, getState) => {
    const { vessels } = getState() as TState;
    const vessel = vessels.vessels.find((v: TVessel) => v.id === vesselId);

    dispatch({
      type: EVesselsActions.ASSIGN_HISTORICAL_VESSEL,
      payload: { historicalVessel: vessel.imo }
    });
  };
}
