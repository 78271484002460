import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { APP_CONTENT } from 'other';

const ScrollToTop: FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.getElementById(APP_CONTENT).scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export { ScrollToTop };
