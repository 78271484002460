import { basicInitialState } from '../../_utils/basicInitialState';
import { TEventsState } from 'store';

export const eventsInitialState: TEventsState = {
  ...basicInitialState,
  event: null,
  events: null,
  isNotFound: false,
  scrollTop: 0
};
