import { createAsyncActions, TPromoState } from 'store';

export enum EPromoActions {
  FETCH_ARTICLES = 'promo/FETCH_ARTICLES',
  SUBMIT_PROMOTION = 'promo/SUBMIT_PROMOTION',
  FETCH_PAYMENT_REPORT = 'promo/FETCH_PAYMENT_REPORT',

  CALCULATE_COST_REQUEST = 'promo/CALCULATE_COST_REQUEST',
  CALCULATE_COST_SUCCESS = 'promo/CALCULATE_COST_SUCCESS',
  CALCULATE_COST_FAIL = 'promo/CALCULATE_COST_FAIL',

  UPDATE_PROMOTION = 'promo/UPDATE_PROMOTION',
  RESET = 'promo/RESET'
}

export const fetchSet = createAsyncActions<TPromoState>(
  EPromoActions.FETCH_ARTICLES
);
export const submitSet = createAsyncActions<TPromoState>(
  EPromoActions.SUBMIT_PROMOTION
);
export const fetchReportSet = createAsyncActions<TPromoState>(
  EPromoActions.FETCH_PAYMENT_REPORT
);
