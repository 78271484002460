export type THttpConfig = {
  baseUrl?: string;
  headers?: {};
};

export type THttpRequestOptions = {
  body: any;
  credentials?: 'include' | 'omit' | 'same-origin';
  crossDomain?: boolean;
  method: 'GET' | 'PATCH' | 'PUT' | 'POST' | 'DELETE';
  responseType?: 'json';
  url: string;
  withCredentials?: boolean;
};

export type THttpResponse<T> = {
  data: T;
  headers: Headers;
  message: string;
  ok: boolean;
  status: number;
  statusText: string;
  url: string;
};

export class Http {
  private readonly API_URL: string;
  private readonly handleSuccess: (response: any) => any;
  private readonly handleError: (error: Error) => PromiseLike<never>;

  private static DEFAULT_OPTIONS = {
    withCredentials: true
  };

  private static defaultErrorHandler = (error: Error) => {
    window.console.error('Http: ', error.message);
    throw error;
  };

  private static defaultSuccessHandler = (r) => r;

  constructor(API_URL: string, handleSuccess?, handleError?) {
    this.API_URL = API_URL;
    this.handleSuccess = handleSuccess || Http.defaultSuccessHandler;
    this.handleError = handleError || Http.defaultErrorHandler;
  }

  private static formBody(data: any): string | null | FormData {
    if (!data) return null;
    if (Http.isFormData(data)) return data;
    return JSON.stringify(data);
  }

  private static isFormData(data: any): boolean {
    return data instanceof FormData;
  }

  private static async parseResponse(
    res: Response
  ): Promise<THttpResponse<any>> {
    const { headers, ok, status, statusText, url } = res;

    try {
      const { data, message, ...rest } = await res.json();

      return {
        ...rest,
        data,
        headers,
        message,
        ok,
        status,
        statusText,
        url
      };
    } catch (e) {
      return res as any;
    }
  }

  private static getHeaders(body: any, headers = {}): {} {
    const content = Http.isFormData(body)
      ? {}
      : {
          'Content-Type': 'application/json;charset=utf-8'
        };
    return { ...content, ...headers };
  }

  private static getOptions(
    options: Partial<THttpRequestOptions>,
    headers
  ): THttpRequestOptions {
    return {
      ...Http.DEFAULT_OPTIONS,
      ...(options as any),
      body: Http.formBody(options.body),
      headers: Http.getHeaders(options.body, headers),
      method: options.method || 'GET'
    };
  }

  private getRequestUrl(url: string, baseUrl?: string): string {
    const _baseUrl: string = baseUrl || this.API_URL;
    return `${_baseUrl}${url}`;
  }

  send(
    options: string | Partial<THttpRequestOptions>,
    config: THttpConfig = {}
  ): Promise<THttpResponse<any>> {
    let init: THttpRequestOptions;
    let path: string;

    if (typeof options === 'string') {
      path = options;
      init = Http.getOptions({}, config.headers);
    } else {
      path = options.url;
      init = Http.getOptions(options, config.headers);
    }

    const url: string = this.getRequestUrl(path, config.baseUrl);
    return fetch(url, init)
      .then(Http.parseResponse)
      .then(this.handleSuccess)
      .catch(this.handleError);
  }
}
