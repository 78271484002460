import React, { FC } from 'react';
import Select, { SelectProps } from 'antd/lib/select';

import { ERecurrence } from 'types';

const currencyOptions = Object.keys(ERecurrence).map((recurrence) => ({
  label: recurrence.replace(/_/g, ' '),
  value: recurrence
}));

const RecurrenceSelect: FC<SelectProps<ERecurrence>> = (props) => (
  <Select {...props} options={currencyOptions} />
);

export { RecurrenceSelect };
