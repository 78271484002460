import React from 'react';
import { ButtonProps } from 'antd/lib/button';

import './DefaultButton.scss';
import { AppButton } from '../helpers';

/**/
type Props = Omit<ButtonProps, 'size'> & {
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL';
};

/**
 *
 */
export function DefaultButton(props: Props) {
  return <AppButton mainClassName="DefaultButton" {...props} />;
}
