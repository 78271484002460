import React, { CSSProperties, ReactElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import message from 'antd/lib/message';
import Upload from 'antd/lib/upload';

import './ImageInput.scss';
import { IconCustom } from 'components';

const MAX_SIZE = {
  label: '20MB',
  value: 20971520
};

const iconCustomStyle = { fontSize: 22 };

type Props = {
  /**
   * React children or a child.
   * A proper place to pass a spinner or a progress bar.
   */
  children: ReactElement;
  /** the root component CSS class. */
  className?: string;
  /** a background image URL. */
  imgUrl?: string;
  /** a callback function. */
  onUpload: (file: File) => void;
  /** an invitation text. */
  placeholder?: string;
};

/**
 * Image file drag-and-drop / click input.
 * @param children
 * @param className
 * @param imgUrl
 * @param onUpload
 * @param placeholder
 * @constructor
 */

class ImageInput extends React.PureComponent<Props> {
  static defaultProps;
  static propTypes;

  handleUpload = ({ file: { originFileObj } }) => {
    if (originFileObj.size > MAX_SIZE.value) {
      return message.error(`File size should be less than ${MAX_SIZE.label}!`);
    }
    this.props.onUpload(originFileObj);
  };

  render() {
    const { children, className, imgUrl, placeholder } = this.props;
    const cls = classnames('ImageInput', className);
    const style: CSSProperties = imgUrl
      ? { backgroundImage: `url("${encodeURI(imgUrl)}")` }
      : null;

    const props = {
      accept: 'image/*',
      customRequest: Function.prototype,
      fileList: null,
      multiple: false,
      name: 'file',
      onChange: this.handleUpload,
      showUploadList: false
    };

    return (
      <div className={cls}>
        {/* @ts-ignore */}
        <Upload.Dragger {...props}>
          <div className="ImageInput__placeholder">
            <div className="ImageInput__info">
              <div className="ImageInput__block">
                <div className="ant-upload-drag-icon">
                  <IconCustom type="photo" style={iconCustomStyle} />
                </div>
                <div className="ant-upload-text">{placeholder}</div>
              </div>
            </div>

            <div className="ImageInput__image" style={style} />
            {children}
          </div>
        </Upload.Dragger>
      </div>
    );
  }
}

/**/
ImageInput.defaultProps = {
  placeholder: 'Drop a photo here or tap to add'
};

ImageInput.propTypes = {
  className: PropTypes.string,
  imgUrl: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired
};

export { ImageInput };
