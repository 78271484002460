import React from 'react';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import './CategoryList.scss';
import { CategoryListItem } from 'components';
import { ENV, APP_CONTENT } from 'other';

/**
 *
 */
class List extends React.PureComponent<RouteComponentProps> {
  componentDidMount() {
    const top = parseInt(window.location.hash.slice(1)) || 0;
    document.getElementById(APP_CONTENT).scroll({
      top: top,
      behavior: 'instant' as any
    });
  }

  /**/
  handleClick = () =>
    this.props.history.replace({
      ...this.props.history,
      hash: '#' + document.getElementById(APP_CONTENT).scrollTop
    });

  /**/
  render() {
    return (
      <section className="CategoryList">
        <h2 className="CategoryList__title">
          {ENV.IS_AF ? (
            <>
              Aquafacts is a digital platform that offers comprehensive data and
              business intelligence about aquaculture
            </>
          ) : (
            <>
              Receive access to <strong>6000+ fishing vessels</strong>,
              technical details, owners, financial statements, analytics, and
              satellite AIS.
            </>
          )}
        </h2>

        {ENV.LANDING_PAGE_NAV_CATEGORIES && (
          <Row>
            <Col lg={{ offset: 5, span: 14 }} span={24}>
              <Row gutter={24}>
                {ENV.LANDING_PAGE_NAV_CATEGORIES.map((category) => (
                  <Col
                    className="app-d-flex app-mb-3"
                    key={category.title}
                    md={8}
                    sm={12}
                    xs={24}
                    onClickCapture={this.handleClick}
                  >
                    <CategoryListItem category={category} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        )}
      </section>
    );
  }
}

/**/
const CategoryList = withRouter(List);
export { CategoryList };
