import React, { FC, ReactElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Collapse, { CollapseProps } from 'antd/lib/collapse';

import './Expander.scss';
import { ExpanderHeader, IconCustom } from 'components';

const expandIcon = () => <IconCustom type="chevron-up" />;

type Props = {
  buttonText?: string;
  children: ReactElement | ReactElement[];
  className?: string;
  isExpanded?: boolean;
  onAdd?: () => void;
  title: string;
};

/**
 *
 */
const Expander: FC<Props> = ({
  buttonText,
  children,
  className,
  onAdd,
  title,
  isExpanded = true
}): ReactElement => {
  const defaultActiveKey = isExpanded ? '1' : null;
  const cls = classnames('Expander', className);

  const header = (
    <ExpanderHeader buttonText={buttonText} title={title} onAdd={onAdd} />
  );

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: header,
      children: children
    }
  ];

  return (
    <section className={cls}>
      <Collapse
        accordion={true}
        bordered={false}
        className="Expander__collapse"
        defaultActiveKey={defaultActiveKey}
        expandIcon={expandIcon}
        items={items}
      />
    </section>
  );
};

/**/
Expander.propTypes = {
  buttonText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  className: PropTypes.string,
  isExpanded: PropTypes.bool,
  onAdd: PropTypes.func,
  title: PropTypes.string.isRequired
};

export { Expander };
