import React from 'react';
import { ENV, isProdHost } from 'other';
import { startTracking } from 'services';
import { TInjectedWithConnection } from 'components';

const FB_SDK = 'fb_sdk';

/**/
class TrackerCore extends React.Component<TInjectedWithConnection> {
  static toggleTracking(isConnectionPoor: boolean): void {
    window[`ga-disable-${ENV.GA_MEASUREMENT_ID}`] = isConnectionPoor;

    if (!isConnectionPoor && isProdHost()) {
      const hasScript = document.getElementById(FB_SDK);
      hasScript || TrackerCore.loadFBSDK();
    }
  }

  static loadFBSDK(): void {
    window['fbAsyncInit'] = function () {
      window['FB'].init({
        appId: ENV.FB_APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v7.0'
      });
    };

    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.id = FB_SDK;
    script.src = 'https://connect.facebook.net/en_US/sdk.js';
    document.head.append(script);
  }

  componentDidMount() {
    const { isConnectionPoor } = this.props;
    TrackerCore.toggleTracking(isConnectionPoor);
    isConnectionPoor || startTracking();
  }

  componentDidUpdate(prevProps: Readonly<TInjectedWithConnection>) {
    const { isConnectionPoor } = this.props;

    if (isConnectionPoor !== prevProps.isConnectionPoor) {
      TrackerCore.toggleTracking(isConnectionPoor);
      isConnectionPoor || startTracking();
    }
  }

  render() {
    return null;
  }
}

/**/
export default TrackerCore;
