import { createSelector } from 'reselect';
import { TSelectOption } from 'types';

type Option = {
  id: number;
  name: string;
};

const getFleetsOptions = (options: Option[]): TSelectOption<number>[] =>
  options.map(({ id, name }) => ({ label: name, value: id }));

export const selectEngines = createSelector(
  (options: Option[]) => options,
  getFleetsOptions
);
