import React, { useState, FC } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import message from 'antd/lib/message';
import Modal from 'antd/lib/modal';
import Upload from 'antd/lib/upload';

import './ImageListInput.scss';
import { getBase64 } from 'other';
import { IconCustom } from 'components';
import { UploadFile } from 'antd/lib/upload/interface';
import { PTUploadFile } from 'types';

function getBase64Promise(file: UploadFile): Promise<string> {
  return new Promise((resolve) => getBase64(file.originFileObj, resolve));
}

const MAX_SIZE = {
  label: '20MB',
  value: 20971520
};

type Props = {
  photos: UploadFile[];
  setPhotos: (photos: UploadFile[]) => void;
  className?: string;
};

const ImageListInput: FC<Props> = ({ photos, setPhotos, className }) => {
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);
  const componentClassName = classnames('ImageListInput', className);
  const beforeUpload = (file: File): boolean => {
    if (file.size > MAX_SIZE.value) {
      message.error(`File size should be less than ${MAX_SIZE.label}!`);
    }

    return false;
  };

  const handleChange = ({ fileList }): void => setPhotos(fileList);
  const handleModalClose = () => setPreviewVisible(false);
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64Promise(file);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  return (
    <>
      <Upload
        accept="image/*"
        beforeUpload={beforeUpload}
        className={componentClassName}
        fileList={photos}
        listType="picture-card"
        onChange={handleChange}
        onPreview={handlePreview}
      >
        <div className="ImageListInput__label">
          <IconCustom type="plus" /> Upload
        </div>
      </Upload>
      <Modal
        footer={null}
        title={previewTitle}
        open={previewVisible}
        onCancel={handleModalClose}
      >
        <img
          alt="example"
          className="ImageListInput__image"
          src={previewImage}
        />
      </Modal>
    </>
  );
};

ImageListInput.propTypes = {
  className: PropTypes.string,
  // @ts-ignore
  photos: PropTypes.arrayOf(PropTypes.exact(PTUploadFile)).isRequired,
  setPhotos: PropTypes.func.isRequired
};

export { ImageListInput };
