import React from 'react';

import './HCSwipeMenu.scss';
import { APP_CONTENT } from 'other';
import { BottomDrawer, IconCustom } from 'components';
import HCPeriodSelect from './PeriodSelect/HCPeriodSelect';
import { PeriodSettings } from '../helpers';

/**/
type Props = {
  onChange: (settings: Partial<PeriodSettings>) => void;
  values: PeriodSettings;
};

type State = {
  isOpen: boolean;
};

/**
 *
 */
class HCSwipeMenu extends React.PureComponent<Props, State> {
  state: State = { isOpen: true };

  /**/
  handleToggle = () => this.setState({ isOpen: !this.state.isOpen });

  /**/
  renderButton() {
    const style = this.state.isOpen ? { display: 'none' } : {};
    return (
      <div className="HCSwipeMenu__trigger">
        <button
          className="MapControlButton HCSwipeMenu__button"
          onClick={this.handleToggle}
          style={style}
        >
          <IconCustom className="HCSwipeMenu__icon" type="calendar-dates" />
        </button>
      </div>
    );
  }

  /**/
  render() {
    const { onChange, values } = this.props;

    return (
      <BottomDrawer
        className="HCSwipeMenu"
        button={this.renderButton()}
        getContainer=".Main__content"
        height={160}
        open={this.state.isOpen}
        toggleClose={this.handleToggle}
      >
        <div className="HCSwipeMenu__content">
          <HCPeriodSelect
            onChange={onChange}
            onClose={this.handleToggle}
            values={values}
          />
        </div>
      </BottomDrawer>
    );
  }
}

/**/
export default HCSwipeMenu;
