import React, { CSSProperties, MouseEventHandler } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import './CommonBanner.scss';
import { DraftOverlay, LinkButton } from 'components';
import { PLACEHOLDER } from 'other';
import { withConnection } from '../withConnection';

/**/
type OwnProps = {
  className?: string;
  src?: string;
  isDraft?: boolean;
  path?: string;
  placeholder?: string;
};

type Props = OwnProps & {
  isConnectionPoor: boolean;
};

type State = { isDisclosed: boolean };

type ControlProps = State & {
  isConnectionPoor: boolean;
  onClick: MouseEventHandler<HTMLElement>;
  src?: string;
};

type FigureProps = {
  isDraft?: boolean;
  path?: string;
  src: string;
};

/**
 *
 */
function Figure(props: FigureProps) {
  const { isDraft, path, src } = props;
  const figureStyle: CSSProperties = {
    backgroundImage: `url("${encodeURI(src)}")`
  };

  const cls = classnames('app-fixed-ratio-content CommonBanner__content', {
    'CommonBanner__content--draft': isDraft
  });

  const children = [
    <figure className="CommonBanner__figure" key={1} style={figureStyle} />,
    <DraftOverlay isShown={isDraft} key={2} />
  ];

  return path ? (
    <Link className={cls} children={children} to={path} />
  ) : (
    <div className={cls} children={children} />
  );
}

/**
 *
 */
function Control(props: ControlProps) {
  const { isConnectionPoor, isDisclosed, onClick, src } = props;
  const isShown = !isDisclosed && src && isConnectionPoor;

  if (!isShown) return null;

  return (
    <div className="CommonBanner__control">
      <span className="CommonBanner__msg">
        Image hidden due to poor connection.
      </span>

      <LinkButton onClick={onClick}>Show anyway</LinkButton>
    </div>
  );
}

/**
 *
 */
class Component extends React.Component<Props, State> {
  state: State = { isDisclosed: false };

  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isDisclosed: true });
  };

  getSrc(): string {
    const { src, placeholder, isConnectionPoor } = this.props;

    return this.state.isDisclosed || !isConnectionPoor
      ? src || placeholder || PLACEHOLDER.BANNER
      : placeholder || PLACEHOLDER.BANNER;
  }

  render() {
    const { className, isDraft, path, ...rest } = this.props;
    const cls = classnames('app-fixed-ratio-container CommonBanner', className);
    const src = this.getSrc();

    return (
      <section className={cls}>
        <Figure isDraft={isDraft} path={path} src={src} />

        <Control
          isDisclosed={this.state.isDisclosed}
          onClick={this.handleClick as any}
          {...rest}
        />
      </section>
    );
  }
}

/**/
const CommonBanner = withConnection(Component);
export { CommonBanner, Component as CommonBannerCore };
