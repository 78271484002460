import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Collapse, { CollapseProps } from 'antd/lib/collapse';

import EnginesFilter from 'components/common/filters/EngineFilter/EnginesFilter';
import { getExpandIcon } from './helpers';
import Sliders from 'components/common/filters/Sliders/Sliders';

type Props = { isDisabled?: boolean };
type State = { isExpanded: boolean };

/**
 *
 */
class VesselTechFilter extends React.PureComponent<Props> {
  private static ACTIVE_KEY = '1';
  static propTypes;
  state: State = { isExpanded: false };

  static getDerivedStateFromProps(props: Props): State {
    return props.isDisabled ? { isExpanded: false } : null;
  }

  handleCollapse = () =>
    this.props.isDisabled ||
    this.setState({
      isExpanded: !this.state.isExpanded
    });

  render() {
    const key = this.state.isExpanded ? VesselTechFilter.ACTIVE_KEY : null;
    const cls = classnames('FilterCollapse', {
      'FilterCollapse--disabled': this.props.isDisabled
    });

    const items: CollapseProps['items'] = [
      {
        key: VesselTechFilter.ACTIVE_KEY,
        label: 'Technical Details',
        children: (
          <>
            <div className="BasicFilter__row">
              <label className="TechFilter__label">ENGINE MODEL</label>
              <EnginesFilter />
            </div>
            <Sliders />
          </>
        )
      }
    ];

    return (
      <Collapse
        accordion={true}
        activeKey={key}
        className={cls}
        expandIcon={getExpandIcon}
        items={items}
        onChange={this.handleCollapse}
      />
    );
  }
}

/**/
VesselTechFilter.propTypes = { isExpanded: PropTypes.bool };

export { VesselTechFilter };
