import { createAsyncActions, TNotificationsState } from 'store';

enum ENotificationActions {
  FETCH_NOTIFICATIONS = 'notifications/FETCH_NOTIFICATIONS',
  MARK_AS_READ = 'notifications/MARK_AS_READ'
}

export const fetchSet = createAsyncActions<TNotificationsState>(
  ENotificationActions.FETCH_NOTIFICATIONS
);

export const markAsReadSet = createAsyncActions<TNotificationsState>(
  ENotificationActions.MARK_AS_READ
);
