import React from 'react';

import './ReportPagePlaceholder.scss';
import { PLACEHOLDER } from 'other';

/**
 *
 */
function ReportPageSizeWarning() {
  return (
    <div className="app-page-text ReportPagePlaceholder">
      <div className="ReportPagePlaceholder__container">
        <div className="ReportPagePlaceholder__content">
          <img
            className="ReportPagePlaceholder__placeholder"
            src={PLACEHOLDER.SIZE_WARNING}
          />

          <h2 className="app-h2 ReportPagePlaceholder__title">
            Your screen size is too small
          </h2>

          <p className="app-body-2 ReportPagePlaceholder__text">
            This feature not available on mobile app. Please use desktop or
            tablet version.
          </p>
        </div>
      </div>
    </div>
  );
}

/**
 *
 */
export { ReportPageSizeWarning };
