import React, { ReactElement } from 'react';
import classnames from 'classnames';
import Drawer, { DrawerProps } from 'antd/lib/drawer';

import './BottomDrawer.scss';

/**/
type Props = Omit<DrawerProps, 'className | rootClassName | title'> & {
  button: ReactElement;
  className?: string;
  toggleClose: () => void;
};

/**
 *
 */
function BottomDrawer({ button, children, className, open, ...rest }: Props) {
  const cls = classnames('BottomDrawer__root', className);

  return (
    <section className="BottomDrawer">
      <div className="BottomDrawer__wrapper">
        {button}

        <Drawer
          className="BottomDrawer__container"
          closable={false}
          destroyOnClose={true}
          open={open}
          placement="bottom"
          rootClassName={cls}
          title={null}
          {...rest}
        >
          {children}
        </Drawer>
      </div>
    </section>
  );
}

/**/
export { BottomDrawer };
