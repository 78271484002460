import { GoogleProvider } from 'leaflet-geosearch';
import { GOOGLE_MAP_KEY } from '../other';

const locationService = new GoogleProvider({ params: GOOGLE_MAP_KEY });

function getCenter(locArr) {
  if (!locArr || !locArr.length) return;

  const arr = locArr.filter(
    ([y, x]) => Number.isFinite(x) && Number.isFinite(y)
  );

  const sumY = arr.reduce((sum, curr) => sum + curr[0], 0);
  const sumX = arr.reduce((sum, curr) => sum + curr[1], 0);
  return [sumY / arr.length, sumX / arr.length];
}

function buildLocationQueries(addresses, force = false) {
  return addresses.map((addr) => {
    const { address, city, country, latitude, longitude, postcode } = addr;

    if (Number.isFinite(latitude) && Number.isFinite(longitude) && !force) {
      return Promise.resolve([latitude, longitude]);
    }

    const query = `${address}, ${city} ${country} ${postcode}`;

    return locationService.search({ query }).then((result) => {
      if (!result || !result[0]) {
        return Promise.reject(
          'Cannot determine coordinates for the given address.'
        );
      }
      const { x, y } = result[0];
      return [y, x];
    });
  });
}

function handleLocation(locationData) {
  if (!locationData || !locationData[0]) {
    return Promise.reject(
      'Cannot determine coordinates for the given address.'
    );
  }
  const { x, y } = locationData[0];
  return {
    latitude: y,
    longitude: x
  };
}

export function getPositionForAddress(location) {
  const { address, city, country, postcode } = location;
  const query = `${address}, ${city} ${country} ${postcode}`;

  return locationService.search({ query }).then(handleLocation);
}

const DEFAULT_PROVIDER_MAP_CENTER = [52, 2];

export { buildLocationQueries, DEFAULT_PROVIDER_MAP_CENTER, getCenter };
