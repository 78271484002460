import { AbstractTrack } from './AbstractTrack';
import {
  Chunk,
  COLOR_MAP_FISHING,
  ESpeedFishing,
  FISHING_ACTIVITY_RANGE,
  FISHING_SPEED_MAP,
  FISHING_VESSEL_TYPES,
  TFishingTrackOptions
} from './helpers';
import { L, TVesselTrackExt, TVesselTrackPoint } from 'types';

/**
 * Draws a track which segments are colored according to the speed.
 */
export class FishingTrack extends AbstractTrack<TFishingTrackOptions> {
  /**/
  protected static createVisibleChunk(speedRange: number): Chunk {
    return {
      color: COLOR_MAP_FISHING[speedRange],
      data: []
    };
  }

  /**/
  init() {
    this.drawVisible(this.createVisibleChunks());
    this.drawHidden(this.createHiddenChunks());
  }

  /**/
  private defineSpeed(speed: number, vesselType: number): ESpeedFishing {
    if (!FISHING_VESSEL_TYPES.includes(vesselType)) return ESpeedFishing.S0;

    const { fishingActivitySpeed } = this.options;
    const fishingSpeedRange =
      FISHING_SPEED_MAP[vesselType] || FISHING_ACTIVITY_RANGE;

    const min =
      fishingSpeedRange[0] > fishingActivitySpeed[0]
        ? fishingSpeedRange[0]
        : fishingActivitySpeed[0];

    const max =
      fishingSpeedRange[1] < fishingActivitySpeed[1]
        ? fishingSpeedRange[1]
        : fishingActivitySpeed[1];

    return !speed || speed < min || speed > max
      ? ESpeedFishing.S0
      : ESpeedFishing.S1;
  }

  /**/
  public remove() {
    this.tooltip?.remove();
    this.segmentsHidden?.forEach((s) => s.remove());
    this.segmentsVisible?.forEach((s) => s.remove());
  }

  /**/
  private createVisibleChunks(): Chunk[] {
    let currentSpeed;
    let currentChunkIdx = -1;
    const chunks: Chunk[] = [];
    const { path } = this.options;

    path.locations?.forEach(
      (p: TVesselTrackPoint, idx: number, arr: TVesselTrackPoint[]) => {
        const nextPoint = arr[idx + 1];
        const speedRange = this.defineSpeed(
          nextPoint?.speed || p?.speed,
          (path as TVesselTrackExt).vesselType
        );

        // Start a new chunk
        if (currentSpeed !== speedRange) {
          if (currentChunkIdx >= 0) {
            chunks[currentChunkIdx].data.push(p);
          }
          currentSpeed = speedRange;
          currentChunkIdx++;

          const chunk = FishingTrack.createVisibleChunk(speedRange);
          chunk.data[0] = p;
          chunks[currentChunkIdx] = chunk;
        } else {
          chunks[currentChunkIdx].data.push(p);
        }
      }
    );

    return chunks;
  }

  /**/
  protected drawVisible(arr: Chunk[]): void {
    this.segmentsVisible = arr.map(
      (ch: Chunk): L.Polyline =>
        window.L.polyline(this.data2points(ch.data), {
          color: ch.color,
          weight: 2
        }).addTo(this.options.map)
    );
  }
}
