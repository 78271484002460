import React, { FC } from 'react';
import PropTypes from 'prop-types';

import './EntityAuthor.scss';
import { PTCreatedBy, TCreatedBy } from 'types';

type Props = {
  createdBy: TCreatedBy;
};

const EntityAuthor: FC<Props> = ({ createdBy }) => {
  return <div className="EntityAuthor">{createdBy?.firstName} CRM</div>;
};

EntityAuthor.propTypes = {
  createdBy: PropTypes.exact(PTCreatedBy).isRequired
};

export { EntityAuthor };
