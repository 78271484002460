import React, { FC, memo } from 'react';
import Select from 'antd/lib/select';
import { CURRENCY_SYMBOLS } from 'other';

const currencyOptions = Object.entries(CURRENCY_SYMBOLS).map(
  ([code, symbol]) => ({
    label: symbol,
    value: code
  })
);

const Component: FC = (props) => (
  <Select {...props} options={currencyOptions} />
);

export const CurrencySelect = memo(Component);
