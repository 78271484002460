import { ENDPOINTS, FARMS_LOCATIONS_UPDATE_INTERVAL } from 'other';
import {
  EFarmsLocationsActions,
  locationsSet
} from './farmsLocationsConstants';
import { http, THttpResponse } from 'services';
import { updateEntriesByTypeAction } from '../mapEntities/mapEntitiesActions';
import {
  store,
  TAction,
  TFarmsLocationsState,
  TFiltersModel,
  TState
} from 'store';
import { TFarmLocation, TFarmArea, EMarkerCategory } from 'types';

/**
 * Starts interval location retrieves. First stops the previous job if any. If the provider filter
 * is different from its defaults, it means that no farms locations should be shown. So, no task
 * is triggered.
 */
export function watchLocationsAction(preserveData?: boolean) {
  return (dispatch, getState) => {
    const {
      providers: { filterSettings }
    } = getState() as TState;

    dispatch(unwatchLocationsAction(preserveData));

    if (
      filterSettings.mapFilterCategories !== null &&
      filterSettings.isMapFilterSet
    ) {
      return;
    }

    const action: TAction<TFarmsLocationsState, EFarmsLocationsActions> = {
      type: EFarmsLocationsActions.START_WATCHING_LOCATIONS,
      payload: {
        locationTimer: startLocationWatch() as any
      }
    };
    dispatch(action);
  };
}

/**
 * Cancels interval location updates.
 */
export function unwatchLocationsAction(preserveData?: boolean) {
  return (dispatch, getState) => {
    const {
      farmsLocations: { locationTimer }
    } = getState() as TState;

    clearInterval(locationTimer);

    locationTimer &&
      dispatch({
        type: EFarmsLocationsActions.STOP_WATCHING_LOCATIONS,
        payload: {
          locationTimer: null,
          ...(preserveData ? {} : { locations: null })
        }
      });
  };
}

/**
 * Fires interval location fetch task.
 */
export function startLocationWatch() {
  store.dispatch(fetchLocationsAction());
  return setInterval(
    () => store.dispatch(fetchLocationsAction()),
    FARMS_LOCATIONS_UPDATE_INTERVAL
  );
}

/**
 * Fetches locations (map vessel entries) with respect to the filter settings.
 */
export function fetchLocationsAction() {
  return (dispatch, getState) => {
    const { filters } = getState() as TState;
    dispatch(locationsSet.request());

    if (filters.facilityValue === null) {
      return dispatch(
        locationsSet.success({
          areas: [],
          locations: []
        })
      );
    }

    Promise.all([
      http.send(getLocationQuery(ENDPOINTS.FARMS_LOCATION, filters)),
      http.send(getLocationQuery(ENDPOINTS.FARM_AREAS, filters))
    ])
      .then(
        ([loc, area]: [
          THttpResponse<TFarmLocation[]>,
          THttpResponse<TFarmArea[]>
        ]) => {
          const { mapEntities } = getState() as TState;
          const entriesUpdate = [];

          if (mapEntities.cages.length > 0) {
            mapEntities.cages.forEach((l: TFarmLocation) => {
              const updated = loc.data.find(
                (v: TFarmLocation) => v.id === l.id
              );

              if (updated) {
                const target = mapEntities.cages.find(
                  (c: TFarmLocation) => c.id === updated.id
                );

                entriesUpdate.push({
                  ...updated,
                  ...(target['uiState'] && { uiState: target['uiState'] })
                });
              }
            });
          }

          dispatch(
            updateEntriesByTypeAction(entriesUpdate, EMarkerCategory.CAGE)
          );
          dispatch(
            locationsSet.success({
              areas: area.data['features'],
              locations: loc.data
            })
          );
        }
      )
      .catch((e) => dispatch(locationsSet.error(e)));
  };
}

/**
 * Returns a query string with respect to the filter settings.
 */
export function getLocationQuery(
  base: string,
  settings: TFiltersModel
): string {
  const { companyValue, countryValue, facilityValue, fleetValue, specieValue } =
    settings;

  let req = '';

  if (facilityValue && facilityValue.length > 0)
    req += `&typeIds=${facilityValue.join(',')}`;
  if (fleetValue.length > 0) req += `&fleetIds=${fleetValue.join(',')}`;
  if (companyValue.length > 0) req += `&companyIds=${companyValue.join(',')}`;
  if (countryValue.length > 0) req += `&flags=${countryValue.join(',')}`;
  if (specieValue.length > 0) req += `&speciesIds=${specieValue.join(',')}`;

  req = req.slice(1);
  return req ? `${base}?${req}` : base;
}
