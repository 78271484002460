import React, { FC } from 'react';
import PropTypes from 'prop-types';

import './Video.scss';

type Props = {
  className?: string;
  src: string;
  title?: string;
};

const Video: FC<Props> = ({ src, className, title = 'Video' }) => (
  <div className="VideoResponsiveFrame">
    <iframe
      allow="accelerometer; autoplay; fullscreen; picture-in-picture; camera; microphone; display-capture clipboard-write; encrypted-media; gyroscope"
      aria-label="Video"
      className={className}
      height="100%"
      scrolling="no"
      src={src}
      title={title}
      width="100%"
    />
  </div>
);

Video.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  title: PropTypes.string
};

export default Video;
