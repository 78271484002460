import React, { FC } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './ProbabilityIndicator.scss';
import { EProbability } from 'types';

type Props = {
  className?: string;
  probability: EProbability;
};

const ProbabilityIndicator: FC<Props> = ({ probability, className }) => {
  const indicatorClassName = classnames(
    'ProbabilityIndicator',
    `ProbabilityIndicator--${probability}`,
    className
  );

  return <div className={indicatorClassName}>{probability}</div>;
};

ProbabilityIndicator.propTypes = {
  className: PropTypes.string,
  probability: PropTypes.oneOf(Object.values(EProbability)).isRequired
};

export { ProbabilityIndicator };
