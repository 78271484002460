import { connect } from 'react-redux';

import { MultiSelect, selectEngines } from 'components';
import { setFiltersAction } from 'store/filters/filtersActions';
import { TState } from 'store';

const mapStateToProps = ({ filters, dictionaries: { engines } }: TState) => ({
  allOptionLabel: 'All engines',
  options: selectEngines(engines),
  value: filters.engineModelValue
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (value: number[]) =>
    dispatch(setFiltersAction({ engineModelValue: value }))
});

export default connect(mapStateToProps, mapDispatchToProps)(MultiSelect);
