type TFormData = {
  [key: string]: string | string[] | TFormData | TFormData[] | any[];
};

/**
 * Clears form data from empty values, useful for Form.list components.
 * @param formData
 */
export const filterEmptyFormData = (formData: TFormData): TFormData => {
  const reduceFormData = (filteredFormData, key) => {
    const value = formData[key];

    if (!value) return filteredFormData;

    if (Array.isArray(value)) {
      // filters formData items.
      const filteredValue = value.filter(
        (formDataItem) =>
          formDataItem &&
          Object.keys(filterEmptyFormData(formDataItem)).length > 0
      );

      // if the array is empty don't add it to the result.
      if (filteredValue.length > 0) {
        filteredFormData[key] = filteredValue;
      }
      // null value will be rejected by the first check
    } else if (typeof value === 'object') {
      filteredFormData[key] = filterEmptyFormData(value);
    } else {
      filteredFormData[key] = value;
    }

    return filteredFormData;
  };

  return Object.keys(formData).reduce(reduceFormData, {});
};
