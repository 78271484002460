import Modal from 'antd/lib/modal';

import { ENDPOINTS } from 'other';
import { http } from 'services';

export const successMessage =
  'Thank you for contacting us. We will get back to you as soon as possible.';

export async function sendRequest(
  serviceProviderId: number
): Promise<undefined> {
  const url = `${ENDPOINTS.SERVICE_PROVIDER}/${serviceProviderId}/contactMe`;
  let errorMessage;

  try {
    await http.send({
      method: 'POST',
      url: url
    });
  } catch ({ message }) {
    errorMessage = message;
  }

  const modalType = errorMessage ? 'error' : 'success';
  const modal = Modal[modalType]({
    content: errorMessage || successMessage,
    centered: true,
    maskClosable: true,
    onCancel: () => modal.destroy(),
    rootClassName: 'ContactUsButton__modal'
  });

  return;
}
