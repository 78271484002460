import React from 'react';
import { NavLink } from 'react-router-dom';

import './AccountLink.scss';
import { IconCustom } from 'components';
import { ROUTES } from 'other';

/**/
type Props = {
  isLoggedIn: boolean;
};

/**
 *
 */
function AccountLink({ isLoggedIn }: Props) {
  const title = isLoggedIn ? 'My account' : 'Log in';
  const route = isLoggedIn ? ROUTES.USER : ROUTES.LOGIN;

  return (
    <NavLink className="app-overline-1 AccountLink" title={title} to={route}>
      <IconCustom className="AccountLink__icon" type="person" />
    </NavLink>
  );
}

/**/
export { AccountLink };
